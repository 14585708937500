import graphql from "babel-plugin-relay/macro";

const AllStatesQuery=graphql`

    query AllStatesQuery{
        allStates{
            id
            name
        }
    }
`

export default AllStatesQuery;
