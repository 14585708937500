import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {connect} from "react-redux";

const PrivateRoute = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render={props => (
            rest.logged_in === false ?
                <Redirect to="/home" />
                : <Component {...props} />
        )} />
    );
};

const mapStateToProps = state => ({
    user_details:state.UserReducer.user_details,
    logged_in:state.UserReducer.logged_in,
});

export default connect(mapStateToProps)(PrivateRoute);

