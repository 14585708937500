import './Footer.scss';
import fb from '../../assets/images/fb.png';
import ind from '../../assets/images/in.png';
import inst from '../../assets/images/instagram.png';
// import logof from '../../assets/images/Logo_footer.png';
// import Logos from '../../assets/images/Logos.png';
// import Logos from "../../assets/icons/Logo.png";
import Logos from "../../assets/images/impacteers-logo.svg"
import logof from "../../assets/images/impacteers-logo.svg"

import mail from '../../assets/images/mail.png';
import phone from '../../assets/images/phone.png'
import twitter from '../../assets/images/twitter.png'
import {Fragment} from "react";
import {LayoutContainer} from "../../Components/LayoutContainer/LayoutContainer";

export const Footer = () => {
    return (
        <div className={"footer-component"} id={"footer-component"}>
            <Fragment>
                <LayoutContainer>
                    <div className="footer_section">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-6 logo">
                                <img src={logof} alt="" className="foot_log0_pic" style={{width:'100%'}}/>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <p className="footer_heading">Trending Courses</p>
                                <div className="footer_subheading">
                                    <p>Profile</p>
                                    <p>My Courses</p>
                                    <p>Payment History</p>
                                    <p>Consultation</p>
                                    <p>Reviews</p>
                                    <p>Referrals & Coupons</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 ">
                                <p className="footer_heading">Quick Links</p>
                                <div className="footer_subheading">
                                    <p>Profile</p>
                                    <p>My Courses</p>
                                    <p>Payment History</p>
                                    <p>Consultation</p>
                                    <p>Reviews</p>
                                    <p>Referrals & Coupons</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <p className="footer_heading">Connect with us</p>
                                <div className="d-flex">
                                    <img src={phone} alt="..." className="sub_logo "/>
                                    <div className="logo_relative mx-2">
                                        <p>9999999999</p>
                                    </div>
                                </div>
                                <div className="d-flex my-3">
                                    <img src={mail} alt="..." className="sub_logo "/>
                                    <div className="logo_relative mx-2">
                                        <p>E-mailk</p>
                                    </div>
                                </div>
                                <div className="d-flex my-3">
                                    <img src={fb} alt="..." className="sub_logo "/>
                                    <div className="logo_relative mx-2">
                                        <p>Facebook</p>
                                    </div>
                                </div>
                                <div className="d-flex my-3">
                                    <img src={twitter} alt="..." className="sub_logo "/>
                                    <div className="logo_relative mx-2">
                                        <p>Twitter</p>
                                    </div>
                                </div>
                                <div className="d-flex my-3">
                                    <img src={ind} alt="..." className="sub_logo "/>
                                    <div className="logo_relative mx-2">
                                        <p>Linkedin</p>
                                    </div>
                                </div>
                                <div className="d-flex my-3">
                                    <img src={inst} alt="..." className="sub_logo "/>
                                    <div className="logo_relative mx-2">
                                        <p>Instagram</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </LayoutContainer>
                <div className="container footer_section_mob">
                    <div className="row">
                        <div className="col-lg-3 col-md-3 my-3">
                            <img src={Logos} alt="" className="foot_logo_pic"/>
                        </div>
                        <div className="mobile_subheading">

                            <ul>
                                <li className="mob ">Profile</li>
                                <li className="mob ">Your cart</li>
                                <li className="mob ">Wishlist</li>
                                <li className="mob ">Courses</li>
                                <li className="mob "> Professors</li>
                                <li className="mob ">Blogs</li>
                                <li className="mob ">About us</li>
                                <li className="mob ">Contact us</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Fragment>
        </div>
    );
}
