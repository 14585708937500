import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'

const mutation = graphql`
     mutation CreateConsultationMutation(
         $collegeName:String!
         $degreeType: DegreeTypeEnum!
         $email:String!
         $instructorId:String!
         $name:String!
         $mobileNumber:String!
         $isWorking: Boolean!
         $yearOfGraduation:String!
         $interest:String!
         $message:String!
         $fieldOrMajor:String!
         $organization:String
         $location:String
         $designation:String
    )
    {
        createConsultation(collegeName:$collegeName,
            degreeType:$degreeType,
            email:$email,
            instructorId:$instructorId,
            name:$name,
            mobileNumber:$mobileNumber,
            isWorking:$isWorking,
            yearOfGraduation:$yearOfGraduation,
            interest:$interest,
            message:$message,
            fieldOrMajor:$fieldOrMajor,
            organization:$organization,
            location:$location,
            designation:$designation
        ){
            status
            message
        }
    }
`

export default (userData, callback,errCallback) => {
    const variables = {
        email: userData.email,
        name:userData.name,
        mobileNumber:userData.mobileNumber,
        collegeName:userData.collegeName,
        degreeType:userData.degreeType,
        instructorId:userData.instructorId,
        isWorking:userData.isWorking,
        yearOfGraduation:userData.yearOfGraduation,
        interest:userData.interest,
        message:userData.message,
        fieldOrMajor:userData.fieldOrMajor,
        organization:userData.organization,
        designation:userData.designation,
        location:userData.location
    }

    // 5
    commitMutation(
        environment,
        {
            mutation,
            variables,
            // 6
            onCompleted: (response,err) => {

                if(response.createConsultation !== null){
                    callback(response)
                } else{
                    errCallback(err[0].message);
                }

            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}