import "./VideoPlayer.scss";
import {MDBBox} from "mdbreact";
import { Player } from 'video-react';

import PlayIcon from "../../assets/images/ant-design_play-circle-filled.svg";
// import {useState} from "react";

export const VideoPlayer = (props) => {

    // const [isplaying,setIsPlaying]=useState(false)
        const onVideoPlay=()=>{
            if(props.hidePreviewContent){
                let previewcard = document.getElementById("previewcard");
                previewcard.style.display = "none";
            }
            // readLessRef.style.display = "flex";
        }
        const onVideoPause=()=>{
            if(props.hidePreviewContent){
                let previewcard = document.getElementById("previewcard");
                previewcard.style.display = "block";
            }
        }
    return(
        <MDBBox className={"video-player-component"}>
            {
                props.videoUrl &&
                <Player
                    fluid={false}
                    // width={window.innerWidth} height={window.innerHeight * 0.85}
                    playsInline
                    poster={PlayIcon}
                    onPause={onVideoPause}
                    onPlay={onVideoPlay}
                    src={props.videoUrl ?? ''}
                    // src={props.videoUrl && props.videoUrl}
                />
            }

        </MDBBox>
    );
}
