// Layout & Grid Spacing
import React from "react";
import "./LayoutContainer.scss";


export const LayoutContainer = (props) => {
    return(
        <div className="layout-container">
            {props.children}
        </div>
    );
}
