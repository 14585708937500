import graphql from "babel-plugin-relay/macro";
const StudentWordsQuery=graphql`

    query StudentWordsQuery{
        studentWords{
            edges {
                node {
                    name
                    description
                    image
                    isActive
                    id
                }
            }
        }
    }

`
export default StudentWordsQuery