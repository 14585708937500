/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DegreeTypeEnum = "Masters" | "Others" | "PG" | "PhD" | "UG" | "%future added value";
export type GenderTypeEnum = "Female" | "Male" | "Others" | "%future added value";
export type UserGender = "FEMALE" | "MALE" | "OTHERS" | "%future added value";
export type CompleteProfileMutationVariables = {|
  collegeName: string,
  courseName: string,
  dateOfBirth: any,
  degreeType: DegreeTypeEnum,
  gender: GenderTypeEnum,
  name: string,
  organizationName?: ?string,
  pincode?: ?string,
  userId: string,
  workExperience?: ?string,
  workHistory: boolean,
  yearOfGraduation: any,
|};
export type CompleteProfileMutationResponse = {|
  +completeProfile: ?{|
    +status: ?string,
    +message: ?string,
    +token: ?string,
    +refreshToken: ?string,
    +user: ?{|
      +id: string,
      +lastLogin: ?any,
      +email: string,
      +mobileNumber: ?string,
      +name: ?string,
      +dob: ?any,
      +gender: ?UserGender,
      +referalCode: string,
      +pincode: ?string,
      +isActive: boolean,
      +isAdmin: boolean,
      +isInstructor: boolean,
      +wishlist: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string
          |}
        |}>
      |},
    |},
  |}
|};
export type CompleteProfileMutation = {|
  variables: CompleteProfileMutationVariables,
  response: CompleteProfileMutationResponse,
|};
*/


/*
mutation CompleteProfileMutation(
  $collegeName: String!
  $courseName: String!
  $dateOfBirth: Date!
  $degreeType: DegreeTypeEnum!
  $gender: GenderTypeEnum!
  $name: String!
  $organizationName: String
  $pincode: String
  $userId: String!
  $workExperience: String
  $workHistory: Boolean!
  $yearOfGraduation: Date!
) {
  completeProfile(name: $name, dateOfBirth: $dateOfBirth, gender: $gender, pincode: $pincode, collegeName: $collegeName, yearOfGraduation: $yearOfGraduation, degreeType: $degreeType, courseName: $courseName, workHistory: $workHistory, organizationName: $organizationName, workExperience: $workExperience, userId: $userId) {
    status
    message
    token
    refreshToken
    user {
      id
      lastLogin
      email
      mobileNumber
      name
      dob
      gender
      referalCode
      pincode
      isActive
      isAdmin
      isInstructor
      wishlist {
        edges {
          node {
            id
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "collegeName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "courseName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dateOfBirth"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "degreeType"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "gender"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pincode"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workExperience"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workHistory"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "yearOfGraduation"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "collegeName",
        "variableName": "collegeName"
      },
      {
        "kind": "Variable",
        "name": "courseName",
        "variableName": "courseName"
      },
      {
        "kind": "Variable",
        "name": "dateOfBirth",
        "variableName": "dateOfBirth"
      },
      {
        "kind": "Variable",
        "name": "degreeType",
        "variableName": "degreeType"
      },
      {
        "kind": "Variable",
        "name": "gender",
        "variableName": "gender"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "organizationName",
        "variableName": "organizationName"
      },
      {
        "kind": "Variable",
        "name": "pincode",
        "variableName": "pincode"
      },
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      },
      {
        "kind": "Variable",
        "name": "workExperience",
        "variableName": "workExperience"
      },
      {
        "kind": "Variable",
        "name": "workHistory",
        "variableName": "workHistory"
      },
      {
        "kind": "Variable",
        "name": "yearOfGraduation",
        "variableName": "yearOfGraduation"
      }
    ],
    "concreteType": "CompleteProfileMutation",
    "kind": "LinkedField",
    "name": "completeProfile",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "refreshToken",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserType",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastLogin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mobileNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dob",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gender",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "referalCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pincode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isActive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isAdmin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isInstructor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CourseTypeConnection",
            "kind": "LinkedField",
            "name": "wishlist",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CourseTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CourseType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompleteProfileMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompleteProfileMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "1834e05badd0ecdcce4802a5aa6ed28b",
    "id": null,
    "metadata": {},
    "name": "CompleteProfileMutation",
    "operationKind": "mutation",
    "text": "mutation CompleteProfileMutation(\n  $collegeName: String!\n  $courseName: String!\n  $dateOfBirth: Date!\n  $degreeType: DegreeTypeEnum!\n  $gender: GenderTypeEnum!\n  $name: String!\n  $organizationName: String\n  $pincode: String\n  $userId: String!\n  $workExperience: String\n  $workHistory: Boolean!\n  $yearOfGraduation: Date!\n) {\n  completeProfile(name: $name, dateOfBirth: $dateOfBirth, gender: $gender, pincode: $pincode, collegeName: $collegeName, yearOfGraduation: $yearOfGraduation, degreeType: $degreeType, courseName: $courseName, workHistory: $workHistory, organizationName: $organizationName, workExperience: $workExperience, userId: $userId) {\n    status\n    message\n    token\n    refreshToken\n    user {\n      id\n      lastLogin\n      email\n      mobileNumber\n      name\n      dob\n      gender\n      referalCode\n      pincode\n      isActive\n      isAdmin\n      isInstructor\n      wishlist {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6748c405c5ad73513629c214e9d16d49';

module.exports = node;
