/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MonthFilterEnum = "CUSTOM" | "LAST_MONTH" | "LAST_SIX_MONTHS" | "LAST_THREE_MONTHS" | "LAST_YEAR" | "%future added value";
export type InstructorDashboardMutationVariables = {|
  monthFilter: MonthFilterEnum,
  startMonth?: ?number,
  startYear?: ?number,
  endMonth?: ?number,
  endYear?: ?number,
  courseId?: ?string,
|};
export type InstructorDashboardMutationResponse = {|
  +instructorDashboard: ?{|
    +consultationGraphData: ?$ReadOnlyArray<?{|
      +count: ?number,
      +createdAt_Date: ?any,
    |}>,
    +enrollmentGraphData: ?$ReadOnlyArray<?{|
      +count: ?number,
      +createdAt_Date: ?any,
    |}>,
    +totalEnrollment: ?number,
    +totalConsultations: ?number,
    +courses: ?$ReadOnlyArray<?{|
      +id: string,
      +name: string,
      +updatedAt: any,
      +enrolledUsers: ?number,
      +averageRating: ?number,
    |}>,
  |}
|};
export type InstructorDashboardMutation = {|
  variables: InstructorDashboardMutationVariables,
  response: InstructorDashboardMutationResponse,
|};
*/


/*
mutation InstructorDashboardMutation(
  $monthFilter: MonthFilterEnum!
  $startMonth: Int
  $startYear: Int
  $endMonth: Int
  $endYear: Int
  $courseId: String
) {
  instructorDashboard(monthFilter: $monthFilter, startMonth: $startMonth, startYear: $startYear, endMonth: $endMonth, endYear: $endYear, courseId: $courseId) {
    consultationGraphData {
      count
      createdAt_Date
    }
    enrollmentGraphData {
      count
      createdAt_Date
    }
    totalEnrollment
    totalConsultations
    courses {
      id
      name
      updatedAt
      enrolledUsers
      averageRating
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "courseId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endMonth"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endYear"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "monthFilter"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startMonth"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startYear"
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "createdAt_Date",
    "storageKey": null
  }
],
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "courseId",
        "variableName": "courseId"
      },
      {
        "kind": "Variable",
        "name": "endMonth",
        "variableName": "endMonth"
      },
      {
        "kind": "Variable",
        "name": "endYear",
        "variableName": "endYear"
      },
      {
        "kind": "Variable",
        "name": "monthFilter",
        "variableName": "monthFilter"
      },
      {
        "kind": "Variable",
        "name": "startMonth",
        "variableName": "startMonth"
      },
      {
        "kind": "Variable",
        "name": "startYear",
        "variableName": "startYear"
      }
    ],
    "concreteType": "InstructorDashBoard",
    "kind": "LinkedField",
    "name": "instructorDashboard",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GraphDataType",
        "kind": "LinkedField",
        "name": "consultationGraphData",
        "plural": true,
        "selections": (v6/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "GraphDataType",
        "kind": "LinkedField",
        "name": "enrollmentGraphData",
        "plural": true,
        "selections": (v6/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalEnrollment",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalConsultations",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CourseType",
        "kind": "LinkedField",
        "name": "courses",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "enrolledUsers",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "averageRating",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "InstructorDashboardMutation",
    "selections": (v7/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "InstructorDashboardMutation",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "f67b0d6cda17b8437f6312d535ec88d1",
    "id": null,
    "metadata": {},
    "name": "InstructorDashboardMutation",
    "operationKind": "mutation",
    "text": "mutation InstructorDashboardMutation(\n  $monthFilter: MonthFilterEnum!\n  $startMonth: Int\n  $startYear: Int\n  $endMonth: Int\n  $endYear: Int\n  $courseId: String\n) {\n  instructorDashboard(monthFilter: $monthFilter, startMonth: $startMonth, startYear: $startYear, endMonth: $endMonth, endYear: $endYear, courseId: $courseId) {\n    consultationGraphData {\n      count\n      createdAt_Date\n    }\n    enrollmentGraphData {\n      count\n      createdAt_Date\n    }\n    totalEnrollment\n    totalConsultations\n    courses {\n      id\n      name\n      updatedAt\n      enrolledUsers\n      averageRating\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1e8c7b3372bc3fca8e177dafb8dcc151';

module.exports = node;
