import graphql from "babel-plugin-relay/macro";

const CourseListByCategoryQuery=graphql`

 query CourseListByCategoryQuery($id:String!){
    categoryById(id:$id) {
    courses(isActive: true) {
      edges {
        node {
          id
          averageRating
          isWishlist
          name
          price
            image
          discountPrice
          instructor{
            user{
              name
            }
          }
        }
      }
    }
  }
}




`

export default CourseListByCategoryQuery