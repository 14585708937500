import {
  CHECKOUT_BEGIN,
    CHECKOUT_SUCCESS,
    CHECKOUT_FAILURE,
    CLEARCHECKOUT
} from '../Actions/OrderAction';



const initialState = {
    checkout_data:{
       order_data:{},
       rzpOrderId:""
    },
    loading: false,
    error: null
}


const OrderReducer =  (state = initialState,action) => {
    switch (action.type) {


        case CHECKOUT_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                loading: true,
                error: null
            };
        case CHECKOUT_SUCCESS:
            // All done: set loading "false".
            // Also, replace the items with the ones from the server
            return {
                ...state,
                loading: false,
                checkout_data: action.payload.checkout_data
            };
        case CHECKOUT_FAILURE:
            // The request failed. It's done. So set loading to "false".
            // Save the error, so we can display it somewhere.
            // Since it failed, we don't have items to display anymore, so set `items` empty.
            //
            // This is all up to you and your app though:
            // maybe you want to keep the items around!
            // Do whatever seems right for your use case.
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                checkout_data: {
                    order_data:{},
                    rzpOrderId:""
                }
            };
        case CLEARCHECKOUT:
            return {
                checkout_data:{
                    order_data:{},
                    rzpOrderId:""
                },
                loading: false,
                error: null
            }
        default:
            return state;
    }

}

export default OrderReducer;
