import React from "react";
import "./ProfessorProfile.scss";

import {BasicButton} from "../../../Components/Buttons/BasicButton";
import Datetime from 'react-datetime';

import edit from "../../../assets/icons/edit.svg"
import professorpic from "../../../assets/images/professorpic.svg"
import delete_btn from "../../../assets/icons/delete_img.svg"
import green_tic from "../../../assets/icons/akar-icons_circle-check-fill-green.svg"
import line from "../../../assets/icons/Line.svg"
// import {withRouter} from "react-router-dom";


import {useEffect, useState, useRef} from "react";
import {FlatButton} from "../../../Components/Buttons/FlatButton";
import ProfessorPanelIndex from "../ProfessorPanelIndex/ProfessorPanelIndex";
import {fetchQuery} from "relay-runtime";
import environment from "../../../Environment";
import AllStatesQuery from "../../../queries/AllStatesQuery";
import InstructorProfileQuery from "../../../queries/InstructorProfileQuery";
import GetDistrictByStateQuery from "../../../queries/GetDistrictByStateQuery";
import uuid from "react-uuid";
import profile_photo from "../../../assets/images/profile_photo.png";
import white_edit from "../../../assets/images/white_edit.png";
import * as EmailValidator from "email-validator";
import axios from "axios";
import {api} from "../../../serviceApi";
import cogoToast from "cogo-toast";
import UpdateInstructorAboutMutation from "../../../mutations/UpdateInstructorAboutMutation";
import UpdateWorkHistoryMutation from "../../../mutations/UpdateWorkHistoryMutation";
import UpdateEducationHistoryMutation from "../../../mutations/UpdateEducationHistoryMutation";
import {EmployementStatus, Nationality, UserGender,DegreeType} from "../../../util/EnumValues";


import StudentWordsQuery from "../../../queries/StudentWordsQuery";
import InstructorRemoveProfilePictureQuery from "../../../queries/InstructorRemoveProfilePictureQuery";

import { createReactEditorJS } from 'react-editor-js'
import { EDITOR_JS_TOOLS } from "./Constants";
import emptystateimg from "../../../assets/icons/emptystate.svg";
import filledimage from "../../../assets/icons/filledthick.svg";
import {compose} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

const ReactEditorJS = createReactEditorJS()



 const ProfessorProfile = (props) => {

    useEffect(()=>{
        if (props.user_details.isInstructor===false){
            props.history.push('/')
        }
    },[])


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <ProfessorPanelIndex activePage={"profile"}>
            <div className={'professor-profile-component'}>
                <Myself/>
                <WorkHistory/>
                <EducationalHistory/>
                <AboutYourself/>
            </div>
        </ProfessorPanelIndex>
    );
}

const Myself = () => {
    const [editBasicDetail, setEditBasicDetail] = useState(false);
    const [profileDetail, setProfileDetail] = useState({});
    const [stateList, setStateList] = useState([]);
    const [districtList, setDistrictList] = useState([]);
    const [valueErrors, setValueErrors] = useState({});
    const [selectedProfilePic, setSelectedProfilePic] = useState(null);
    const [deleteProfilePic,setDeleteProfilePic]=useState(false)

    useEffect(() => {
        fetchProfilDetails();
        fetchStateDetails();
    }, []);

    useEffect(() => {
        if (profileDetail.instructor) {
            fetchDistrictByState(profileDetail.instructor.state)
        }
    }, [profileDetail]);


    const fetchProfilDetails = () => {
        fetchQuery(environment, InstructorProfileQuery)
            .toPromise()
            .then(response => {
                if (response.myProfile !== null) {
                    setProfileDetail(response.myProfile)
                } else {
                    setProfileDetail({});
                }
            }, err => {
                // console.log(err);
                setProfileDetail({});
            });
    }

    const fetchStateDetails = () => {
        fetchQuery(environment, AllStatesQuery)
            .toPromise()
            .then(response => {
                if (response.allStates !== null) {
                    setStateList(response.allStates)
                } else {
                    setStateList([]);
                }
            }, err => {
                // console.log(err);
                setStateList([]);
            });
    }

    const fetchDistrictByState = (selectedState) => {
        if (selectedState === null) {
            return
        }
        let variables = {
            stateId: selectedState.id
        }
        fetchQuery(environment, GetDistrictByStateQuery, variables)
            .toPromise()
            .then(response => {
                if (response.getDistrictByState !== null) {
                    setDistrictList(response.getDistrictByState)
                } else {
                    setDistrictList([]);
                }
            }, err => {
                setDistrictList([]);
            });
    }
    const fetchprofilipicdelete=()=>{
        fetchQuery(environment,InstructorRemoveProfilePictureQuery)
            .toPromise()
            .then(response => {
                if (response.instructorRemoveProfilePicture !== null) {
                    // setstudentWordsstate(response.studentWords.edges);
                    let tmp_profile_data = {...profileDetail};
                    tmp_profile_data['instructor'] = {
                        ...tmp_profile_data['instructor'],
                        profilePic: null
                    }
                    setProfileDetail(tmp_profile_data);

                } else {
                    // setstudentWordsstate([]);
                }
            }, err => {
                // setstudentWordsstate([])
            });
    }

    const handleInputChanges = (e, feild, nested_object_key, input_type) => {
        if (editBasicDetail) {
            let tmp_profile_data = {...profileDetail};
            if (nested_object_key) {
                let value;
                if (input_type === "number") {
                    value = (e.target.validity.valid || e.target.value === "") ? e.target.value : tmp_profile_data[nested_object_key][feild];
                } else {
                    value = e.target.value;
                }
                tmp_profile_data[nested_object_key] = {
                    ...tmp_profile_data[nested_object_key],
                    [feild]: value
                }
            } else {
                let value;
                if (input_type === "number") {
                    value = (e.target.validity.valid || e.target.value === "") ? e.target.value : tmp_profile_data[feild];
                } else {
                    value = e.target.value;
                }
                tmp_profile_data[feild] = value;
            }
            setProfileDetail(tmp_profile_data);
        }
    }

    const handleStateChange = (e, feild, nested_object_key) => {
        if (editBasicDetail) {
            let tmp_profile_data = {...profileDetail};
            let tmp_selected_state = stateList.find(d => d["id"] === e.target.value);
            if (nested_object_key) {
                tmp_profile_data[nested_object_key] = {
                    ...tmp_profile_data[nested_object_key],
                    [feild]: tmp_selected_state
                }
            } else {
                tmp_profile_data[feild] = tmp_selected_state;
            }
            setProfileDetail(tmp_profile_data);
            fetchDistrictByState(tmp_selected_state);
        }
    }

    const handleDistrictChange = (e, feild, nested_object_key) => {
        if (editBasicDetail) {
            let tmp_profile_data = {...profileDetail};
            let tmp_selected_district = districtList.find(d => d["id"] === e.target.value);
            if (nested_object_key) {
                tmp_profile_data[nested_object_key] = {
                    ...tmp_profile_data[nested_object_key],
                    [feild]: tmp_selected_district
                }
            } else {
                tmp_profile_data[feild] = tmp_selected_district;
            }
            setProfileDetail(tmp_profile_data);
        }
    }

    const handleImageChange = (e) => {
        let tmp_profile_data = {...profileDetail};
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            tmp_profile_data['instructor'] = {
                ...tmp_profile_data['instructor'],
                profilePic: reader.result
            }
            setSelectedProfilePic(file);
            setProfileDetail(tmp_profile_data);
        }
        reader.readAsDataURL(file);
    }


    const submitBasicForm = () => {
        let error = {};
        if (profileDetail.name === "") {
            error = {
                field: "name",
                message: "Please fill name"
            };
            setValueErrors(error);
        } else if (profileDetail.dob === "") {
            error = {
                field: "dob",
                message: "Please choose Date of Birth"
            };
            setValueErrors(error);
        } else if (profileDetail.gender === "") {
            error = {
                field: "gender",
                message: "Please choose gender"
            };
            setValueErrors(error);
        } else if (EmailValidator.validate(profileDetail.email) === false) {
            error = {
                field: "email",
                message: "Please fill valid email"
            };
            setValueErrors(error);
        } else if (profileDetail.mobileNumber === "") {
            error = {
                field: "mobileNumber",
                message: "Please fill mobile number"
            };
            setValueErrors(error);
        } else if (profileDetail.mobileNumber.length < 10) {
            error = {
                field: "mobileNumber",
                message: "mobile number should be 10 digits"
            };
            setValueErrors(error);
        } else if (profileDetail.instructor.nationality === "" || profileDetail.instructor.nationality === null || profileDetail.instructor.nationality === undefined) {
            error = {
                field: "nationality",
                message: "Please choose nationality"
            };
            setValueErrors(error);
        } else if (profileDetail.instructor.state === "" || profileDetail.instructor.state === null || profileDetail.instructor.state === undefined) {
            error = {
                field: "state",
                message: "Please choose state"
            };
            setValueErrors(error);
        } else if (profileDetail.instructor.district === "" || profileDetail.instructor.district === null || profileDetail.instructor.district === undefined) {
            error = {
                field: "district",
                message: "Please choose district"
            };
            setValueErrors(error);
        } else if (profileDetail.pincode === "") {
            error = {
                field: "pincode",
                message: "Please fill pincode"
            };
            setValueErrors(error);
        } else if (profileDetail.pincode.length < 6 || profileDetail.pincode.length > 6) {
            error = {
                field: "pincode",
                message: "Pincode should be 6 digits"
            };
            setValueErrors(error);
        } else if (profileDetail.instructor.experience === "") {
            error = {
                field: "experience",
                message: "experience should be less then 100 digits"
            };
            setValueErrors(error);
        } else if (profileDetail.instructor.employeeStatus === "") {
            error = {
                field: "employeeStatus",
                message: "please fill employee Status"
            };
            setValueErrors(error);
        } else {
            let userData = {
                dateOfBirth: profileDetail.dob,
                districtId: profileDetail.instructor.district.id,
                email: profileDetail.email,
                gender: profileDetail.gender.toLowerCase().replace(/./, c => c.toUpperCase()),
                mobileNumber: profileDetail.mobileNumber,
                name: profileDetail.name,
                nationality: profileDetail.instructor.nationality,
                pincode: profileDetail.pincode,
                stateId: profileDetail.instructor.state.id,
                yearsOfExperience: profileDetail.instructor.experience,
                employementStatus: profileDetail.instructor.employeeStatus

            };
            error = {};
            setValueErrors(error);
            let formData = new FormData();
            formData.append("query", "mutation UpdateInstructorProfileMutation(\n $dateOfBirth:Date!\n $districtId:String\n $email:String!\n $gender:GenderTypeEnum!\n $mobileNumber:String!\n  $name:String!\n $nationality:String!\n $pincode:String!\n $stateId:String \n $yearsOfExperience:Int \n $employementStatus:EmployeeStatusEnumForInstructor \n) {\n     updateInstructorProfile( dateOfBirth:$dateOfBirth districtId:$districtId email:$email gender:$gender phoneNumber:$mobileNumber  name:$name nationality:$nationality pincode:$pincode stateId:$stateId yearsOfExperience:$yearsOfExperience  employementStatus:$employementStatus){     \n   message    }\n  }");
            formData.append("variables", JSON.stringify(userData));
            if (selectedProfilePic) {
                formData.append("image", selectedProfilePic);
            }
            let token = localStorage.getItem('user_token');
            let config;
            if (token !== null && token !== undefined && token !== "") {
                config = {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'JWT ' + token
                    }
                };
            } else {
                config = {
                    headers: {
                        'Accept': 'application/json'
                    }
                };
            }
            axios.post(api, formData, config)
                .then(function (response) {
                    // debugger
                    if (response.data.data.updateInstructorProfile !== null) {
                        cogoToast.success(response.data.data.updateInstructorProfile.message, {position: 'top-center'});
                        setEditBasicDetail(false);
                        if(deleteProfilePic===true){
                            fetchprofilipicdelete()
                        }

                    } else {
                        cogoToast.success("Your Details Not Saved", {position: 'top-center'});
                    }
                })
                .catch(function (err) {
                    cogoToast.error(err, {position: 'top-center'});
                });

        }
    }
    return (
        <div className={"myself"}>
            <div className={'header'}>
                <h3 className={'my-auto'}>Information about yourself</h3>
                <div>
                    {editBasicDetail === false ?
                        <BasicButton handleClick={() => setEditBasicDetail(!editBasicDetail)}>
                            <img src={edit} className={"edit mr-2"} alt={"edit icon"}/>
                            Edit Changes
                        </BasicButton>
                        :
                        <div onClick={() => submitBasicForm()}>
                            <FlatButton>
                                Save Changes
                                <img src={green_tic} alt={green_tic} className={'pl-2'}/>
                            </FlatButton>
                        </div>
                    }
                </div>

            </div>
            <img src={line} alt="" className={'w-100'}/>
            <div className={'myself_body'}>
                <div className={'body_top  w-100 d-flex'}>
                    <div className={'row mx-0 w-50'}>
                        <div className={'col-12 px-0'}>
                            <div className={'d-flex w-80 mb-32px'}>
                                <div className={'field_Name'}>
                                    Full Name
                                </div>
                                <div>
                                    <input
                                        type={'text'}
                                        className={editBasicDetail === true ? "editable" : "not-editable"}
                                        value={profileDetail.name}
                                        onChange={(e) => handleInputChanges(e, "name")}
                                    />
                                    {
                                        valueErrors.field === "name" &&
                                        <p className={"error-message"}>{valueErrors.message}</p>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={'col-12 px-0'}>
                            <div className={'d-flex w-80 mb-32px'}>
                                <div className={'field_Name'}>
                                    Date of Birth
                                </div>
                                <div>
                                    <input
                                        type={'date'}
                                        className={editBasicDetail === true ? "editable" : "not-editable"}
                                        required
                                        value={profileDetail.dob}
                                        onChange={(e) => handleInputChanges(e, "dob")}
                                    />
                                    {
                                        valueErrors.field === "dob" &&
                                        <p className={"error-message"}>{valueErrors.message}</p>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={'col-12 px-0'}>
                            <div className={'d-flex w-80 mb-32px'}>
                                <div className={'field_Name'}>
                                    Email ID
                                </div>
                                <div>
                                    <input
                                        type={'email'}
                                        className={editBasicDetail === true ? "editable" : "not-editable"}
                                        value={profileDetail.email}
                                        onChange={(e) => handleInputChanges(e, "email")}/>
                                    {
                                        valueErrors.field === "email" &&
                                        <p className={"error-message"}>{valueErrors.message}</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'d-flex w-50'}>
                        <div className={'field_Name img_full_name'}>Profile Photo</div>
                        <div className={'w-50'}>
                            <img
                                src={profileDetail.instructor && profileDetail.instructor.profilePic ? profileDetail.instructor.profilePic : professorpic}
                                className={"professorpic"} alt={"professor image"}/>
                            <div className={'button_edit_delete d-flex'}>
                                {
                                    editBasicDetail === true &&
                                    <>
                                        <label htmlFor="file-input">
                                            <div className={'change_button'}>
                                                <img src={white_edit} alt={"edit button"} className={"editWhite"}/>
                                            </div>
                                        </label>
                                        <input className={"code d-none"} id="file-input" type="file"
                                               onChange={(e) => handleImageChange(e)}/>

                                        <div className={'editbutton'} >
                                            <BasicButton handleClick={()=>fetchprofilipicdelete()} >
                                                <img src={delete_btn} alt={"delete button"} className={"delete_btn"}/>
                                            </BasicButton>
                                        </div>
                                    </>

                                }

                            </div>
                        </div>
                    </div>
                </div>
                <div className={'body_bottom'}>
                    <div className={'d-flex mb-32px gap-10'}>
                        <div className={'d-flex w-40'}>
                            <div className={"w-45 field_Name"}>Gender</div>
                            <div>
                                <select className={editBasicDetail === true ? "editable" : "not-editable"}
                                        value={profileDetail.gender && profileDetail.gender.toLowerCase()}
                                        onChange={(e) => handleInputChanges(e, "gender")}
                                        id="gender"
                                >
                                    <option value={""}>--select--</option>
                                    {
                                        UserGender.map((item) => {
                                            return (
                                                <option disabled={!editBasicDetail}
                                                        value={`${item.name.toLowerCase()}`}
                                                        key={uuid()}>
                                                    {item.name}
                                                </option>
                                            );
                                        })
                                    }
                                </select>
                                {
                                    valueErrors.field === "gender" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>
                        </div>
                        <div className={'d-flex w-50'}>
                            <div className={"w-45 field_Name"}>Employment Status</div>
                            <div>
                                <select
                                    className={editBasicDetail === true ? "editable" : "not-editable"}
                                    name="gender"
                                    required
                                    id="gender"
                                    value={profileDetail.instructor && profileDetail.instructor.employeeStatus}
                                    onChange={(e) => handleInputChanges(e, "employeeStatus", "instructor")}
                                >
                                    <option disabled={true}>--select--</option>
                                    {
                                        EmployementStatus.map((item) => {
                                            return (
                                                <option disabled={!editBasicDetail} key={uuid()}
                                                        value={`${item.name}`}>{item.name}</option>
                                            );
                                        })
                                    }
                                </select>
                                {
                                    valueErrors.field === "employementStatus" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={'d-flex mb-32px gap-10'}>
                        <div className={'d-flex  w-40'}>
                            <div className={"w-45 field_Name"}>Phone Number</div>
                            <div>
                                <input
                                    type="text"
                                    pattern="[0-9]*"
                                    maxLength="10"
                                    className={editBasicDetail === true ? "editable" : "not-editable"}
                                    required
                                    value={profileDetail.mobileNumber}
                                    onInput={(e) => handleInputChanges(e, "mobileNumber", null, "number")}
                                    onChange={(e) => handleInputChanges(e, "mobileNumber", null, "number")}
                                />
                                {
                                    valueErrors.field === "mobileNumber" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>
                        </div>
                        <div className={'d-flex  w-50'}>
                            <div className={"w-45 field_Name"}>Years of Experience</div>
                            <div>
                                <input
                                    className={editBasicDetail === true ? "editable" : "not-editable"}
                                    type={'number'}
                                    name="gender"
                                    required
                                    id="gender"
                                    value={profileDetail.instructor && profileDetail.instructor.experience}
                                    onChange={(e) => handleInputChanges(e, "experience", "instructor")}
                                />
                                {
                                    valueErrors.field === "experienceLevel" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={'d-flex mb-32px gap-10'}>
                        <div className={'d-flex   w-40'}>
                            <div className={"w-45 field_Name"}>Nationality</div>
                            <div>
                                <select
                                    className={editBasicDetail === true ? "editable" : "not-editable"}
                                    name="gender"
                                    required
                                    id="gender"
                                    value={profileDetail.instructor && profileDetail.instructor.nationality}
                                    onChange={(e) => handleInputChanges(e, "nationality", "instructor")}>
                                    <option value={""}>--select--</option>
                                    {
                                        Nationality.map((item) => {
                                            return (
                                                <option disabled={!editBasicDetail} value={item.name}
                                                        key={uuid()}>{item.name}</option>
                                            );
                                        })
                                    }
                                </select>
                                {
                                    valueErrors.field === "nationality" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>
                        </div>
                        <div className={'d-flex   w-50'}>
                            <div className={"w-45 field_Name"}>State</div>
                            <div>
                                <select
                                    className={editBasicDetail === true ? "editable" : "not-editable"}
                                    name="gender"
                                    required
                                    // id="gender"
                                    value={profileDetail.instructor && profileDetail.instructor.state && profileDetail.instructor.state.id}
                                    onChange={(e) => handleStateChange(e, "state", "instructor")}
                                >
                                    <option value={""}>--select--</option>
                                    {
                                        stateList.map((item) => {
                                            return (
                                                <option key={uuid()} value={`${item.id}`}>{`${item.name}`}</option>
                                            );
                                        })

                                    }
                                </select>
                                {
                                    valueErrors.field === "state" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={'d-flex mb-32px gap-10'}>
                        <div className={'d-flex  w-40'}>
                            <div className={"w-45 field_Name"}>District</div>
                            <div>
                                <select
                                    className={editBasicDetail === true ? "editable" : "not-editable"}
                                    name="gender"
                                    required
                                    id="gender"
                                    value={profileDetail.instructor && profileDetail.instructor.district && profileDetail.instructor.district.id}
                                    onChange={(e) => {
                                        handleDistrictChange(e, "district", "instructor")
                                    }}
                                >
                                    <option value={""}>--select--</option>
                                    {
                                        districtList.map((item, index) => {
                                            return (
                                                <option
                                                    disabled={!editBasicDetail}
                                                    value={`${item.id}`}
                                                    key={uuid()}>{`${item.name}`}
                                                </option>
                                            );

                                        })

                                    }
                                </select>
                                {
                                    valueErrors.field === "district" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>
                        </div>
                        <div className={'d-flex  w-50'}>
                            <div className={"w-45 field_Name"}>Pincode</div>
                            <div>
                                <input
                                    type="text"
                                    pattern="[0-9]*"
                                    maxLength="6"
                                    className={editBasicDetail === true ? "editable" : "not-editable"}
                                    required
                                    value={profileDetail.pincode}
                                    onInput={(e) => handleInputChanges(e, "pincode", null, "number")}
                                    onChange={(e) => handleInputChanges(e, "pincode", null, "number")}
                                />
                                {
                                    valueErrors.field === "pincode" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

const WorkHistory = () => {
    const [editWorkHistory, setEditWorkHistory] = useState(false)
    const [customMonthFilter,setCustomMonthFilter]=useState(false)
    const [curentlyWorkArray,setCurentlyWorkArray]=useState([])
    const [valueErrors, setValueErrors] = useState({});
    const [workHistoryObj, setWorkHistoryObj] = useState({})
    const workHistoryFormate = {
        name: "",
        Location: "",
        Designation: "",
        YearofJoining: "",
        YearofDeparture: ""
    }

    useEffect(() => {
        fetchProfilDetails();
    }, []);

    const addNewWorkHistory = () => {
        let tmp_work_history = {...workHistoryObj};
        let size = Object.keys(tmp_work_history).length;
        tmp_work_history[size] = workHistoryFormate;
        setWorkHistoryObj(tmp_work_history);
    }

    const fetchProfilDetails = () => {
        fetchQuery(environment, InstructorProfileQuery)
            .toPromise()
            .then(response => {
                if (response.myProfile !== null) {
                    let profileData = response.myProfile;
                    if (profileData.instructor && profileData.instructor.workHistory !== null) {
                        let json = JSON.parse(profileData.instructor.workHistory);
                        let tmp_obj = {};
                        Object.keys(json).map((item,index)=>{
                            let tmp_currently_working;
                            if(json[item].YearofDeparture === "Currently working here"){
                                tmp_currently_working = true;
                            } else if(json[item].YearofDeparture === "" || isDate(json[item].YearofDeparture)){
                                tmp_currently_working = false;
                            }
                            tmp_obj[index] = {
                                name: json[item].name,
                                Location: json[item].Location,
                                Designation: json[item].Designation,
                                YearofJoining: json[item].YearofJoining,
                                YearofDeparture: json[item].YearofDeparture,
                                currentlyWorkingHere:tmp_currently_working

                            }
                        })
                        setWorkHistoryObj(tmp_obj);
                    }
                } else {
                    setWorkHistoryObj({});
                }
            }, err => {
                // console.log(err);
                setWorkHistoryObj({});
            });
    }

    const isDate = (dateStr) => {
        return !isNaN(new Date(dateStr).getDate());
    }
    const handleInputChanges = (e, feild, index) => {
        // debugger
        if (feild==='YearofJoining'||'YearofDeparture' && editWorkHistory){
           // const temp_year =((JSON.stringify(e._d)).slice(1,5))
           //  console.log(JSON.stringify(e._d))
            let temp_year= new Date(e._d).getFullYear()
            let temp_db = {...workHistoryObj}
            temp_db[index][feild] = temp_year;
            setWorkHistoryObj(temp_db);
            // console.log(workHistoryObj)

        }
        else if (editWorkHistory) {
                let temp_db = {...workHistoryObj}
                temp_db[index][feild] = e.target.value;
                setWorkHistoryObj(temp_db);
        }
    }



    const removeWorkHistory = (indexvalue) => {
        let indexvalue_1 = parseInt(indexvalue)
        let tempdeletedb = {...workHistoryObj};
        let new_keys = Object.keys(tempdeletedb).filter((item) => {
            return parseInt(item) !== indexvalue_1
        });
        let new_obj = {};
        new_keys.forEach(key => new_obj[parseInt(key)] = tempdeletedb[parseInt(key)]);
        setWorkHistoryObj(new_obj)
    }

    const submitWorkHistory = () => {
        let edu_temp = JSON.stringify(workHistoryObj);
        let error = {};
        let count = 0;
        Object.keys(workHistoryObj).map((key, value) => {
            // debugger
            if (workHistoryObj && workHistoryObj[key] && workHistoryObj[key].name === "") {
                error = {
                    index: key,
                    field: "name",
                    message: "Please fill name"
                };
                count = count + 1;
                setValueErrors(error);

            } else if (workHistoryObj && workHistoryObj[key] && workHistoryObj[key].Location === "") {
                error = {
                    index: key,
                    field: "Location",
                    message: "Please fill Location"
                };
                setValueErrors(error);
                count = count + 1;

            } else if (workHistoryObj && workHistoryObj[key] && workHistoryObj[key].Designation === "") {
                error = {
                    index: key,
                    field: "Designation",
                    message: "Please fill Designation"
                };
                setValueErrors(error);
                count = count + 1;

            } else if (workHistoryObj && workHistoryObj[key] && workHistoryObj[key].YearofJoining === "") {
                error = {
                    index: key,
                    field: "YearofJoining",
                    message: "Please fill year of joining"
                };
                setValueErrors(error);
                count = count + 1;

            }else if(workHistoryObj && workHistoryObj[key] && workHistoryObj[key].currentlyWorkingHere){
                // debugger
                let temp_db = {...workHistoryObj}
                temp_db[key]['YearofDeparture'] ='Currently working here';
                setWorkHistoryObj(temp_db);
            }
            else if (workHistoryObj && workHistoryObj[key] && workHistoryObj[key].YearofDeparture === "") {
                error = {
                    index: key,
                    field: "YearofDeparture",
                    message: "Please fill Year of Departure"
                };
                setValueErrors(error);
                count = count + 1;
            } else {
                count = count + 0;
            }
        })
        if (count === 0) {
           // debugger
            let edu_temp = JSON.stringify(workHistoryObj);
            let userData = {
                data: edu_temp,
            };
            error = {};
            setValueErrors(error);

            UpdateWorkHistoryMutation(userData, function (response) {
                if (response.updateWorkHistory !== null) {
                    cogoToast.success(response.updateWorkHistory.message, {position: 'top-center'});
                    setEditWorkHistory(false);
                } else {
                    cogoToast.error("something went wrong", {position: 'top-center'});
                }
            }, function (err) {
                cogoToast.error(err, {position: 'top-center'});
            });
        }
    }

    const curentlyworkfun=(index,feild,e)=>{
// debugger
        if (editWorkHistory) {
            let temp_db = {...workHistoryObj}
            temp_db[index][feild] =e;
            setWorkHistoryObj(temp_db);
        }

    }




    return (
        <div className={'workhistory'}>
            <div className={'header'}>
                <h3 className={'my-auto'}>Work History</h3>
                <div>
                    {editWorkHistory === false ?
                        <BasicButton handleClick={() => setEditWorkHistory(true)}>
                            <img src={edit} className={"edit mr-2"} alt={"edit icon"}/>
                            Edit Changes
                        </BasicButton>
                        :
                        <div onClick={() => submitWorkHistory()}>
                            <FlatButton>
                                Save Changes
                                <img src={green_tic} alt={green_tic} className={'pl-2'}/>
                            </FlatButton>
                        </div>
                    }
                </div>
            </div>
            <img src={line} alt="" className={'w-100 px-4'}/>
            {
                Object.keys(workHistoryObj).map((key, index) => {

                    if (workHistoryObj && workHistoryObj[key] && workHistoryObj[key].YearofDeparture ==="Currently working here")
                    {

                        // setCurentlyWorkArray(curentlyWorkArray.filter(element => element !== index));
                    }


                    return (
                        <>
                            <div className={editWorkHistory === false ? "workbody bgfirst" : "workbody"}>
                                <div className={'d-flex  bodycount'}>
                                    <div className={'count'}>{index + 1}</div>
                                    {editWorkHistory === true &&
                                    <>
                                        <div onClick={() => removeWorkHistory(key)}>
                                            <BasicButton>
                                                Remove this employer
                                            </BasicButton>
                                        </div>

                                    </>
                                    }
                                </div>

                                <div className={'d-flex mb-32px insidework'}>
                                    <div className={'w-22_7 field_Name'}>Employer Name</div>
                                    <div className={'w-100'}>
                                        <input
                                            type={'text'}
                                            className={editWorkHistory === true ? "editable customwidth" : "customwidth not-editable"}
                                            value={workHistoryObj && workHistoryObj[key] && workHistoryObj[key].name}
                                            onChange={(e) => handleInputChanges(e, "name", key)}
                                        />
                                        {valueErrors.index === key &&
                                        valueErrors.field === "name" &&
                                        <p className={"error-message"}>{valueErrors.message}</p>
                                        }
                                    </div>
                                </div>
                                <div className={'d-flex mb-32px gap-10'}>
                                    <div className={'d-flex   w-40'}>
                                        <div className={"w-45 field_Name"}>Designation</div>
                                        <div>
                                            <input
                                                type={"text"}
                                                className={editWorkHistory === true? "editable" : "not-editable"}
                                                value={workHistoryObj && workHistoryObj[key] && workHistoryObj[key].Designation}
                                                onChange={(e) => handleInputChanges(e, "Designation", key)}
                                            />
                                            {
                                                valueErrors.index === key &&
                                                valueErrors.field === "Designation" &&
                                                <p className={"error-message"}>{valueErrors.message}</p>
                                            }
                                        </div>
                                    </div>
                                    <div className={'d-flex  w-50'}>
                                        <div className={"w-45 field_Name"}>Location</div>
                                        <input
                                            type={"text"}
                                            className={editWorkHistory === true ? "editable" : "not-editable"}
                                            value={workHistoryObj && workHistoryObj[key] && workHistoryObj[key].Location}
                                            onChange={(e) => handleInputChanges(e, "Location", key)}
                                        />
                                        {valueErrors.index === key &&
                                        valueErrors.field === "Location" &&
                                        <p className={"error-message"}>{valueErrors.message}</p>
                                        }
                                    </div>
                                </div>
                                <div className={'d-flex mb-32px gap-10'}>
                                    <div className={'d-flex  w-40'}>
                                        <div className={"w-45 field_Name"}>Year of Joining</div>
                                        <div>
                                            {/*<input*/}
                                            {/*    className={editWorkHistory === true? "editable" : "not-editable"}*/}
                                            {/*    type={"date"}*/}
                                            {/*    value={workHistoryObj && workHistoryObj[key] && workHistoryObj[key].YearofJoining}*/}
                                            {/*    onChange={(e) => handleInputChanges(e, "YearofJoining", key)}*/}
                                            {/*/>*/}

                                            <Datetime className={editWorkHistory === true? "editable_date" : "not-editable_date"}
                                                      dateFormat="YYYY"
                                                      initialViewMode={"years"}
                                                      inputProps={{readOnly: true}}
                                                      timeFormat={false} closeOnSelect={true}
                                                      onChange={(e) => handleInputChanges(e, "YearofJoining", key)}
                                                      value={new Date(workHistoryObj && workHistoryObj[key] && workHistoryObj[key].YearofJoining.toString())}
                                            />

                                            {valueErrors.index === key &&
                                            valueErrors.field === "YearofJoining" &&
                                            <p className={"error-message"}>{valueErrors.message}</p>
                                            }
                                        </div>
                                    </div>
                                    <div className={'d-flex   w-50'}>
                                        <div className={"w-45 field_Name"}>Year of Departure</div>
                                        <div>
                                            { workHistoryObj && workHistoryObj[key]  &&  workHistoryObj[key].currentlyWorkingHere ?
                                                <input className={editWorkHistory === true ? "editable" : "not-editable"}
                                                type={"text"} readOnly={true}
                                                value={workHistoryObj && workHistoryObj[key]  &&  workHistoryObj[key].currentlyWorkingHere?"Currently working here":workHistoryObj && workHistoryObj[key] && workHistoryObj[key].YearofDeparture}
                                                onChange={(e) => handleInputChanges(e, "YearofDeparture", key)}
                                                />
                                                :
                                                <Datetime dateFormat="YYYY"
                                                className={editWorkHistory === true ? "editable_2" : "not-editable"}
                                                initialViewMode={"years"}
                                                inputProps={{readOnly: true}}
                                                timeFormat={false} closeOnSelect={true}
                                                onChange={(e) => handleInputChanges(e, "YearofDeparture", key)}
                                                value={isDate(workHistoryObj && workHistoryObj[key] && workHistoryObj[key].YearofDeparture) ?
                                                    new Date(workHistoryObj && workHistoryObj[key] && workHistoryObj[key].YearofDeparture.toString()) : new Date() }/>
                                            }
                                            {valueErrors.index === key &&
                                            valueErrors.field === "YearofDeparture" &&
                                            <p className={"error-message"}>{valueErrors.message}</p>
                                            }

                                            {editWorkHistory === true &&
                                                <div onClick={()=>curentlyworkfun(index,'currentlyWorkingHere',!(workHistoryObj && workHistoryObj[key] && workHistoryObj[key] &&  workHistoryObj[key].currentlyWorkingHere))} className={'Currentlyworkinghere'}>
                                                    {/*<img  src={ !(curentlyWorkArray.includes(index)) && !customMonthFilter?emptystateimg:filledimage} className={'Currentlyworkinghere_img'}/>*/}
                                                    <img  src={ !(workHistoryObj && workHistoryObj[key] && workHistoryObj[key] &&  workHistoryObj[key].currentlyWorkingHere) ? emptystateimg:filledimage} className={'Currentlyworkinghere_img'}/>
                                                    {/*{workHistoryObj && workHistoryObj[key]  &&  workHistoryObj[key].currentlyWorkingHere ? "true" :"false"}*/}
                                                    Currently working here
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                index !== Object.keys(workHistoryObj).length-1 &&
                                <>
                                    <img src={line} alt="" className={'w-100'}/>
                                </>
                            }
                        </>
                    );
                })
            }

            {
                editWorkHistory === true &&
                <div className={'addnewbtn'}>
                    <div className={'top_btn'} onClick={()=>addNewWorkHistory()}>
                        <BasicButton>
                        +    Add educational History
                        </BasicButton>
                    </div>
                </div>

            }
        </div>
    );
}

const EducationalHistory = () => {
    const [editEducationalHistory, setEditEducationalHistory] = useState(false)
    const [valueErrors, setValueErrors] = useState({});
    const [educationalHistoryObj, setEducationalHistoryObj] = useState({})
    const educationalHistoryFormate = {
        DegreeType: "",
        Finalpercentage: "",
        YearofGraduaon: "",
        field: "",
        name: ""
    }

    useEffect(() => {
        fetchProfilDetails();
    }, []);

    const addNewEducationHistory = () => {
        let tmp_educational_history = {...educationalHistoryObj};
        let size = Object.keys(tmp_educational_history).length;
        tmp_educational_history[size] = educationalHistoryFormate;
        setEducationalHistoryObj(tmp_educational_history);
    }

    const fetchProfilDetails = () => {
        fetchQuery(environment, InstructorProfileQuery)
            .toPromise()
            .then(response => {
                if (response.myProfile !== null) {
                    let profileData = response.myProfile;
                    if (profileData.instructor && profileData.instructor.education !== null) {
                        let json = JSON.parse(profileData.instructor.education);
                        let tmp_obj = {};
                        Object.keys(json).map((item,index)=>{
                            tmp_obj[index] = {
                                DegreeType: json[item].DegreeType,
                                Finalpercentage: json[item].Finalpercentage,
                                YearofGraduaon: json[item].YearofGraduaon,
                                field: json[item].field,
                                name: json[item].name
                            }
                        })
                        setEducationalHistoryObj(tmp_obj);
                    }
                } else {
                    setEducationalHistoryObj({});
                }
            }, err => {
                // console.log(err);
                setEducationalHistoryObj({});
            });
    }

    const handleInputChanges = (e, feild, index) => {
        if (feild==='YearofGraduaon'){
            let temp_year= new Date(e._d).getFullYear()
            let temp_db = {...educationalHistoryObj}
            temp_db[index][feild] = temp_year;
            setEducationalHistoryObj(temp_db);
        } else if (editEducationalHistory) {
            let temp_db = {...educationalHistoryObj}
            temp_db[index][feild] = e.target.value;
            setEducationalHistoryObj(temp_db);
        }
    }

    const removeWorkHistory = (indexvalue) => {
        let indexvalue_1 = parseInt(indexvalue)
        let tempdeletedb = {...educationalHistoryObj};
        let new_keys = Object.keys(tempdeletedb).filter((item) => {
            return parseInt(item) !== indexvalue_1
        });
        let new_obj = {};
        new_keys.forEach(key => new_obj[parseInt(key)] = tempdeletedb[parseInt(key)]);
        setEducationalHistoryObj(new_obj)
    }

    const submitEducationalHistory = () => {
        let edu_temp = JSON.stringify(educationalHistoryObj);
        let error = {};
        let count = 0;
        Object.keys(educationalHistoryObj).map((key, value) => {
            // debugger
            if (educationalHistoryObj && educationalHistoryObj[key] && educationalHistoryObj[key].name === "") {
                error = {
                    index: key,
                    field: "name",
                    message: "Pleas fill the degree type"
                };
                count = count + 1;
                setValueErrors(error);

            } else if (educationalHistoryObj && educationalHistoryObj[key] && educationalHistoryObj[key].field === "") {
                error = {
                    index: key,
                    field: "field",
                    message: "Please fill the Field or Major "
                };
                count = count + 1;
                setValueErrors(error);

            } else if (educationalHistoryObj && educationalHistoryObj[key] && educationalHistoryObj[key].DegreeType === "") {

                error = {
                    index: key,
                    field: "DegreeType",
                    message: "Please fill Designation"
                };
                count = count + 1;
                setValueErrors(error);

            } else if (educationalHistoryObj && educationalHistoryObj[key] && educationalHistoryObj[key].YearofGraduaon === "") {
                error = {
                    index: key,
                    field: "YearofGraduaon",
                    message: "Please fill year of graduation"
                };
                count = count + 1;
                setValueErrors(error);

            } else if (educationalHistoryObj && educationalHistoryObj[key] && educationalHistoryObj[key].Finalpercentage === "") {
                error = {
                    index: key,
                    field: "Finalpercentage",
                    message: "Please fill year of joining"
                };
                count = count + 1;
                setValueErrors(error);
            } else {
                count = count + 0;
            }
        })
        // debugger
        if (count === 0) {
            let userData = {
                data: edu_temp,
            };
            error = {};
            setValueErrors(error);
            UpdateEducationHistoryMutation(userData, function (response) {
                if (response.updateEducationHistory !== null) {
                    cogoToast.success(response.updateEducationHistory.message, {position: 'top-center'});
                    setEditEducationalHistory(false);
                } else {
                    cogoToast.error("something went wrong", {position: 'top-center'});
                }
            }, function (err) {
                cogoToast.error(err, {position: 'top-center'});
            });
        }
    }

    return (
        <div className={'workhistory'}>
            <div className={'header'}>
                <h3 className={'my-auto'}>Educational History</h3>
                <div>
                    {editEducationalHistory === false ?
                        <BasicButton handleClick={() => setEditEducationalHistory(true)}>
                            <img src={edit} className={"edit mr-2"} alt={"edit icon"}/>
                            Edit Changes
                        </BasicButton>
                        :
                        <div onClick={() => submitEducationalHistory()}>
                            <FlatButton>
                                Save Changes
                                <img src={green_tic} alt={green_tic} className={'pl-2'}/>
                            </FlatButton>
                        </div>
                    }
                </div>
            </div>
            <img src={line} alt="" className={'w-100 px-4'}/>
            {
                Object.keys(educationalHistoryObj).map((key, index) => {
                    return (
                        <>
                            <div className={editEducationalHistory === false ? "workbody bgfirst" : "workbody"}>
                                <div className={'d-flex  bodycount'}>
                                    <div className={'count'}>{index + 1}</div>
                                    {editEducationalHistory === true &&
                                    <>
                                        <div onClick={() => removeWorkHistory(key)}>
                                            <BasicButton>
                                                Remove this employer
                                            </BasicButton>
                                        </div>

                                    </>
                                    }
                                </div>

                                <div className={'d-flex mb-32px insideEdhucation input_parent'}>
                                    <div className={'w-22_7 field_Name'}>University/ School/
                                         College Name
                                    </div>
                                    <div className={'w-82'}>
                                        <input
                                            className={editEducationalHistory === true ? "editable customwidth" : "customwidth not-editable"}
                                            type={'text'}
                                            value={educationalHistoryObj && educationalHistoryObj[key] && educationalHistoryObj[key].name}
                                            onChange={(e) => handleInputChanges(e, "name", key)}
                                        />
                                        {valueErrors.index === key &&
                                        valueErrors.field === "name" &&
                                        <p className={"error-message"}>{valueErrors.message}</p>
                                        }
                                    </div>
                                </div>
                                <div className={'d-flex mb-32px gap-10'}>
                                    <div className={'d-flex input_parent  w-40'}>
                                        <div className={"w-45 field_Name"}>Field or Major</div>
                                        <div className={'w-55'}>
                                            <input
                                                className={editEducationalHistory === true ? "editable" : "not-editable"}
                                                type={"text"}
                                                value={educationalHistoryObj && educationalHistoryObj[key] && educationalHistoryObj[key].field}
                                                onChange={(e) => handleInputChanges(e, "field", key)}
                                            />
                                            {valueErrors.index === key &&
                                            valueErrors.field === "field" &&
                                            <p className={"error-message"}>{valueErrors.message}</p>
                                            }
                                        </div>
                                    </div>
                                    <div className={'d-flex input_parent  w-50'}>
                                        <div className={"w-45 field_Name"}>Degree Type</div>
                                        <select className={editEducationalHistory === true ? "editable w-55" : "w-55 not-editable"}
                                                value={educationalHistoryObj && educationalHistoryObj[key] && educationalHistoryObj[key].DegreeType}
                                                onChange={(e) => handleInputChanges(e, "DegreeType", key)}

                                        >
                                            <option value={""}>--select--</option>
                                            {
                                                DegreeType.map((item) => {
                                                    return (
                                                        <option disabled={!editEducationalHistory} value={item.name}
                                                                key={uuid()}>{item.name}</option>
                                                    );
                                                })
                                            }
                                        </select>
                                        {valueErrors.index === key &&
                                            valueErrors.field === "DegreeType" &&
                                            <p className={"error-message"}>{valueErrors.message}</p>
                                        }

                                    </div>

                                </div>
                                <div className={'d-flex mb-32px gap-10'}>
                                    <div className={'d-flex input_parent w-40'}>
                                        <div className={"w-45 field_Name"}>Year of
                                            Graduation
                                        </div>
                                        <div className={'w-55'}>
                                            {/*<input*/}
                                            {/*    className={editEducationalHistory === true ? "editable" : "not-editable"}*/}
                                            {/*    type={"date"}*/}
                                            {/*    value={educationalHistoryObj && educationalHistoryObj[key] && educationalHistoryObj[key].YearofGraduaon}*/}
                                            {/*    // value={date}*/}
                                            {/*    onChange={(e) => handleInputChanges(e, "YearofGraduaon", key)}*/}
                                            {/*/>*/}
                                            <Datetime dateFormat="YYYY"
                                                      className={editEducationalHistory === true ? "editable_2" : "not-editable"}
                                                      initialViewMode={"years"}
                                                      inputProps={{readOnly: true}}
                                                      timeFormat={false} closeOnSelect={true}
                                                      onChange={(e) => handleInputChanges(e, "YearofGraduaon", key)}
                                                      value={new Date(educationalHistoryObj && educationalHistoryObj[key] && educationalHistoryObj[key].YearofGraduaon.toString())}/>

                                            {valueErrors.index === key &&
                                            valueErrors.field === "YearofGraduaon" &&
                                            <p className={"error-message"}>{valueErrors.message}</p>
                                            }
                                        </div>

                                    </div>
                                    <div className={'d-flex input_parent   w-50'}>
                                        <div className={"w-45 field_Name"}>Final percentage</div>
                                        <div className={'w-55'}>
                                            <input
                                                className={editEducationalHistory === true ? "editable" : "not-editable"}
                                                type={"text"}
                                                value={educationalHistoryObj && educationalHistoryObj[key] && educationalHistoryObj[key].Finalpercentage}
                                                onChange={(e) => handleInputChanges(e, "Finalpercentage", key)}
                                            />
                                            {valueErrors.index === key &&
                                            valueErrors.field === "Finalpercentage" &&
                                            <p className={"error-message"}>{valueErrors.message}</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                index !== Object.keys(educationalHistoryObj).length-1 &&
                                <>
                                    <img src={line} alt="" className={'w-100'}/>
                                </>
                            }
                        </>
                    );
                })
            }

            {
                editEducationalHistory === true &&
                <div className={'addnewbtn'}>
                    <div className={'top_btn'} onClick={()=>addNewEducationHistory()}>
                        <BasicButton>
                            +    Add educational History
                        </BasicButton>
                    </div>
                </div>

            }
        </div>
    );
}

const AboutYourself = () => {
    const [editAboutCourse, setEditAboutCourse] = useState(false)
    const [about, setAbout] = useState({})

    const editorCore = React.useRef(null)
    useEffect(() => {
        if(editorCore.current){
            editorCore.current._editorJS.isReady .then(()=>{
                fetchProfilDetailsabout();
            });
        }
    }, []);


    const fetchProfilDetailsabout = () => {
        fetchQuery(environment, InstructorProfileQuery)
            .toPromise()
            .then(response => {
                // debugger

                if (response.myProfile.instructor && response.myProfile.instructor.aboutMe && response.myProfile.instructor.aboutMe !== null) {
                    let json = JSON.parse(response.myProfile.instructor.aboutMe);
                    setAbout(json);
                    if(json && Object.keys(json).length > 0){
                        // editorCore.current._editorJS.isReady .then(()=>{
                            editorCore.current.clear();
                            editorCore.current.render(json);
                        // });


                        // console.log(response.myProfile.instructor.aboutMe)
                    }
                } else {
                    setAbout({});
                }
            }, err => {
                // console.log(err);
                setAbout({});
            });
    }

    const handleInitialize = React.useCallback((instance) => {
        // debugger
        editorCore.current = instance;

    }, [])



    const handleSave = async () => {
        const savedData = await editorCore.current.save();
        let userData = {
            about: JSON.stringify(savedData)
        };
        UpdateInstructorAboutMutation(userData, function (response) {

            if (response.updateInstructorAbout !== null) {
                setEditAboutCourse(false);
                cogoToast.success(response.updateInstructorAbout.message, {position: 'top-center'});
            } else {
                cogoToast.error("something went wrong", {position: 'top-center'});
            }
        }, function (err) {
            cogoToast.error(err, {position: 'top-center'});
        });

    }

    return (
        <div className={'aboutyourself'}>
            <div className={'header'}>
                <h3 className={'my-auto'}>About Yourself</h3>
                <div >
                    {
                        editAboutCourse === false?
                        <BasicButton handleClick={() => setEditAboutCourse(true)}>
                            <img src={edit} className={"edit mr-2"} alt={"edit icon"}/>
                            Edit Changes
                        </BasicButton>
                        :
                        <FlatButton handleClick={handleSave}>
                            Save Changes
                            <img src={green_tic} alt={green_tic} className={'pl-2'}/>
                        </FlatButton>
                    }
                </div>
            </div>
            <img src={line} alt="" className={'w-100 px-4'}/>

            <div className={editAboutCourse === true?"editable":"not-editable"}>
                <ReactEditorJS
                    tools={EDITOR_JS_TOOLS}
                    // defaultValue={about}
                    // data={about}
                    onInitialize={handleInitialize}
                    // enableReInitialize={true}
                />
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    user_details: state.UserReducer.user_details,
});

export default compose(
    withRouter,connect(mapStateToProps, null)
)(ProfessorProfile);


