import { MDBTable, MDBTableBody, MDBTableHead,MDBModal,MDBModalBody} from 'mdbreact';
// import { MDBModal, MDBModalBody } from 'mdbreact';
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";

import "./Consultations.scss"
import React, {useEffect, useState, useCallback, useRef, createRef} from "react";
import {BasicButton} from "../../../Components/Buttons/BasicButton";
import filter from "../../../assets/icons/filter.svg"
import threedot from "../../../assets/icons/threedot.svg"
import onlyedit from "../../../assets/icons/onlyEdit.svg"
import closebtn from  "../../../assets/icons/closebtnblack.svg"
import modalcofbtn from "../../../assets/images/smallgreen.svg"
import modalrejecbtn from "../../../assets/images/close_bt.png"
import smallred from "../../../assets/images/smallred.svg"
import smallgreen from "../../../assets/images/smallgreen.svg"
import line from "../../../assets/icons/Line.svg"
import ProfessorPanelIndex from "../ProfessorPanelIndex/ProfessorPanelIndex";
import {fetchQuery} from "relay-runtime";
import environment from "../../../Environment";
import getInstructorConsultationsQuery from "../../../queries/GetInstructorConsultationsQuery";
import cogoToast from "cogo-toast";
import ChangeConsultationStatusMutation from "../../../mutations/ChangeConsultationStatusMutation";
import Loader from "../../../Components/Loader/Loader";
import uuid from "react-uuid";
import { useClickOutside} from "../../../Components/ProfessorFilterButton/ProfessorFilterButton";
import {ProfessorFilterButton} from "../../../Components/ProfessorFilterButton/ProfessorFilterButton";
// import {MDBDropdown, MDBDropdownMenu, MDBDropdownToggle,MDBDropdownItem} from "mdb-react-ui-kit";
import {withRouter} from "react-router-dom";
import {compose} from "redux";
import {connect} from "react-redux";

const Consultations=(props)=>{

    useEffect(()=>{
        if (props.user_details.isInstructor===false){
            props.history.push('/')
        }
    },[])

    const [pendingConsultations,setPendingConsultations]=useState([]);
    const [approvedConsultations,setapprovedConsultations]=useState([]);
    const [completedConsultations,setCompletedConsultations]=useState([]);
    const [declinedConsultations,setDeclinedConsultations]=useState([]);
    const [activeTab,setActiveTab]=useState("pending");
    const [activeRowIndex,setActiveRowIndex]=useState();
    const [openActiveRowAction,setOpenActiveRowAction]=useState(false);
    const [viewMoreModal,setViewMoreModal]=useState(false);
    const [selectedConsultation,setSelectedConsultation]=useState({});
    const [openSortByMenu,setOpenSortByMenu]=useState(false);
    const [loading,setLoading]=useState(false);
    const [orderbystate,setorderbystate]=useState('-createdAt')


    useEffect(() => {
        getConsultationList();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const getConsultationList = () => {
        setLoading(true);
        let variables = {
            orderBy:selectedOption.value
        }
        fetchQuery(environment,getInstructorConsultationsQuery,variables)
            .toPromise()
            .then(response => {
                setLoading(false);
                if (response.getInstructorConsultations !== null) {
                    let pending_consultation = response.getInstructorConsultations.edges.filter( function (consultation) {
                        return consultation.node.status === "PENDING"
                    });

                    let approved_consultation = response.getInstructorConsultations.edges.filter( function (consultation) {
                        return consultation.node.status === "APPROVED"
                    });
                    let completed_consultation = response.getInstructorConsultations.edges.filter( function (consultation) {
                        return consultation.node.status === "COMPLETED"
                    });
                    let canceled_consultation = response.getInstructorConsultations.edges.filter( function (consultation) {
                        return consultation.node.status === "REJECTED"
                    });
                    pending_consultation.map((item,index)=>{
                        pending_consultation[index]={...pending_consultation[index],isopen:false}
                    })
                    setPendingConsultations(pending_consultation);
                    setapprovedConsultations(approved_consultation);
                    setCompletedConsultations(completed_consultation);
                    setDeclinedConsultations(canceled_consultation);

                } else {
                    setPendingConsultations([]);
                    setapprovedConsultations([])
                    setCompletedConsultations([]);
                    setDeclinedConsultations([]);

                }
            }, err => {
                setLoading(false);
                setPendingConsultations([]);
                setapprovedConsultations([])
                setCompletedConsultations([]);
                setDeclinedConsultations([]);

            });
    }


    const submitForm = (status,id) => {
        let userData = {
            status: status,
            consultationId:id
        };


        ChangeConsultationStatusMutation(userData, function (response) {
            if (response.changeConsultationStatus !== null) {
                cogoToast.success("status changed successfully", {position: 'top-center'});
                setActiveRowIndex(null);
                setOpenActiveRowAction(false);
                closeViewMoreModal();
                getConsultationList();
            } else {
                cogoToast.error("something went wrong", {position: 'top-center'});
            }
        }, function (err) {
            cogoToast.error(err, {position: 'top-center'});
        });

    }

    const viewMoreData = (item) => {
        setSelectedConsultation(item.node);
        setViewMoreModal(true);
        setActiveRowIndex(null);
        setOpenActiveRowAction(false);
    }

    const closeViewMoreModal = () => {
        setSelectedConsultation({});
        setViewMoreModal(false);
        setOpenActiveRowAction(!openActiveRowAction);

    }

    const [selectedOption,setSelectedOption]=useState({})

    const callback = useCallback((selectedOption) => {
        setSelectedOption(selectedOption);
    }, []);


    useEffect(() => {
            getConsultationList();
    }, [selectedOption]);

    const toggleRowDropDown = (index) => {
        setActiveRowIndex(openActiveRowAction === false?index:null);
        setOpenActiveRowAction(!openActiveRowAction);
        let temparr=[...pendingConsultations]
        temparr[index].isopen=!temparr[index].isopen
        setPendingConsultations(temparr)
    }
    let domNode1=useRef([]);
    domNode1.current = pendingConsultations.map((element, i) => domNode1.current[i] ?? createRef());

    useEffect(()=>{
        let maybrHandler =(e)=>{
            let temparr=[...pendingConsultations]
            pendingConsultations.map((iten,index)=>{
                if (!domNode1.current[index].current.contains(e.target)&&temparr[index].isopen===true){
                    temparr[index].isopen=false
                    setPendingConsultations(temparr)
                    setActiveRowIndex(null);
                    setOpenActiveRowAction(!openActiveRowAction);
                }
            })
        }
        document.addEventListener("mousedown",maybrHandler);
        return ()=>{
            document.removeEventListener("mousedown",maybrHandler)
        }
    })


    return(
        <ProfessorPanelIndex activePage={"consultations"}>
            {
                loading === true &&
                <div>
                    <Loader full={true} />
                </div>
            }
            <div className={'consultations'}>
                <div className={'top_index'}>
                    <div className={activeTab === "pending"?'indexactive':'indexinactive'}
                         onClick={()=>setActiveTab('pending')}>
                        Pending
                    <hr className={activeTab === "pending"?'hilitetitle':'d-none'}/>
                    </div>
                    <div className={activeTab === "Accepted"?'indexactive':'indexinactive'}
                         onClick={()=>setActiveTab('Accepted')}>
                        Accepted
                        <hr className={activeTab === "Accepted"?'hilitetitle':'d-none'}/>
                    </div>
                    <div className={activeTab === "completed"?'indexactive':'indexinactive'}
                         onClick={()=>setActiveTab('completed')}>
                        Completed
                        <hr className={activeTab === "completed"?'hilitetitle':'d-none'}/>
                    </div>
                    <div className={activeTab === "canceled"?'indexactive':'indexinactive'}
                         onClick={()=>setActiveTab('canceled')}>
                        Declined
                        <hr className={activeTab === "canceled"?'hilitetitle':'d-none'}/>
                    </div>
                </div>
                <img src={line} className={'w-100 limeimg'}/>

                <div className={'bodymain'}>
                    <div className={'header mb-2'}>
                        <div className={'my-auto bodymaintitle'}>Consultation Requests</div>
                        <div className={'parent-sort'}>
                            <ProfessorFilterButton  parentCallback={callback} children={'stor by'}/>
                        </div>
                    </div>
                    <MDBTable striped>
                        <MDBTableHead>
                            <tr>
                                <th>Name</th>
                                <th>Field of Interest</th>
                                <th>Email ID</th>
                                <th>Phone Number</th>
                                <th>Actions</th>
                            </tr>
                        </MDBTableHead>
                        {
                            activeTab === "pending" &&
                            <MDBTableBody>
                                {
                                    pendingConsultations.map((item,index)=>{
                                        // debugger
                                        return(
                                            <tr key={uuid()}>
                                                <td>{item.node.name}</td>
                                                <td>{item.node.interest}</td>
                                                <td>{item.node.email}</td>
                                                <td>{item.node.mobileNumber}</td>
                                                <td className={'topdropdown'}
                                                    ref={domNode1.current[index]}
                                                >
                                                    <img src={threedot} alt={'dropdown'} id={`threedot-${index}`} onClick={()=>toggleRowDropDown(index)}/>

                                                    <div  className={activeRowIndex === index && openActiveRowAction ?'dropdownbtn':'d-none'}>
                                                        <div onClick={()=>submitForm('APPROVED',`${item.node.id}`)}>Accept</div>
                                                        <div onClick={()=>submitForm('REJECTED',`${item.node.id}`)}>Decline</div>
                                                        <div onClick={()=>viewMoreData(item)}>
                                                            View more
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                })
                                }
                            </MDBTableBody>
                        }
                        {
                            activeTab==="Accepted" &&
                            <MDBTableBody>
                                {
                                    approvedConsultations.map((item,index)=>{
                                        return(
                                            <tr key={uuid()}>
                                                <td>{item.node.name}</td>
                                                <td>{item.node.interest}</td>
                                                <td>{item.node.email}</td>
                                                <td>{item.node.mobileNumber}</td>
                                                <td className={'topdropdown'} >
                                                    <div onClick={()=>viewMoreData(item)}>
                                                        <span className={'statusCompleted'}>Approved</span>
                                                        <img src={onlyedit} alt={'onlyedit'} className={'pl-2'} />
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </MDBTableBody>

                        }
                        {
                            activeTab === "completed" &&
                            <MDBTableBody>
                                {
                                    completedConsultations.map((item,index)=>{
                                        return(
                                            <tr key={uuid()}>
                                                <td>{item.node.name}</td>
                                                <td>{item.node.interest}</td>
                                                <td>{item.node.email}</td>
                                                <td>{item.node.mobileNumber}</td>
                                                <td className={'topdropdown'} >
                                                    <div onClick={()=>viewMoreData(item)}>
                                                        <span className={'statusCompleted'}>Completed</span>
                                                        <img src={onlyedit} alt={'onlyedit'} className={'pl-2'} />
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </MDBTableBody>
                        }
                        {
                            activeTab === "canceled" &&
                            <MDBTableBody>
                                {
                                    declinedConsultations.map((item,index)=>{
                                        return(
                                            <tr key={uuid()}>
                                                <td>{item.node.name}</td>
                                                <td>{item.node.interest}</td>
                                                <td>{item.node.email}</td>
                                                <td>{item.node.mobileNumber}</td>
                                                <td className={'topdropdown'} >
                                                    <div onClick={()=>viewMoreData(item)}>
                                                        <span className={'statusDecline'}>Declined</span>
                                                        <img src={onlyedit} alt={'onlyedit'} className={'pl-2'}/>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </MDBTableBody>
                        }
                    </MDBTable>
                    <MDBModal isOpen={viewMoreModal} fullHeight position="right">
                        <MDBModalBody>
                            <img src={closebtn} className={'closebtn'} alt={'close button'} onClick={()=>closeViewMoreModal()}/>

                            {
                                activeTab === "pending" &&
                                    <>
                                        <div className={'d-flex justify-content-between detsilscontent'}>
                                            <div className={'modaldetails'}>
                                                <h3 className={'ModaluserName'}>{selectedConsultation.name}</h3>
                                                <p className={'modalusercontact'}>{selectedConsultation.email} | {selectedConsultation.mobileNumber}</p>
                                                <h4 className={'modalinstructorname'}>{selectedConsultation.interest}</h4>
                                            </div>
                                            <div className={'d-flex rcfullcomponent'}>
                                                <div className={'rcname text-center'} onClick={()=>submitForm('COMPLETED',`${selectedConsultation.id}`)}>
                                                    <img src={modalcofbtn} alt={'complete button'} className={'confirmbutton rcbtn'}/><br/>
                                                    Completed
                                                </div>
                                                <div className={'rcname text-center mr-3'}  onClick={()=>submitForm('REJECTED',`${selectedConsultation.id}`)}>
                                                    <img src={modalrejecbtn} alt={'rejuct button'} className={'rejectmbutton rcbtn'}/><br/>
                                                    Declined
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'wordEducationHistory'}>
                                            <h3 className={'modal_insidetitle'}>Educational History</h3>
                                            <p className={'company'}>{selectedConsultation.collegeName}</p>
                                            <p className={'modalcrouse'}>{selectedConsultation.degreeType}</p>
                                            <h4 className={'modalsubcrouse'}>{selectedConsultation.fieldOrMajor} | {selectedConsultation.yearOfGraduation}</h4>
                                            <hr/>
                                            <h3 className={'modal_insidetitle'}>Work History</h3>
                                            <p className={'company'}>{selectedConsultation.organization}</p>
                                            <h4 className={'modalsubcrouse'}>{selectedConsultation.designation} | {selectedConsultation.location}</h4>
                                        </div>
                                        <div className={'modalmessage'}>
                                            <h4 className={'modalinstructorname'}>Message</h4>
                                            <p className={'modalpara'} >{selectedConsultation.message}</p>
                                        </div>
                                    </>
                            }

                            {
                                activeTab === "Accepted" &&
                                <>
                                    <div className={'d-flex justify-content-between detsilscontent'}>
                                        <div className={'modaldetails'}>
                                            <h3 className={'ModaluserName'}>{selectedConsultation.name}</h3>
                                            <p className={'modalusercontact'}>{selectedConsultation.email} | {selectedConsultation.mobileNumber}</p>
                                            <h4 className={'modalinstructorname'}>{selectedConsultation.interest}</h4>
                                        </div>
                                        <div className={'d-flex rcfullcomponent'}>
                                            <div className={'rcname text-center'} onClick={()=>submitForm('COMPLETED',`${selectedConsultation.id}`)}>
                                                <img src={modalcofbtn} alt={'complete button'} className={'confirmbutton rcbtn'}/><br/>
                                                Completed
                                            </div>
                                            <div className={'rcname text-center mr-3'}  onClick={()=>submitForm('REJECTED',`${selectedConsultation.id}`)}>
                                                <img src={modalrejecbtn} alt={'rejuct button'} className={'rejectmbutton rcbtn'}/><br/>
                                                Declined
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'wordEducationHistory'}>
                                        <h3 className={'modal_insidetitle'}>Educational History</h3>
                                        <p className={'company'}>{selectedConsultation.collegeName}</p>
                                        <p className={'modalcrouse'}>{selectedConsultation.degreeType}</p>
                                        <h4 className={'modalsubcrouse'}>{selectedConsultation.fieldOrMajor} | {selectedConsultation.yearOfGraduation}</h4>
                                        <hr/>
                                        <h3 className={'modal_insidetitle'}>Work History</h3>
                                        <p className={'company'}>{selectedConsultation.organization}</p>
                                        <h4 className={'modalsubcrouse'}>{selectedConsultation.designation} | {selectedConsultation.location}</h4>
                                    </div>
                                    <div className={'modalmessage'}>
                                        <h4 className={'modalinstructorname'}>Message</h4>
                                        <p className={'modalpara'} >{selectedConsultation.message}</p>
                                    </div>
                                </>
                            }

                            {
                                activeTab === "completed" &&
                                    <>
                                        <div className={'d-flex justify-content-between detsilscontent'}>
                                            <div className={'modaldetails'}>
                                                <h3 className={'ModaluserName'}>{selectedConsultation.name}</h3>
                                                <p className={'modalusercontact'}>{selectedConsultation.email} | {selectedConsultation.mobileNumber}</p>
                                                <h4 className={'modalinstructorname'}>{selectedConsultation.interest}</h4>
                                            </div>
                                            <div className={'d-flex rcfullcomponent'}>
                                                <div className={'rcname text-center mr-3'}>
                                                    <img src={smallgreen} alt={'rejuct button'} className={'rejectmbutton rbtn'}/>
                                                    Completed
                                                    <img src={onlyedit} className={'modaledit'}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'wordEducationHistory'}>
                                            <h3 className={'modal_insidetitle'}>Educational History</h3>
                                            <p className={'company'}>{selectedConsultation.collegeName}</p>
                                            <p className={'modalcrouse'}>{selectedConsultation.degreeType}</p>
                                            <h4 className={'modalsubcrouse'}>{selectedConsultation.fieldOrMajor} | {selectedConsultation.yearOfGraduation}</h4>
                                            <hr/>
                                            <h3 className={'modal_insidetitle'}>Work History</h3>
                                            <p className={'company'}>{selectedConsultation.organization}</p>
                                            <h4 className={'modalsubcrouse'}>{selectedConsultation.designation} | {selectedConsultation.location}</h4>
                                        </div>
                                        <div className={'modalmessage'}>
                                            <h4 className={'modalinstructorname'}>Message</h4>
                                            <p className={'modalpara'} >{selectedConsultation.message}</p>
                                        </div>
                                    </>
                            }
                            {
                                activeTab === "canceled" &&
                                    <>
                                        <div className={'d-flex justify-content-between detsilscontent'}>
                                            <div className={'modaldetails'}>
                                                <h3 className={'ModaluserName'}>{selectedConsultation.name}</h3>
                                                <p className={'modalusercontact'}>{selectedConsultation.email} | {selectedConsultation.mobileNumber}</p>
                                                <h4 className={'modalinstructorname'}>{selectedConsultation.interest}</h4>
                                            </div>
                                            <div className={'d-flex rcfullcomponent'}>
                                                <div className={'rcname text-center mr-3'}>
                                                    <img src={smallred} alt={'rejuct button'} className={'rejectmbutton rbtn'}/>
                                                    Declined
                                                    <img src={onlyedit} className={'modaledit'}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'wordEducationHistory'}>
                                            <h3 className={'modal_insidetitle'}>Educational History</h3>
                                            <p className={'company'}>{selectedConsultation.collegeName}</p>
                                            <p className={'modalcrouse'}>{selectedConsultation.degreeType}</p>
                                            <h4 className={'modalsubcrouse'}>{selectedConsultation.fieldOrMajor} | {selectedConsultation.yearOfGraduation}</h4>
                                            <hr/>
                                            <h3 className={'modal_insidetitle'}>Work History</h3>
                                            <p className={'company'}>{selectedConsultation.organization}</p>
                                            <h4 className={'modalsubcrouse'}>{selectedConsultation.designation} | {selectedConsultation.location}</h4>
                                        </div>
                                        <div className={'modalmessage'}>
                                            <h4 className={'modalinstructorname'}>Message</h4>
                                            <p className={'modalpara'} >{selectedConsultation.message}</p>
                                        </div>
                                    </>
                            }
                        </MDBModalBody>
                    </MDBModal>

                </div>

            </div>
        </ProfessorPanelIndex>
    );
}

const mapStateToProps = state => ({
    user_details: state.UserReducer.user_details,
});

export default compose(
    withRouter,connect(mapStateToProps, null)
)(Consultations);

