/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveFromWishListQueryVariables = {|
  courseId?: ?string
|};
export type RemoveFromWishListQueryResponse = {|
  +removeFromWishList: ?$ReadOnlyArray<?{|
    +id: string,
    +name: string,
    +instructor: ?{|
      +user: {|
        +name: ?string
      |}
    |},
    +image: ?string,
    +price: any,
    +discountPrice: any,
    +averageRating: ?number,
    +isWishlist: ?boolean,
  |}>
|};
export type RemoveFromWishListQuery = {|
  variables: RemoveFromWishListQueryVariables,
  response: RemoveFromWishListQueryResponse,
|};
*/


/*
query RemoveFromWishListQuery(
  $courseId: String
) {
  removeFromWishList(courseId: $courseId) {
    id
    name
    instructor {
      user {
        name
        id
      }
      id
    }
    image
    price
    discountPrice
    averageRating
    isWishlist
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "courseId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "courseId",
    "variableName": "courseId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountPrice",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "averageRating",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isWishlist",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveFromWishListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CourseType",
        "kind": "LinkedField",
        "name": "removeFromWishList",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "InstructorType",
            "kind": "LinkedField",
            "name": "instructor",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserType",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveFromWishListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CourseType",
        "kind": "LinkedField",
        "name": "removeFromWishList",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "InstructorType",
            "kind": "LinkedField",
            "name": "instructor",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserType",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dda75e142397b4ba6021aa3cb9aabd8b",
    "id": null,
    "metadata": {},
    "name": "RemoveFromWishListQuery",
    "operationKind": "query",
    "text": "query RemoveFromWishListQuery(\n  $courseId: String\n) {\n  removeFromWishList(courseId: $courseId) {\n    id\n    name\n    instructor {\n      user {\n        name\n        id\n      }\n      id\n    }\n    image\n    price\n    discountPrice\n    averageRating\n    isWishlist\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd1ca1fb0b851b30dd6dee06d45491fbb';

module.exports = node;
