import "./CourseDetail.scss";
import {MDBBox} from "mdbreact";
import ProfessorPanelIndex from "../../ProfessorPanelIndex/ProfessorPanelIndex";
import React, {useEffect, useState} from "react";
import leftarrow from "../../../../assets/icons/left_arrow.svg";
import staricon from "../../../../assets/icons/Star.svg";
import usericon from "../../../../assets/icons/user.svg";
import {BasicButton} from "../../../../Components/Buttons/BasicButton";
import black_edit from "../../../../assets/icons/edit.svg";
import crt from "../../../../assets/images/green_crt_btn.png";
import redStar from "../../../../assets/images/redStar.png";
import upload from "../../../../assets/icons/upload.svg";
import filter from "../../../../assets/icons/filter.svg";
import qut from "../../../../assets/images/Group19880.png";
import star from "../../../../assets/images/Star.png";
import eye from "../../../../assets/icons/eye.svg";
import ModulesInfo from "./ModulesInfo";
import {useParams, withRouter} from "react-router-dom";
import {fetchQuery} from "relay-runtime";
import environment from "../../../../Environment";
import CourseByIdQuery from "../../../../queries/CourseByIdQuery";
import CategoryListQuery from "../../../../queries/CategoryListQuery";
import uuid from "react-uuid";
import CourseLevelListQuery from "../../../../queries/CourseLevelListQuery";
import edit from "../../../../assets/icons/edit_white_btn.svg";
import axios from "axios";
import {api} from "../../../../serviceApi";
import cogoToast from "cogo-toast";
import dateFormat from "dateformat";
import Loader from "../../../../Components/Loader/Loader";
import {createReactEditorJS} from 'react-editor-js'
import {EDITOR_JS_TOOLS} from "../../Profile/Constants";
import {compose} from "redux";
import {connect} from "react-redux";
import professorCoursespageState from "../../../../Reducers/ProfessorCoursespageStateReducer";
import {fetchProfessorCoursespageStateReviews} from "../../../../Actions/ProfessorCoursesPageStateAction";
import {
    clearCart,
    fetchCartListBegin,
    fetchCartListFailure,
    fetchCartListSuccess
} from "../../../../Actions/CartAction";
import {fetchWishListSuccess} from "../../../../Actions/wishListAction";
import {checkoutSuccess} from "../../../../Actions/OrderAction";
import ProfessorCoursespageState from "../../../../Reducers/ProfessorCoursespageStateReducer";
// import {withRouter} from "react-router-dom";

const ReactEditorJS = createReactEditorJS()

const CourseDetail = (props) => {

    useEffect(()=>{
        if (props.user_details.isInstructor===false){
            props.history.push('/')
        }
    },[])
    // console.log('professorCoursespageState',props.ProfessorCoursespageState)

    const [editCourseInfo, setEditCourseInfo] = useState(false)
    const [reviwesFilter, setReviweszfilter] = useState(false)
    // const [activeTab, setActiveTab] = useState("course-info");
    const [courseInfo,setCourseInfo] = useState({});
    const [categoryList, setCategoryList] = useState([]);
    const [courseLevelsList, setCourseLevelsList] = useState([]);
    const [valueErrors, setValueErrors] = useState({});
    const [desktopThumbnail,setDesktopThumbnail] = useState(null);
    const [mobileThumbnail,setMobileThumbnail] = useState(null);
    const [moduleList,setModuleList] = useState([]);
    const [reviewList,setReviewList] = useState([]);
    const [loading,setLoading] = useState(false);
    const [count,setcount]=useState(0)
    const [noteEditor,setnoteEditor]=useState({})
    const [aboutEditor,setaboutEditor]=useState({})

    const aboutEditorCore = React.useRef(null);
    const noteEditorCore = React.useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const params = useParams();


    useEffect(()=>{
        setEditorData1()
    },[noteEditor,aboutEditor,noteEditorCore,aboutEditorCore])

    useEffect(function (){
        getCourseById();
        getCategoryList();
        getCourseLevelsList();
    },[count]);

    useEffect(()=>{
        getCourseById();
    },[props.ProfessorCoursespageState === "course-info"])

    const getCourseById = () => {
        let variables = {
            id: params.id
        }
        setLoading(true);
        fetchQuery(environment, CourseByIdQuery, variables)
            .toPromise()
            .then(response => {
                setLoading(false);
                if (response.courseById !== null) {
                    let tmp_res = response.courseById;
                    let  dataFormate = {
                        name:tmp_res.name,
                        price: tmp_res.price,
                        discountPrice:tmp_res.discountPrice,
                        overallPrice:parseInt(tmp_res.price) - parseInt(tmp_res.discountPrice),
                        category: tmp_res.category,
                        level:tmp_res.level,
                        duration: tmp_res.duration,
                        desktopThumbnail: tmp_res.image,
                        mobileThumbnail: tmp_res.mobileImage,
                        about: tmp_res.about,
                        note: tmp_res.Note,
                        averageRating: tmp_res.averageRating,
                        enrolledUsers: tmp_res.enrolledUsers,
                        totalModules:tmp_res.totalModules,
                        totalLectures:tmp_res.totalLectures
                    }

                    //review list
                    let tmp_reviews = tmp_res.reviews.edges;
                    let review_list = [];
                    tmp_reviews.map((item, index) => {
                        review_list[index] = item.node;
                    });

                    setReviewList(review_list);
                    setCourseInfo(dataFormate);
                    setModuleList(tmp_res.modules.edges);
                    let tmp_about = tmp_res.about? JSON.parse(tmp_res.about):{};
                    let tmp_note = tmp_res.Note? JSON.parse(tmp_res.Note):{};
                    // setEditorData(tmp_about,tmp_note);
                    setaboutEditor(tmp_about)
                    setnoteEditor(tmp_note)


                } else {
                    setCourseInfo({});
                    setModuleList([]);
                    setReviewList([]);
                    // setEditorData({},{});
                    setnoteEditor({})
                    setaboutEditor({})
                }
            }, err => {
                setLoading(false);
                setCourseInfo({});
                setModuleList([]);
                setReviewList([]);
                // setEditorData({},{});
                setnoteEditor({})
                setaboutEditor({})
            });
    }

    const setEditorData1 = () => {
        if(Object.keys(aboutEditor).length > 0  ){
            aboutEditorCore && aboutEditorCore.current && Object.keys(aboutEditorCore.current._editorJS).length >0 && aboutEditorCore.current.clear();
            aboutEditorCore && aboutEditorCore.current && Object.keys(aboutEditorCore.current._editorJS).length >0 && aboutEditorCore.current.render(aboutEditor);
        }
        if(Object.keys(noteEditor).length > 0){
            noteEditorCore && noteEditorCore.current && Object.keys(noteEditorCore.current._editorJS).length >0 && noteEditorCore.current.clear();
            noteEditorCore && noteEditorCore.current && Object.keys(noteEditorCore.current._editorJS).length >0 && noteEditorCore.current.render(noteEditor);
        }
    }
    // const setEditorData = (about,note) => {
    //     if(Object.keys(about).length > 0 && count===0){
    //        aboutEditorCore.current.clear();
    //        aboutEditorCore.current.render(about);
    //     }
    //     if(Object.keys(note).length > 0){
    //         noteEditorCore.current.clear();
    //         noteEditorCore.current.render(note);
    //     }
    // }

    const getCategoryList = () => {
        fetchQuery(environment, CategoryListQuery)
            .toPromise()
            .then(response => {
                if (response.categories !== null) {
                    let tmp_courses = response.categories.edges;
                    let course_list = [];
                    tmp_courses.map((item, index) => {
                        course_list[index] = item.node;
                    });
                    setCategoryList(course_list);
                } else {
                    setCategoryList([]);
                }
            }, err => {
                setCategoryList([]);
            });
    }

    const getCourseLevelsList = () => {
        fetchQuery(environment, CourseLevelListQuery)
            .toPromise()
            .then(response => {
                if (response.levels !== null) {
                    let tmp_courses = response.levels.edges;
                    let course_list = [];
                    tmp_courses.map((item, index) => {
                        course_list[index] = item.node;
                    });
                    setCourseLevelsList(course_list);
                } else {
                    setCourseLevelsList([]);
                }
            }, err => {
                setCourseLevelsList([]);
            });
    }

    const GoBack = () => {
        props.history.goBack();
    }

    const handleTextChanges = (e,feild) => {
        let tmp_course_info = {...courseInfo};
        tmp_course_info[feild] = e.target.value;
        setCourseInfo(tmp_course_info);
    }

    const handleSelectChanges = (e,feild,list) => {
        let value = e.target.value;
        let tmp_course_info = {...courseInfo};
        let selected_index = list.findIndex(item => item.id === value);
        if(selected_index > -1){
            tmp_course_info[feild] = list[selected_index];
            setCourseInfo(tmp_course_info);
        }
    }

    const updateCourseIfo = async () => {
        let error = {};
        if (courseInfo.name === "") {
            error = {
                field: "name",
                message: "Please fill course name"
            };
            setValueErrors(error);
        }  else if (courseInfo.price === 0 || courseInfo.price === "") {
            error = {
                field: "price",
                message: "Please fill course price"
            };
            setValueErrors(error);
        } else if (courseInfo.discountPrice === 0 || courseInfo.discountPrice === "") {
            error = {
                field: "discountPrice",
                message: "Please fill course discount price"
            };
            setValueErrors(error);
        }   else if (Object.keys(courseInfo.category).length === 0) {
            error = {
                field: "category",
                message: "Please choose course category"
            };
            setValueErrors(error);
        } else if (Object.keys(courseInfo.level).length === 0) {
            error = {
                field: "level",
                message: "Please choose course level"
            };
            setValueErrors(error);
        } else if (courseInfo.desktopThumbnail === "") {
            error = {
                field: "desktopThumbnail",
                message: "Please choose thumbnail for desktop"
            };
            setValueErrors(error);
        } else if (courseInfo.mobileThumbnail === "") {
            error = {
                field: "mobileThumbnail",
                message: "Please choose thumbnail for mobile"
            };
            setValueErrors(error);
        }
        // else if (courseInfo.about === "") {
        //     error = {
        //         field: "about",
        //         message: "Please fill course about"
        //     };
        //     setValueErrors(error);
        // } else if (courseInfo.note === "") {
        //     error = {
        //         field: "note",
        //         message: "Please fill course note"
        //     };
        //     setValueErrors(error);
        // }
        else {
            error = {};
            setValueErrors(error);
            setLoading(true);
            let aboutSavedData = await aboutEditorCore.current.save();
            let noteSavedData =  await noteEditorCore.current.save();


            let input_data = {
                courseId: params.id,
                about: JSON.stringify(aboutSavedData),
                categoryId: courseInfo.category.id,
                discountPrice: courseInfo.discountPrice,
                duration: courseInfo.duration,
                isActive: true,
                levelId: courseInfo.level.id,
                name: courseInfo.name,
                note: JSON.stringify(noteSavedData),
                price: courseInfo.price
            };
            var data = new FormData();
            data.append("query", "mutation UpdateCourseMutation(\n    $courseId: String!\n    $about: JSONString!\n    $categoryId: String!\n    $discountPrice: String!\n    $duration: String\n    $isActive: Boolean!\n    $levelId: String!\n    $name: String!\n    $note: JSONString!\n    $price: String!\n  ) {\n     updateCourse(\n        courseId:$courseId\n        about: $about\n        categoryId: $categoryId\n        discountPrice: $discountPrice\n        duration: $duration\n        isActive: $isActive\n        levelId: $levelId\n        name: $name\n        note: $note\n        price: $price\n     ){\n        course{\n          id\n          averageRating\n          isWishlist\n          name\n          price\n          image\n          discountPrice\n          enrolledUsers\n          instructor{\n            user{\n              name\n            }\n          }\n        }\n        message\n        status\n     }\n  }");
            data.append("variables", JSON.stringify(input_data));
            if(desktopThumbnail){
                data.append("desktop-image", desktopThumbnail);
            }
            if(mobileThumbnail){
                data.append("mobile-image", mobileThumbnail);
            }

            let token = localStorage.getItem('user_token');

            if (token !== null && token !== undefined && token !== "") {

                var config = {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'JWT ' + token
                    },
                };

            } else {

                var config = {
                    headers: {
                        'Accept': 'application/json'
                    },
                };
            }
            axios.post(api, data, config)
                .then(function (response) {
                    setLoading(false);
                    if (response.data.data.updateCourse) {
                        cogoToast.success("Course Updated Successfully", {position: 'top-center'});
                        setEditCourseInfo(false);
                    }
                })
                .catch(function (err) {
                    setLoading(false);
                    cogoToast.error(err, {position: 'top-center'});
                });
        }

    }

    const handleDurationChange = (e) => {
        let error = {}
        if(isNaN(e.target.value)){
            error = {
                field: "duration",
                message: "Please enter a duration"
            };
            setValueErrors(error);

        } else if (parseInt(e.target.value)%30!==0){
            error = {
                field: "duration",
                message: "duration should be multiples of 30"
            };
            setValueErrors(error);
        } else {
            setValueErrors({});
        }
        let tmp_course_info = {...courseInfo};
        tmp_course_info['duration'] = e.target.value;
        setCourseInfo(tmp_course_info);
    }

    const handleFileChange = (e,feild) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.addEventListener("load", () => {
            let tmp_course_info = {...courseInfo};
            if(feild === "desktop"){
                setDesktopThumbnail(file);
                tmp_course_info['desktopThumbnail'] = reader.result;
                setCourseInfo(tmp_course_info);
            }
            if(feild === "mobile"){
                setMobileThumbnail(file);
                tmp_course_info['mobileThumbnail'] = reader.result;
                setCourseInfo(tmp_course_info);
            }
        });
        reader.readAsDataURL(file);
    }

    const handleAboutInitialize = React.useCallback((instance) => {
        aboutEditorCore.current = instance;
    }, [ReactEditorJS])

    const handleNoteInitialize = React.useCallback((instance) => {
        // debugger
        noteEditorCore.current = instance;
    }, [ReactEditorJS])


    return(
        <ProfessorPanelIndex activePage={"courses"}>
            {
                loading === true &&
                <div>
                    <Loader full={true} />
                </div>
            }
            <MDBBox className={"professor-course-detail-component"}>
                <div className={'main-section'}>
                    <div className={'d-flex align-items-start'}>
                          <span className={'insideCourseBackButton'} onClick={GoBack}>
                            <img src={leftarrow} alt={"arrow"}/>
                          </span>
                        <div className={"heading-section"}>
                            <h4 className={'coursesname m-0'}>{courseInfo.name}</h4>
                            <div className={'title'}>
                                <p className={'heading'}>{courseInfo && courseInfo.category && courseInfo.category.name}</p>
                                <div className={'d-flex align-items-start rating-content'}>
                                    <img src={staricon}/>
                                    <p className={'starvalue'}>{courseInfo.averageRating}</p>
                                    <img src={usericon} className={''}/>
                                    <p className={'uservalue'}>{courseInfo.enrolledUsers}</p>
                                </div>
                            </div>
                        </div>
                        <p className={'coursesLevel'}>{courseInfo && courseInfo.level && courseInfo.level.name}</p>
                    </div>

                    <div className={'subtitles'}>
                        <div
                            onClick={() => props.fetchProfessorCoursespageStateReviews('course-info')}
                            className={props.ProfessorCoursespageState === 'course-info' ? 'enablestate' : 'disabledstate'}
                        >
                            Course info
                            {
                                props.ProfessorCoursespageState === "course-info" && <hr/>
                            }
                        </div>
                        <div className={props.ProfessorCoursespageState === "modules" ? "enablestate" : "disabledstate"}
                             onClick={() => props.fetchProfessorCoursespageStateReviews('modules')}
                        >Modules
                            {
                                props.ProfessorCoursespageState === "modules" && <hr/>
                            }
                        </div>
                        <div className={props.ProfessorCoursespageState === "reviews" ? "enablestate" : "disabledstate"}
                             onClick={() => props.fetchProfessorCoursespageStateReviews('reviews')}
                        >Reviews
                            {
                                props.ProfessorCoursespageState === "reviews" && <hr/>
                            }
                        </div>
                    </div>
                    <hr className={'ml-4'}/>
                    {
                        props.ProfessorCoursespageState === "course-info" &&
                        <div className={'courses-body'}>
                            <div className={'courses-edit-btn'}>
                                {
                                    editCourseInfo === false ?
                                        <BasicButton handleClick={()=>setEditCourseInfo(true)}>
                                            <img src={black_edit} className={'pr-2'}/>
                                            Edit Changes
                                        </BasicButton>
                                    :
                                    <div className={'savebtn'}>
                                        <BasicButton handleClick={updateCourseIfo}>
                                            <span><u>save Changes</u></span>
                                            <img src={crt}className={'pl-2 '}/>
                                        </BasicButton>
                                    </div>
                                }
                            </div>
                            <div className={'form-field'}>
                                <div className="Create">Name of the Course
                                    <span>
                                    <img src={redStar} className="redStar" alt="redStar"/>
                                </span>
                                </div>
                                <input
                                    type="text"
                                    placeholder="Name"
                                    className={`code bg-input ${editCourseInfo === true?"":"disable-pointer"}`}
                                    required
                                    value={courseInfo.name}
                                    onChange={(e)=>handleTextChanges(e,"name")}
                                />
                                {
                                    valueErrors.field === "name" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>

                            <div className={'d-flex prices'}>
                                <div className={'form-field'}>
                                    <div className="Create">Course Price
                                        <span>
                                            <img src={redStar} className="redStar" alt="redStar"/>
                                        </span>
                                    </div>
                                    <input
                                        type="number"
                                        placeholder="Course Price"
                                        className={`code bg-input ${editCourseInfo === true?"":"disable-pointer"}`}
                                        required
                                        value={courseInfo.price}
                                        onChange={(e)=>handleTextChanges(e,"price")}
                                    />
                                    {
                                        valueErrors.field === "price" &&
                                        <p className={"error-message"}>{valueErrors.message}</p>
                                    }
                                </div>
                                <div className={'form-field'}>
                                    <div className="Create">Discount Cost
                                        <span>
                                    <img src={redStar} className="redStar" alt="redStar"/>
                                </span>
                                    </div>
                                    <input
                                        type="number"
                                        placeholder="Discount Cost"
                                        className={`code bg-input ${editCourseInfo === true?"":"disable-pointer"}`}
                                        required
                                        value={courseInfo.discountPrice}
                                        onChange={(e)=>handleTextChanges(e,"discountPrice")}
                                    />
                                    {
                                        valueErrors.field === "discountPrice" &&
                                        <p className={"error-message"}>{valueErrors.message}</p>
                                    }
                                </div>
                                <div className={'form-field'}>
                                    <div className="Create">Overall Price
                                        <span>
                                            <img src={redStar} className="redStar" alt="redStar"/>
                                        </span>
                                    </div>
                                    <input
                                        type="number"
                                        placeholder="Overall Price"
                                        className={`code bg-input disable-pointer`}
                                        required
                                        readOnly={true}
                                        value={courseInfo.overallPrice}
                                    />
                                </div>
                            </div>
                            <div className={'d-flex prices'}>
                                <div className={'form-field'}>
                                    <div className="Create">Category
                                        <span>
                                            <img src={redStar} className="redStar" alt="redStar"/>
                                        </span>
                                    </div>
                                    <select
                                        className={`select1 bg-input ${editCourseInfo === true?"":"disable-pointer"}`}
                                        name="gender"
                                        required
                                        id="category"
                                        value={courseInfo && courseInfo.category && courseInfo.category.id}
                                        onChange={(e)=>handleSelectChanges(e,"category",categoryList)}
                                    >
                                        <option value="" disabled selected>
                                            Select
                                        </option>
                                        {
                                            categoryList.map((item,index)=>{
                                                return(
                                                    <option value={item.id} key={uuid()}>{item.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    {
                                        valueErrors.field === "category" &&
                                        <p className={"error-message"}>{valueErrors.message}</p>
                                    }
                                </div>
                                <div className={'form-field'}>
                                    <div className="Create">Course Level
                                        <span>
                                            <img src={redStar} className="redStar" alt="redStar"/>
                                        </span>
                                    </div>
                                    <select
                                        className={`select1 bg-input ${editCourseInfo === true?"":"disable-pointer"}`}
                                        name="gender"
                                        required
                                        id="level"
                                        value={courseInfo && courseInfo.level && courseInfo.level.id}
                                        onChange={(e)=>handleSelectChanges(e,"level",courseLevelsList)}
                                    >
                                        <option value="" disabled selected>
                                            Select
                                        </option>
                                        {
                                            courseLevelsList.map((item,index)=>{
                                                return(
                                                    <option value={item.id} key={uuid()}>{item.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    {
                                        valueErrors.field === "level" &&
                                        <p className={"error-message"}>{valueErrors.message}</p>
                                    }
                                </div>
                                <div className={'form-field'}>
                                    <div className="Create">Course Duration in days
                                        <span>
                                            <img src={redStar} className="redStar" alt="redStar"/>
                                        </span>
                                    </div>
                                    <input
                                        type="number"
                                        placeholder="Number of days"
                                        className={`code bg-input ${editCourseInfo === true?"":"disable-pointer"}`}
                                        required
                                        value={courseInfo.duration}
                                        onChange={(e)=>handleDurationChange(e)}
                                    />
                                    {
                                        valueErrors.field === "duration" &&
                                        <p className={"error-message"}>{valueErrors.message}</p>
                                    }
                                </div>
                            </div>
                            <div className={'d-flex justify-content-around'} style={{gap:"40px"}}>
                                <>
                                    <div className="d-flex top-form-field">
                                        <div className={'form-field  name-field'}>
                                            Upload the thumbnail
                                            image for Desktop
                                            <img src={redStar} className="textreadStae" alt="redStar"/>
                                        </div>
                                        <div className={'form-field'}>
                                            <label htmlFor="desktop-file-upload"
                                                   className={`custom-file-upload ${editCourseInfo === true?"":"disable-pointer"}`}
                                                   style={{backgroundImage:`url(${courseInfo.desktopThumbnail})`,backgroundSize:"contain"}}
                                            >
                                                {
                                                    editCourseInfo === true &&
                                                    <>
                                                        {
                                                            courseInfo.desktopThumbnail ?
                                                                <MDBBox className={"edit-bar text-center"}>
                                                                    <img src={edit} alt={"edit"}/>
                                                                </MDBBox>
                                                                :
                                                                <div className={'inside_customise'}>
                                                                    <img src={upload} className={'upload pr-2'} alt={'upload'}/>
                                                                    Upload here
                                                                </div>
                                                        }
                                                    </>
                                                }

                                            </label>
                                            <input id="desktop-file-upload" type="file"
                                                   onChange={(e)=>handleFileChange(e,"desktop")}
                                            />
                                        </div>
                                    </div>

                                    {
                                        valueErrors.field === "desktopThumbnail" &&
                                        <p className={"error-message"}>{valueErrors.message}</p>
                                    }
                                </>
                                <>
                                    <div className="d-flex top-form-field">
                                        <div className={'form-field  name-field'}>
                                            Upload the thumbnail
                                            image for Mobile
                                            <img src={redStar} className="textreadStae" alt="redStar"/>
                                        </div>
                                        <div className={'form-field'}>
                                            <label htmlFor="file-upload"
                                                   className={`custom-file-upload ${editCourseInfo === true?"":"disable-pointer"}`}
                                                   style={{backgroundImage:`url(${courseInfo.mobileThumbnail})`,backgroundSize:"contain"}}

                                            >
                                                {
                                                    editCourseInfo === true &&
                                                    <>
                                                        {
                                                            courseInfo.mobileThumbnail ?
                                                                <MDBBox className={"edit-bar text-center"}>
                                                                    <img src={edit} alt={"edit"}/>
                                                                </MDBBox>
                                                                :
                                                                <div className={'inside_customise'}>
                                                                    <img src={upload} className={'upload pr-2'} alt={'upload'}/>
                                                                    Upload here
                                                                </div>
                                                        }
                                                    </>
                                                }
                                            </label>
                                            <input id="file-upload" type="file"
                                                   onChange={(e)=>handleFileChange(e,"mobile")}

                                            />
                                        </div>
                                    </div>

                                    {
                                        valueErrors.field === "mobileThumbnail" &&
                                        <p className={"error-message"}>{valueErrors.message}</p>
                                    }
                                </>

                            </div>


                            <div className={'form-field'}>
                                <div className="Create" >About the Course
                                    <span>
                                    <img src={redStar} className="redStar" alt="redStar"/>
                                </span>
                                </div>
                                {/*<textarea name="" id="" cols="30" rows="10"*/}
                                {/*          className={`bg-input ${editCourseInfo === true?"":"disable-pointer"}`}*/}
                                {/*          placeholder={'Type the about the course'}*/}
                                {/*          value={courseInfo.about}*/}
                                {/*          onChange={(e)=>handleTextChanges(e,"about")}*/}
                                {/*>*/}
                                {/*    */}
                                {/*</textarea>*/}
                                {/*{*/}

                                <ReactEditorJS
                                    tools={EDITOR_JS_TOOLS}
                                    onInitialize={handleAboutInitialize} holder="about-custom"
                                >
                                    <div id="about-custom" className={`bg-input ${editCourseInfo === true?"":"disable-pointer"}`} />
                                </ReactEditorJS>

                                {
                                    valueErrors.field === "about" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>

                            <div className={'form-field'}>
                                <div className="Create">Instructor’s note
                                    <span>
                                    <img src={redStar} className="redStar" alt="redStar"/>
                                </span>
                                </div>
                                {/*<textarea name="" id="" cols="30" rows="10"*/}
                                {/*          className={`bg-input ${editCourseInfo === true?"":"disable-pointer"}`}*/}
                                {/*          placeholder={'Type the Instructor\'s the notes'}*/}
                                {/*          value={courseInfo.note}*/}
                                {/*          onChange={(e)=>handleTextChanges(e,"note")}*/}
                                {/*>*/}
                                {/*</textarea>*/}
                                <ReactEditorJS
                                    tools={EDITOR_JS_TOOLS}
                                    onInitialize={handleNoteInitialize} holder="note-custom"
                                >
                                    <div id="note-custom" className={`bg-input ${editCourseInfo === true?"":"disable-pointer"}`} />
                                </ReactEditorJS>

                                {
                                    valueErrors.field === "note" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>


                        </div>
                    }
                    {
                        props.ProfessorCoursespageState === "modules" &&
                        <ModulesInfo modules={moduleList} course_info={courseInfo} setcount={setcount}/>
                    }
                    {
                        props.ProfessorCoursespageState === "reviews" &&
                        <div className={'activeReviews'}>
                            <div className={'d-flex justify-content-between header'}>
                                <div className={'titleReviews'}>Course Reviews <span>({reviewList.length} Reviews)</span></div>
                                <div className={'filterbtn'} onClick={() => setReviweszfilter(!reviwesFilter)}>
                                    <BasicButton>
                                        <img src={filter} className={'pr-2'}/>
                                        Sort By
                                    </BasicButton>

                                </div>
                                {reviwesFilter ? <div className={'d-flex filterdropdown'}>
                                    <div>New to Oldest</div>
                                    <div>Oldest to New</div>
                                </div> : <>

                                </>}
                            </div>
                            {
                                reviewList.map((item, index) => {
                                    return (
                                        <div className={'bodyreviews mb-3 mt-2'} key={uuid()}>
                                            <div className={'row'}>
                                                <div className={'col-11'}>
                                                    <div className={'d-flex justify-content-between mb-3'}>
                                                        <div className={'reviewname'}>
                                                            <img src={qut} className={'qut'}/> {item.user.name}
                                                        </div>
                                                        <div className={'stardate'}>
                                                            <img src={star}/> {item.rating} <span
                                                            className={'verticalline'}/>
                                                            {
                                                                dateFormat(item.updatedAt, "mmmm dS, yyyy")
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className={'reviewspara'}>
                                                        {
                                                            item.content
                                                        }
                                                    </div>
                                                </div>
                                                <div className={'col-1 m-auto text-center'}>
                                                    <img src={eye}/>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                </div>
            </MDBBox>
        </ProfessorPanelIndex>
    );


}

const mapStateToProps = state => ({
    user_details: state.UserReducer.user_details,
    ProfessorCoursespageState: state.ProfessorCoursespageState.ProfessorCoursesActiveClass,
});
const mapDispatchToProps = dispatch => ({
    fetchProfessorCoursespageStateReviews: (data) => dispatch(fetchProfessorCoursespageStateReviews(data)),

});

export default compose(
    withRouter,connect(mapStateToProps, mapDispatchToProps)
)(CourseDetail);


// export default withRouter(CourseDetail);
