import "./ViewDetails.scss"
import {RoundedButton} from "../../../Components/Buttons/RoundedButton";
import useMediaQuery from "../../../util/useMediaQuery";
import left_yellowarrow from "../../../assets/images/left_yellowarrow.png"
import React, {useEffect, useState} from "react";
import {fetchQuery} from "relay-runtime";
import environment from "../../../Environment";
// import PaymentHistoryListQuery from "../../../Queries/PaymentHistoryListQuery";
import ProfileViewDetailsQuery from "../../../queries/ProfileViewDetailsQuery";
import uuid from 'react-uuid'
import ProfileNavbar from "../ProfileNavbar/ProfileNavbar";
import {useParams} from "react-router-dom";
import Header from "../../../Containers/Header/Header";
import {Footer} from "../../../Containers/Footer/Footer";
import cogoToast from "cogo-toast";
import GenerateInvoiceMutation from "../../../mutations/GenerateInvoiceMutation";

export const ViewDetails=()=>{
    const isDesktop = useMediaQuery("(min-width: 770px)");
    const [orderListbyID,setOrderListbyID]=useState([])
    const [listOfOrders,setlistOfOrders]=useState([])
    const [fulldatewithyear,setfulldatewithyear]=useState({})
    let params = useParams();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const fetchOrderList=()=>{
        const variables={
            orderId:params.id
        }


        fetchQuery(environment,ProfileViewDetailsQuery,variables)
            .toPromise()
            .then(response => {

                if (response.orderById !== null && Object.keys(response.orderById).length > 0) {
                    setOrderListbyID(response.orderById)
                    setlistOfOrders(response.orderById.orderitems)
                } else {
                    setOrderListbyID([])
                }
            }, err => {
                // console.log(err);
                setOrderListbyID([])
            });
    }

    // fetching title list once
    // fetchTitleList()

    useEffect(() => {
        fetchOrderList()
    }, []);
    useEffect(() => {
        if (orderListbyID&&Object.keys(orderListbyID).length>0){
// debugger
                const arrdatetime = (orderListbyID['updatedAt']).split("-");
                const arrdate=(arrdatetime[2]).split("T")
                const date=arrdate[0]
                const fullmonthname = ["January","February","March","April","May","June","July","August","September","October","November","December"];
                const month=arrdatetime[1]
                let monthname = fullmonthname[month-1];
                const year=arrdatetime[0]
                const full_date={date,monthname,year}
                setfulldatewithyear(full_date)
        }

    }, [orderListbyID]);

    // console.log(orderListbyID)



    const getInvoice = (id) => {
        if (id===""){
            cogoToast.error("Some Thing Went Wrong", {position: 'top-center'});
        }
        let variables = {
            orderId: id
        }
        cogoToast.info("Please wait a moment", {position: 'top-center'});
        GenerateInvoiceMutation(variables,function (response){
            if (response.generateInvoice.invoice.invoiceUrl !== null) {
                const fileURL = response.generateInvoice.invoice.invoiceUrl
                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL;
            } else {
                cogoToast.info("Some Thing Went Wrong", {position: 'top-center'});
            }
        },function (err){
            cogoToast.error("Some Thing Went Wrong", {position: 'top-center'});
        })

    }



    return(
        <>
            <Header page={""}/>
            <div className={'ViewDetails'}>
            <div className="container">

                <div className="d-flex justify-content-between first_container">
                    {isDesktop?<></>:<div className={'mb_title'}>
                    <span className={"mb_title_button mr-3"}><img src={left_yellowarrow} /></span>Purchase History
                    </div>}

                    <div className="header">
                        <p className="sub_heading">Order Details</p>
                        <p className="orders">Order No:<span className="orderid">{orderListbyID['orderId']}</span></p>
                        <p className="orders mb_orders">Purchase Date<span className="orderid mb_orderid ">{fulldatewithyear['date']}th,{fulldatewithyear['monthname']} {fulldatewithyear['year']}</span></p>
                        <p className="orders mb_orders">Purchase Total<span className="orderid mb_orderid">₹{orderListbyID['totalPayable']}</span></p>
                    </div>
                    <div onClick={()=>getInvoice(orderListbyID['id'])}>
                        <p className="voice_text"><u>Download Invoice</u></p>
                    </div>
                </div>
                <hr className="higher"/>
                <p className="sub_heading ">Purchased Items  {isDesktop?<></>:<>(3)</>}   </p>

                {/*(Object.values(orderListbyID['orderitems']['edges']))*/}
                {Object.keys(listOfOrders).length>0?
                    Object.entries(listOfOrders['edges']).map((item,index)=> {
                    // console.log(item[1]['node']['course']['instructor']['user']['name'])
                    // console.log(typeof (item))


                        return (
                        <div className="card1 container" key={uuid()}>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="card_head orders">
                                        {item[1]['node']['course']['name']}
                                    </p>
                                </div>
                                <div className={'dk_button'}>
                                    <RoundedButton>
                                        View Course
                                    </RoundedButton>
                                </div>
                            </div>
                            <hr className="higher"/>
                            <div className="d-flex justify-content-between sector_flex">
                                <div className="professor_text">
                                    <p className="text_para text_para_1">Instructor</p>
                                    <p className="text_paras">
                                        {item[1]['node']['course']['instructor']['user']['name']}
                                    </p>
                                </div>
                                <div className="professor_text">
                                    <p className="text_para">Purchase Date</p>
                                    <p className="text_paras">{fulldatewithyear['date']}th,{fulldatewithyear['monthname']} {fulldatewithyear['year']}</p>
                                </div>
                                <div className="professor_text">
                                    <p className="text_para">Expired Date</p>
                                    <p className="text_paras">7th, March 2021</p>
                                </div>
                                <div className="professor_text">
                                    <p className="text_para">Course Price</p>
                                    <p className="text_paras">₹
                                        {item[1]['node']['course']['price']}
                                    </p>
                                </div>
                                <div className={'dk_button1'}>
                                    <RoundedButton>
                                        View Course
                                    </RoundedButton>
                                </div>
                            </div>
                        </div>


                    );
                    })
                    :
                    <></>
                }

            </div>
        </div>
            <Footer/>
        </>

    )
}
