/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserGender = "FEMALE" | "MALE" | "OTHERS" | "%future added value";
export type RegisterUserMutationVariables = {|
  email: string,
  mobileNumber: string,
  password: string,
  passwordConfirm: string,
  referedBy?: ?string,
|};
export type RegisterUserMutationResponse = {|
  +registerUser: ?{|
    +status: ?string,
    +message: ?string,
    +user: ?{|
      +id: string,
      +lastLogin: ?any,
      +email: string,
      +mobileNumber: ?string,
      +name: ?string,
      +dob: ?any,
      +gender: ?UserGender,
      +referalCode: string,
      +pincode: ?string,
      +isActive: boolean,
      +isAdmin: boolean,
      +isInstructor: boolean,
      +wishlist: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string
          |}
        |}>
      |},
    |},
  |}
|};
export type RegisterUserMutation = {|
  variables: RegisterUserMutationVariables,
  response: RegisterUserMutationResponse,
|};
*/


/*
mutation RegisterUserMutation(
  $email: String!
  $mobileNumber: String!
  $password: String!
  $passwordConfirm: String!
  $referedBy: String
) {
  registerUser(mobileNumber: $mobileNumber, email: $email, password: $password, passwordConfirm: $passwordConfirm, referedBy: $referedBy) {
    status
    message
    user {
      id
      lastLogin
      email
      mobileNumber
      name
      dob
      gender
      referalCode
      pincode
      isActive
      isAdmin
      isInstructor
      wishlist {
        edges {
          node {
            id
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "mobileNumber"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "password"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "passwordConfirm"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "referedBy"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "mobileNumber",
        "variableName": "mobileNumber"
      },
      {
        "kind": "Variable",
        "name": "password",
        "variableName": "password"
      },
      {
        "kind": "Variable",
        "name": "passwordConfirm",
        "variableName": "passwordConfirm"
      },
      {
        "kind": "Variable",
        "name": "referedBy",
        "variableName": "referedBy"
      }
    ],
    "concreteType": "RegisterUserMutation",
    "kind": "LinkedField",
    "name": "registerUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserType",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastLogin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mobileNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dob",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gender",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "referalCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pincode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isActive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isAdmin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isInstructor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CourseTypeConnection",
            "kind": "LinkedField",
            "name": "wishlist",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CourseTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CourseType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RegisterUserMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RegisterUserMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "6d23183f1bb10b2d81921bfdb3a420ce",
    "id": null,
    "metadata": {},
    "name": "RegisterUserMutation",
    "operationKind": "mutation",
    "text": "mutation RegisterUserMutation(\n  $email: String!\n  $mobileNumber: String!\n  $password: String!\n  $passwordConfirm: String!\n  $referedBy: String\n) {\n  registerUser(mobileNumber: $mobileNumber, email: $email, password: $password, passwordConfirm: $passwordConfirm, referedBy: $referedBy) {\n    status\n    message\n    user {\n      id\n      lastLogin\n      email\n      mobileNumber\n      name\n      dob\n      gender\n      referalCode\n      pincode\n      isActive\n      isAdmin\n      isInstructor\n      wishlist {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '37239da97f0037c63ac7f5e84fb61ebf';

module.exports = node;
