import graphql from "babel-plugin-relay/macro";
const ProfileViewDetailsQuery=graphql`

    query ProfileViewDetailsQuery($orderId:String!){
        orderById(orderId:$orderId){
            id
            orderId
            updatedAt
            totalPayable
            orderitems{
                edges{
                    node{
                        course{
                            name
                            price
                            expiryDate
                            instructor{
                                user{
                                    name
                                }
                            }
                        }
                    }
                }

            }
        }
    }
    

`
export default ProfileViewDetailsQuery
