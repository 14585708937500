import React, { useEffect, useRef, useState } from "react";
import "./AboutCourse.scss";
import { MDBBox } from "mdbreact";
import Output from 'editorjs-react-renderer';

import ArrowDown from "../../assets/icons/dashicons_arrow-down.svg";

export const AboutCourse = (props) => {

    const { course_description } = props;

    const [showExpand, setShowExpand] = useState(false);

    const expandDescription = () => {
        let readMoreRef = document.getElementById("readMoreRef");
        let readLessRef = document.getElementById("readLessRef");
        let discriptionRef = document.getElementById("discriptionRef");

        if (readMoreRef && readLessRef && discriptionRef) {
            readMoreRef.style.display = "none";
            readLessRef.style.display = "flex";
            discriptionRef.style.height = discriptionRef.scrollHeight + "px";
        }

    }

    const closeDescription = () => {
        let readMoreRef = document.getElementById("readMoreRef");
        let readLessRef = document.getElementById("readLessRef");
        let discriptionRef = document.getElementById("discriptionRef");
        if (readMoreRef && readLessRef && discriptionRef) {
            readMoreRef.style.display = "flex";
            readLessRef.style.display = "none";
            discriptionRef.style.height = "250px";
            discriptionRef?.scrollIntoView({
                behavior
                    :
                    'smooth'
            });
        }

    }

    useEffect(() => {
        let discriptionRef = document.getElementById("discriptionRef");
        if (discriptionRef) {
            if (discriptionRef.scrollHeight > 250) {
                setShowExpand(true);
                discriptionRef.style.height = "250px";
            }
        }
    }, []);

    const convertEditorJsTexToHtml = (data) => {
        if (data) {
            return (JSON?.parse(data))
        } else {
            return ("")
        }
    }


    return (
        <MDBBox className={"about-course-component"}>
            <p className={"title"}>About the Course</p>
            <MDBBox className={"description-wrapper"}>
                <div className={"description"} id={"discriptionRef"}>
                    <Output data={convertEditorJsTexToHtml(course_description)} />
                </div>
                {
                    showExpand && (
                        <>
                            <MDBBox className={"read-more-content"} id={"readMoreRef"} onClick={expandDescription}>
                                <span>Read More</span>
                                <img src={ArrowDown} alt={"arrow-down"} className={"img-fluid arrow-down"} />
                            </MDBBox>
                            <MDBBox className={"read-less-content"} id={"readLessRef"} onClick={closeDescription}>
                                <span>Read Less</span>
                                <img src={ArrowDown} alt={"arrow-down"} className={"img-fluid arrow-down"} />
                            </MDBBox>
                        </>
                    )
                }
            </MDBBox>
        </MDBBox>
    );
}
