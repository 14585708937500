/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MyWishListQueryVariables = {||};
export type MyWishListQueryResponse = {|
  +myWishList: ?$ReadOnlyArray<?{|
    +id: string,
    +name: string,
    +instructor: ?{|
      +user: {|
        +name: ?string
      |}
    |},
    +image: ?string,
    +price: any,
    +discountPrice: any,
    +averageRating: ?number,
    +isWishlist: ?boolean,
  |}>
|};
export type MyWishListQuery = {|
  variables: MyWishListQueryVariables,
  response: MyWishListQueryResponse,
|};
*/


/*
query MyWishListQuery {
  myWishList {
    id
    name
    instructor {
      user {
        name
        id
      }
      id
    }
    image
    price
    discountPrice
    averageRating
    isWishlist
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountPrice",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "averageRating",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isWishlist",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MyWishListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CourseType",
        "kind": "LinkedField",
        "name": "myWishList",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "InstructorType",
            "kind": "LinkedField",
            "name": "instructor",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserType",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MyWishListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CourseType",
        "kind": "LinkedField",
        "name": "myWishList",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "InstructorType",
            "kind": "LinkedField",
            "name": "instructor",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserType",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "30f1ecb5b642f41174c0e48a51429372",
    "id": null,
    "metadata": {},
    "name": "MyWishListQuery",
    "operationKind": "query",
    "text": "query MyWishListQuery {\n  myWishList {\n    id\n    name\n    instructor {\n      user {\n        name\n        id\n      }\n      id\n    }\n    image\n    price\n    discountPrice\n    averageRating\n    isWishlist\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '885ef8121b2f5d81b61043bd731bc8b7';

module.exports = node;
