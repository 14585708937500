//main-files
import './Consultation.scss'
import {LayoutContainer} from "../../Components/LayoutContainer/LayoutContainer";

// images
import fb from '../../assets/images/Facebook.png'
import linkedin from '../../assets/images/linkedin.png'
import twitter from '../../assets/images/twitter.png'
import instagram from '../../assets/images/instagram.png'
import redStar from "../../assets/images/redStar.png";
import {RoundedButton} from "../../Components/Buttons/RoundedButton";
import useMediaQuery from "../../util/useMediaQuery";
import {MDBRadio} from 'mdb-react-ui-kit';
import React, {useEffect, useState} from "react";
import * as EmailValidator from "email-validator";
import ContactUsMutation from "../../mutations/ContactUsMutation";
import cogoToast from "cogo-toast";
import CreateConsultationMutation from "../../mutations/CreateConsultationMutation";
import {fetchQuery} from "relay-runtime";
import environment from "../../Environment";
import ProfessorListQuery from "../../queries/ProfessorListQuery";
import {CourseCard} from "../../Containers/CourseCard/CourseCard";
import Header from "../../Containers/Header/Header";
import {Footer} from "../../Containers/Footer/Footer";
import AllStatesQuery from "../../queries/AllStatesQuery";
import uuid from "react-uuid";
import CategoryListQuery from "../../queries/CategoryListQuery";
import Datetime from "react-datetime";
import ProfessorDetailsQuery from "../../queries/ProfessorDetailsQuery";
import {useParams} from "react-router-dom";



export const Consultation = (props) => {
    const isDesktop = useMediaQuery("(min-width: 720px)");
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const params = useParams();


    const [oncheck, setoncheck] = useState(false);
    const [nameState, setNameState] = useState("");
    const [numberState, setNumberState] = useState("");
    const [emailState, setEmailState] = useState("");
    const [TextAreaState, setTextAreaState] = useState("");

    const [collegeName, setcollegeName] = useState("");
    const [degreeType, setdegreeType] = useState("");
    const [instructorId, setinstructorId] = useState("");
    const [yearOfGraduation, setyearOfGraduation] = useState("");
    const [interest, setinterest] = useState("");

    const [major, setMajor] = useState("")
    const [OrganisationName, setOrganisationName] = useState("")
    const [Designation, setDesignation] = useState("")
    const [Location, setLocation] = useState("")
    const [valueErrors, setValueErrors] = useState({});
    const [instructorsdetails,setinstructorsdetails]=useState({})
    const [categoryList, setCategoryList] = useState([]);
    const [getProfessorName, setProfessorName] = useState('');


useEffect(()=>{
    if (params.id && params.id.length >0){
        setinstructorId((params.id))
    }
},[params])




    const getCategoryList = () => {

        fetchQuery(environment, CategoryListQuery)
            .toPromise()
            .then(response => {
                if (response.categories !== null && response.categories.edges.length > 0) {
                    let tmp_data = [];
                    let categories = response.categories.edges;
                    categories.map((item,index)=>{
                        tmp_data[index] = {
                            "id":item.node.id,
                            "title":item.node.name
                        }
                    });
                    setCategoryList(tmp_data);
                } else {
                    setCategoryList([])
                }
            }, err => {
                setCategoryList([])
            });
    }


    const toemptyfield=()=>{
        setNameState("")
        setNumberState("")
        setEmailState("")
        setTextAreaState("")
        setcollegeName("")
        setdegreeType("")
        setinstructorId("")
        setyearOfGraduation("")
        setinterest("")
        setMajor("")
        setOrganisationName("")
        setDesignation("")
        setLocation("")
        setValueErrors("")
    }

    const onChangeHandler1 = (e) => {
        e.preventDefault();
        setNameState(e.target.value);
    };
    const onChangeHandler2 = (e) => {
        e.preventDefault();
        let value = (e.target.validity.valid || e.target.value === "") ? e.target.value : numberState;
        setNumberState(value);
    };
    const onChangeHandler3 = (e) => {
        e.preventDefault();
        setEmailState(e.target.value);
    };
    const onChangeHandler4 = (e) => {
        e.preventDefault();
        setinterest(e.target.value);
    };
    const onChangeHandler5 = (e) => {
        // debugger
       e.preventDefault();
        // console.log(e.target.value)
        setinstructorId(e.target.value);
    };
    const onChangeHandler6 = (e) => {
        e.preventDefault();
        setcollegeName(e.target.value);
    };
    const onChangeHandler7 = (e) => {
        e.preventDefault();
        setMajor(e.target.value);
    };
    const onChangeHandler8 = (e) => {
        e.preventDefault();
        setdegreeType(e.target.value);
    };
    const onChangeHandler9 = (e) => {
        // e.preventDefault();
        let temp_year= new Date(e._d).getFullYear()
        setyearOfGraduation(temp_year);
    };

    const onChangeHandler11 = (e) => {
        e.preventDefault();
        setTextAreaState(e.target.value);
    };
    const onChangeHandler12 = (e) => {
        e.preventDefault();
        setOrganisationName(e.target.value);
    };
    const onChangeHandler13 = (e) => {
        e.preventDefault();
        setDesignation(e.target.value);
    };
    const onChangeHandler14 = (e) => {
        e.preventDefault();
        setLocation(e.target.value);
    };


    const submitForm = () => {
        let error = {};
        if (EmailValidator.validate(emailState) === false) {
            error = {
                field: "email",
                message: "Please fill valid email"
            };
            setValueErrors(error);
        } else if (numberState  === "") {
            error = {
                field: "number",
                message: "number can't be empty"
            };
            setValueErrors(error);
        }else if (collegeName  === "") {
            error = {
                field: "collegeName",
                message: "collegeName can't be empty"
            };
            setValueErrors(error);
        }
        else if (degreeType  === "") {
            error = {
                field: "degreeType",
                message: "degreeType can't be empty"
            };
            setValueErrors(error);
        }
        else if (instructorId  === "") {
            error = {
                field: "instructorId",
                message: "instructorId can't be empty"
            };
            setValueErrors(error);
        }
        else if (yearOfGraduation  === "") {
            error = {
                field: "yearOfGraduation",
                message: "yearOfGraduation can't be empty"
            };
            setValueErrors(error);
        }
        else if (interest  === "") {
            error = {
                field: "interest",
                message: "interest can't be empty"
            };
            setValueErrors(error);
        }
        else if (TextAreaState  === "") {
            error = {
                field: "TextAreaState",
                message: "TextAreaState can't be empty"
            };
            setValueErrors(error);
        }
        else if (major  === "") {
            error = {
                field: "major",
                message: "major can't be empty"
            };
            setValueErrors(error);
        }else if (oncheck  === true) {
            if (OrganisationName === "") {
                error = {
                    field: "OrganisationName",
                    message: "OrganisationName can't be empty "
                };
                setValueErrors(error);
            }

            else if (Location  === "") {
                error = {
                    field: "Location",
                    message: "Location can't be empty"
                };
                setValueErrors(error);
            }

            else if (Designation  === "") {
                error = {
                    field: "Designation",
                    message: "Designation can't be empty"
                };
                setValueErrors(error);
            }

            else {
                    let userData = {
                        collegeName:collegeName,
                        degreeType: degreeType,
                        email:emailState,
                        instructorId:instructorId,
                        name:nameState,
                        mobileNumber:numberState,
                        isWorking: oncheck,
                        yearOfGraduation:yearOfGraduation,
                        interest:interest,
                        message:TextAreaState,
                        fieldOrMajor:major,
                        organization:OrganisationName,
                        location:Location,
                        designation:Designation
                    };
                    error = {};
                    setValueErrors(error);
                    CreateConsultationMutation(userData, function (response) {
                        if (response.createConsultation !== null) {
                            cogoToast.success(response.createConsultation.message, {position: 'top-center'});
                            toemptyfield();
                            props.sendUserDatasToStore(response.createConsultation.user);
                            localStorage.setItem('user_token',response.createConsultation.token);

                        } else {
                            cogoToast.error("something went wrong", {position: 'top-center'});
                        }
                    }, function (err) {
                        cogoToast.error(err, {position: 'top-center'});
                    });
                }
            }




        else {
            let userData = {
                collegeName:collegeName,
                degreeType: degreeType,
                email:emailState,
                instructorId:instructorId,
                name:nameState,
                mobileNumber:numberState,
                isWorking: oncheck,
                yearOfGraduation:yearOfGraduation,
                interest:interest,
                message:TextAreaState,
                fieldOrMajor:major,
            };
            error = {};
            setValueErrors(error);


            CreateConsultationMutation(userData, function (response) {
                if (response.createConsultation !== null) {
                    cogoToast.success(response.createConsultation.message, {position: 'top-center'});
                     // props.sendUserDatasToStore(response.createConsultation.user);
                     localStorage.setItem('user_token',response.createConsultation.token);
                    toemptyfield();
                } else {
                    cogoToast.error("something went wrong", {position: 'top-center'});
                }
            }, function (err) {
                cogoToast.error(err, {position: 'top-center'});
            });
        }
    }

    const [professorList,setProfessorList]=useState([])
    useEffect(()=>{
        getCategoryList()
    },[])

    useEffect(()=>{
        fetchProfessorDetails()
    },[instructorId])


    const fetchProfessorDetails = () => {
        let variables = {
            id: instructorId
        }
        fetchQuery(environment, ProfessorDetailsQuery, variables)
            .toPromise()
            .then(response => {
                if (response.getInstructorById !== null) {
                    // debugger
                    setProfessorName(response.getInstructorById.user.name)
                } else {
                    setProfessorName('');
                }
            }, err => {
                setProfessorName('');
            });
    }


    const fetchTitleList=()=>{
        const variables={}

        fetchQuery(environment,ProfessorListQuery)
            .toPromise()
            .then(response => {
                if (response.allInstructors !== null && response.allInstructors.edges.length > 0) {
                    setProfessorList(response.allInstructors.edges)
                } else {
                    setProfessorList([])
                }
            }, err => {

                // console.log(err);
                setProfessorList([])
            });
    }

    useEffect(() => {
        fetchTitleList()
    }, []);
    // debugger
    // debugger
    // console.log('dfcz',getProfessorName)

    return (
        <>
            <Header page={""}/>
            <div className={'Consultation'}>
            <div className={'header header-font'}>
                Consultation Form
            </div>
            <LayoutContainer>
                <div className={'row main-container'}>
                    <div className={'col-sm-12 col-md-6  order-sm-2 order-md-1 order-2'}>
                        <h3 className={'title header-font'}>
                            Get In Touch!
                        </h3>
                        <p className={'para'}>
                            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
                            consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet
                            minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat
                            duis enim velit mollit.
                        </p>
                        <hr className={'hr_tag'}/>
                        <h5 className={'small_header inquiries'}>For general inquiries:</h5>
                        <p className={'email'}> {isDesktop ? <></> : <span className={'span-mail'}>Mail us @</span>}
                            <u>general@mail.com</u></p>
                        <p className={'small_header follow'}>Follow us on social platforms:</p>
                        <div>
                            <p className={'social-buttons'}><img src={fb} className={'fb  mr-3'}
                                                                 alt={'facebook'}/><u>Facebook</u></p>
                            <p className={'social-buttons'}><img src={twitter} className={'twitter  mr-3 '}
                                                                 alt={'twitter'}/><u>twitter</u></p>
                            <p className={'social-buttons'}><img src={linkedin} className={'linkedin  mr-3'}
                                                                 alt={'linkedin'}/><u>linkedin</u></p>
                            <p className={'social-buttons'}><img src={instagram} className={'instagram  mr-3'}
                                                                 alt={'instagram'}/><u>instagram</u></p>
                        </div>
                    </div>

                    <div className={'col-sm-12 col-md-6 order-1  order-sm-1 order-md-2 right-side'}>
                        <h5 className={'small_header '}>Consultation Form</h5>


                        <div className="flex1">
                            <div className="Create">Full name <span>
                                <img src={redStar} className="redStar" alt="redStar"/>
                            </span>
                            </div>
                            <input
                                type="text"
                                placeholder="Full name"
                                className="code name"
                                required
                                onChange={(e) => onChangeHandler1(e)}
                            />
                            {
                                valueErrors.field === "name" &&
                                <p className={"error-message"}>{valueErrors.message}</p>
                            }
                        </div>

                        <div className="d-flex top-form-field">

                            <div className={'form-field'}>
                                <div className="Create">Mobile Number
                                    <span>
                                    <img src={redStar} className="redStar" alt="redStar"/>
                                </span>
                                </div>
                                <input
                                    type="text"
                                    pattern="[0-9]*"
                                    maxLength="10"
                                    placeholder="10-digit number"
                                    className="code number"
                                    required
                                    value={numberState}
                                    onInput={(e) => onChangeHandler2(e)}
                                    onChange={(e) => onChangeHandler2(e)}
                                />
                                {/*<input*/}
                                {/*    type="number"*/}
                                {/*    placeholder="10-digit number"*/}
                                {/*    className="code number"*/}
                                {/*    required*/}
                                {/*    onChange={(e) => onChangeHandler2(e)}*/}
                                {/*/>*/}
                                {
                                    valueErrors.field === "mobileNumber" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>

                            <div className={'form-field'}>
                                <div className="Create">Email ID
                                    <span>
                                    <img src={redStar} className="redStar" alt="redStar"/>
                                </span>
                                </div>
                                <input
                                    type="text"
                                    placeholder="Valid Email Address"
                                    className="code"
                                    required
                                    onChange={(e) => onChangeHandler3(e)}
                                />
                                {
                                    valueErrors.field === "email" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>

                        </div>

                        <div className={'d-flex top-form-field'}>

                            <div className="form-field">
                                <div className="Create">
                                    Consultation Interest
                                    <span>
                                            <img src={redStar} className="redStar" alt="redStar"/>
                                     </span>
                                </div>

                                <select
                                    className="select1"
                                    name="gender"
                                    required
                                    id="gender"
                                    onChange={(e) => onChangeHandler4(e)}
                                >
                                    <option value="" disabled selected>
                                        Select
                                    </option>
                                    {
                                        categoryList.map((item,index)=>{
                                            // console.log(item)
                                            return(
                                                <>
                                                    <option value={`${item.title}`}>{item.title}</option>
                                                </>
                                            )
                                        })
                                    }
                                </select>
                                {
                                    valueErrors.field === "interest" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>

                            <div className="form-field">
                                <div className="Create">
                                    Conultation Professor
                                    <span>
                  <img src={redStar} className="redStar" alt="redStar"/>
                </span>
                                </div>

                                <select
                                    className="select1"
                                    name="gender"
                                    required
                                    id="gender"
                                    onChange={(e) => onChangeHandler5(e)}
                                    // value={getProfessorName!==null?getProfessorName:''}
                                >
                                    <option value={getProfessorName !==''?getProfessorName:""}>{getProfessorName !==""?getProfessorName:"--select--"}</option>
                                    {
                                        professorList.map((item) => {
                                            // console.log(item.node.user.id)
                                            return (
                                                <option  key={uuid()} value={`${item.node.id}`}>{item.node.user.name}</option>
                                            );
                                        })
                                    }
                                </select>
                                {
                                    valueErrors.field === "instructorId" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>

                        </div>

                        <div className="flex1">
                            <div className="Create">University/School/College Name<span>
                                <img src={redStar} className="redStar" alt="redStar"/>
                            </span>
                            </div>
                            <input
                                type="text"
                                placeholder="College Name"
                                className="code"
                                required
                                onChange={(e) => onChangeHandler6(e)}
                            />
                            {
                                valueErrors.field === "collegeName" &&
                                <p className={"error-message"}>{valueErrors.message}</p>
                            }
                        </div>


                        <div className={'d-flex top-form-field'}>

                            <div className="form-field">
                                <div className="Create">
                                    Field or Major
                                    <span>
                  <img src={redStar} className="redStar" alt="redStar"/>
                </span>
                                </div>

                                <select
                                    className="select1"
                                    name="gender"
                                    required
                                    id="gender"
                                    onChange={(e) => onChangeHandler7(e)}
                                >
                                    <option value="" disabled selected>
                                        Select
                                    </option>
                                    <option value="ECE">ECE</option>
                                    <option value="CSE">CSE</option>
                                    <option value="Others">Others</option>
                                </select>
                                {
                                    valueErrors.field === "fieldOrMajor" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>

                            <div className="form-field">
                                <div className="Create">
                                    Degree Type
                                    <span>
                  <img src={redStar} className="redStar" alt="redStar"/>
                </span>
                                </div>

                                <select
                                    className="select1"
                                    name="gender"
                                    required
                                    id="gender"
                                    onChange={(e) => onChangeHandler8(e)}
                                >
                                    <option value="" disabled selected>
                                        Select
                                    </option>
                                    <option value="UG">UG</option>
                                    <option value="PG">PG</option>
                                    <option value="Others">Others</option>
                                </select>
                                {
                                    valueErrors.field === "degreeType" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>


                        </div>

                        <div className={'d-flex top-form-field'}>

                            <div className="form-field">
                                <div className="Create">
                                    Year of Graduation
                                    <span>
                  <img src={redStar} className="redStar" alt="redStar"/>
                </span>
                                </div>
                                <Datetime className={"select1"}
                                          dateFormat="YYYY"
                                          initialViewMode={"years"}
                                          inputProps={{readOnly: true}}
                                          timeFormat={false} closeOnSelect={true}
                                          onChange={(e) => onChangeHandler9(e, "YearofJoining")}
                                          value={yearOfGraduation ?yearOfGraduation.toString() : new Date()}
                                />
                                {/*<select*/}
                                {/*    className="select1"*/}
                                {/*    name="gender"*/}
                                {/*    required*/}
                                {/*    id="gender"*/}
                                {/*    onChange={(e) => onChangeHandler9(e)}*/}
                                {/*>*/}
                                {/*    <option value="" disabled selected>*/}
                                {/*        Select*/}
                                {/*    </option>*/}
                                {/*    <option value="2021">2021</option>*/}
                                {/*    <option value="2022">2022</option>*/}
                                {/*    <option value="Others">Others</option>*/}
                                {/*</select>*/}
                                {
                                    valueErrors.field === "yearOfGraduation" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>

                            <div className="form-field">
                                <div className="Create1">
                                    Are you currently working?
                                    <span>
                  <img src={redStar} className="redStar" alt="redStar"/>
                </span>
                                </div>
                                <div>
                                    <MDBRadio name='inlineRadio' id='inlineRadio1' value='option1' label='yes'
                                              onClick={() => setoncheck(true)} inline/>
                                    <MDBRadio name='inlineRadio' id='inlineRadio2' value='option2' label='No'
                                              onClick={() => setoncheck(false)} inline/>
                                </div>


                            </div>


                        </div>


                        {oncheck ?
                            <>
                                <div className="flex1">
                                    <div className="Create">Organisation Name<span>
                                <img src={redStar} className="redStar" alt="redStar"/>
                            </span>
                                    </div>
                                    <input
                                        type="text"
                                        placeholder="Organisation/Company name"
                                        className="code"
                                        required
                                        onChange={(e) => onChangeHandler12(e)}
                                    />
                                    {
                                        valueErrors.field === "organization" &&
                                        <p className={"error-message"}>{valueErrors.message}</p>
                                    }
                                </div>


                                <div className={'d-flex top-form-field'}>


                                    <div className="flex1">
                                        <div className="Create">Designation<span>
                                <img src={redStar} className="redStar" alt="redStar"/>
                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            placeholder="Job Tittle"
                                            className="code"
                                            required
                                            onChange={(e) => onChangeHandler13(e)}

                                        />
                                        {
                                            valueErrors.field === "designation" &&
                                            <p className={"error-message"}>{valueErrors.message}</p>
                                        }
                                    </div>

                                    <div className="form-field">
                                        <div className="Create">
                                            Location
                                            <span>
                  <img src={redStar} className="redStar" alt="redStar"/>
                </span>
                                        </div>

                                        <select
                                            className="select1"
                                            name="gender"
                                            required
                                            id="gender"
                                            onChange={(e) => onChangeHandler14(e)}

                                        >
                                            <option value="" disabled selected>
                                                Select
                                            </option>
                                            <option value="Chennai">Chennai</option>
                                            <option value="ongole">ongole</option>
                                            <option value="Others">Others</option>
                                        </select>
                                        {
                                            valueErrors.field === "location" &&
                                            <p className={"error-message"}>{valueErrors.message}</p>
                                        }
                                    </div>


                                </div>
                            </>
                            :
                            <>

                            </>}


                        <div className={'flex1'}>
                            <div className="Create">Tell us your message <span>
                  <img src={redStar} className="redStar" alt="redStar"/>
                </span>
                            </div>
                            <textarea className={''} required placeholder={'Your message...'}
                                      onChange={(e) => onChangeHandler11(e)}>

                      </textarea>


                        </div>

                        <div onClick={submitForm}>
                            <RoundedButton>Submit</RoundedButton>
                        </div>


                    </div>
                </div>
            </LayoutContainer>
        </div>
            <Footer/>
        </>
    )
}
