export const FETCH_CARTDATA_BEGIN   = 'FETCH_CARTDATA_BEGIN';
export const FETCH_CARTDATA_SUCCESS = 'FETCH_CARTDATA_SUCCESS';
export const FETCH_CARTDATA_FAILURE = 'FETCH_CARTDATA_FAILURE';
export const CLEARCART = 'CLEARCART';


export const fetchCartListBegin = () => ({
    type: FETCH_CARTDATA_BEGIN
});

export const fetchCartListSuccess = cart_data => ({
    type: FETCH_CARTDATA_SUCCESS,
    payload: { cart_data }
});

export const fetchCartListFailure = error => ({
    type: FETCH_CARTDATA_FAILURE,
    payload: { error }
});

export const clearCart = () => ({
    type: CLEARCART,
});
