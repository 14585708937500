import graphql from "babel-plugin-relay/macro";

const BlogListQuery=graphql`

    query BlogListQuery{
        allBlog{
            edges{
                node{
                    id
                    title
                    body
                    featuredImage
                    featuredMobileImage
                    thumbnailImage
                    updatedAt
                }
            }
        }
    }
`

export default BlogListQuery