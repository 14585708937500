import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'

const mutation = graphql`
    mutation ChangeConsultationStatusMutation(
        $consultationId: String!
        $status: ConsultationStatusEnum!
    ) {
        changeConsultationStatus(consultationId:$consultationId,status:$status){
            status
        }
    }
`

export default (userData, callback,errCallback) => {
    const variables = {
        consultationId:userData.consultationId,
        status:userData.status,
    }

    // 5
    commitMutation(
        environment,
        {
            mutation,
            variables,
            // 6
            onCompleted: (response,err) => {

                if(response.changeConsultationStatus !== null){
                    callback(response)
                } else{
                    errCallback(err[0].message);
                }

            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}
