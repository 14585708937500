import React, {useState, useEffect, useRef, Fragment, useContext} from "react";
import "./Header.scss";

// import Logo from "../../assets/icons/Logo.png";
import Logo from "../../assets/images/impacteers-logo.svg";
import SearchIcon from "../../assets/icons/akar-icons_search.svg";
import WishListIcon from "../../assets/icons/wish-list.svg";
import CartIcon from "../../assets/icons/cart.svg";
import CloseIcon from "../../assets/icons/close-icon.svg";
import persone from "../../assets/icons/person-circle.svg";
import downbutton from "../../assets/icons/arrow-down.svg"

import {withRouter} from "react-router-dom";
import {RoundedButton} from "../../Components/Buttons/RoundedButton";
import {LayoutContainer} from "../../Components/LayoutContainer/LayoutContainer";
import {MDBModal, MDBModalBody} from "mdbreact";
import Login from "../../Pages/login/Login";
import Singup from "../../Pages/singup/Singup";
import { connect } from 'react-redux'
import {compose} from 'redux';
import {UserLoggedout} from "../../Actions";
import {clearCart} from "../../Actions/CartAction";
import {clearWishList} from "../../Actions/wishListAction";
import {OpenLoginModal ,CloseLoginModal ,SetModalType} from "../../Actions";



const Header = (props) => {
    const {isOpen, modal_type ,openLoginModal,
        closeLoginModal,
        setModalType} = props
    const [isActive, setActive] = useState(false);
    // const [modelOpen, setmodelOpen] = useState(false);
    const [activePage, setActivePage] = useState('');
    // const [modalContent, setModalContent] = useState('login');

    useEffect(() => {
        setActivePage(props.page);
    });

    const toggleMenu = () => {
        setActive(!isActive);
        // if (!isActive) {
        //     document.body.style = "overflow:hidden";
        // } else {
        //     document.body.style = "overflow:auto";
        // }
    }
    const handlePageChange = (page) => {
        setActivePage(page);
        setActive(false);
        props.history.push("/"+page);
    }

    const toggleModal = () => {
        // setmodelOpen(!modelOpen);
        if(isOpen){
            closeLoginModal();
        }else{
            openLoginModal();
        }

    }
    const logoutUser = () => {
        dropdown();
        localStorage.clear();
        props.sendUserLoggedoutDatasToStore();
        props.clearCartFromStore();
        props.clearWishListFromStore();
        props.history.push('/');
        window.location.reload();
    }
    const [showDropDown,setshowDropDown]=useState(false)
    const dropdown=()=>{
        setshowDropDown(!showDropDown)
    }
    const insidedropdown=(argu)=>{
        handlePageChange(argu)
        setshowDropDown(!showDropDown)
    }
// console.log(props.user_details)
    return (
        <>
            <div className={"header-component"} id={"header-component"}>
                <LayoutContainer>
                    <nav className="Navigation">
                        <img src={Logo} alt={"VERTICAL LIMIT"} className={"img-fluid Navigation-smallLogo"}
                             onClick={() => {
                                 props.history.push("/");
                             }}/>
                        <ul className="Navigation-list mobile-navigation-list">
                            <li className={activePage === "/course-list" ? "active" : ""}
                                onClick={() => handlePageChange("course-list")}
                            >
                                <img src={SearchIcon} alt={"search"} className={"img-fluid"}/>
                            </li>
                            <li className={activePage === "/cart" ? "active badged-list" : "badged-list"}
                                onClick={() => handlePageChange("cart")}
                            >
                                <img src={CartIcon} alt={"cart"} className={"img-fluid"}/>
                                {
                                    props.cart_data.checkoutitems && props.cart_data.checkoutitems.length > 0 &&
                                    <span className="badge custom-badges">
                                            {
                                                props.cart_data.checkoutitems.length
                                            }
                                        </span>
                                }
                            </li>
                        </ul>
                        <div className={`Overlay ${isActive ? 'Overlay--on' : ''}`}>
                            <ul className="Navigation-list">
                                <li className={activePage === "home" || activePage === "" ? "active" : ""}
                                    onClick={() => handlePageChange("home")}
                                >
                                    <a>Home</a>
                                </li>
                                <li className={activePage === "course-list" ? "active" : ""}
                                    onClick={() => handlePageChange("course-list")}
                                >
                                    <a>Courses</a>
                                </li>
                                <li className={activePage === "professor-list" ? "active" : ""}
                                    onClick={() => handlePageChange("professor-list")}
                                >
                                    <a>Professors</a>
                                </li>
                                <li className={activePage === "blogs"? "active" : ""}
                                    onClick={() => handlePageChange("blogs")}
                                >
                                    <a>Blogs</a>
                                </li>


                                <li className={activePage === "wishlist"? "active hide-for-desktop"  : "hide-for-desktop"}
                                    onClick={() => props.logged_in ? handlePageChange("wishlist") : toggleModal()}
                                >
                                    <a>Wishlist</a>
                                </li>
                                <li className={activePage === "cart"? "active hide-for-desktop" : "hide-for-desktop"}
                                    onClick={() => handlePageChange("cart")}
                                >
                                    <a>Checkout Cart</a>
                                </li>
                                <li className={(activePage === "myprofile" || activePage === "mycourses" || activePage === "payment-history" || activePage === "consultations" || activePage === "reviews")? "active hide-for-desktop" : "hide-for-desktop"}
                                    onClick={() => handlePageChange("myprofile")}
                                >
                                    <a>Your Profile</a>
                                </li>


                                <li className={activePage === "aboutus" ? "active" : ""}
                                    onClick={() => handlePageChange("aboutus")}
                                >
                                    <a>About us</a>
                                </li>
                                <li className={activePage === "contactus" ? "active" : ""}
                                    onClick={() => handlePageChange("contactus")}
                                >
                                    <a>Contact</a>
                                </li>

                                {
                                    props.logged_in ? (
                                        <li className={"hide-for-desktop"}
                                            onClick={() => logoutUser()}
                                        >
                                            <a>Sign out</a>
                                        </li>
                                    ) : (
                                        <li className={"hide-for-desktop"}
                                            onClick={() => toggleModal()}
                                        >
                                            <a>Login/Sign up</a>
                                        </li>
                                    )
                                }
                                



                                <li className={activePage === "course-list" ? "hide-for-mobile" : "hide-for-mobile"}
                                    onClick={() => handlePageChange("course-list")}
                                >
                                    <img src={SearchIcon} alt={"search"} className={"img-fluid"}/>
                                </li>
                                <li className={activePage === "wishlist" ? "hide-for-mobile badged-list" : "hide-for-mobile badged-list"}
                                   onClick={() => props.logged_in ? handlePageChange("wishlist") : toggleModal()}
                                >
                                    <img src={WishListIcon} alt={"wishlist"} className={"img-fluid"}/>
                                    {
                                        props.wishlist_data.length > 0 &&
                                        <span className="badge custom-badges">
                                            {
                                                props.wishlist_data.length
                                            }
                                        </span>
                                    }
                                </li>
                                <li className={activePage === "cart" ? "hide-for-mobile badged-list" : "hide-for-mobile badged-list"}
                                    onClick={() => handlePageChange("cart")}
                                >
                                    <img src={CartIcon} alt={"cart"} className={"img-fluid"}/>
                                    {
                                        props.cart_data.checkoutitems && props.cart_data.checkoutitems.length > 0 &&
                                        <span className="badge custom-badges">
                                            {
                                                props.cart_data.checkoutitems.length
                                            }
                                        </span>
                                    }
                                </li>
                                <li className={"hide-for-mobile"}

                                >
                                    {
                                        props.logged_in === true?
                                            <>

                                                 <div onClick={() => dropdown()}>
                                                     <img src={ props.user_details && props.user_details.profile && props.user_details.profile.profilePic ? props.user_details.profile.profilePic : persone} className={'persone'}/>  hello,{ props.user_details && props.user_details.name ?props.user_details.name:"User"}
                                                     <img src={downbutton} className={'downbutton'}/>
                                                 </div>
                                                   <ul className={showDropDown?"dropdown_active":"dropdown_disactive"}>
                                                       <li className={activePage === "myprofile" ? "active" : ""} onClick={() =>insidedropdown("myprofile")}>Profile</li>
                                                       <li className={activePage === "mycourses" ? "active" : ""} onClick={() =>insidedropdown("myprofile/mycourses")}>My Courses</li>
                                                       <li className={activePage === "payment-history" ? "active" : ""} onClick={() =>insidedropdown("myprofile/payment-history") }> Payment History</li>
                                                       <li className={activePage === "consultations" ? "active" : ""} onClick={() =>insidedropdown("myprofile/consultations") }>Consultation</li>
                                                       <li className={activePage === "reviews" ? "active" : ""} onClick={() =>insidedropdown("myprofile/reviews")}>Reviews</li>
                                                       {/* <li className={activePage === "referral" ? "active" : ""} onClick={() =>insidedropdown("myprofile/referral")}>Referrals & Coupone</li> */}
                                                       <li onClick={() => logoutUser()}>Sign out</li>
                                                   </ul>
                                            </>

                                            // <RoundedButton handleClick={logoutUser} showIcon={false}>Logout</RoundedButton>
                                            :
                                            <RoundedButton handleClick={toggleModal} showIcon={false}>Login/Sign up</RoundedButton>

                                    }
                                </li>
                            </ul>
                            <img src={CloseIcon} alt={"close"} className={"close-navbar"} onClick={toggleMenu}/>
                        </div>
                        <section className="Navigation--collapsed" onClick={toggleMenu}>
                            <div className={`BurgerMenu ${isActive ? 'BurgerMenu--on' : ''}`}/>
                        </section>
                    </nav>
                </LayoutContainer>
                <MDBModal isOpen={isOpen} toggle={toggleModal} centered size="fluid" tabIndex='-1'>
                    <MDBModalBody>
                        {
                            modal_type === "login" ?
                            <Login setlogin={(type)=>setModalType(type)} closeLogin={toggleModal}/> :
                            <Singup setlogin={(type)=>setModalType(type)} closeLogin={toggleModal}/>
                        }
                    </MDBModalBody>
                </MDBModal>
            </div>
            <div style={{height:"80px"}}/>
        </>
    );
}

const mapStateToProps = state => ({
    user_details: state.UserReducer.user_details,
    logged_in:state.UserReducer.logged_in,
    wishlist_data: state.WishListReducer.wishlist_data,
    cart_data: state.CartReducer.cart_data,
    isOpen:state.ModalReducer.isOpen,
    modal_type:state.ModalReducer.modal_type,
});

const mapDispatchToProps = dispatch => ({
    sendUserLoggedoutDatasToStore: () => dispatch(UserLoggedout()),
    clearCartFromStore: () => dispatch(clearCart()),
    clearWishListFromStore: () => dispatch(clearWishList()),
    openLoginModal:() => dispatch(OpenLoginModal()),
    closeLoginModal:() => dispatch(CloseLoginModal()),
    setModalType:(type) => dispatch(SetModalType(type)),  
});
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Header);


