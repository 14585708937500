/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OrderStatus = "CANCELLED" | "COMPLETED" | "PENDING" | "PROCESSING" | "%future added value";
export type CheckoutCompleteMutationVariables = {|
  id: string
|};
export type CheckoutCompleteMutationResponse = {|
  +checkoutComplete: ?{|
    +status: ?string,
    +payment: ?{|
      +order: ?{|
        +id: string,
        +totalPayable: any,
        +status: OrderStatus,
        +orderId: ?string,
        +user: {|
          +name: ?string,
          +email: string,
          +mobileNumber: ?string,
        |},
      |},
      +rzpOrderId: ?string,
    |},
  |}
|};
export type CheckoutCompleteMutation = {|
  variables: CheckoutCompleteMutationVariables,
  response: CheckoutCompleteMutationResponse,
|};
*/


/*
mutation CheckoutCompleteMutation(
  $id: String!
) {
  checkoutComplete(id: $id) {
    status
    payment {
      order {
        id
        totalPayable
        status
        orderId
        user {
          name
          email
          mobileNumber
          id
        }
      }
      rzpOrderId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalPayable",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "orderId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mobileNumber",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rzpOrderId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CheckoutCompleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CheckoutCompleteMutation",
        "kind": "LinkedField",
        "name": "checkoutComplete",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PaymentNode",
            "kind": "LinkedField",
            "name": "payment",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderNode",
                "kind": "LinkedField",
                "name": "order",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v2/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserType",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CheckoutCompleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CheckoutCompleteMutation",
        "kind": "LinkedField",
        "name": "checkoutComplete",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PaymentNode",
            "kind": "LinkedField",
            "name": "payment",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderNode",
                "kind": "LinkedField",
                "name": "order",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v2/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserType",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v9/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "53f5eb273fc8bfcc0bd1a05c8464776f",
    "id": null,
    "metadata": {},
    "name": "CheckoutCompleteMutation",
    "operationKind": "mutation",
    "text": "mutation CheckoutCompleteMutation(\n  $id: String!\n) {\n  checkoutComplete(id: $id) {\n    status\n    payment {\n      order {\n        id\n        totalPayable\n        status\n        orderId\n        user {\n          name\n          email\n          mobileNumber\n          id\n        }\n      }\n      rzpOrderId\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '61ffa579292b5e0a5538f185d4e255fc';

module.exports = node;
