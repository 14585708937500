/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AllStatesQueryVariables = {||};
export type AllStatesQueryResponse = {|
  +allStates: ?$ReadOnlyArray<?{|
    +id: string,
    +name: string,
  |}>
|};
export type AllStatesQuery = {|
  variables: AllStatesQueryVariables,
  response: AllStatesQueryResponse,
|};
*/


/*
query AllStatesQuery {
  allStates {
    id
    name
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "StateType",
    "kind": "LinkedField",
    "name": "allStates",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AllStatesQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AllStatesQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "54ee6022a12cbc3e5cdc12f0872ac352",
    "id": null,
    "metadata": {},
    "name": "AllStatesQuery",
    "operationKind": "query",
    "text": "query AllStatesQuery {\n  allStates {\n    id\n    name\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '032b1599ef421dcf06de0977d8cc51d0';

module.exports = node;
