import {BasicButton} from "../Buttons/BasicButton";
import React, {useEffect, useRef, useState} from "react";
import "./ProfessorFilterButton.scss"
import filter from "../../assets/icons/filter.svg"
import uuid from "react-uuid";
import PropTypes from "prop-types";

export const useClickOutside=(handler)=>{

    let domNode=useRef();
    useEffect(()=>{
        let maybrHandler =(e)=>{
            if (!domNode.current.contains(e.target)){

                handler()
            }
        }
        document.addEventListener("mousedown",maybrHandler);
        return ()=>{
            document.removeEventListener("mousedown",maybrHandler)
        }
    })
    return domNode
}

export const ProfessorFilterButton=(props)=>{
    const {children,parentCallback} = props;

    const [isOpen, setIsOpen] = useState(false);
    const options=[{
        value:"-createdAt",
        name:"New to Oldest"
    },{
        value:"createdAt",
        name:"Oldest to New"
    }]
    const [selectedOption,setSelectedOption]=useState({})
    const handleoptionschange=(item)=>{
        setSelectedOption(item)
        setIsOpen(false)
    }
    let domNode=useClickOutside(()=>{
        setIsOpen(false)
    })

    return(<div>
            <div ref={domNode} className={'professor-filter-button-class'}>
                <div>
                    <BasicButton handleClick={()=>setIsOpen(isOpen=>!isOpen)}>
                        <img src={filter} alt={'filter'} className={'filter'}/>
                        {children}
                    </BasicButton>
                </div>
                <div className={isOpen===true?"d-flex sortfield":"d-none"}>
                    {options.map((item)=>{
                        return(
                            <div key={uuid()} onClick={()=>{handleoptionschange(item)
                                parentCallback(item) }}>
                                {item.name}
                            </div>
                        );
                    })}
                </div>

            </div>
        </div>
    );
}
ProfessorFilterButton.propTypes = {
    children: PropTypes.string,
};
