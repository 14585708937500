import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'

const mutation = graphql`
    mutation InstructorDashboardMutation(
    $monthFilter:MonthFilterEnum!,
    $startMonth:Int,
    $startYear:Int,
    $endMonth:Int,
    $endYear:Int,
    $courseId:String
    )
    {
        instructorDashboard(
    monthFilter:$monthFilter,
    startMonth:$startMonth,
    startYear:$startYear,
    endMonth:$endMonth,
    endYear:$endYear,
    courseId:$courseId
        ){
            consultationGraphData{
                count
                createdAt_Date
            }
            enrollmentGraphData{
                count
                createdAt_Date
            }
            totalEnrollment
            totalConsultations
            courses{
                id
                name
                updatedAt
                enrolledUsers
                averageRating
            }
        }
    }
`

export default (userData, callback,errCallback) => {
    const variables = {
        monthFilter:userData.monthFilter,
        startMonth:userData.startMonth,
        startYear:userData.startYear,
        endMonth:userData.endMonth,
        endYear:userData.endYear,
        courseId:userData.courseId
    }

    commitMutation(
        environment,

        {
            mutation,
            variables,
            onCompleted: (response,err) => {
                if(response.instructorDashboard !== null){
                    callback(response)
                } else{
                    errCallback(err[0].message);
                }
            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}
