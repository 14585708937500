import React from "react";
import "./FlatButton.scss"
import PropTypes from 'prop-types';
import { MDBBox } from 'mdbreact';

import EditIcon from "../../assets/icons/edit-icon.svg";


export const FlatButton = (props) => {
    const {handleClick,children,showIcon} = props;
    return(
        <MDBBox className={"flat-button-component"}>
            <button type={"button"} className={"btn"} onClick={handleClick}>
                <span>{children}</span>
                {
                    showIcon === true &&
                        <img src={EditIcon} alt={"edit"} className={"img-fluid edit-icon"}/>
                }
            </button>
        </MDBBox>
    );
}

FlatButton.propTypes = {
    handleClick: PropTypes.func,
    children: PropTypes.string,
    showIcon:PropTypes.bool
};


