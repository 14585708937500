import "./ProfessorList.scss";
import {MDBBox} from "mdbreact";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import Slider from "react-slick";

import {LayoutContainer} from "../../Components/LayoutContainer/LayoutContainer";

import Eclipse from "../../assets/images/Ellipse.png";
import ProfessorProfile from "../../assets/images/professor.png";
import ActiveIllustrator from "../../assets/Illustrations/Polygon-Light.png";
import Instructor from "../../assets/images/professorpic.svg";
import {BasicButton} from "../../Components/Buttons/BasicButton";
import {ProfessorCard} from "../../Containers/ProfessorCard/ProfessorCard";
import React, {useEffect, useState} from "react";
import {fetchQuery} from "relay-runtime";
import environment from "../../Environment";
import ProfessorListQuery from "../../queries/ProfessorListQuery";
import Loader from "../../Components/Loader/Loader";
import uuid from 'react-uuid'
import Header from "../../Containers/Header/Header";
import {Footer} from "../../Containers/Footer/Footer";


export const ProfessorList = (props) => {

    const [professorList,setProfessorList]=useState([]);
    const [loading,setLoading]=useState(false);
    const [pageCount,setPageCount]=useState(16);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        getProfessorList()
    }, []);

    const getProfessorList = () => {
        setLoading(true);
        fetchQuery(environment,ProfessorListQuery)
            .toPromise()
            .then(response => {
                setLoading(false);
                if (response.allInstructors !== null && response.allInstructors.edges) {
                    setProfessorList(response.allInstructors.edges);
                } else {
                    setProfessorList([]);
                }
            }, err => {
                setLoading(false);
                setProfessorList([]);
            });
    }

    const loadMore = () =>{
        setPageCount(pageCount+16);
    }
    if (loading) {
        return (
            <div>
                <Loader full={true} />
                <div  style={{height:window.innerHeight}}/>
            </div>
        );
    }
    return(
        <>
            <Header page={"professor-list"}/>
            <MDBBox className={"professor-list-component"}>
            <MDBBox className={"mobile-title"}>
                <p>Learn from the Experts</p>
            </MDBBox>
            <LayoutContainer>
                <MDBBox className={"professor-intro-section"}>
                    <MDBRow>
                        <MDBCol size="12" sm={"7"} className={"d-flex flex-column justify-content-center grid-column"}>
                            <MDBBox className={"content-part "}>
                                <p className={"title"}>Learn from the Experts</p>
                                <p className={"description"}>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</p>
                            </MDBBox>
                        </MDBCol>
                        <MDBCol size="12" sm={"5"} className={"grid-column"}>
                            <MDBBox className={"image-section"}>
                                {/*<MDBBox className={"professor-profile"}>*/}
                                     <img src={ProfessorProfile} alt={"ProfessorProfile"} className={"img-fluid professor-profile-pic"}/>
                                {/*</MDBBox>*/}
                                <MDBBox className={"professor-highlight-points d-flex justify-content-center align-items-center flex-column"}>
                                    <p className={"points"}>100+</p>
                                    <p className={"point-text"}>Professors</p>
                                </MDBBox>
                                <MDBBox className={"professor-highlight-points d-flex justify-content-center align-items-center flex-column"}>
                                    <p className={"points"}>4.4</p>
                                    <p className={"point-text"}>Ratings</p>
                                </MDBBox>
                                <MDBBox className={"professor-highlight-points d-flex justify-content-center align-items-center flex-column"}>
                                    <p className={"points"}>500+</p>
                                    <p className={"point-text"}>Students</p>
                                </MDBBox>
                                <MDBBox className={"professor-highlight-points d-flex justify-content-center align-items-center flex-column"}>
                                    <p className={"points"}>200+</p>
                                    <p className={"point-text"}>Courses</p>
                                </MDBBox>
                            </MDBBox>
                        </MDBCol>
                    </MDBRow>
                </MDBBox>
                <MDBBox className={"professor-list-section"}>
                    <p className={"professor-title"}>Professors</p>
                    <MDBBox className={"large-device-professor-list"}>
                        <MDBRow>
                            {
                                professorList.slice(0,pageCount).map((item,index)=>{
                                    return(
                                        <MDBCol sm="3" key={uuid()}>
                                            <ProfessorCard professorData={item.node}/>
                                        </MDBCol>
                                    );
                                })
                            }
                        </MDBRow>
                    </MDBBox>
                    <MDBBox className={"small-device-professor-list"}>
                        <MultipleItemsSlider professorList={professorList.slice(0,pageCount)}/>
                        {/*<MultipleItemsSlider/>*/}
                    </MDBBox>
                    {
                        pageCount < professorList.length && (
                            <MDBBox className={"load-more-btn-section"}>
                                <BasicButton handleClick={loadMore}>Load More</BasicButton>
                            </MDBBox>
                        )
                    }                    
                </MDBBox>
            </LayoutContainer>
        </MDBBox>
            <Footer/>
        </>
    );
}




export const MultipleItemsSlider = (props) => {
    const {professorList} = props;

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true
    };
    return (
        <div className={"multiple-slider-item-component"}>
            <Slider {...settings}>
                {
                    professorList.map((item,index)=>{
                        return(
                            <div key={uuid()}>
                                <ProfessorCard professorData={item.node}/>
                            </div>
                        );
                    })
                }
            </Slider>
        </div>
    );
}
