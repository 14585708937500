import graphql from "babel-plugin-relay/macro";


const MyProfileQuery=graphql`
    query MyProfileQuery{
        myProfile{
            id
            email
            mobileNumber
            name
            dob
            gender
            pincode
            profile{
                nationality
                profilePic
                district{
                    id
                    name
                }
                college
                employementStatus
                state{
                    id
                    name
                }
                experienceLevel
                degreeType
                organizationName
                courseName
                yearOfGraduation
            }
        }
        
    }

`


export default MyProfileQuery;






