import "./ProfessorPanelIndex.scss";
import {MDBBox, MDBCol, MDBContainer, MDBRow, MDBTypography} from "mdbreact";
import {Link} from "react-router-dom";


// import Logo from "../../../assets/images/white-logo.png";
import Logo from "../../../assets/images/impacteers-logo.svg";
import DashboardIcon from "../../../assets/icons/dashboard.svg";
import DashboardActiveIcon from "../../../assets/icons/dashboard-active.svg";
import CoursesIcon from "../../../assets/icons/courses.svg";
import CoursesActiveIcon from "../../../assets/icons/courses-active.svg";
import ConsultationIcon from "../../../assets/icons/consultation.svg";
import ConsultationActiveIcon from "../../../assets/icons/consultation-active.svg";
import ProfileIcon from "../../../assets/icons/profile.svg";
import ProfileActiveIcon from "../../../assets/icons/profile-active.svg";
import SignoutIcon from "../../../assets/icons/sign-out.svg";
import Profileic from "../../../assets/images/test-user.png";
import NotificationIcon from "../../../assets/icons/notification.svg";
import {UserLoggedout} from "../../../Actions";
import {clearCart} from "../../../Actions/CartAction";
import {clearWishList} from "../../../Actions/wishListAction";
import {compose} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {fetchQuery} from "relay-runtime";
import environment from "../../../Environment";
import InstructorProfileQuery from "../../../queries/InstructorProfileQuery";
import {useEffect, useState} from "react";
import TopHeader from "../TopHeader/TopHeader";


const ProfessorPanelIndex = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const logoutUser = () => {
        localStorage.clear();
        props.sendUserLoggedoutDatasToStore();
        props.clearCartFromStore();
        props.clearWishListFromStore();
        props.history.push('/');
        window.location.reload();
    }
    return(
        <MDBBox className={"professor-panel-index-component"}>
            <MDBContainer fluid={true}>
                <MDBRow>
                    <MDBCol size="3" className={"p-0"}>
                        <Sidebar
                            activePage={props.activePage}
                            logoutUser={logoutUser}
                        />
                    </MDBCol>
                    <MDBCol size="9"  className={"p-0"} style={{backgroundColor:"#F2F5FA"}}>
                        <TopHeader/>
                        {
                            props.children
                        }
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </MDBBox>
    );
}

const mapDispatchToProps = dispatch => ({
    sendUserLoggedoutDatasToStore: () => dispatch(UserLoggedout()),
    clearCartFromStore: () => dispatch(clearCart()),
    clearWishListFromStore: () => dispatch(clearWishList())
});

export default compose(
    withRouter,
    connect(null, mapDispatchToProps)
)(ProfessorPanelIndex);




const Sidebar = (props) => {
    const {activePage,logoutUser} = props;
    return(
        <MDBBox className={"sidebar-component"}>
            <Link to={"/"}>
                <img src={Logo} alt={"Logo"} className={"img-fluid"}/>
            </Link>
            <MDBBox className={"menu-list"}>
                <Link to={"/professor/dashboard"}>
                    <MDBBox className={`menu-item ${activePage === "dashboard"?"active":""}`}>
                        <img src={activePage === "dashboard"?DashboardActiveIcon:DashboardIcon} alt={"dashboard"} className={"img-fluid"}/>
                        <MDBTypography tag={"p"} className={"title"}>Dashboard</MDBTypography>
                    </MDBBox>
                </Link>
                <Link to={"/professor/courses"}>
                    <MDBBox className={`menu-item ${activePage === "courses"?"active":""}`}>
                        <img src={activePage === "courses"?CoursesActiveIcon:CoursesIcon} alt={"dashboard"} className={"img-fluid"}/>
                        <MDBTypography tag={"p"} className={"title"}>Courses</MDBTypography>
                    </MDBBox>
                </Link>
                <Link to={"/professor/consultations"}>
                    <MDBBox className={`menu-item ${activePage === "consultations"?"active":""}`}>
                        <img src={activePage === "consultations"?ConsultationActiveIcon:ConsultationIcon} alt={"dashboard"} className={"img-fluid"}/>
                        <MDBTypography tag={"p"} className={"title"}>Consultations</MDBTypography>
                    </MDBBox>
                </Link>
                <Link to={"/professor/profile"}>
                    <MDBBox className={`menu-item ${activePage === "profile"?"active":""}`}>
                        <img src={activePage === "profile"?ProfileActiveIcon:ProfileIcon} alt={"dashboard"} className={"img-fluid"}/>
                        <MDBTypography tag={"p"} className={"title"}>Profile</MDBTypography>
                    </MDBBox>
                </Link>
                <MDBBox className={"menu-item signout"} onClick={logoutUser}>
                    <img src={SignoutIcon} alt={"dashboard"} className={"img-fluid"}/>
                    <MDBTypography tag={"p"} className={"title signout"}>Sign out</MDBTypography>
                </MDBBox>
            </MDBBox>
        </MDBBox>
    );
}
//
// const TopHeader = (props) => {
//
// const [getProfileName,setProfileName]=useState('')
//
//
//     const fetchProfilDetails = () => {
//         fetchQuery(environment, InstructorProfileQuery)
//             .toPromise()
//             .then(response => {
//                 if (response.myProfile !== null) {
//                     setProfileName(response.myProfile.name)
//                 } else {
//                     setProfileName('');
//                 }
//             }, err => {
//                 // console.log(err);
//                 setProfileName('');
//             });
//     }
//
//     useEffect(()=>{
//         fetchProfilDetails()
//     })
//
//
//     return(
//         <MDBBox className={"top-header-component"}>
//             <MDBTypography tag={"p"} className={"welcome-text"}>
//                 Welcome <MDBTypography tag={"span"} className={"username"}>{getProfileName}</MDBTypography>
//             </MDBTypography>
//             <MDBBox className={"right-section"}>
//                 <MDBBox className={"notification-section"}>
//                     <img src={NotificationIcon} alt={"notification"} className={"img-fluid"}/>
//                 </MDBBox>
//                 {/*<MDBBox className={"profile-pic-section"}>*/}
//                 {/*    <img src={Profileic} alt={"ProfilePic"} className={"img-fluid"}/>*/}
//                 {/*</MDBBox>*/}
//             </MDBBox>
//         </MDBBox>
//     );
// }


