import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'

const mutation = graphql`
  mutation VerifyOtpMutation(
    $mobileNumber: String
    $otp: String
  ) {
      verifyOtp(mobileNumber:$mobileNumber,otp:$otp){
        status
        message
      }
  }
`

export default (userData, callback,errCallback) => {
    const variables = {
        mobileNumber: userData.mobileNumber,
        otp: userData.otp
    }

    // 5
    commitMutation(
        environment,
        {
            mutation,
            variables,
            // 6
            onCompleted: (response,err) => {

                if(response.verifyOtp !== null){
                    callback(response)
                } else{
                    errCallback(err[0].message);
                }

            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}
