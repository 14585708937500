import graphql from "babel-plugin-relay/macro";
const IndustrialSpeakersQuery=graphql`

    query IndustrialSpeakersQuery{
        industrialSpeakers{
            edges {
                node {
                    id
                    image
                    title
                    description
                    url
                    order
                }
            }
        }
    }

`
export default IndustrialSpeakersQuery