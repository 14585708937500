import "./CoursePreviewCard.scss";
import { MDBBox, MDBModal, MDBModalBody } from "mdbreact";

import HeartIcon from "../../assets/icons/heart-icon.svg";
import CartIcon from "../../assets/icons/Check-out cart.svg";
import { RatingCircle } from "../RatingCircle/RatingCircle";
import { CourseInstructorCard } from "../CourseInstructorCard/CourseInstructorCard";
import { VideoPlayer } from "../VideoPlayer/VideoPlayer";
import AddToCartMutation from "../../mutations/AddToCartMutation";
import cogoToast from "cogo-toast";
import { fetchCartListSuccess } from "../../Actions/CartAction";
import { fetchWishListSuccess } from "../../Actions/wishListAction";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fetchQuery } from "relay-runtime";
import environment from "../../Environment";
import AddToWishListQuery from "../../queries/AddToWishListQuery";
import RemoveFromWishListQuery from "../../queries/RemoveFromWishListQuery";
import redheart from "../../assets/images/redheart.png";
import { useEffect, useState } from "react";
import Login from "../../Pages/login/Login";
import Singup from "../../Pages/singup/Singup";
import { OpenLoginModal, CloseLoginModal, SetModalType } from "../../Actions";


const CoursePreviewCard = (props) => {
    const { isOpen, modal_type, openLoginModal,
        closeLoginModal,
        setModalType, courseData, logged_in } = props
    const [circle1Data, setCircle1Data] = useState({});
    const [circle2Data, setCircle2Data] = useState({});
    const [circle3Data, setCircle3Data] = useState({});
    // const [modelOpen, setmodelOpen] = useState(false);
    // const [modalContent, setModalContent] = useState('login');


    useEffect(function () {

        if (Object.keys(props.courseData).length > 0) {
            let tmp_course_data = props.courseData;
            setCircle1Data({
                count: tmp_course_data.enrolledUsers ? tmp_course_data.enrolledUsers : 0,
                content: "Students"
            });
            setCircle2Data({
                count: tmp_course_data.averageRating + "/5",
                content: "Ratings"
            });
            setCircle3Data({
                count: tmp_course_data.durationInMonth,
                content: "Access"
            });
        }

    }, [props.courseData]);

    const toggleWishList = () => {
        if (props.courseData.isWishlist) {
            removeFromWishList();
        } else {
            addToWishList();
        }
    }

    const addToCart = (buyNow) => {
        let variables = {
            courseId: props.courseData.id
        }

        AddToCartMutation(variables, function (response) {
            if (response.addToCart !== null) {
                let dataFormate = {
                    id: response.addToCart.cart.id,
                    total: parseInt(response.addToCart.cart.total),
                    discount: parseInt(response.addToCart.cart.discount),
                    discountName: response.addToCart.cart.discountName,
                    checkoutitems: response.addToCart.cart.checkoutitems.edges
                }
                props.cartListSuccess(dataFormate);
                cogoToast.success("Course Added To cart successfully", { position: 'top-center' });
                if (buyNow === true) {
                    props.history.push("/cart");
                }
            } else {
                cogoToast.success("Course Not Added To cart", { position: 'top-center' });
            }
        }, function (err) {
            cogoToast.error("Course Not Added To cart", { position: 'top-center' });
        })
    }

    const addToWishList = () => {

        let variables = {
            courseId: props.courseData.id
        }
        fetchQuery(environment, AddToWishListQuery, variables)
            .toPromise()
            .then(response => {
                if (response.addToWishList !== null) {
                    props.wishListSuccess(response.addToWishList);
                    if (props.updateCourseList) {
                        props.updateCourseList(props.courseData, true);
                    }
                    cogoToast.success("Course Added To Wishlist successfully", { position: 'top-center' });
                } else {
                    cogoToast.info("Course Not Added To Wishlist", { position: 'top-center' });
                }
            }, err => {
                cogoToast.error("Course Not Added To Wishlist", { position: 'top-center' });
            });
    }

    const removeFromWishList = () => {
        let variables = {
            courseId: props.courseData.id
        }
        fetchQuery(environment, RemoveFromWishListQuery, variables)
            .toPromise()
            .then(response => {
                if (response.removeFromWishList !== null) {
                    props.wishListSuccess(response.removeFromWishList);
                    if (props.updateCourseList) {
                        props.updateCourseList(props.courseData, false);
                    }
                    cogoToast.success("Course Removed From wishlist successfully", { position: 'top-center' });
                } else {
                    cogoToast.info("Course Not Removed From wishlist", { position: 'top-center' });
                }
            }, err => {
                cogoToast.error("Course Not Removed From wishlist", { position: 'top-center' });
            });
    }

    const toggleModal = () => {
        if (isOpen) {
            closeLoginModal();
        } else {
            openLoginModal();
        }

    }

    const buyNowCourse = () => {
        if (logged_in) {
            addToCart(true);
        }
        else {
            if (isOpen) {
                closeLoginModal();
            } else {
                openLoginModal();
            }
        }
    }

    const OpenModal = () => {
        if (isOpen) {
            closeLoginModal();
        } else {
            openLoginModal();
        }
    }


    return (
        <div className="course-preview-container">
            <MDBBox className={"course-preview-card-component"}>
                <MDBBox className={"first-section"}>
                    <p className={"course-name"}>{courseData.name}</p>
                    {/* <MDBBox className={"best-sellers-tag"}>
                        <BestSellersTag />
                    </MDBBox> */}
                    <MDBBox className={"video-player-section"}>
                        <VideoPlayer videoUrl={courseData.previewVideo} hidePreviewContent={false} />
                    </MDBBox>
                    <MDBBox className={"preview-content-section"}>
                        <MDBBox className={"price-and-buynow"}>
                            <PriceCard courseData={courseData} />
                            <BuyNowBtn
                                toggleWishList={toggleWishList}
                                buyNowCourse={buyNowCourse}
                                isWishlist={courseData.isWishlist}
                                logged_in={logged_in}
                            />
                        </MDBBox>
                        <MDBBox className={"card-spacer"} />
                        <AddToCartBtn
                            addToCart={() => logged_in ? addToCart() : OpenModal()}
                        />
                        <MDBBox className={"course-rating-circle"}>
                            <RatingCircle circle1Data={circle1Data} circle2Data={circle2Data} circle3Data={circle3Data} />
                        </MDBBox>
                        {
                            courseData.instructor &&
                            <MDBBox className={"course-instructor-section"}>
                                <p className={"instructor-section-title"}>Course Instructor</p>
                                <CourseInstructorCard professorDetail={courseData.instructor} />
                            </MDBBox>
                        }
                    </MDBBox>
                </MDBBox>
            </MDBBox>
            <MDBModal isOpen={isOpen} toggle={toggleModal} centered size="fluid" tabIndex='-1'>
                <MDBModalBody>
                    {
                        modal_type === "login" ?
                            <Login setlogin={(type) => setModalType(type)} closeLogin={toggleModal} /> :
                            <Singup setlogin={(type) => setModalType(type)} closeLogin={toggleModal} />
                    }
                </MDBModalBody>
            </MDBModal>
        </div>
    );
}

const mapStateToProps = state => ({
    logged_in: state.UserReducer.logged_in,
    cart_data: state.CartReducer.cart_data,
    isOpen: state.ModalReducer.isOpen,
    modal_type: state.ModalReducer.modal_type,
});

const mapDispatchToProps = dispatch => ({
    cartListSuccess: (data) => dispatch(fetchCartListSuccess(data)),
    wishListSuccess: (data) => dispatch(fetchWishListSuccess(data)),
    openLoginModal: () => dispatch(OpenLoginModal()),
    closeLoginModal: () => dispatch(CloseLoginModal()),
    setModalType: (type) => dispatch(SetModalType(type)),

})
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(CoursePreviewCard);

export const PriceCard = (props) => {
    const { courseData } = props;
    return (
        <MDBBox className={"price-card-component d-flex align-items-center"}>
            <p className={"original-price"}>₹{(courseData.discountPrice * 100) / 100}</p>
            <MDBBox>
                <p className={"override-price"}><del>₹{(courseData.price * 100) / 100}</del></p>
                <p className={"discount"}>Discount {courseData.discountPercentage}% off</p>
            </MDBBox>
        </MDBBox>
    );
}

export const BuyNowBtn = (props) => {
    const { toggleWishList, buyNowCourse, isWishlist, logged_in } = props;
    return (
        <MDBBox className={"butnow-btn-component"}>
            <MDBBox className={"d-flex align-item-center btn-section"}>
                <button type={"button"} className={"btn buy-now-btn"} onClick={buyNowCourse}>Buy Now</button>
                {
                    logged_in && (
                        <button type={"button"} className={"btn heart-btn"} onClick={toggleWishList}>
                            <img src={isWishlist ? redheart : HeartIcon} alt={"heart"} className={"img-fluid"} />
                        </button>
                    )
                }

            </MDBBox>
        </MDBBox>
    );
}

export const AddToCartBtn = (props) => {
    const { addToCart } = props;
    return (
        <MDBBox className={"add-to-cart-btn-component"}>
            <button type={"button"} className={"btn add-to-cart-btn"} onClick={addToCart}>
                <img src={CartIcon} alt={"Cart"} className={"img-fluid cart-icon"} />
                <span>Add to Cart</span>
            </button>
        </MDBBox>
    );
}

export const BestSellersTag = (props) => {
    return (
        <MDBBox className={"best-sellers-tag-component"}>
            Best Seller
        </MDBBox>
    );
}
