import "./ReferalsRedeem.scss"
import {Invite} from "../../Homepage/Homepage";
import React, {useState} from "react";
import copy_img from "../../../assets/images/copy_img.png"
import gift from "../../../assets/images/gift.png"
import {LayoutContainer} from "../../../Components/LayoutContainer/LayoutContainer";
import {Step, Stepper} from 'react-form-stepper';
// import expert from "../../assets/images/expert.png";
// import OurExperts_bg from "../../assets/Illustrations/Polygon-Light.png";
import uuid from 'react-uuid'
import ProfileNavbar from "../ProfileNavbar/ProfileNavbar";
import Header from "../../../Containers/Header/Header";
import {Footer} from "../../../Containers/Footer/Footer";

export const ReferalsRedeem = () => {
    return (
        <>
            <Header page={"referral"}/>
            <ProfileNavbar active_page={"Referrals & Coupons"}>
            <div className={'ReferalsRedeem'}>
                <Invite/>
                <ReferalsRedeemTabs/>
            </div>
            </ProfileNavbar>
            <Footer/>
        </>
    )
}

const ReferalsRedeemTabs = () => {
    const [active1,setactive1]=useState(true)
    const [active2,setactive2]=useState(false)
    const function1=()=>{
        setactive2(false)
        setactive1(true)
    }

    const function2=()=>{
        setactive1(false)
        setactive2(true)
    }

    return (
       <>
        <div className={'ReferalsRedeemTabs'}>
            <div className={'d-flex main_flex'}>

                <div className={active1?'enable':'disable'} onClick={()=>function1()}>
                    Referrals
                    <div className={active1?'border_marker':'hide_mark'}> <hr/>  </div>
                </div>

                <div className={active2?'enable':'disable'} onClick={()=>function2()}>
                    Redeem
                    <div className={active2?'border_marker':'hide_mark'}> <hr/>  </div>
                </div>
            </div>
        </div>
            <LayoutContainer>
                {/*<ReferralsCode/>*/}
                <PendingReferrals/>
            </LayoutContainer>
            </>
    )
}

const ReferralsCode=()=>{
    return(
        <div className={'ReferralsCode'}>
            <div className={'row'}>

                {[1, 2, 3, 4].map((item, index) => {
                    return (
                        <div className={'col-md-6 col-12'} key={uuid()}>
                            <div className="card card_border mx-auto">
                                <div className="container">
                                    <div className="d-flex section_flex">
                                        <div>
                                            <img src={gift} alt="gift" className="gift_image"/>
                                        </div>
                                        <div className="text">
                                            <p className="sub_num m-0">10%</p>
                                            <p className="sub_text m-0">Discount</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="linear_colors">
                                    <div className={'left_half_circle'}>

                                    </div>
                                    <div className={'right_half_circle'}>

                                    </div>
                                    <hr className="higher_section"/>
                                    <div className="container">
                                        <div className="card card_parts">
                                            <div className="d-flex align-items-center justify-content-center">
                                                <p className="code ">1XVI091SUCHITHA</p>
                                                <img src={copy_img} alt="..." className="box_image"/>
                                            </div>
                                        </div>
                                        <p className="carder_texts">Valid through November 21, 2022</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}




             </div>
        </div>
    );
}
const PendingReferrals=()=>{
    return(
        <div className={'PendingReferrals'}>
            <div className={''}>

            </div>
             <Stepper activeStep={2}>
                <Step label="Children Step 1" />
                <Step label="Children Step 2" />
                <Step label="Children Step 3" />
                 <Step label="Children Step 4" />
                 <Step label="Children Step 5" />
            </Stepper>
        </div>
    );
}
//,
