export const FETCH_WISHLIST_BEGIN   = 'FETCH_PRODUCTS_BEGIN';
export const FETCH_WISHLIST_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_WISHLIST_FAILURE = 'FETCH_PRODUCTS_FAILURE';

export const CLEAR_WISHLIST = 'CLEAR_WISHLIST';


export const fetchWishListBegin = () => ({
    type: FETCH_WISHLIST_BEGIN
});

export const fetchWishListSuccess = courses => ({
    type: FETCH_WISHLIST_SUCCESS,
    payload: { courses }
});

export const fetchWishListFailure = error => ({
    type: FETCH_WISHLIST_FAILURE,
    payload: { error }
});

export const clearWishList = () => ({
    type: CLEAR_WISHLIST,
});
