import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'

const mutation = graphql`
    mutation ForgetPasswordChangeMutation(
        $confirmPassword:String!
        $newPassword:String!
    ) {
        forgetPasswordChange(newPassword: $newPassword,confirmPassword: $confirmPassword ){
          status
            message

        }
    }
`

export default (data, callback,errCallback) => {
    const variables = {
        newPassword: data.newPassword,
        confirmPassword:data.confirmPassword
    }

    // 5
    commitMutation(
        environment,
        {
            mutation,
            variables,
            // 6
            onCompleted: (response,err) => {

                if(response.forgetPasswordChange !== null){
                    callback(response)
                } else{
                    errCallback(err[0].message);
                }

            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}
