import './Reviews.scss'
import Group19880 from "../../../assets/images/Group19880.png"
import ReactStars from "react-rating-stars-component";
import editicon from '../../../assets/icons/edit-icon.svg'
import React, {useEffect, useState} from "react";
import useMediaQuery from "../../../util/useMediaQuery";
import {fetchQuery} from "relay-runtime";
import environment from "../../../Environment";
import ProfileReviewsQuery from "../../../queries/ProfileReviewsQuery";
import cogoToast from "cogo-toast";
import UpdateReviewMutation from "../../../mutations/UpdateReviewMutation";
import uuid from 'react-uuid'
import ProfileNavbar from "../ProfileNavbar/ProfileNavbar";
import Header from "../../../Containers/Header/Header";
import {Footer} from "../../../Containers/Footer/Footer";
import ErrorMessage from "../../../Components/ErrorMessage/ErrorMessage";
import emptycart_img from "../../../assets/icons/emptyCart.svg";

export const Reviews = () => {
    const isDesktop = useMediaQuery("(min-width: 770px)");
    const [editdetails, seteditdetails] = useState(false);
    const [reviewList, setReviewList] = useState([]);
    const [valueErrors, setValueErrors] = useState({});
    useEffect(() => {
        fetchReviewList()
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const fetchReviewList = () => {
        fetchQuery(environment, ProfileReviewsQuery)
            .toPromise()
            .then(response => {
                if (response.myProfile !== null) {
                    setReviewList(response.myProfile.reviews.edges);
                } else {
                    setReviewList([]);
                }
            }, err => {
                // console.log(err);
                setReviewList([]);
            });
    }
    const [ratingstate, setrating] = useState();
    const [contentstate, setContent] = useState('');
    const [reviewidstate, setreviewid] = useState('');

    useEffect(() => {
        if (Object.keys(reviewList).length > 0) {
            setreviewid(reviewList[0]['node']['id'])
            setrating((reviewList[0]['node']['rating']))
            // console.log(ratingstate)
        }
    }, [reviewList])

    const handleTextChange = (e,reviewItem) => {
        let index = reviewList.findIndex(review => review.node.id === reviewItem.id);
        let tmp_review_list = reviewList.slice();
        if(index > -1){
            tmp_review_list[index]={
                ...tmp_review_list[index],
               node:{
                   ...tmp_review_list[index]['node'],
                   content:e.target.value
               }
            }
            setReviewList(tmp_review_list);
        }
    };

    const saveReview = (reviewItem) => {
        let error = {};
        if (reviewItem.content === "") {
            error = {
                id: reviewItem.id,
                message: "Please fill content"
            };
            setValueErrors(error);
        } else {
            let userData = {
                content: reviewItem.content,
                rating: reviewItem.rating,
                reviewId: reviewItem.id,
            };
            error = {};
            setValueErrors(error);
            UpdateReviewMutation(userData, function (response) {
                if (response.updateReview !== null) {
                    seteditdetails(false);
                    cogoToast.success("Review Updated Successfully", {position: 'top-center'});
                } else {
                    seteditdetails(true);
                    cogoToast.error("something went wrong", {position: 'top-center'});
                }
            }, function (err) {
                seteditdetails(true);
                cogoToast.error(err, {position: 'top-center'});
            });
        }
    }

    const ratingChanged = (newValue,reviewItem) => {
        let index = reviewList.findIndex(review => review.node.id === reviewItem.id);
        let tmp_review_list = reviewList.slice();
        if(index > -1){
            tmp_review_list[index]={
                ...tmp_review_list[index],
                node:{
                    ...tmp_review_list[index]['node'],
                    rating:newValue
                }
            }
            setReviewList(tmp_review_list);
        }
    }
    return (
        <>
            <Header page={"reviews"}/>
            <ProfileNavbar active_page={"Reviews"}>
            <div className={'Reviews'}>
                <div className={'title'}>
                    Course Reviews
                </div>
                {
                    reviewList.length > 0 && reviewList.map((item) => {
                        let reviewItem = item.node;
                        return (
                            <div className={'card1'} key={uuid()}>
                                <div className={"title-section"}>
                                    <div className={'title1'}>{reviewItem.course.name}</div>
                                    {
                                        isDesktop &&
                                        <>
                                            {
                                                editdetails === false ?
                                                    <div className={'editicon'} onClick={() => seteditdetails(!editdetails)}>
                                                        <u>Edit the review</u>
                                                        <img src={editicon} alt={'edit-icon'} className={'editico pl-2'}/>
                                                    </div>
                                                    :
                                                    <div className={'editicon'} onClick={()=>saveReview(reviewItem)}>
                                                        <u>Save the review</u>
                                                        <img src={editicon} alt={'edit-icon'} className={'editico pl-2'}/>
                                                    </div>
                                            }
                                        </>
                                    }
                                </div>
                                <div className={'name'}>By {reviewItem.instructor && reviewItem.instructor.user.name}</div>
                                <div className={'img'}>
                                    <ReactStars
                                        count={5}
                                        onChange={newValue => {
                                            ratingChanged(newValue,reviewItem);
                                        }}
                                        size={20}
                                        color={"rgba(233, 181, 28, 1)"}
                                        activeColor={"rgba(233, 181, 28, 1)"}
                                        value={reviewItem.rating}
                                        edit={editdetails}
                                        a11y={true}
                                        isHalf={true}
                                        emptyIcon={<i className="far fa-star"/>}
                                        halfIcon={<i className="fa fa-star-half-alt"/>}
                                        filledIcon={<i className="fa fa-star"/>}
                                    />
                                </div>
                                {
                                    editdetails === true ?
                                        <div className={"editable"}>
                                            <textarea value={reviewItem.content}
                                                      onChange={(e) => handleTextChange(e,item.node)}>
                                            </textarea>
                                            {
                                                valueErrors.id === reviewItem.id &&
                                                <p className={"error-message"}>{valueErrors.message}</p>
                                            }
                                        </div>
                                        :
                                        <div className={"inner_card"}>
                                            <img src={Group19880} alt={'Group19880'} className={'shap'}/>
                                            <p className={'para'}>
                                                {reviewItem.content}
                                            </p>
                                        </div>
                                }
                                {
                                    isDesktop === false &&
                                    <>
                                        {
                                            editdetails === false ?
                                                <div className={'editicon'} style={{textAlign:"end"}} onClick={() => seteditdetails(!editdetails)}>
                                                    <u>Edit the review</u>
                                                    <img src={editicon} alt={'edit-icon'} className={'editico pl-2'}/>
                                                </div>
                                                :
                                                <div className={'editicon'} style={{textAlign:"end"}} onClick={()=>saveReview(reviewItem)}>
                                                    <u>Save the review</u>
                                                    <img src={editicon} alt={'edit-icon'} className={'editico pl-2'}/>
                                                </div>
                                        }
                                    </>
                                }
                            </div>
                        );
                    })
                }
                {
                    reviewList.length === 0 &&
                    <ErrorMessage img={emptycart_img} title={"No review are Add"} text={"Looks like you haven’t added any review "} onclickhandeler={`/course-list`}/>
                }
            </div>
        </ProfileNavbar>
            <Footer/>
        </>
    )
}
