import {MDBBox} from "mdbreact";
import "./ProfessorCard.scss"
import ActiveIllustrator from "../../assets/Illustrations/Polygon-Light.png";
import {Link} from "react-router-dom";
import {useRef} from "react";
import Instructor from "../../assets/images/professorpic.svg";




export const ProfessorCard = (props) => {
    const imageEl = useRef(null);
    const {professorData} = props;
    return(
        <MDBBox className={"professor-card-component"} >
            <Link to={"/professor-detail/edit/"+professorData.id}>
                <MDBBox className={"instructor-image-section"}>
                    {/* <img src={ActiveIllustrator} alt={"expertbg"} className={"img-fluid instructor-bg"}/> */}
                    <img src={professorData.profilePic?professorData.profilePic:Instructor}
                         alt={"expert"} className={"img-fluid instructor-profile"}
                         ref={imageEl}
                         onError={() => {
                             imageEl.current.src = Instructor;
                         }}
                    />
                    {/* <div className={'shadow'}>

                    </div> */}
                </MDBBox>
                <MDBBox className={"instructor-detail-section"}>
                    <h3 className={'professor-name'}>{professorData.user.name}</h3>
                    <p className={'role'}>{professorData.designation}</p>
                    <p className={"city"}>{professorData.organization}</p>
                </MDBBox>
            </Link>
        </MDBBox>
    );
}
