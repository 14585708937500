/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type VideoEventMutationVariables = {|
  currentTime?: ?string,
  duration?: ?string,
  lessonId: string,
|};
export type VideoEventMutationResponse = {|
  +event: ?{|
    +status: ?string
  |}
|};
export type VideoEventMutation = {|
  variables: VideoEventMutationVariables,
  response: VideoEventMutationResponse,
|};
*/


/*
mutation VideoEventMutation(
  $currentTime: String
  $duration: String
  $lessonId: String!
) {
  event(currentTime: $currentTime, duration: $duration, lessonId: $lessonId) {
    status
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "currentTime"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "duration"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lessonId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "currentTime",
        "variableName": "currentTime"
      },
      {
        "kind": "Variable",
        "name": "duration",
        "variableName": "duration"
      },
      {
        "kind": "Variable",
        "name": "lessonId",
        "variableName": "lessonId"
      }
    ],
    "concreteType": "CreateorUpdateWatchLogMutation",
    "kind": "LinkedField",
    "name": "event",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VideoEventMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VideoEventMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d3095cd1a45fcf85ed6af3fa510b6297",
    "id": null,
    "metadata": {},
    "name": "VideoEventMutation",
    "operationKind": "mutation",
    "text": "mutation VideoEventMutation(\n  $currentTime: String\n  $duration: String\n  $lessonId: String!\n) {\n  event(currentTime: $currentTime, duration: $duration, lessonId: $lessonId) {\n    status\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '907a8805f6a4822a7112701e16e34c3e';

module.exports = node;
