import graphql from "babel-plugin-relay/macro";

const PaymentHistoryListQuery=graphql`

    query PaymentHistoryListQuery{
        orders{
            edges{
                node{
                    id
                    orderId
                    totalPayable
                    updatedAt
                }
            }
        }
    }
`

export default PaymentHistoryListQuery
