/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EnrollStatus = "CANCELED" | "COMPLETED" | "NOT_STARTED" | "STARTED" | "%future added value";
export type MyCoursesListQueryVariables = {||};
export type MyCoursesListQueryResponse = {|
  +myEnrolls: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +status: EnrollStatus,
        +certificateUrl: string,
        +course: {|
          +id: string,
          +name: string,
          +image: ?string,
          +mobileImage: ?string,
          +instructor: ?{|
            +user: {|
              +name: ?string
            |}
          |},
          +durationInMonth: ?string,
          +lessonsLeftToComplete: ?string,
          +percentageCompleted: ?string,
          +weeklyWatchHours: ?string,
        |},
      |}
    |}>
  |}
|};
export type MyCoursesListQuery = {|
  variables: MyCoursesListQueryVariables,
  response: MyCoursesListQueryResponse,
|};
*/


/*
query MyCoursesListQuery {
  myEnrolls {
    edges {
      node {
        id
        status
        certificateUrl
        course {
          id
          name
          image
          mobileImage
          instructor {
            user {
              name
              id
            }
            id
          }
          durationInMonth
          lessonsLeftToComplete
          percentageCompleted
          weeklyWatchHours
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "certificateUrl",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mobileImage",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "durationInMonth",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lessonsLeftToComplete",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "percentageCompleted",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "weeklyWatchHours",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MyCoursesListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EnrollNodeConnection",
        "kind": "LinkedField",
        "name": "myEnrolls",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EnrollNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EnrollNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CourseType",
                    "kind": "LinkedField",
                    "name": "course",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InstructorType",
                        "kind": "LinkedField",
                        "name": "instructor",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserType",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MyCoursesListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EnrollNodeConnection",
        "kind": "LinkedField",
        "name": "myEnrolls",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EnrollNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EnrollNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CourseType",
                    "kind": "LinkedField",
                    "name": "course",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InstructorType",
                        "kind": "LinkedField",
                        "name": "instructor",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserType",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v0/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "39cf162fbc7c2a1bdfd2d77024655d9e",
    "id": null,
    "metadata": {},
    "name": "MyCoursesListQuery",
    "operationKind": "query",
    "text": "query MyCoursesListQuery {\n  myEnrolls {\n    edges {\n      node {\n        id\n        status\n        certificateUrl\n        course {\n          id\n          name\n          image\n          mobileImage\n          instructor {\n            user {\n              name\n              id\n            }\n            id\n          }\n          durationInMonth\n          lessonsLeftToComplete\n          percentageCompleted\n          weeklyWatchHours\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7475c1b1e1292c066bfc0fb03b1e4b33';

module.exports = node;
