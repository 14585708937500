import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'

const mutation = graphql`
  mutation ApplyVoucherMutation(
    $voucherCode: String!
  ) {
     applyVoucher(voucherCode:$voucherCode){
        status
        cart{
            id
            total
            discount
            discountName
            voucherCode
            checkoutitems{
                edges{
                    node{
                        course{
                            id
                            name
                            instructor{
                                user{
                                    name
                                }
                            }
                            image
                            price
                            discountPrice
                            averageRating
                            isWishlist
                        }
                    }
                }
            }
        }
     }
  }
`

export default (data, callback,errCallback) => {
    const variables = {
        voucherCode: data.voucherCode,
    }

    // 5
    commitMutation(
        environment,
        {
            mutation,
            variables,
            // 6
            onCompleted: (response,err) => {

                if(response.applyVoucher !== null){
                    callback(response)
                } else{
                    errCallback(err[0].message);
                }

            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}
