import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'

const mutation = graphql`
  mutation UpdateAllModuleMutation(
    $data: [ModuleCustomInputType]
    $courseId: String!
  ) {
     updateModule(
        data:$data
        courseId: $courseId
     ){    
        modules{
            id
            name
            lessonCount
            isCompleted
            totalDuration
            order
            description
            isActive
            lessons{
                 edges{
                    node{
                        id
                        name
                        video
                        type
                        currentTime
                        isWatched
                        isWatching
                        transcodedVideo
                        isTranscoded
                        duration
                        transcript
                        isActive
                        order
                    }
                 }
            }
        }    
        message
        status
     }
  }
`

export default (modules, callback,errCallback) => {
    const variables = {
        data:modules.data,
        courseId: modules.courseId
    }
    // 5
    commitMutation(
        environment,
        {
            mutation,
            variables,
            // 6
            onCompleted: (response,err) => {

                if(response.updateModule !== null){
                    callback(response)
                } else{
                    errCallback(err[0].message);
                }

            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}
