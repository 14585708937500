/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ForgetPasswordChangeMutationVariables = {|
  confirmPassword: string,
  newPassword: string,
|};
export type ForgetPasswordChangeMutationResponse = {|
  +forgetPasswordChange: ?{|
    +status: ?string,
    +message: ?string,
  |}
|};
export type ForgetPasswordChangeMutation = {|
  variables: ForgetPasswordChangeMutationVariables,
  response: ForgetPasswordChangeMutationResponse,
|};
*/


/*
mutation ForgetPasswordChangeMutation(
  $confirmPassword: String!
  $newPassword: String!
) {
  forgetPasswordChange(newPassword: $newPassword, confirmPassword: $confirmPassword) {
    status
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "confirmPassword"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "newPassword"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "confirmPassword",
        "variableName": "confirmPassword"
      },
      {
        "kind": "Variable",
        "name": "newPassword",
        "variableName": "newPassword"
      }
    ],
    "concreteType": "ForgetPasswordChangeMutation",
    "kind": "LinkedField",
    "name": "forgetPasswordChange",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ForgetPasswordChangeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ForgetPasswordChangeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d2f10140d8aea93019b32aaa9bf46ba5",
    "id": null,
    "metadata": {},
    "name": "ForgetPasswordChangeMutation",
    "operationKind": "mutation",
    "text": "mutation ForgetPasswordChangeMutation(\n  $confirmPassword: String!\n  $newPassword: String!\n) {\n  forgetPasswordChange(newPassword: $newPassword, confirmPassword: $confirmPassword) {\n    status\n    message\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cc42cada8143d255715ba4903456db61';

module.exports = node;
