import {fetchCartListSuccess} from "../../Actions/CartAction";
import {clearCheckout} from "../../Actions/OrderAction";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import React, {useEffect} from "react";
import {Razorpay_Key} from "../../serviceApi";
import {BasicButton} from "../../Components/Buttons/BasicButton";
import Header from "../../Containers/Header/Header";
import {Footer} from "../../Containers/Footer/Footer";


const Payment = (props) => {
    useEffect(function (){
        if(Object.keys(props.checkout_data).length > 0){
            processPayment();
        } else {
            props.history.push('/');
        }
    },[]);

    const processPayment = () => {
        let data = props.checkout_data;
        var options = {
            "key": Razorpay_Key, // Enter the Key ID generated from the Dashboard
            "amount": data.order_data.totalPayable, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise or INR 500.
            "currency": "INR",
            "name": "Vertical Limit",
            "image": "",
            "order_id": data.rzpOrderId,//This is a sample Order ID. Create an Order using Orders API. (https://razorpay.com/docs/payment-gateway/orders/integration/#step-1-create-an-order). Refer the Checkout form table given below
            "handler": function (response,err){
                sendPaymentResponse();
            },
            "prefill": {
                "name": data.order_data.user.name,
                "email": data.order_data.user.email,
                "contact": data.order_data.user.mobileNumber
            },
            "notes": {
            },
            "theme": {
                "color": "#000"
            }
        };
        const rzp1 = new window.Razorpay(options);

        rzp1.open();
    }
    const sendPaymentResponse = () => {
        // props.clearCheckout();
        props.history.push('/myprofile/mycourses');
    }

    return(
        <>
            <Header page={""}/>
            <div>
            <div className="order-confirmation">
                <h3 className={"text-center"}>
                    Make Payment <br /> To Complete Your Order
                </h3>
                <div className="order-confirmation__actions">
                    <BasicButton handleClick={processPayment}>Make Payment</BasicButton>
                </div>
            </div>
        </div>
            <Footer/>
        </>
    );
}

const mapStateToProps = state => ({
    checkout_data: state.OrderReducer.checkout_data,
});

const mapDispatchToProps = dispatch => ({
    cartListSuccess: (data) => dispatch(fetchCartListSuccess(data)),
    clearCheckout: () => dispatch(clearCheckout()),
});


export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Payment);
