import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'

const mutation = graphql`
  mutation VideoEventMutation(
    $currentTime: String
    $duration: String
    $lessonId: String!
  ) {
     event(currentTime:$currentTime,duration:$duration,lessonId:$lessonId){
        status
     }
  }
`

export default (inputData, callback,errCallback) => {
    const variables = {
        currentTime:inputData.currentTime,
        duration:inputData.duration,
        lessonId:inputData.lessonId
    }

    // 5
    commitMutation(
        environment,
        {
            mutation,
            variables,
            // 6
            onCompleted: (response,err) => {

                if(response.event !== null){
                    callback(response)
                } else{
                    errCallback(err[0].message);
                }

            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}
