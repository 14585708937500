/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ConsultationStatusEnum = "APPROVED" | "COMPLETED" | "REJECTED" | "%future added value";
export type ChangeConsultationStatusMutationVariables = {|
  consultationId: string,
  status: ConsultationStatusEnum,
|};
export type ChangeConsultationStatusMutationResponse = {|
  +changeConsultationStatus: ?{|
    +status: ?string
  |}
|};
export type ChangeConsultationStatusMutation = {|
  variables: ChangeConsultationStatusMutationVariables,
  response: ChangeConsultationStatusMutationResponse,
|};
*/


/*
mutation ChangeConsultationStatusMutation(
  $consultationId: String!
  $status: ConsultationStatusEnum!
) {
  changeConsultationStatus(consultationId: $consultationId, status: $status) {
    status
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "consultationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "status"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "consultationId",
        "variableName": "consultationId"
      },
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      }
    ],
    "concreteType": "ChangeConsultationStatus",
    "kind": "LinkedField",
    "name": "changeConsultationStatus",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeConsultationStatusMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangeConsultationStatusMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bcf1253ca350c88d2940ba5cbbbfbd61",
    "id": null,
    "metadata": {},
    "name": "ChangeConsultationStatusMutation",
    "operationKind": "mutation",
    "text": "mutation ChangeConsultationStatusMutation(\n  $consultationId: String!\n  $status: ConsultationStatusEnum!\n) {\n  changeConsultationStatus(consultationId: $consultationId, status: $status) {\n    status\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '660316cc9c1767fea0f81c6dda5eecbc';

module.exports = node;
