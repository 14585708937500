import "./Login.scss";
// import Logo from "../../assets/images/Logo.png";
import Logo from "../../assets/images/impacteers-logo.svg"
import close_bt from "../../assets/images/close_bt.png";
import yellow_crt_btn from "../../assets/images/yellow_crt_btn.png";
import {Fragment, useContext, useState} from "react";
import * as EmailValidator from "email-validator";
import cogoToast from "cogo-toast";
import LoginMutation from "../../mutations/LoginMutation";
// import AuthContext from "../../Providers/AuthProvider/auth-context";
import {UserLoggedin} from "../../Actions";
import {connect} from "react-redux";
import {fetchQuery} from "relay-runtime";
import environment from "../../Environment";
import MyCartQuery from "../../queries/MyCartQuery";
import {fetchCartListBegin, fetchCartListFailure, fetchCartListSuccess} from "../../Actions/CartAction";
import MyWishListQuery from "../../queries/MyWishListQuery";
import {fetchWishListBegin, fetchWishListFailure, fetchWishListSuccess} from "../../Actions/wishListAction";
import {withRouter} from 'react-router-dom';
import {compose} from "redux";
import redStar from "../../assets/icons/_.svg";
import green_crt_btn from "../../assets/icons/akar-icons_circle-check-fill-green.svg";
import useMediaQuery from "../../util/useMediaQuery";
import SentOtpMutation from "../../mutations/SentOtpMutation";
import {BasicButton} from "../../Components/Buttons/BasicButton";
import VerifyOtpMutation from "../../mutations/VerifyOtpMutation";
import ForgetPasswordChangeMutation from "../../mutations/ForgetPasswordChangeMutation";
import ForgetPasswordMutation from "../../mutations/ForgetPasswordMutation";
import ForgetPasswordVerifyMutation from "../../mutations/ForgetPasswordVerifyMutation";

 const Login = (props) => {
    const [mob_or_email, setMobileEmail] = useState("");
    const [password, setPassword] = useState("");
    const [click, setclick] = useState("");
    const [valueErrors, setValueErrors] = useState({});
     const isDesktop = useMediaQuery("(min-width: 765px)");

     const [getForgetPassword,setForgetPassword]=useState(false)
     const [getMobileNumber,setMobileNumber]=useState('')
     const [isMobileNumberverified,setMobileNumberverified]=useState(false)
     const [getOtp,setOtp]=useState('')
     const [isOptAndMobileNumberValid,setOptAndMobileNumberValid]=useState(false)
     const [getNewPassword,setNewPassword]=useState('')
     const [getConfirmNewPassword,setConfirmNewPassword]=useState('')
    // const authContext = useContext(AuthContext);

    const submitForm = () => {
        let error = {};
        if (mob_or_email === "") {
            error = {
                field: "email",
                message: "Please fill email or mobile number"
            };
            setValueErrors(error);
        } else if (mob_or_email !== "") {
            let is_email = validateEmail(mob_or_email);
            let is_mobile_number = validatePhone(mob_or_email);
            if(is_email === true && EmailValidator.validate(mob_or_email) === false){
                error = {
                    field: "email",
                    message: "Please fill valid email"
                };
                setValueErrors(error);
            } else if(is_mobile_number === true && mob_or_email.length < 10){
                error = {
                    field: "email",
                    message: "Please fill valid mobile number"
                };
                setValueErrors(error);
            } else if(is_email === false && is_mobile_number === false) {
                error = {
                    field: "email",
                    message: "Please fill valid email or mobile number"
                };
                setValueErrors(error);
            } else if (password === "") {
                error = {
                    field: "password",
                    message: "password can't be empty"
                };
                setValueErrors(error);
            } else {
                let userData = {
                    emailOrMobile: mob_or_email,
                    password: password
                };
                error = {};
                setValueErrors(error);
                LoginMutation(userData, function (response) {
                    if (response.login !== null && response.login.user !== null && response.login.token) {
                        cogoToast.success(response.login.message, {position: 'top-center'});
                        props.sendUserDatasToStore(response.login.user);
                        localStorage.setItem('user_token',response.login.token);
                        props.closeLogin();
                        getCartData();
                        getMyWishList();
                        if(response.login.user.isInstructor === true){
                            props.history.push('/professor/dashboard');
                        } else {
                            window.location.reload();
                        }
                    } else {
                        cogoToast.error("something went wrong", {position: 'top-center'});
                    }
                }, function (err) {
                    cogoToast.error(err, {position: 'top-center'});
                });
            }

        }
    }

    const getCartData = () => {
         props.beginCartList();
         fetchQuery(environment, MyCartQuery)
             .toPromise()
             .then(response => {
                 if (response.myCart !== null) {
                     let dataFormate = {
                         id:response.myCart.id,
                         total:parseInt(response.myCart.total),
                         discount:parseInt(response.myCart.discount),
                         discountName:response.myCart.discountName,
                         checkoutitems:response.myCart.checkoutitems.edges
                     }
                     props.cartListSuccess(dataFormate);
                 } else  {
                     let error = {
                         message: "No Data Found"
                     }
                     props.cartListFailure(error);
                 }
             }, err => {
                 let error = {
                     message: "No Data Found"
                 }
                 props.cartListFailure(error);
             });
     }

     const getMyWishList = () => {
         props.beginWishList();
         fetchQuery(environment, MyWishListQuery)
             .toPromise()
             .then(response => {
                 if (response.myWishList !== null) {
                     props.wishListSuccess(response.myWishList);
                 } else {
                     let error = {
                         message: "No Data Found"
                     }
                     props.wishListFailure(error);
                 }
             }, err => {
                 let error = {
                     message: "No Data Found"
                 }
                 props.wishListFailure(error);
             });
     }

     const validateEmail = (email) => {
         let re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
         return re.test(email);
     }

     const validatePhone = (phone) => {
         let phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
         if(phone.match(phoneno)) {
             return true;
         }
         else {
             return false;
         }
     }


     const verifyMobileNumber = () => {
         let error = {};
        if (getMobileNumber.length < 10) {
             error = {
                 field: "mobileNumber",
                 message: "Mobile number should be 10 digits"
             };
             setValueErrors(error);
         } else if (getMobileNumber === "") {
             error = {
                 field: "mobileNumber",
                 message: "Mobile number can't be empty"
             };
             setValueErrors(error);
         } else {
             let userData = {
                 emailOrMobile: getMobileNumber,
             };
             error = {};
             setValueErrors(error);
             // props.setLoading(true);
            ForgetPasswordMutation(userData, function (response) {
                 // props.setLoading(false);
                 if (response.sentOtp !== null && response.forgetPassword.otp) {
                     setMobileNumberverified(true);
                     cogoToast.success(response.forgetPassword.otp, {position: 'top-center'});
                 } else {
                     setMobileNumberverified(false);
                     cogoToast.error("something went wrong", {position: 'top-center'});
                 }
             }, function (err) {
                 // props.setLoading(false);
                 setMobileNumberverified(false);
                 cogoToast.error(err, {position: 'top-center'});
             });

         }
     }

     const verifyOtp = () => {
         let error = {};
         if (getMobileNumber.length < 10) {
             error = {
                 field: "mobileNumber",
                 message: "Mobile number should be 10 digits"
             };
             setValueErrors(error);
         } else if (getMobileNumber === "") {
             error = {
                 field: "mobileNumber",
                 message: "Mobile number can't be empty"
             };
             setValueErrors(error);
         } else if (getOtp.length < 4) {
             error = {
                 field: "otp",
                 message: "OTP number should be 4 digits"
             };
             setValueErrors(error);
         } else if (getOtp === "") {
             error = {
                 field: "otp",
                 message: "OTP number can't be empty"
             };
             setValueErrors(error);
         } else {
             let userData = {
                 mobile: getMobileNumber,
                 otp: getOtp
             };
             error = {};
             setValueErrors(error);
             // props.setLoading(true);
             ForgetPasswordVerifyMutation(userData, function (response) {
                 // props.setLoading(false);
                 // debugger
                 if (response.forgetPasswordVerify !== null) {
                     setOptAndMobileNumberValid(true);
                     localStorage.setItem('user_token',response.forgetPasswordVerify.token);
                     cogoToast.success(response.forgetPasswordVerify.message, {position: 'top-center'});

                 } else {
                     setOptAndMobileNumberValid(false);
                     cogoToast.error("something went wrong", {position: 'top-center'});
                 }
             }, function (err) {
                 // props.setLoading(false);
                 setOptAndMobileNumberValid(false);
                 cogoToast.error(err, {position: 'top-center'});
             });
         }
     };

     const PasswordChange=()=>{
         let error = {};
         if (getNewPassword.length < 4) {
             error = {
                 field: "NewPassword",
                 message: "password should be minimum 4 digits"
             };
             setValueErrors(error);
         } else if (getNewPassword !== getConfirmNewPassword) {
             error = {
                 field: "confirmPassword",
                 message: "your Password is not matching"
             };
             setValueErrors(error);
         }  else {
             let userData = {
                 newPassword:getNewPassword,
                 confirmPassword:getConfirmNewPassword
             };
             error = {};
             setValueErrors(error);
             // props.setLoading(true);
             ForgetPasswordChangeMutation(userData, function (response) {
                 // props.setLoading(false);
                 if (response.forgetPasswordChange !== null) {
                     localStorage.clear('user_token');
                     props.history.push('/')
                     cogoToast.success(response.forgetPasswordChange.message, {position: 'top-center'});
                 } else {
                     cogoToast.error("something went wrong", {position: 'top-center'});
                 }
             }, function (err) {
                 // props.setLoading(false);
                 cogoToast.error(err, {position: 'top-center'});
             });
         }
     }


     const handleMobileNumberChange=(e)=>{
         let value = (e.target.validity.valid || e.target.value === "") ? e.target.value : getMobileNumber;
         setMobileNumber(value);
     }

     const handleConfirmNewPasswordChange=(e)=>{
         setConfirmNewPassword(prevState => e.target.value)
     }
     const handleNewPasswordChange=(e)=>{
         setNewPassword(prevState =>  e.target.value)
     }

     const handleOTPChange = (e) => {
         let value = (e.target.validity.valid || e.target.value === "") ? e.target.value : getOtp;
         setOtp(value);
     }




    return (
        <div className="login">
            <div className="main"
                 // data-aos="fade-left"
                 // data-aos-anchor="#example-anchor"
                 // data-aos-offset="500"
                 // data-aos-duration="500"
            >
                <img src={close_bt} alt="close button" className="closebutton" onClick={props.closeLogin}/>
                <div className="row mbg-w">
                    <div className="col-md-5 col-sm-12 side-logo ">
                        <div className="inner-side">
                            <img src={Logo} className="Logo" alt="Logo"/>
                            <span>Welcome back to Impacteers!</span>
                        </div>
                    </div>
                    {getForgetPassword === true ?

                            isOptAndMobileNumberValid === true ?
                                <div className="col-md-7 col-sm-12 content ">
                                    <div className={"form-feild-item new-password forget-password"}>
                                        <div className="Mobile form-label">New Password
                                            <span>
                                        <img src={redStar} className="redStar" alt="redStar"/>
                                    </span>
                                        </div>
                                        <div className="conf_phone">
                                            <input
                                                type="password"
                                                required
                                                className={'phone'}
                                                value={getNewPassword}
                                                placeholder={'New Password'}
                                                onChange={(e) => handleNewPasswordChange(e)}
                                            />
                                        </div>
                                        {
                                            valueErrors.field === "NewPassword" &&
                                            <p className={"error-message"}>{valueErrors.message}</p>
                                        }
                                        <div className="Mobile mobile-top form-label">Confirm Password
                                            <span>
                                        <img src={redStar} className="redStar" alt="redStar"/>
                                    </span>
                                        </div>
                                        <div className={'d-flex custome-opt-container'}>
                                            <input type={'password'}
                                                   className={'phone'}
                                                   placeholder="Re-Type your new password"
                                                   required
                                                // style={codeverified ? {border: '0.5px solid #58A882'} : {}}
                                                   value={getConfirmNewPassword}
                                                   onChange={(e) => handleConfirmNewPasswordChange(e)}
                                            />
                                            {
                                                valueErrors.field === "confirmPassword" &&
                                                <p className={"error-message"}>{valueErrors.message}</p>
                                            }
                                        </div>
                                        <div className={'custome-enter-btn'} onClick={()=>PasswordChange()}>
                                            <BasicButton>
                                                Enter
                                            </BasicButton>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="col-md-7 col-sm-12 content forget-password">
                                    <div className={"form-feild-item"}>
                                        <div className="Mobile form-label">Mobile Number
                                            <span>
                                        <img src={redStar} className="redStar" alt="redStar"/>
                                    </span>
                                        </div>
                                        <div className="conf_phone">
                                            <input
                                                type="text"
                                                pattern="[0-9]*"
                                                maxLength="10"
                                                placeholder="10-Digit Number"
                                                className="phone"
                                                required
                                                style={isMobileNumberverified ? {border: '0.5px solid #58A882'} : {}}
                                                value={getMobileNumber}
                                                onInput={(e) => handleMobileNumberChange(e)}
                                                onChange={(e) => handleMobileNumberChange(e)}
                                            />
                                            {isDesktop ?
                                                <Fragment>
                                                    <button
                                                        type="button"
                                                        className="conf-btn w-62"
                                                        style={isMobileNumberverified ? {display: "none"} : {}}
                                                        onClick={() => verifyMobileNumber()}
                                                    >
                                                        Verify
                                                    </button>

                                                    <div style={isMobileNumberverified ? {} : {display: "none"}}>

                                                        <img
                                                            src={green_crt_btn}
                                                            className="yellow_crt_btn1"
                                                            alt="yellow_crt_btn"
                                                        />
                                                    </div>
                                                </Fragment>
                                                :
                                                <Fragment></Fragment>
                                            }
                                        </div>
                                        {
                                            valueErrors.field === "mobileNumber" &&
                                            <p className={"error-message"}>{valueErrors.message}</p>
                                        }
                                        <div className="Mobile mobile-top form-label">Verification Code
                                            <span>
                                        <img src={redStar} className="redStar" alt="redStar"/>
                                    </span>
                                        </div>
                                        <div className={'d-flex custome-opt-container'}>
                                            <input type={'text'}
                                                   className={'opt-input-field phone'}
                                                   pattern="[0-9]*"
                                                   maxLength="4"
                                                   placeholder="x-x-x-x"
                                                   required
                                                // style={codeverified ? {border: '0.5px solid #58A882'} : {}}
                                                   value={getOtp}
                                                   onInput={(e) => handleOTPChange(e)}
                                                   onChange={(e) => handleOTPChange(e)}
                                            />
                                            <p className={"to_login text-right pt-3 mb-0"} onClick={() => verifyMobileNumber()}>
                                        <span className="login-sapn">
                                            <u>Resend Otp</u>
                                        </span>
                                            </p>
                                        </div>
                                        <div className={'custome-enter-btn'} onClick={()=>verifyOtp()}>
                                            <BasicButton>
                                                Enter
                                            </BasicButton>
                                        </div>
                                    </div>
                                </div>


                        :
                        <div className="col-md-7 col-sm-12 content">
                            <h3 className="title m-0 ds-pb-16">
                                Sign in to continue to your account!
                            </h3>
                            <form>
                                <label className="lable w-100 pt-3 lable-Email">
                                    Email or Phone number
                                    <span>
                                <img src={redStar} className="redStar" alt="redStar"/>
                            </span>
                                    <br/>
                                    <input
                                        type="email"
                                        placeholder="Email or Phone number"
                                        className="email my-3"
                                        required
                                        value={mob_or_email}
                                        onChange={(e) => setMobileEmail(e.target.value)}                                />
                                </label>
                                {
                                    valueErrors.field === "email" &&
                                    <p className={"error-message-email"}>{valueErrors.message}</p>
                                }
                                <label className="lable w-100 m-0">
                                    Password
                                    <span>
                                <img src={redStar} className="redStar" alt="redStar"/>
                            </span>
                                    <br/>
                                    <input
                                        type="password"
                                        placeholder="Type your Password"
                                        className="password  mt-3"
                                        required
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}                                />
                                </label>
                                {
                                    valueErrors.field === "password" &&
                                    <p className={"error-message-password"}>{valueErrors.message}</p>
                                }
                                <div className="forget" onClick={()=>setForgetPassword(true)}>
                                    <u> Forgot Password? </u>
                                </div>
                                <button className="button w-100 my-3" type={"button"}
                                        onClick={submitForm}
                                >
                                    Sign In
                                </button>

                                <label className="check">
                                    {click ? (
                                        <p className="box mr-3" onClick={() => setclick(!click)}></p>
                                    ) : (
                                        <img
                                            src={yellow_crt_btn}
                                            onClick={() => setclick(!click)}
                                            alt="yellow_crt_btn"
                                            className="yellow_crt_btn"
                                        />
                                    )}
                                    Keep me signed in until I sign out
                                </label>
                            </form>
                            <div>
                                <div className='or'>or</div>
                            </div>
                            <div className="tosing" onClick={() => props.setlogin("signup")}>
                                Don’t have an account?
                                <span className="sapn-sing">
                <u>Sign Up</u>
              </span>
                            </div>
                        </div>

                    }

                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    user_details:state.UserReducer.user_details,
});

const mapDispatchToProps = dispatch => ({
    sendUserDatasToStore: user_data => dispatch(UserLoggedin(user_data)),
    beginCartList: () => dispatch(fetchCartListBegin()),
    cartListSuccess: (data) => dispatch(fetchCartListSuccess(data)),
    cartListFailure: (error) => dispatch(fetchCartListFailure(error)),
    beginWishList: () => dispatch(fetchWishListBegin()),
    wishListSuccess: (data) => dispatch(fetchWishListSuccess(data)),
    wishListFailure: (error) => dispatch(fetchWishListFailure(error)),
});


export default compose(withRouter, connect(mapStateToProps,mapDispatchToProps))(Login);
