import React, {useEffect, useState} from 'react';
import './TopHeader.scss'
import notification from "../../../assets/icons/notification.svg"
import {MDBModal, MDBModalBody} from "mdbreact";
import closebtn from "../../../assets/icons/closebtnblack.svg";
import line from "../../../assets/icons/Line.svg"
import InstructorProfileQuery from "../../../queries/InstructorProfileQuery";
import environment from "../../../Environment";
import {fetchQuery} from "relay-runtime";
import GetNotificationsQuery from "../../../queries/GetNotificationsQuery";
import ChangePasswordMutation from "../../../mutations/ChangePasswordMutation";
import cogoToast from "cogo-toast";
import ReadNotificationsMutation from "../../../mutations/ReadNotificationsMutation";
import {withRouter} from "react-router-dom";
import {compose} from "redux";
import {connect} from "react-redux";
import {fetchProfessorCoursespageStateReviews} from "../../../Actions/ProfessorCoursesPageStateAction";


export const TopHeader=(props)=>{
    const [notificationOn,setNotificationOn]=useState(false)

     const [getProfileName,setProfileName]=useState('')
     const [getNotification,setNotification]=useState([])
    const [getNotificationId,setNotificationId]=useState([])
    const [getnewnotification,setnewnotification]=useState(false)


    const fetchProfilDetails = () => {
        fetchQuery(environment, InstructorProfileQuery)
            .toPromise()
            .then(response => {
                if (response.myProfile !== null) {
                    setProfileName(response.myProfile.name)
                } else {
                    setProfileName('');
                }
            }, err => {
                // console.log(err);
                setProfileName('');
            });
    }


    const fetchNotificationDetails = () => {
        fetchQuery(environment, GetNotificationsQuery)
            .toPromise()
            .then(response => {
                if (response.getNotifications !== null) {
                    // debugger
                    setNotification(response.getNotifications.edges)
                    response.getNotifications.edges.map((item,index)=>{
                        if (item.node.isRead===false){
                            setnewnotification(true)
                        }
                    })
                } else {
                    setNotification([]);
                }
            }, err => {
                console.log(err);
                setNotification([]);
            });
    }

    const submitPasswordChange = (getNotificationId) => {
        let error = {};
        // debugger
        if (getNotificationId.length < 0) {


        } else {
            let userData = {
                notificationIds:getNotificationId
            }
                ReadNotificationsMutation(userData, function (response) {
                if (response.readNotifications !== null) {
                    // cogoToast.success(response.changePassword.message, {position: 'top-center'});

                } else {
                    // cogoToast.error("something went wrong", {position: 'top-center'});
                }
            }, function (err) {
                // cogoToast.error(err, {position: 'top-center'});
            });
        }
    }


    useEffect(()=>{
        fetchProfilDetails()
        fetchNotificationDetails()
    },[])
    // console.log(getNotification)


    function gotoConsultations(props){
        // debugger
        props.history.push('/professor/consultations')
    }

    function gotoReviews(props,id){
        props.fetchProfessorCoursespageStateReviews('reviews')
        props.history.push(`courses/`+id)
    }

    function clicknotificationimage(){
        setNotificationOn(!notificationOn)
        // getNotification
        let temparr=[]
        getNotification.map((item,index)=>{
            // debugger
            setNotificationId(oldArray=>[...oldArray,item.node.id])
            temparr.push(item.node.id)
            // console.log()
        })
        // console.log(getNotificationId)
        // setcount(getcount+1)
        submitPasswordChange(temparr)
    }

    return (
        <div className={'topheader d-flex justify-content-between'}>
            <div className={'name'}>
                Welcome <span>{getProfileName}</span>
            </div>
            <div className={'notification'} onClick={()=>clicknotificationimage()}>
                <div className={getnewnotification===true?'newnotification':'d-none'}></div>
                <img src={notification}/>
            </div>
            <MDBModal isOpen={notificationOn} fullHeight position="right">
                <MDBModalBody>
                    <img src={closebtn} className={'closebtn'} alt={'close button'} onClick={()=>setNotificationOn(!notificationOn)}/>
                    <h3 className={'text-center notificationtitle'}>Notifications</h3>'
                    {getNotification.map((item,index)=>{
                        const tern=(item.node.updatedAt)
                        const arrdatetime = (item.node.updatedAt).split("-");
                        const arrdate=(arrdatetime[2]).split("T")
                        const date=arrdate[0]
                        const fullmonthname = ["January","February","March","April","May","June","July","August","September","October","November","December"];
                        const month=arrdatetime[1]
                        let monthname = fullmonthname[month-1];
                        const year=arrdatetime[0]
                        console.log('item',item.node)
                        // const date=item.node.createdAt
                        return(
                            <>
                                <div className={'d-flex justify-content-between bodycontent'}>
                                    <div className={'maincontent'}>
                                        <p className={'m-0'}>{item.node.message}<br/><span>{date}th, {monthname} {year}</span></p>

                                    </div>
                                    <div className={'viewbtn'}
                                         onClick={()=>{item.node.type==="CONSULTATION"?gotoConsultations(props):gotoReviews(props,item.node.relatedId)}}
                                    >
                                        View
                                    </div>
                                </div>
                                <img src={line} className={'px-4'}/>

                            </>
                        )
                    })

                    }

                </MDBModalBody>
            </MDBModal>
        </div>
    );
}
const mapDispatchToProps = dispatch => ({
    fetchProfessorCoursespageStateReviews: (data) => dispatch(fetchProfessorCoursespageStateReviews(data)),

});
export default compose(
    withRouter,connect(null, mapDispatchToProps)
)(TopHeader);

