import graphql from "babel-plugin-relay/macro";

const ProfessorDetailsQuery=graphql`

 query ProfessorDetailsQuery($id:String!){
    getInstructorById(id:$id){
        id
        aboutMe
        profilePic
        experience
        designation
        qualification
        organization
        
        user{
          name
        }
        courses(isActive: true)
        {
            edges {
                node {
                    id
                    name
                    instructor{
                        user{
                            name
                        }
                    }
                    image
                    price
                    discountPrice
                    averageRating
                    isWishlist
                }
            }
        }
  }
}




`

export default ProfessorDetailsQuery
