import React, { useEffect, useState } from "react";
import "./CourseCard.scss"
import star from "../../assets/icons/Star_full.svg";
import redheart from "../../assets/icons/red_heart.svg";
import heart from "../../assets/icons/heart-icon.svg";
import smallUser from "../../assets/images/smallUser.svg";
import CartIcon from "../../assets/icons/white-cart-icon.svg";

import { withRouter } from "react-router-dom";
import { fetchQuery } from "relay-runtime";
import environment from "../../Environment";
import AddToWishListQuery from "../../queries/AddToWishListQuery";
import { compose } from "redux";
import { connect } from "react-redux";
import cogoToast from "cogo-toast";
import RemoveFromWishListQuery from "../../queries/RemoveFromWishListQuery";
import AddToCartMutation from "../../mutations/AddToCartMutation";
import { fetchCartListSuccess } from "../../Actions/CartAction";
import { fetchWishListSuccess } from "../../Actions/wishListAction";
import TextTruncate from 'react-text-truncate';
import CourseCardBg from "../../assets/images/coursecard-bg.png";
import Loader from "../../Components/Loader/Loader";
import { TrunkString } from "../../util/TrunkString";
const CourseCard = (props) => {
    const { courseData, logged_in, wishlist_data } = props;
    const [loading, setLoading] = useState(false);
    const [isWishlist, setIsWhishList] = useState(courseData.isWishlist);

    useEffect(() => {
        if (wishlist_data.length > 0) {
            let isWishList = wishlist_data.filter((item) => item.id === courseData.id);
            if (isWishList.length > 0) {
                setIsWhishList(true);
            } else {
                setIsWhishList(false);
            }
        } else {
            setIsWhishList(false);
        }

    }, [wishlist_data]);



    const toggleWishList = (e) => {
        e.stopPropagation();
        if (isWishlist) {
            removeFromWishList();
        } else {
            addToWishList();
        }
    }

    const addToWishList = () => {

        let variables = {
            courseId: props.courseData.id
        }
        fetchQuery(environment, AddToWishListQuery, variables)
            .toPromise()
            .then(response => {
                if (response.addToWishList !== null) {
                    props.wishListSuccess(response.addToWishList);
                    if (props.updateCourseList) {
                        props.updateCourseList(props.courseData, true);
                    }
                    cogoToast.success("Course Added To Wishlist successfully", { position: 'top-center' });
                } else {
                    cogoToast.info("Course Not Added To Wishlist", { position: 'top-center' });
                }
            }, err => {
                cogoToast.error("Course Not Added To Wishlist", { position: 'top-center' });
            });
    }

    const removeFromWishList = () => {
        setLoading(true)

        let variables = {
            courseId: props.courseData.id
        }
        fetchQuery(environment, RemoveFromWishListQuery, variables)
            .toPromise()
            .then(response => {
                setLoading(false)
                if (response.removeFromWishList !== null) {
                    props.wishListSuccess(response.removeFromWishList);
                    if (props.updateCourseList) {
                        props.updateCourseList(props.courseData, false);
                    }
                    cogoToast.success("Course Removed From wishlist successfully", { position: 'top-center' });
                } else {
                    cogoToast.info("Course Not Removed From wishlist", { position: 'top-center' });
                }
            }, err => {
                setLoading(false)
                cogoToast.error("Course Not Removed From wishlist", { position: 'top-center' });
            });
    }

    const addToCart = (e) => {
        e.stopPropagation();
        let variables = {
            courseId: props.courseData.id
        }
        AddToCartMutation(variables, function (response) {
            if (response.addToCart !== null) {
                let dataFormate = {
                    id: response.addToCart.cart.id,
                    total: parseInt(response.addToCart.cart.total),
                    discount: parseInt(response.addToCart.cart.discount),
                    discountName: response.addToCart.cart.discountName,
                    checkoutitems: response.addToCart.cart.checkoutitems.edges
                }
                props.cartListSuccess(dataFormate);
                cogoToast.success("Course Added To cart successfully", { position: 'top-center' });
            } else {
                cogoToast.success("Course Not Added To cart", { position: 'top-center' });
            }
        }, function (err) {
            cogoToast.error("Course Not Added To cart", { position: 'top-center' });
        })

    }
    const goToPreview = (courseId) => {
        props.history.push("/course-preview/" + courseId);
    }

    if (loading) {
        return (
            <div>
                <Loader full={true} />
                <div style={{ height: window.innerHeight }} />
            </div>
        );
    }

    return (
        <div className={"course-card-component"}>
            <div className="CourseCard p-2" onClick={() => goToPreview(courseData.id)}>
                <div className="bg" style={{ backgroundImage: `url(${courseData.image !== null ? courseData.image : CourseCardBg})` }} >
                    <div className="star1 py-2 m-2">
                        <img src={star} className="Star" alt="Star" />
                        {courseData.averageRating}
                    </div>
                    {
                        logged_in && (
                            <div className="heart1">
                                <img src={isWishlist ? redheart : heart}
                                    className="heart" alt="heart"
                                    onClick={(e) => toggleWishList(e)}
                                />
                            </div>
                        )
                    }
                </div>
                <div className={"content-section"}>
                    <div className="courseName">

                        <TrunkString name={courseData.name} length={30} />
                        {/*<TextTruncate*/}
                        {/*    line={2}*/}
                        {/*    element="span"*/}
                        {/*    truncateText="…"*/}
                        {/*    text= {}*/}
                        {/*/>*/}
                    </div>
                    <div className="parentUser">
                        <img src={smallUser} className="smallUser" alt="smallUser" />
                        {courseData.instructor && courseData.instructor.user ? courseData.instructor.user.name : ""}
                    </div>
                    <div className="RateTage">
                        ₹  {courseData.discountPrice}
                        <del className="realRate">₹ {courseData.price}</del>
                        {" "}
                        {/* <span className="tag">Best Seller</span> */}
                    </div>
                </div>
                {
                    logged_in &&
                    <button type={"button"} className="btn cart-btn" onClick={addToCart}>
                        <img src={CartIcon} className="inCart" alt="Cart" />
                    </button>
                }
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    cart_data: state.CartReducer.cart_data,
    wishlist_data: state.WishListReducer.wishlist_data,
    logged_in: state.UserReducer.logged_in,
})


const mapDispatchToProps = dispatch => ({
    cartListSuccess: (data) => dispatch(fetchCartListSuccess(data)),
    wishListSuccess: (data) => dispatch(fetchWishListSuccess(data)),

})

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(CourseCard);
