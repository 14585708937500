/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ChangePasswordMutationVariables = {|
  oldPassword: string,
  newPassword: string,
  confirmPassword: string,
|};
export type ChangePasswordMutationResponse = {|
  +changePassword: ?{|
    +message: ?string,
    +status: ?string,
  |}
|};
export type ChangePasswordMutation = {|
  variables: ChangePasswordMutationVariables,
  response: ChangePasswordMutationResponse,
|};
*/


/*
mutation ChangePasswordMutation(
  $oldPassword: String!
  $newPassword: String!
  $confirmPassword: String!
) {
  changePassword(oldPassword: $oldPassword, newPassword: $newPassword, confirmPassword: $confirmPassword) {
    message
    status
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "confirmPassword"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "newPassword"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "oldPassword"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "confirmPassword",
        "variableName": "confirmPassword"
      },
      {
        "kind": "Variable",
        "name": "newPassword",
        "variableName": "newPassword"
      },
      {
        "kind": "Variable",
        "name": "oldPassword",
        "variableName": "oldPassword"
      }
    ],
    "concreteType": "ChangePasswordMutation",
    "kind": "LinkedField",
    "name": "changePassword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangePasswordMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ChangePasswordMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "2d8b4cc5597651638ae2f660e4209b7d",
    "id": null,
    "metadata": {},
    "name": "ChangePasswordMutation",
    "operationKind": "mutation",
    "text": "mutation ChangePasswordMutation(\n  $oldPassword: String!\n  $newPassword: String!\n  $confirmPassword: String!\n) {\n  changePassword(oldPassword: $oldPassword, newPassword: $newPassword, confirmPassword: $confirmPassword) {\n    message\n    status\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cdad8f6c6535faca69b0d1f225547f77';

module.exports = node;
