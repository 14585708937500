/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GenerateInvoiceMutationVariables = {|
  orderId: string
|};
export type GenerateInvoiceMutationResponse = {|
  +generateInvoice: ?{|
    +invoice: ?{|
      +invoiceUrl: ?string
    |}
  |}
|};
export type GenerateInvoiceMutation = {|
  variables: GenerateInvoiceMutationVariables,
  response: GenerateInvoiceMutationResponse,
|};
*/


/*
mutation GenerateInvoiceMutation(
  $orderId: ID!
) {
  generateInvoice(orderId: $orderId) {
    invoice {
      invoiceUrl
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "orderId",
    "variableName": "orderId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invoiceUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GenerateInvoiceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GenerateInvoice",
        "kind": "LinkedField",
        "name": "generateInvoice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InvoiceNode",
            "kind": "LinkedField",
            "name": "invoice",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GenerateInvoiceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GenerateInvoice",
        "kind": "LinkedField",
        "name": "generateInvoice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InvoiceNode",
            "kind": "LinkedField",
            "name": "invoice",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c346d224e8940b64ede5ad9fb5e860ff",
    "id": null,
    "metadata": {},
    "name": "GenerateInvoiceMutation",
    "operationKind": "mutation",
    "text": "mutation GenerateInvoiceMutation(\n  $orderId: ID!\n) {\n  generateInvoice(orderId: $orderId) {\n    invoice {\n      invoiceUrl\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bc2e1da23594652041910f8d26827fd7';

module.exports = node;
