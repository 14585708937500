import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'

const mutation = graphql`
  mutation ModuleOrderUpdateMutation(
    $moduleId: String
    $order: Int
  ) {
       moduleOrderUpdate(moduleId:$moduleId,order:$order){
        status
        message
      }
  }
`

export default (data, callback,errCallback) => {
    const variables = {
        moduleId:data.moduleId,
        order:data.order
    }
    // 5
    commitMutation(
        environment,
        {
            mutation,
            variables,
            // 6
            onCompleted: (response,err) => {

                if(response.moduleOrderUpdate !== null){
                    callback(response)
                } else{
                    errCallback(err[0].message);
                }

            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}
