/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PaymentHistoryListQueryVariables = {||};
export type PaymentHistoryListQueryResponse = {|
  +orders: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +orderId: ?string,
        +totalPayable: any,
        +updatedAt: any,
      |}
    |}>
  |}
|};
export type PaymentHistoryListQuery = {|
  variables: PaymentHistoryListQueryVariables,
  response: PaymentHistoryListQueryResponse,
|};
*/


/*
query PaymentHistoryListQuery {
  orders {
    edges {
      node {
        id
        orderId
        totalPayable
        updatedAt
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "OrderNodeConnection",
    "kind": "LinkedField",
    "name": "orders",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OrderNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrderNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "orderId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalPayable",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PaymentHistoryListQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PaymentHistoryListQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "5f0e0e1af3d73818ca1c69f94b9ee955",
    "id": null,
    "metadata": {},
    "name": "PaymentHistoryListQuery",
    "operationKind": "query",
    "text": "query PaymentHistoryListQuery {\n  orders {\n    edges {\n      node {\n        id\n        orderId\n        totalPayable\n        updatedAt\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '03a49cd1ff770e6a9b20decc0593e455';

module.exports = node;
