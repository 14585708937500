import graphql from "babel-plugin-relay/macro";

const CourseByIdQuery=graphql`

 query CourseByIdQuery($id:String!){
      courseById(id:$id){
         id
         name
         price
         discountPrice
         viewerPurchased
         averageRating
         about
         totalModules
         totalLectures
         totalDuration
         previewVideo
         duration
         isWishlist
         percentageCompleted
         durationInMonth
         lessonsLeftToComplete
         weeklyWatchHours
         discountPercentage
         enrolledUsers
         expiryDate
         Note
         image
         mobileImage
         latestWatchLog{
            currentTime
            module{
               id
            }
            lesson{
                id
                isFree
            }
         }
         level{
            id
            name
         }
         category{
            id
            name
         }
         modules{
            edges{
                node{
                    id
                    name
                    lessonCount
                    isCompleted
                    totalDuration
                    order
                    description
                    isActive
                    lessons{
                         edges{
                            node{
                                id
                                name
                                video
                                type
                                pdf
                                currentTime
                                isWatched
                                isWatching
                                transcodedVideo
                                urls
                                attachments{
                                    edges {
                                        node {
                                            id
                                            file
                                           createdAt
                                            updatedAt
                                            name
                                            order
                                        }
                                    }
                                }
                                isTranscoded
                                isFree
                                duration
                                transcript
                                isActive
                                order
                            }
                         }
                    }
                }
            }
         }
         instructor{
            id
            designation
            organization
            experience
             district {
                 id
                 name
             }
             state {
                 id
                 name
             }
            profilePic
            user{
                name                
            }
            courses(isActive: true){
                edges{
                  node{
                    id
                    averageRating
                    isWishlist
                    name
                    price
                      image
                    discountPrice
                    instructor{
                        user{
                            name
                        }
                    }
                  }
                }
            }
         }
         reviews{
            edges{
                node{
                    id
                    content
                    rating
                    updatedAt
                    user{
                        name
                        profile{
                            profilePic
                        }
                    }
                }
            }
         } 
      }
}
`

export default CourseByIdQuery;
