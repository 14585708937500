export const LOGGEDIN_USER = 'LOGGEDIN_USER';

export const LOGGEDOUT_USER = 'LOGGEDOUT_USER';

export const OPEN_MODAL = 'OPEN_MODAL';

export const CLOSE_MODAL = 'CLOSE_MODAL';

export const SET_MODAL_TYPE = 'SET_MODAL_TYPE';

export const UserLoggedin = data => ({
    type: LOGGEDIN_USER,
    payload: {
        user_details:data,
        logged_in: true
    }
});

export const UserLoggedout = () => ({
    type: LOGGEDOUT_USER,
});

export const OpenLoginModal = () => ({
    type: OPEN_MODAL,
});

export const CloseLoginModal = () => ({
    type: CLOSE_MODAL,
});

export const SetModalType = (modal_type) => ({
    type: SET_MODAL_TYPE,
    payload: {
        modal_type: modal_type
    }
});
