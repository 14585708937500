import './ProfileNavbar.scss'
import React, {useEffect} from "react";
import useMediaQuery from "../../../util/useMediaQuery";
import uuid from 'react-uuid'
import {withRouter} from "react-router-dom";

const ProfileNavbar = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const titles = [
        {
            id: 1,
            name: 'My Profile',
            active: false,
            url:"/myprofile"
        },
        {
            id: 2,
            name: 'My courses',
            active: false,
            url:"/myprofile/mycourses"
        },
        {
            id: 3,
            name: 'Payment History',
            active: false,
            url:"/myprofile/payment-history"
        },
        {
            id: 4,
            name: 'Consultations',
            active: false,
            url:"/myprofile/consultations"
        },
        {
            id: 5,
            name: 'Reviews',
            active: false,
            url:"/myprofile/reviews"
        },
        // {
        //     id: 6,
        //     name: 'Referrals & Coupons',
        //     active: false,
        //     url:"/myprofile/referral"
        // },
        {
            id: 7,
            name: 'Change Password',
            active: false,
            url:"/myprofile/change-password"
        }
    ]
    const addclass = (path) => {
        props.history.push(path);
    }

    const isDesktop = useMediaQuery("(min-width: 900px)");
    return (
        <div className={'profileTitles'}>
            <div className={'d-flex flex_name nav_bar'}>
                {isDesktop ?
                    <>
                        {titles.map((item) => {
                            return (
                                <div className={'top'} key={uuid()}>
                                    <div key={item.index} className={`title_name ${item.name === props.active_page ? 'active1' : ''}`}
                                         onClick={() => addclass(item.url)}>
                                        {item.name}
                                        <div className={'line'}/>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </>
                    :
                    <>
                        {
                            titles.map((item) => {
                                return (
                                    <div key={uuid()} className={`title_name ${item.name === props.active_page ? 'active2' : ''}`}
                                         onClick={() => addclass(item.url)}>
                                        <div className={'line'}/>
                                        {item.name}
                                    </div>
                                )
                            })
                        }
                    </>
                }
            </div>
            <div className={'activeclass'}>
                {props.children}
            </div>
        </div>
    )
}

export default withRouter(ProfileNavbar);
