import './ProfessorsDetail.scss'

import {LayoutContainer} from "../../Components/LayoutContainer/LayoutContainer";

import CourseCard from "../../Containers/CourseCard/CourseCard";
import {RoundedButton} from "../../Components/Buttons/RoundedButton";
import useMediaQuery from "../../util/useMediaQuery";
import leftsmallarrow from "../../assets/images/leftSmallarrow.png";
import React, {useEffect, useRef, useState} from "react";
import {CourseInstructorCard} from "../../Containers/CourseInstructorCard/CourseInstructorCard";
import Slider from "react-slick";
import {fetchQuery} from "relay-runtime";
import environment from "../../Environment";
import {
    useParams
} from "react-router-dom";
import ProfessorDetailsQuery from "../../queries/ProfessorDetailsQuery";
import parse from 'html-react-parser';
import Instructor from "../../assets/images/professorpic.svg";
import uuid from 'react-uuid'
import Header from "../../Containers/Header/Header";
import {Footer} from "../../Containers/Footer/Footer";
// import {Carrer} from "../Homepage/Homepage";
import carrer_left_img from "../../../src/assets/images/unsplash_W3Jl3jREpDY.png"
import {BasicButton} from "../../Components/Buttons/BasicButton";
import {compose} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";



 const ProfessorsDetail = (props) => {
    const [professorDetail, setProfessorDetail] = useState({})
    const [courseList, setCourseList] = useState([]);
    let params = useParams();

    useEffect(() => {
        fetchProfessorDetails()
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const fetchProfessorDetails = () => {
        let variables = {
            id: params.id
        }
        fetchQuery(environment, ProfessorDetailsQuery, variables)
            .toPromise()
            .then(response => {
                if (response.getInstructorById !== null) {
                    setCourseList(response.getInstructorById.courses.edges)
                    setProfessorDetail(response.getInstructorById)
                } else {
                    setProfessorDetail({});
                    setCourseList([]);
                }
            }, err => {
                setProfessorDetail({});
                setCourseList([]);
            });
    }

    const updateCourseList = (course,booleanValue) => {
        let tmp_course_list = courseList.slice();
        let index = courseList.findIndex(course_item => course_item.node.id === course.id);
        if(index > -1){
            tmp_course_list[index] = {
                ...tmp_course_list[index],
                node: {
                    ...tmp_course_list[index].node,
                    isWishlist: booleanValue
                }
            };
        }
        setCourseList(tmp_course_list);
    }

    return (
        <>
            <Header page={""}/>
            <div className={'professors-detail-component'}>
            <ProfessorsDetail_top/>
            <LayoutContainer>
                {
                    Object.keys(professorDetail).length > 0 &&
                    <ProfessorsDetailDescription professorDetail={professorDetail}/>

                }
                <ProfessorsCourseList professorDetail={professorDetail} courseList={courseList} updateCourseList={updateCourseList}/>
                <ProfessorCarrer history={props.history} id={params.id}/>
            </LayoutContainer>

        </div>
            <Footer/>
        </>
    );
}

const ProfessorsDetail_top = () => {


    return (<div className={'ProfessorsDetail_top'}>
        <div className={'title'}>Instructor Profile</div>
    </div>);
}
const ProfessorsDetailDescription = (props) => {
    const imageEl = useRef(null);

    const isDesktop = useMediaQuery("(min-width: 765px)");

    const getProfessorDescription = () => {
        let professor_detail = props.professorDetail;
        let aboutProfessor = JSON.parse(professor_detail.aboutMe);
        // debugger
        let test =professorDetail.experience
        return aboutProfessor && aboutProfessor.blocks && aboutProfessor.blocks[0] ? aboutProfessor.blocks[0].data.text:"";
    }

    const {professorDetail} = props;
    return (
        <div className={'professors-detail-description'}>
            <div className={'title'}>{professorDetail.user && professorDetail.user.name}</div>
            {!isDesktop &&
                <CourseInstructorCard professorDetail={professorDetail}/>}

            {isDesktop ? <></> :
                <div className={'title2'}>{professorDetail.user && professorDetail.user.name} </div>}

            <div className={"d-flex"}>
                <div className={'description'}>
                    {
                        parse(getProfessorDescription())
                    }
                </div>
                {
                    isDesktop &&
                    <div className={'ProfessorsDetail_image'}>
                        <img  src={professorDetail.profilePic?professorDetail.profilePic:Instructor}
                              ref={imageEl}
                              onError={() => {
                                  imageEl.current.src = Instructor;
                              }}
                        />
                        <div className={"professor-experience-section"}>
                            <p className={"experience text-center"}>{professorDetail.experience !== null ? professorDetail.experience : '0' }+</p>
                            <p className={"content text-center"}>Years of Experience</p>
                        </div>
                    </div>
                }
            </div>



        </div>)
};

const ProfessorsCourseList = (props) => {
    const isDesktop = useMediaQuery("(min-width: 765px)");
    const multiple_slider_settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1.25,
        slidesToScroll: 1,
        arrows: false
    };
    const {professorDetail,courseList,updateCourseList} = props;
    const isDesktopOrLapTop = useMediaQuery("(min-width: 1400px)");
    return (
        <div className={'professors-course-list-component'}>
            <div className={'ProfessorsDetailtitle'}>
                <h3 className={'title '}> {professorDetail.user && professorDetail.user.name} Courses</h3>
                {
                    isDesktop ?
                        <></> :
                        <RoundedButton showIcon={true}>
                            View All
                            {/*<p className={'text'}>View All</p>*/}
                            {/*<img src={leftsmallarrow} className={'leftsmallarrow'} alt={'leftsmallarrow'}/>*/}
                        </RoundedButton>
                }
            </div>
            {

                isDesktop ?
                    <div className={'row search_Courses_row'}>
                        {


                            courseList.map((item, index) => {
                                return (
                                    <div className={isDesktopOrLapTop?'col-md-6 col-lg-4 col-xl-3 inside_cousrses_row':'col-md-6 col-lg-4 col-xl-4 inside_cousrses_row'}  key={uuid()}>
                                        <CourseCard courseData={item.node} updateCourseList={updateCourseList}/>
                                    </div>
                                );
                            })
                        }
                    </div> :
                    <div className={"small-device-professor-courses"}>
                        <Slider {...multiple_slider_settings} className={"course-slider"}>
                            {
                                courseList.map((item, index) => {
                                    return (
                                        <div key={uuid()}>
                                            <CourseCard courseData={item.node} updateCourseList={updateCourseList}/>
                                        </div>
                                    );
                                })
                            }
                        </Slider>
                    </div>
            }
        </div>
    );
}


const ProfessorCarrer=(props)=>{


    return(
        <div className={'carrer row'}>
            <div className={'col-6 left-side px-0'}>
                <img src={carrer_left_img} alt={'professor set imag'}/>
            </div>
            <div className={'col-6 rigth-side'}>
                <div className="heading">Take <br/>
                    <div className={'cross_bg'}>
                        <div className="consult">
                            Carrer Consultation
                        </div>
                    </div>
                    Now with <br/> name</div>
                <div className={'text'}>
                    Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim.
                </div>
                <div onClick={() => props.history.push(`/consultation/${props.id}`)}>
                    <BasicButton>
                        Consult Now
                    </BasicButton>
                </div>

            </div>
        </div>
    )
}


export default compose(
    withRouter
)(ProfessorsDetail);
