import React from "react";
import "./RoundedButton.scss"
import { MDBBox } from 'mdbreact';

import Arrow from "../../assets/icons/chevron-right.svg";
import PropTypes from "prop-types";
import {FlatButton} from "./FlatButton";

export const RoundedButton = (props) => {
    const {handleClick,children,showIcon} = props;
    return(
        <MDBBox className={"rounded-button-component"}>
            <button type={"button"} className={"btn"} onClick={handleClick}>
                {children}
                {
                    showIcon &&
                    <img src={Arrow} alt={"arrow"} className={"img-fluid arrow-right"}/>
                }
            </button>
        </MDBBox>
    );
}

RoundedButton.propTypes = {
    handleClick: PropTypes.func,
    children: PropTypes.string,
    showIcon:PropTypes.bool
};


