import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'

const mutation = graphql`
    mutation ForgetPasswordMutation(
        $emailOrMobile:String!
    ) {
        forgetPassword(emailOrMobile:$emailOrMobile ){
           message
            status
            otp
        }
    }

`

export default (data, callback,errCallback) => {
    const variables = {
        emailOrMobile: data.emailOrMobile,
    }

    // 5
    commitMutation(
        environment,
        {
            mutation,
            variables,
            // 6
            onCompleted: (response,err) => {

                if(response.forgetPassword !== null){
                    callback(response)
                } else{
                    errCallback(err[0].message);
                }

            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}
