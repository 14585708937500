import graphql from "babel-plugin-relay/macro";

const MyConsultationQuery=graphql`
    query MyConsultationQuery{
        myConsultations {
            edges {
                node {
                    id
                    interest
                    createdAt
                    updatedAt
                    name
                    status
                    consultationDate
                    consultationTime
                }
            }
        }
    }




`

export default MyConsultationQuery
