/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type InstructorRemoveProfilePictureQueryVariables = {||};
export type InstructorRemoveProfilePictureQueryResponse = {|
  +instructorRemoveProfilePicture: ?{|
    +id: string
  |}
|};
export type InstructorRemoveProfilePictureQuery = {|
  variables: InstructorRemoveProfilePictureQueryVariables,
  response: InstructorRemoveProfilePictureQueryResponse,
|};
*/


/*
query InstructorRemoveProfilePictureQuery {
  instructorRemoveProfilePicture {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "InstructorType",
    "kind": "LinkedField",
    "name": "instructorRemoveProfilePicture",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "InstructorRemoveProfilePictureQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "InstructorRemoveProfilePictureQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "ca4ece83df96739e607dc093c5de3cc3",
    "id": null,
    "metadata": {},
    "name": "InstructorRemoveProfilePictureQuery",
    "operationKind": "query",
    "text": "query InstructorRemoveProfilePictureQuery {\n  instructorRemoveProfilePicture {\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ef7b1a412b2500b75870680a57dc2d55';

module.exports = node;
