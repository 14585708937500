/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CourseLevelListQueryVariables = {||};
export type CourseLevelListQueryResponse = {|
  +levels: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
      |}
    |}>
  |}
|};
export type CourseLevelListQuery = {|
  variables: CourseLevelListQueryVariables,
  response: CourseLevelListQueryResponse,
|};
*/


/*
query CourseLevelListQuery {
  levels {
    edges {
      node {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "LevelTypeConnection",
    "kind": "LinkedField",
    "name": "levels",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LevelTypeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LevelType",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CourseLevelListQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CourseLevelListQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "7aec2f239a7b951292329487bcf2aae9",
    "id": null,
    "metadata": {},
    "name": "CourseLevelListQuery",
    "operationKind": "query",
    "text": "query CourseLevelListQuery {\n  levels {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '352955b7a94c7553b813ca598eb40bf8';

module.exports = node;
