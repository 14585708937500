import graphql from "babel-plugin-relay/macro";

const MyConsultationQuery=graphql`

    query MyCoursesListQuery{
        myEnrolls{
            edges{
                node{
                    id
                    status
                    certificateUrl
                    course{
                        id
                        name
                        image
                        mobileImage
                        instructor{
                            user{
                                name
                            }
                        }
                        durationInMonth
                        lessonsLeftToComplete
                        percentageCompleted
                        weeklyWatchHours
                    }
                }
            }
        }
    }




`

export default MyConsultationQuery