/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ConsultationStatus = "APPROVED" | "CANCELLED" | "COMPLETED" | "PENDING" | "REJECTED" | "%future added value";
export type MyConsultationQueryVariables = {||};
export type MyConsultationQueryResponse = {|
  +myConsultations: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +interest: string,
        +createdAt: any,
        +updatedAt: any,
        +name: string,
        +status: ConsultationStatus,
        +consultationDate: ?any,
        +consultationTime: ?any,
      |}
    |}>
  |}
|};
export type MyConsultationQuery = {|
  variables: MyConsultationQueryVariables,
  response: MyConsultationQueryResponse,
|};
*/


/*
query MyConsultationQuery {
  myConsultations {
    edges {
      node {
        id
        interest
        createdAt
        updatedAt
        name
        status
        consultationDate
        consultationTime
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ConsultationNodeConnection",
    "kind": "LinkedField",
    "name": "myConsultations",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ConsultationNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ConsultationNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "interest",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "consultationDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "consultationTime",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MyConsultationQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MyConsultationQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "df29de4d4128e991ddfd3e33b655195f",
    "id": null,
    "metadata": {},
    "name": "MyConsultationQuery",
    "operationKind": "query",
    "text": "query MyConsultationQuery {\n  myConsultations {\n    edges {\n      node {\n        id\n        interest\n        createdAt\n        updatedAt\n        name\n        status\n        consultationDate\n        consultationTime\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ca0b64c061311cc402919d9588f8b41d';

module.exports = node;
