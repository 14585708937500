import "./ProfessorsCourses.scss"

import filter from "../../../assets/icons/filter.svg"
import {BasicButton} from "../../../Components/Buttons/BasicButton";
import uuid from "react-uuid";
import React, {useCallback, useEffect, useState} from "react";
import star from "../../../assets/images/Star.png";
import edit from "../../../assets/icons/edit_white_btn.svg";
import live from "../../../assets/images/fluent_live-20-filled.svg"
import professoruserlogo from "../../../assets/icons/professoruserlogo.svg"
import yellowdate from "../../../assets/icons/yellowdate.svg"
import close_bgwhite from "../../../assets/images/close_bt.png"
import upload from "../../../assets/icons/upload.svg"
import {MDBBox, MDBModal, MDBModalBody} from "mdbreact";
import redStar from "../../../assets/images/redStar.png";
import CourseCardBg from "../../../assets/images/coursecard-bg.png";
import ProfessorPanelIndex from "../ProfessorPanelIndex/ProfessorPanelIndex";
import {fetchQuery} from "relay-runtime";
import environment from "../../../Environment";
import InstructorCourseListQuery from "../../../queries/InstructorCourseListQuery";
import CategoryListQuery from "../../../queries/CategoryListQuery";
import CourseLevelListQuery from "../../../queries/CourseLevelListQuery";
import axios from "axios";
import {api} from "../../../serviceApi";
import cogoToast from "cogo-toast";
import {EDITOR_JS_TOOLS} from "../Profile/Constants";
import {createReactEditorJS} from 'react-editor-js'
import TextTruncate from 'react-text-truncate';
import {Test} from "../../Test";
import {ProfessorFilterButton} from "../../../Components/ProfessorFilterButton/ProfessorFilterButton";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {TrunkString} from "../../../util/TrunkString";
import {connect} from "react-redux";
import useMediaQuery from "../../../util/useMediaQuery";
// import {withRouter} from "react-router-dom";

const ReactEditorJS = createReactEditorJS()


const ProfessorsCourses = (props) => {


    useEffect(()=>{
        if (props.user_details.isInstructor===false){
            props.history.push('/')
        }
    },[])


    const [addCrouseState, setAddCrouseState] = useState(false);
    const [filterdropdown, setfilterdropdown] = useState(false);
    const [courseList, setCourseList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [courseLevelsList, setCourseLevelsList] = useState([]);
    const [courseName,setCourseName] = useState("");
    const [aboutCourse,setAboutCourse] = useState("");
    const [selectedCategory,setSelectedCategory] = useState({});
    const [discountPrice,setDiscountPrice] = useState("");
    const [duration,setDuration] = useState(0);
    const [selectedLevel,setSelectedLevel] = useState({});
    const [note,setNote] = useState("");
    const [price,setPrice] = useState(0);
    const [desktopThumbnail,setDesktopThumbnail] = useState(null);
    const [mobileThumbnail,setMobileThumbnail] = useState(null);
    const [desktopThumbnailSrc,setDesktopThumbnailSrc] = useState("");
    const [mobileThumbnailSrc,setMobileThumbnailSrc] = useState("");
    const [valueErrors, setValueErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const aboutEditorCore = React.useRef(null);
    const noteEditorCore = React.useRef(null);
    const [orderbystate,setorderbystate]=useState('-createdAt')
    const [selectedOption,setSelectedOption]=useState({})


    useEffect(function () {
        getCoursesList();
        getCategoryList();
        getCourseLevelsList();
    }, []);

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])


    const getCoursesList = () => {
        let variables = {
            orderBy:selectedOption.value
        }
        fetchQuery(environment, InstructorCourseListQuery,variables)
            .toPromise()
            .then(response => {
                if (response.getInstructorCourses !== null) {
                    let tmp_courses = response.getInstructorCourses.edges;
                    let course_list = [];
                    tmp_courses.map((item, index) => {
                        course_list[index] = item.node;
                    });
                    setCourseList(course_list);
                } else {
                    setCourseList([]);
                }
            }, err => {
                setCourseList([]);
            });
    }

    const getCategoryList = () => {
        fetchQuery(environment, CategoryListQuery)
            .toPromise()
            .then(response => {
                if (response.categories !== null) {
                    let tmp_courses = response.categories.edges;
                    let course_list = [];
                    tmp_courses.map((item, index) => {
                        course_list[index] = item.node;
                    });
                    setCategoryList(course_list);
                } else {
                    setCategoryList([]);
                }
            }, err => {
                setCategoryList([]);
            });
    }

    const getCourseLevelsList = () => {
        fetchQuery(environment, CourseLevelListQuery)
            .toPromise()
            .then(response => {
                if (response.levels !== null) {
                    let tmp_courses = response.levels.edges;
                    let course_list = [];
                    tmp_courses.map((item, index) => {
                        course_list[index] = item.node;
                    });
                    setCourseLevelsList(course_list);
                } else {
                    setCourseLevelsList([]);
                }
            }, err => {
                setCourseLevelsList([]);
            });
    }

    const handleCategoryChange = (e) => {
        let value = e.target.value;
        let selected_category_index = categoryList.findIndex(category => category.id === value);
        if(selected_category_index > -1){
            setSelectedCategory(categoryList[selected_category_index]);
        }
    }

    const handleLevelsChange = (e) => {
        // debugger
        let value = e.target.value;
        let selected_level_index = courseLevelsList.findIndex(level => level.id === value);
        if(selected_level_index > -1){
            // debugger
            // setSelectedLevel(categoryList[selected_level_index]);
            setSelectedLevel(courseLevelsList[selected_level_index]);
        }
    }

    const handleFileChange = (e,feild) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.addEventListener("load", () => {
            if(feild === "desktop"){
                setDesktopThumbnail(file);
                setDesktopThumbnailSrc(reader.result);
            }
            if(feild === "mobile"){
                setMobileThumbnail(file);
                setMobileThumbnailSrc(reader.result);
            }
        });
        reader.readAsDataURL(file);
    }

    const createCourse = async () => {
        let error = {};
        if (courseName === "") {
            error = {
                field: "courseName",
                message: "Please fill course name"
            };
            setValueErrors(error);
        } else if (Object.keys(selectedCategory).length === 0) {
            error = {
                field: "category",
                message: "Please choose course category"
            };
            setValueErrors(error);
        } else if (Object.keys(selectedLevel).length === 0) {
            error = {
                field: "level",
                message: "Please choose course level"
            };
            setValueErrors(error);
        } else if (price === 0 || price === "") {
            error = {
                field: "price",
                message: "Please fill course price"
            };
            setValueErrors(error);
        } else if (discountPrice === 0 || discountPrice === "") {
            error = {
                field: "discountPrice",
                message: "Please fill course discount price"
            };
            setValueErrors(error);
        } else if (!desktopThumbnail) {
            error = {
                field: "desktopThumbnail",
                message: "Please choose thumbnail for desktop"
            };
            setValueErrors(error);
        } else if (!mobileThumbnail) {
            error = {
                field: "mobileThumbnail",
                message: "Please choose thumbnail for mobile"
            };
            setValueErrors(error);
        }
        // else if (aboutCourse === "") {
        //     error = {
        //         field: "about",
        //         message: "Please fill course about"
        //     };
        //     setValueErrors(error);
        // } else if (note === "") {
        //     error = {
        //         field: "note",
        //         message: "Please fill course note"
        //     };
        //     setValueErrors(error);
        // }
        else {
            error = {};
            setValueErrors(error);
            setLoading(true);
            let aboutSavedData = await aboutEditorCore.current.save();
            let noteSavedData =  await noteEditorCore.current.save();
            // debugger
            let input_data = {
                about: JSON.stringify(aboutSavedData),
                categoryId: selectedCategory.id,
                discountPrice: discountPrice,
                duration: duration,
                isActive: false,
                levelId: selectedLevel.id,
                name: courseName,
                note: JSON.stringify(noteSavedData),
                price: price
            };
            var data = new FormData();
            data.append("query", " mutation AddCourseMutation(\n    $about: JSONString!\n    $categoryId: String!\n    $discountPrice: String!\n    $duration: Int!\n    $isActive: Boolean!\n    $levelId: String!\n    $name: String!\n    $note: JSONString!\n    $price: String!\n  ) {\n     addCourse(\n        about: $about\n        categoryId: $categoryId\n        discountPrice: $discountPrice\n        duration: $duration\n        isActive: $isActive\n        levelId: $levelId\n        name: $name\n        note: $note\n        price: $price\n     ){\n        course{\n          id\n          averageRating\n          isWishlist\n          name\n          price\n          image\n          discountPrice\n          enrolledUsers\n          instructor{\n            user{\n              name\n            }\n          }\n        }\n        message\n        status\n     }\n  }");
            data.append("variables", JSON.stringify(input_data));
            data.append("desktop-image", desktopThumbnail);
            data.append("mobile-image", mobileThumbnail);

            let token = localStorage.getItem('user_token');

            if (token !== null && token !== undefined && token !== "") {

                var config = {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'JWT ' + token
                    },
                };

            } else {

                var config = {
                    headers: {
                        'Accept': 'application/json'
                    },
                };
            }
            axios.post(api, data, config)
                .then(function (response) {
                    setLoading(false);
                    if (response.data.data.addCourse) {
                        cogoToast.success("Course Created Successfully", {position: 'top-center'});
                        setAddCrouseState(false);
                        let tmp_courses = [...courseList];
                        tmp_courses.push(response.data.data.addCourse.course);
                        setCourseList(tmp_courses);

                        resetForm();

                            props.history.push("/professor/courses/"+response.data.data.addCourse.course.id);

                    }
                })
                .catch(function (err) {
                    setLoading(false);
                    cogoToast.error(err, {position: 'top-center'});
                });
        }

    }
    const resetForm = () => {
        setCourseName("");
        setSelectedCategory({});
        setSelectedLevel({});
        setPrice(0);
        setDiscountPrice(0);
        setDuration(0);
        setDesktopThumbnail(null);
        setDesktopThumbnailSrc("");
        setMobileThumbnail(null);
        setMobileThumbnailSrc("");
        setAboutCourse("");
        setNote("");
        setValueErrors({});
    }
    const handleDurationChange = (e) => {
        let error = {}
        if(isNaN(e.target.value)){
            error = {
                field: "duration",
                message: "Please enter a duration"
            };
            setValueErrors(error);

        } else if (parseInt(e.target.value)%30!==0){
            error = {
                field: "duration",
                message: "Validity should be multiple of 30 days"
            };
            setValueErrors(error);
        } else {
            setValueErrors({});
        }
        setDuration(e.target.value);
    }
    const handleAboutInitialize = React.useCallback((instance) => {
        aboutEditorCore.current = instance;

    }, [])

    const handleNoteInitialize = React.useCallback((instance) => {
        noteEditorCore.current = instance;

    }, [])

    const callback = useCallback((selectedOption) => {
        setSelectedOption(selectedOption);
    }, []);

    useEffect(function () {
        getCoursesList();
    }, [selectedOption]);
    const isDesktopOrLapTop = useMediaQuery("(min-width: 1400px)");

    return (
        <ProfessorPanelIndex activePage={"courses"}>
            <div className={'professorcourses'}>
                <div className={'header d-flex justify-content-between'}>
                    <h4 className={'headertitle'}>Courses</h4>
                    <div className={'d-flex'}>
                            <div className={'parent-sort'}>
                                <ProfessorFilterButton  parentCallback={callback}/>
                            </div>



                        <div onClick={() => setAddCrouseState(!addCrouseState)}>
                            <BasicButton>
                                + New Course
                            </BasicButton>
                        </div>
                        <MDBModal isOpen={addCrouseState} fullHeight position="right">
                            <MDBModalBody>
                                <img src={close_bgwhite} alt={'close button'} className={'modalCloseBtn'}
                                     onClick={() => {
                                         setAddCrouseState(!addCrouseState);
                                         resetForm();
                                     }}/>
                                <div className={'modal_title'}>New Course Details</div>
                                <div className={'form-field'}>
                                    <div className="Create">What is your Course name?
                                        <span>
                                            <img src={redStar} className="redStar" alt="redStar"/>
                                        </span>
                                    </div>
                                    <input
                                        type="text"
                                        placeholder="Type the name"
                                        className="code"
                                        required
                                        value={courseName}
                                        onChange={(e)=>setCourseName(e.target.value)}
                                    />
                                    {
                                        valueErrors.field === "courseName" &&
                                        <p className={"error-message"}>{valueErrors.message}</p>
                                    }
                                </div>
                                <div className="form-field">
                                    <div className="Create">
                                        Category of the Course?
                                        <span>
                                            <img src={redStar} className="redStar" alt="redStar"/>
                                        </span>
                                    </div>
                                    <select
                                        className="select1"
                                        name="gender"
                                        required
                                        id="gender"
                                        value={selectedCategory.id}
                                        onChange={(e)=>handleCategoryChange(e)}
                                    >
                                        <option value="" disabled selected>
                                            Select
                                        </option>
                                        {
                                            categoryList.map((item,index)=>{
                                                return(
                                                    <option value={item.id} key={uuid()}>{item.name}</option>
                                                )
                                            })
                                        }

                                    </select>
                                    {
                                        valueErrors.field === "category" &&
                                        <p className={"error-message"}>{valueErrors.message}</p>
                                    }
                                </div>
                                <div className="form-field">
                                    <div className="Create">
                                        What is the Course Level?
                                        <span>
                                          <img src={redStar} className="redStar" alt="redStar"/>
                                        </span>
                                    </div>
                                    <select
                                        className="select1"
                                        name="gender"
                                        required
                                        id="gender"
                                        value={selectedLevel.id}
                                        onChange={(e)=>handleLevelsChange(e)}

                                    >
                                        <option value="" disabled selected>
                                            Select
                                        </option>
                                        {
                                            courseLevelsList.map((item,index)=>{
                                                return(
                                                    <option value={item.id} key={uuid()}>{item.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    {
                                        valueErrors.field === "level" &&
                                        <p className={"error-message"}>{valueErrors.message}</p>
                                    }
                                </div>
                                <div className="d-flex top-form-field">
                                    <div className={'form-field'}>
                                        <div className="Create">Course Cost
                                            <span>
                                                <img src={redStar} className="redStar" alt="redStar"/>
                                            </span>
                                        </div>
                                        <input
                                            type="number"
                                            placeholder="Course Cost"
                                            className="code number"
                                            required
                                            value={price !== 0 && price}
                                            onChange={(e)=>setPrice(e.target.value)}
                                        />
                                        {
                                            valueErrors.field === "price" &&
                                            <p className={"error-message"}>{valueErrors.message}</p>
                                        }
                                    </div>
                                    <div className={'form-field'}>
                                        <div className="Create">Course Discount Cost
                                            <span>
                                                <img src={redStar} className="redStar" alt="redStar"/>
                                            </span>
                                        </div>
                                        <input
                                            type="number"
                                            placeholder="Discount Cost"
                                            className="code"
                                            required
                                            value={discountPrice !== 0 && discountPrice}
                                            // aria-placeholder={'Discount Cost'}
                                            onChange={(e)=>setDiscountPrice(e.target.value)}
                                        />
                                        {
                                            valueErrors.field === "discountPrice" &&
                                            <p className={"error-message"}>{valueErrors.message}</p>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex top-form-field">
                                    <div className={'form-field'}>
                                        <div className="Create">Course Validity
                                            <span>
                                                <img src={redStar} className="redStar" alt="redStar"/>
                                            </span>
                                        </div>
                                        <input
                                            type="number"
                                            placeholder="Course Validity"
                                            className="code number"
                                            required
                                            value={duration !== 0 && duration}
                                            onChange={(e)=>handleDurationChange(e)}
                                        />
                                        {
                                            valueErrors.field === "duration" &&
                                            <p className={"error-message"}>{valueErrors.message}</p>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex top-form-field mb-2">
                                    <div className={'form-field  name-field'}>
                                        Upload the thumbnail
                                        image for Desktop
                                        <img src={redStar} className="textreadStae" alt="redStar"/>
                                    </div>
                                    <div className={'form-field'}>
                                        <label htmlFor="desktop-file-upload"
                                               className="custom-file-upload"
                                               style={{backgroundImage:`url(${desktopThumbnailSrc})`,backgroundSize:"contain"}}
                                        >
                                            {
                                                desktopThumbnail ?
                                                    <MDBBox className={"edit-bar text-center"}>
                                                        <img src={edit} alt={"edit"}/>
                                                    </MDBBox>
                                                    :
                                                    <div className={'inside_customise'}>
                                                        <img src={upload} className={'upload pr-2'} alt={'upload'}/>
                                                        Upload here
                                                    </div>
                                            }
                                        </label>
                                        <input id="desktop-file-upload" type="file" accept="image/png, image/gif, image/jpeg"
                                               onChange={(e)=>handleFileChange(e,"desktop")}
                                        />
                                    </div>
                                </div>
                                {
                                    valueErrors.field === "desktopThumbnail" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                                <div className="d-flex top-form-field">
                                    <div className={'form-field  name-field'}>
                                        Upload the thumbnail
                                        image for Mobile
                                        <img src={redStar} className="textreadStae" alt="redStar"/>
                                    </div>
                                    <div className={'form-field'}>
                                        <label htmlFor="mobile-file-upload"
                                               className="custom-file-upload"
                                               style={{backgroundImage:`url(${mobileThumbnailSrc})`,backgroundSize:"contain"}}

                                        >
                                            {
                                                mobileThumbnail ?
                                                    <MDBBox className={"edit-bar text-center"}>
                                                        <img src={edit} alt={"edit"}/>
                                                    </MDBBox>
                                                    :
                                                    <div className={'inside_customise'}>
                                                        <img src={upload} className={'upload pr-2'} alt={'upload'}/>
                                                        Upload here
                                                    </div>
                                            }
                                        </label>
                                        <input id="mobile-file-upload" type="file" accept="image/png, image/gif, image/jpeg"
                                               onChange={(e)=>handleFileChange(e,"mobile")}

                                        />
                                    </div>
                                </div>
                                {
                                    valueErrors.field === "mobileThumbnail" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                                <div className={'form-field'}>
                                    <div className="Create">About the Course
                                        <span>
                                            <img src={redStar} className="redStar" alt="redStar"/>
                                        </span>
                                    </div>
                                    {/*<textarea name="" id="" cols="30" rows="10"*/}
                                    {/*          placeholder={'Type the about the course'}*/}
                                    {/*          value={aboutCourse}*/}
                                    {/*          onChange={(e)=>setAboutCourse(e.target.value)}*/}
                                    {/*>*/}
                                    {/*</textarea>*/}
                                    <ReactEditorJS
                                        tools={EDITOR_JS_TOOLS}
                                        onInitialize={handleAboutInitialize} holder="about-custom"
                                    >
                                        <div id="about-custom"
                                             className={"input-feild"}
                                        />
                                    </ReactEditorJS>
                                    {
                                        valueErrors.field === "about" &&
                                        <p className={"error-message"}>{valueErrors.message}</p>
                                    }
                                </div>
                                <div className={'form-field'}>
                                    <div className="Create">Instructor Note
                                        <span>
                                            <img src={redStar} className="redStar" alt="redStar"/>
                                        </span>
                                    </div>
                                    <ReactEditorJS
                                        tools={EDITOR_JS_TOOLS}
                                        onInitialize={handleNoteInitialize} holder="note-custom"
                                    >
                                        <div id="note-custom"
                                             className={"input-feild"} />
                                    </ReactEditorJS>
                                    {
                                        valueErrors.field === "note" &&
                                        <p className={"error-message"}>{valueErrors.message}</p>
                                    }
                                </div>
                                <div>
                                    <img src={redStar} className="redStar" alt="redStar"/>
                                    Marked are important
                                </div>
                                <div className={'modal_final_buttom'}>
                                    <BasicButton handleClick={createCourse} disabled={loading}>
                                        Create the Course
                                    </BasicButton>
                                </div>

                            </MDBModalBody>
                        </MDBModal>
                    </div>
                </div>
                <div className={'row'}>
                    {courseList.map((courseItem, courseIndex) => {
                        return (<div className={isDesktopOrLapTop?'col-md-6 col-lg-4 col-xl-3 mb-3 mt-2 course-card-column':'col-md-6 col-lg-4 col-xl-4 mb-3 mt-2 course-card-column'} key={uuid()}>
                            <div className="CourseCard p-2"
                                 onClick={() => {
                                     props.history.push("/professor/courses/" + courseItem.id);
                                 }}>
                                <div className="bg" style={{backgroundImage: `url(${courseItem.image?courseItem.image:CourseCardBg})`}}>
                                    <div className="star1 py-2 m-2">
                                        <img src={star} className="Star ml-2" alt="Star"/>
                                        {courseItem.averageRating}
                                    </div>
                                    <div className="heart1 py-2">
                                        <img src={professoruserlogo}
                                             className="heart pr-2" alt="heart"
                                        />
                                        {courseItem.enrolledUsers}
                                    </div>
                                </div>
                                <div className={"content-section d-flex justify-content-between"}>
                                    <div>
                                        <div className="parentUser">
                                            <TrunkString name={courseItem.name} length={20}/>
                                        </div>

                                        <div className={'status'}>
                                            {courseItem.isActive?
                                              <div className={'live-class'}>
                                                <img src={live} className={'pr-2'}
                                                     alt={'pending'}/>
                                               Live
                                            </div>
                                                :
                                                <div>
                                                    <img src={yellowdate} className={'pr-2'}
                                                         alt={'pending'}/>
                                                    Waiting for Consent
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <button type={"button"} className={'edit_button my-auto'}>
                                        <img src={edit} className="inCart" alt="Cart"/>
                                    </button>
                                </div>
                            </div>
                        </div>);
                    })}
                </div>
            </div>
        </ProfessorPanelIndex>
    );
}

// export default ;


const mapStateToProps = state => ({
    user_details: state.UserReducer.user_details,
});

export default compose(
    withRouter,connect(mapStateToProps, null)
)(ProfessorsCourses);




//
// export default compose(
//     withRouter,
// )();