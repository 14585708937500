//lib
import Slider from "react-slick";

import {MDBModal, MDBModalBody} from 'mdbreact';
//files
import './CourseList.scss'
import {LayoutContainer} from "../../Components/LayoutContainer/LayoutContainer";
import expert from "../../assets/images/professorpic.svg";
import OurExperts_bg from "../../assets/Illustrations/Polygon-Light.png";
import React, {useEffect, useState} from "react";
import useMediaQuery from "../../util/useMediaQuery";

// import {RoundedButton} from "../Components/Buttons/RoundedButton";
// import leftsmallarrow from "../assets/images/leftSmallarrow.png";


//images
import {FlatButton} from "../../Components/Buttons/FlatButton";
import blogCardBg1 from "../../assets/images/blogCardBg1.png"
import {BasicButton} from "../../Components/Buttons/BasicButton";
import filter_bitton_img from "../../assets/images/filter_button.svg";
import search_img from "../../assets/images/search_img.png"
import close_button_bg_black from "../../assets/images/close_button_bg_black.png"
import star5 from "../../assets/images/star5.png"
import star4 from "../../assets/images/star4.png"
import star3 from "../../assets/images/star3.png"
import star2 from "../../assets/images/star2.png"
import star1 from "../../assets/images/star1.png"
import search_black_ing from "../../assets/images/search_black_ing.png";
import right_yello_arrow from "../../assets/images/right_yello_arrow.png"
import CourseCard from "../../Containers/CourseCard/CourseCard";
import {fetchQuery} from "relay-runtime";
import environment from "../../Environment";
import CourseListQuery from "../../queries/CourseListQuery";
import ProfessorListQuery from "../../queries/ProfessorListQuery";
import {ProfessorCard} from "../../Containers/ProfessorCard/ProfessorCard";
import parse from "html-react-parser";
import dateFormat, { masks } from "dateformat";
import { Range } from 'react-range';
import PriceRangeIcon from "../../assets/images/price-range-icon.svg";
import uuid from 'react-uuid'
import CategoryListQuery from "../../queries/CategoryListQuery";
import SearchMutation from "../../mutations/SearchMutation";
import CourseLevelListQuery from "../../queries/CourseLevelListQuery";

import {withRouter} from "react-router-dom";
import Header from "../../Containers/Header/Header";
import {Footer} from "../../Containers/Footer/Footer";
import Loader from "../../Components/Loader/Loader";

const CourseList = (props) => {
    const [courseList, setCourseList] = useState([]);
    const [filteredCourseList, setFilteredCourseList] = useState([]);
    const [professorList,setProfessorList]=useState([]);
    const [filteredProfessorList, setFilteredProfessorList] = useState([]);
    const [courseLevels, setCourseLevels] = useState([]);

    const [blogList,setBlogList]=useState([]);
    const [categoryList, setCategoryList] = useState([]);

    const [filterApplied,setFilterApplied] = useState(false);


    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedProfessor, setSelectedProfessor] = useState([]);
    const [selectedCourseLevel, setSelectedCourseLevel] = useState([]);
    const [selectedReview, setSelectedReview] = useState([]);
    const [activeAttribute,setActiveAttribute] = useState("category");
    const [searchText,setSearchText] = useState("");
    const [priceRange,setPriceRange] = useState([0, 100000]);

    const [loading,setLoading]=useState(false);


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const AveragestudentReview = [
        {
            id: 1,
            image: star4,
            type: "image",
            title: "& up",
            value:4
        },
        {
            id: 2,
            image: star3,
            type: "image",
            title: "& up",
            value:3
        },
        {
            id: 3,
            image: star2,
            type: "image",
            title: "& up",
            value:2
        },
        {
            id: 4,
            image: star1,
            type: "image",
            title: "& up",
            value:1

        }];

    const isDesktop = useMediaQuery("(min-width: 765px)");
    useEffect(function () {
        getCategoryList();
        getCourseLevelList();
        getProfessorList();
        getCoursesList();
    }, []);

    const getCoursesList = () => {
        setLoading(true);
        fetchQuery(environment, CourseListQuery)
            .toPromise()
            .then(response => {
                setLoading(false);
                if (response.courses !== null) {
                    let tmp_courses = response.courses.edges;
                    let course_list = [];
                    tmp_courses.map((item,index) => {
                        course_list[index] = item.node;
                    });
                    setCourseList(course_list);
                    setFilteredCourseList(course_list);
                } else {
                    setCourseList([]);
                    setFilteredCourseList([]);
                }
            }, err => {
                setLoading(false);
                setCourseList([]);
                setFilteredCourseList([]);
            });
    }

    const getProfessorList = () => {
        setLoading(true);
        fetchQuery(environment,ProfessorListQuery)
            .toPromise()
            .then(response => {
                setLoading(false);
                if (response.allInstructors !== null && response.allInstructors.edges) {
                    let tmp_data = [];
                    let professors = response.allInstructors.edges;
                    professors.map((item,index)=>{
                        tmp_data[index] = {
                            "id":item.node.id,
                            "title":item.node.user.name
                        }
                    });
                    setProfessorList(tmp_data);
                } else {
                    setProfessorList([]);
                }
            }, err => {
                setLoading(false);
                setProfessorList([]);
            });
    }

    const getCategoryList = () => {
        setLoading(true);
        fetchQuery(environment, CategoryListQuery)
            .toPromise()
            .then(response => {
                setLoading(false);
                if (response.categories !== null && response.categories.edges.length > 0) {
                    let tmp_data = [];
                    let categories = response.categories.edges;
                    categories.map((item,index)=>{
                        tmp_data[index] = {
                            "id":item.node.id,
                            "title":item.node.name
                        }
                    });
                    setCategoryList(tmp_data);
                } else {
                    setCategoryList([])
                }
            }, err => {
                setLoading(false);
                setCategoryList([])
            });
    }

    const getCourseLevelList = () => {
        setLoading(true);
        fetchQuery(environment, CourseLevelListQuery)
            .toPromise()
            .then(response => {
                setLoading(false);
                if (response.levels !== null && response.levels.edges.length > 0) {
                    let tmp_data = [];
                    let levels = response.levels.edges;
                    levels.map((item,index)=>{
                        tmp_data[index] = {
                            "id":item.node.id,
                            "title":item.node.name
                        }
                    });
                    setCourseLevels(tmp_data);
                } else {
                    setCourseLevels([]);
                }
            }, err => {
                setLoading(false);
                setCourseLevels([]);
            });
    }

    const updateCourseList = (course, booleanValue) => {
        // debugger
        let tmp_course_list = courseList.slice();
        // debugger
        let index = courseList.findIndex(course_item => course_item.id === course.id);
        if (index > -1) {
            tmp_course_list[index] = {
                ...tmp_course_list[index],
                node: {
                    ...tmp_course_list[index].node,
                    isWishlist: booleanValue
                }
            };
        }
        setCourseList(tmp_course_list);
    }

    const handleCategoryChange = (data) => {
        let tmp_selected_category_list = selectedCategory.slice();
        let index = tmp_selected_category_list.findIndex(x => x.id === data.id);
        if(index > -1){
            tmp_selected_category_list.splice(index, 1);
        } else {
            tmp_selected_category_list.push(data);
        }
        setSelectedCategory(tmp_selected_category_list);
        // searchCourse();
    }

    const handleProfessorChange = (data) => {
        let tmp_selected_professor_list = selectedProfessor.slice();
        let index = tmp_selected_professor_list.findIndex(x => x.id === data.id);
        if(index > -1){
            tmp_selected_professor_list.splice(index, 1);
        } else {
            tmp_selected_professor_list.push(data);
        }
        setSelectedProfessor(tmp_selected_professor_list);
    }

    const handleCourseLevelChange = (data) => {
        let tmp_selected_course_level_list = selectedCourseLevel.slice();
        let index = tmp_selected_course_level_list.findIndex(x => x.id === data.id);
        if(index > -1){
            tmp_selected_course_level_list.splice(index, 1);
        } else {
            tmp_selected_course_level_list.push(data);
        }
        setSelectedCourseLevel(tmp_selected_course_level_list);
    }

    const handleReviewChange = (data) => {
        let tmp_selected_review_list = selectedReview.slice();
        let index = tmp_selected_review_list.findIndex(x => x.id === data.id);
        if(index > -1){
            tmp_selected_review_list.splice(index, 1);
        } else {
            tmp_selected_review_list.push(data);
        }
        setSelectedReview(tmp_selected_review_list);
    }

    const searchCourse = () => {
        // debugger
        setLoading(true);
        let variables = {
            "searchText":searchText,
            "Categories":selectedCategory.map(a => a.id),
            "Instructors":selectedProfessor.map(a => a.id),
            "levels": selectedCourseLevel.map(a => a.id),
            "rating": selectedReview.map(a => a.value),
            "fromPrice": priceRange[0],
            "toPrice": priceRange[1]
        }
        SearchMutation(variables,function (response){
            setLoading(false);
            setOpenFilterModal(false);
            if(response.search !== null){
                setFilteredCourseList(response.search.courses);
                setFilteredProfessorList(response.search.instructors);
                setBlogList(response.search.blogs);
                setFilterApplied(true);
            } else {
                setFilteredCourseList([]);
                setFilteredProfessorList([]);
                setBlogList([]);
                setFilterApplied(true);
            }
        },function (err){
            setLoading(false);
            setFilteredCourseList([]);
            setFilteredProfessorList([]);
            setBlogList([]);
            setFilterApplied(false);
            setOpenFilterModal(false);
        })
    }

    const resetFilter = () => {
        setSelectedCategory([]);
        setPriceRange([0,100000]);
        setSelectedProfessor([]);
        setSelectedCourseLevel([]);
        setSelectedReview([]);
        setFilteredCourseList(courseList);
        setFilteredProfessorList([]);
        setBlogList([]);
        setFilterApplied(false);
    }

    const viewAllList = (path) => {
        props.history.push(path);
    }

    let search_result_text;
    if(searchText !== "") {
        search_result_text = `${filteredCourseList.length} Search Results for “${searchText}” In Courses`;
    } else {
        search_result_text = `${filteredCourseList.length} Search Results In Courses`;
    }

    let search_result_text1;
    if(searchText !== "") {
        search_result_text1 = `${filteredProfessorList.length} Search Results for “${searchText}” In Professors`;
    } else {
        search_result_text1 = `${filteredProfessorList.length} Search Results In Professors`;
    }

    let search_result_text2;
    if(searchText !== "") {
        search_result_text2 = `${blogList.length} Search Results for “${searchText}” In Blogs`;
    } else {
        search_result_text2 = `${blogList.length} Search Results In Blogs`;
    }

    if (loading) {
        return (
            <div>
                <Loader full={true} />
                <div  style={{height:window.innerHeight}}/>
            </div>
        );
    }

    return (
        <>
            <Header page={"course-list"}/>
            <div className={'course-list-component'}>
                <LayoutContainer>
                    <div className={'course-filter-component'}>
                        <div className={'filter-input-section'}>
                            <input type="text" className={'filter_input'}
                                   placeholder={"Search..."}
                                   value={searchText}
                                   onChange={(e)=> {
                                       if(e.target.value === ""){
                                           setFilteredCourseList(courseList);
                                           setFilteredProfessorList([]);
                                           setBlogList([]);
                                           setFilterApplied(false);
                                       }
                                       setSearchText(e.target.value);
                                   }}
                                   onKeyDown={(e) => {
                                       if (e.code === "Enter") {
                                           e.preventDefault();
                                           searchCourse();
                                       }
                                   }}
                            />
                            <img src={isDesktop ? search_img : search_black_ing} className={'search_icon'} alt={'search_img'} onClick={searchCourse}/>
                            <div onClick={() => setOpenFilterModal(!openFilterModal)}>
                                <div className={'filter_button'}>
                                    <button type={"button"}>
                                        <img src={filter_bitton_img} className={'filter_bitton_img'} alt={'filter_bitton_img'}/>
                                        {isDesktop ? <>Filters</> : <></>}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <MDBModal isOpen={openFilterModal} fullHeight position="left" id={"course-filter-modal"}>
                            <img onClick={() => setOpenFilterModal(false)} src={close_button_bg_black}
                                 alt={'close_button_bg_black'}
                                 className={'modal-close-btn'}/>
                            <MDBModalBody className={'modal-content-section'}>
                                <div className={"large-device-filter-attributes"}>
                                    <div className={'filter-property-title'}>Categories</div>
                                    <FilterAttribute attributes={categoryList}
                                                     selectedAttribute={selectedCategory}
                                                     handleAttributeChange={handleCategoryChange} />

                                    <div className={'filter-property-title'}>Price Range</div>
                                    <PriceAttribute values={priceRange} setValues={setPriceRange}/>

                                    <div className={'filter-property-title'}>Professors</div>
                                    <FilterAttribute attributes={professorList}
                                                     selectedAttribute={selectedProfessor}
                                                     handleAttributeChange={handleProfessorChange} />


                                    <div className={'filter-property-title'}>CourseLevels</div>
                                    <FilterAttribute attributes={courseLevels}
                                                     selectedAttribute={selectedCourseLevel}
                                                     handleAttributeChange={handleCourseLevelChange} />

                                    <div className={'filter-property-title'}>Average student Review</div>
                                    <FilterAttribute attributes={AveragestudentReview}
                                                     selectedAttribute={selectedReview}
                                                     handleAttributeChange={handleReviewChange} />
                                    <div className={"filter-action-section"}>
                                        <button type={"button"} className={"btn clear-filter-btn"}
                                                onClick={resetFilter}
                                        >
                                            Clear All
                                        </button>
                                        <button type={"button"} className={"btn apply-filter-btn"}
                                                onClick={searchCourse}
                                        >
                                            Apply Filter
                                        </button>
                                    </div>
                                </div>
                                <div className={"small-device-filter-attributes"}>
                                    <div className={'content-section'}>
                                        <div className={'row'}>
                                            <div className={'left-column col-4 p-0'}>
                                                <h3 className={'header'}>Filter by</h3>
                                                <div className={`attribute-item ${activeAttribute === 'category'?'active':''}`}
                                                     onClick={() => setActiveAttribute("category")}
                                                >
                                                    <h5 className={'title'}>Category</h5>
                                                </div>
                                                <div className={`attribute-item ${activeAttribute === 'price'?'active':''}`}
                                                     onClick={() => setActiveAttribute("price")}
                                                >
                                                    <h5 className={'title'}>Price</h5>
                                                </div>
                                                <div className={`attribute-item ${activeAttribute === 'professor'?'active':''}`}
                                                     onClick={() => setActiveAttribute("professor")}
                                                >
                                                    <h5 className={'title'}>Professors</h5>
                                                </div>
                                                <div className={`attribute-item ${activeAttribute === 'levels'?'active':''}`}
                                                     onClick={() => setActiveAttribute("levels")}
                                                >
                                                    <h5 className={'title'}>Levels</h5>
                                                </div>
                                                <div className={`attribute-item ${activeAttribute === 'ratings'?'active':''}`}
                                                     onClick={() => setActiveAttribute("ratings")}
                                                >
                                                    <h5 className={'title'}>Ratings</h5>
                                                </div>
                                            </div>
                                            <div className={'right-column col-8 p-0'}>
                                                <div className={'button-section'}>
                                                    <FlatButton handleClick={resetFilter}>Clear all</FlatButton>
                                                </div>
                                                <div className={'attribute-content-section'}>
                                                    {
                                                        activeAttribute === "category" &&
                                                        <FilterAttribute attributes={categoryList}
                                                                         selectedAttribute={selectedCategory}
                                                                         handleAttributeChange={handleCategoryChange} />
                                                    }
                                                    {
                                                        activeAttribute === "price" &&
                                                        <PriceAttribute values={priceRange} setValues={setPriceRange}/>
                                                    }
                                                    {
                                                        activeAttribute === "professor" &&
                                                        <FilterAttribute attributes={professorList}
                                                                         selectedAttribute={selectedProfessor}
                                                                         handleAttributeChange={handleProfessorChange} />
                                                    }
                                                    {
                                                        activeAttribute === "levels" &&
                                                        <FilterAttribute attributes={courseLevels}
                                                                         selectedAttribute={selectedCourseLevel}
                                                                         handleAttributeChange={handleCourseLevelChange} />
                                                    }
                                                    {
                                                        activeAttribute === "ratings" &&
                                                        <FilterAttribute attributes={AveragestudentReview}
                                                                         selectedAttribute={selectedReview}
                                                                         handleAttributeChange={handleReviewChange} />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'action-btn-section'}>
                                            <div className={'close-btn action-btn'} onClick={() => setOpenFilterModal(false)}>Close</div>
                                            <div className={'apply-btn action-btn'} onClick={searchCourse}>Apply</div>
                                        </div>
                                    </div>
                                </div>
                            </MDBModalBody>
                        </MDBModal>
                    </div>
                </LayoutContainer>

                {
                    filterApplied === true &&
                    <LayoutContainer>
                        <SearchResultTitle
                            content={search_result_text}
                            mobileContent={"Search Results in Courses"}
                            view_all_redirect={"/course-list"}
                            viewAllList={viewAllList}
                        />
                    </LayoutContainer>
                }
                {
                    filteredCourseList.length > 0 ?
                        <Courses courseList={filteredCourseList}
                                 updateCourseList={updateCourseList}
                                 filterApplied={filterApplied}
                                 searchText={searchText}
                        />:
                        <div className={"empty-message"}>
                            No courses Found
                        </div>
                }
                
                {
                    filterApplied &&
                    <LayoutContainer>
                        <SearchResultTitle
                            content={search_result_text1}
                            mobileContent={"Search Results in Professors"}
                            view_all_redirect={"/professor-list"}
                            viewAllList={viewAllList}
                        />
                    </LayoutContainer>
                }
                {
                    filterApplied === true &&
                    <>
                        {
                            filteredProfessorList.length > 0 ?
                                <ProfessorList professorList={filteredProfessorList}
                                               filterApplied={filterApplied}
                                               searchText={searchText}
                                />  :
                                <div className={"empty-message"}>
                                    No Professors Found
                                </div>
                        }
                    </>
                }
              
                {
                    filterApplied === true &&
                    <LayoutContainer>
                        <SearchResultTitle
                            content={search_result_text2}
                            mobileContent={"Search Results in Blogs"}
                            view_all_redirect={"/blogs"}
                            viewAllList={viewAllList}
                        />
                    </LayoutContainer>
                }
                {
                    filterApplied === true &&
                    <>
                        {
                            blogList.length > 0 ?
                                <BlogList blogList={blogList}
                                          filterApplied={filterApplied}
                                          searchText={searchText}
                                />
                                :
                                <div className={"empty-message"}>
                                    No Blogs Found
                                </div>
                        }
                    </>
                }


            </div>
            <Footer/>
        </>
    )
}

export default withRouter(CourseList);

const FilterAttribute = (props) => {
    const {attributes,selectedAttribute,handleAttributeChange} = props;
    return(
        <div className={"filter-attribute-component"}>
            {
                attributes.map((item) => {
                    return (
                        <div className={'filter-property-item'} key={uuid()}>
                            <CustomCheckBox
                                selectedValue={selectedAttribute}
                                check_box_item={item}
                                handleCheckboxChange={handleAttributeChange}
                            />
                        </div>
                    );
                })
            }
        </div>
    );
}

const CustomCheckBox = (props) => {
    const {check_box_item, selectedValue, handleCheckboxChange} = props;
    const isSelected = () => {
        let selectedAttributes = props.selectedValue.slice();
        let index = selectedAttributes.findIndex(x => x.id === check_box_item.id);
        return index > -1;
    }
    return (
        <div className={"custom-checkbox-component"}>
            <div className="custom-controls-stacked d-block">
                <label className="custom-control overflow-checkbox">
                    <input type="checkbox" className="overflow-control-input"
                           value={selectedValue.id}
                           // checked={selectedValue.id === check_box_item.id}
                        checked={isSelected()}
                           onChange={(e) => handleCheckboxChange(check_box_item,e)}
                    />
                    <span className="overflow-control-indicator"/>
                    <span className="overflow-control-description">
                        {
                            check_box_item.type === "image" &&
                            <>
                                <img src={check_box_item.image} alt={""}/>
                            </>
                        }
                        {check_box_item.title}
                    </span>
                </label>
            </div>
        </div>
    );
}

const SearchResultTitle = (props) => {
    const isDesktop1 = useMediaQuery("(min-width: 765px)");
    const {content,mobileContent,viewAllList,view_all_redirect} = props;
    return (
        <div className={"search-result-title-section"}>
            <div className={'result-title '}>
                {isDesktop1?content:mobileContent}
            </div>
            <div className={"view-all-btn"}>
                <FlatButton handleClick={()=>viewAllList(view_all_redirect)}>View All
                    <img src={right_yello_arrow} className={'right_yello_arrow'}
                                          alt={'right_yello_arrow'}/>
                </FlatButton>
            </div>
        </div>
    )
}

export const ProfessorList = (props) => {
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5.25,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 4.25,
                    slidesToScroll: 3,

                }
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 3.45,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2.45,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1.45,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const {professorList,searchText,filterApplied} = props;

    return (
        <div className={'professor-list-section'}>

            <div className={'ourExperts'}>
                <Slider {...settings}>
                    {
                        professorList.map((item, index) => {
                        return (
                            <div key={uuid()}>
                                <ProfessorCard professorData={item}/>
                            </div>
                        );
                    })}
                </Slider>
            </div>

        </div>
    )
}

const Courses = (props) => {

    // const [loading,setLoading]=useState(false);
    const isDesktopOrLapTop = useMediaQuery("(min-width: 1400px)");

        // setLoading(true)
    const {courseList, updateCourseList,filterApplied,searchText} = props;
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1.25,
        slidesToScroll: 1,
    };

  // setLoading(false)

    // if (loading) {
    //     return (
    //         <div>
    //             <Loader full={true} />
    //             <div  style={{height:window.innerHeight}}/>
    //         </div>
    //     );
    // }


    return (
        <div className={'courses-list-section'}>
            <div className={"large-device-course-section"}>
                <div className={'row search_Courses_results'}>
                    {
                        courseList.map((item, index) => {
                            return (
                                <div className={isDesktopOrLapTop?'col-md-6 col-lg-4 col-xl-3':'col-md-6 col-lg-4 col-xl-4'} key={index}>
                                    <CourseCard courseData={item} updateCourseList={updateCourseList}/>
                                </div>
                            );
                        })
                    }
                </div>
                {/*<BasicButton>*/}
                {/*    Load More*/}
                {/*</BasicButton>*/}
            </div>
            <div className={"small-device-course-section"}>
                <Slider {...settings}>
                    {
                        courseList.map((item, index) => {
                            return (
                                <div key={index}>
                                    <CourseCard courseData={item} updateCourseList={updateCourseList}/>
                                </div>
                            );
                        })
                    }
                </Slider>
            </div>

    </div>
    )
}

const BlogCard = (props) => {
    function truncateString(str, num) {
        if (str.length <= num) {
            return str
        }
        return str.slice(0, num) + '...'
    }
    const getBlockContent = () => {
        let tmp_blog_data = props.blogData;
        let blogContent = JSON.parse(tmp_blog_data.body);
        return blogContent.blocks && blogContent.blocks[0] ? truncateString(parse(blogContent.blocks[0].data.text),80):"";
    }
    const {blogData} = props;
    return (
        <div className={"blog-card-section"}>
            <div className={'blogCard large-device-blog-card'}>
                <img src={blogData.featuredImage} className={'blog-img'} alt={'blogCardBg1'}/>
                <p className={'blog-date'}>{dateFormat(blogData.updatedAt, "dd, mmmm yyyy")}</p>
                <h3 className={'blog-question'}>{blogData.title}</h3>
                <p className={'blog-answer'}>{getBlockContent()}</p>
            </div>
            <div className={"blogCard small-device-blog-card"}>
                <div className={"image-section"}>
                    <img src={blogData.featuredImage} className={'blog-img'} alt={'blogCardBg1'}/>
                </div>
                <div className={"content-section"}>
                    <h3 className={'blog-question'}>{blogData.title}</h3>
                    <div className={"blog-date-section"}>
                        <p className={'blog-date'}>{dateFormat(blogData.updatedAt, "dd, mmmm yyyy")}</p>
                        <p className={"blog-read-time"}>5 mins read</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const BlogList = (props) => {
    const {blogList,filterApplied,searchText} = props;

    return (
        <div className={'blog-list-section'}>

            <div className={"blog-list-content-section"}>
                <div className={'row search_blog_results'}>
                    {
                        blogList.map((item, index) => {
                            return (
                                <div className="col-12 col-md-6 col-lg-4 col-xl-4 columns" key={uuid()}>
                                    <BlogCard blogData={item} />
                                </div>
                            );
                        })
                    }
                </div>

                {/*<div className={"load-more-btn-section"}>*/}
                {/*    <BasicButton>*/}
                {/*        Load More*/}
                {/*    </BasicButton>*/}
                {/*</div>*/}
            </div>
        </div>
    )
}

const PriceAttribute = (props) => {

    const {values,setValues} = props;

    const getTrackBackground = ({values, colors, min, max, direction = 'to right', rtl = false}) => {
        if (rtl && direction === 'to right') {
            direction = 'to left';
        } else if (rtl && 'to left') {
            direction = 'to right';
        }
        // sort values ascending
        const progress = values.slice(0).sort((a, b) => a - b).map(value => ((value - min) / (max - min)) * 100);
        const middle = progress.reduce(
            (acc, point, index) =>
                `${acc}, ${colors[index]} ${point}%, ${colors[index + 1]} ${point}%`,
            ''
        );
        return `linear-gradient(${direction}, ${colors[0]} 0%${middle}, ${colors[colors.length - 1]
        } 100%)`;
    }

    const handlePriceChange = (e,feild) => {
        let tmp_values = values.slice();
        if (feild === "min"){
            tmp_values[0] = (e.target.validity.valid && e.target.value !== "") ? parseInt(e.target.value) : 0;
        }
        if (feild === "max"){
            // console.log(e.target.validity.valid)
            tmp_values[1] = (e.target.validity.valid && e.target.value !== "") ? parseInt(e.target.value) : 0;
        }
        setValues(tmp_values);
    }
    // console.log(values)

    return(
        <div className={"price-attribute-section"}>
            <div className={"price-attribute-feild-section"}>
                <input type="text"
                       pattern="[0-9]*"
                       maxLength="3"
                       className={"price-feild"}
                       value={values[0]}
                       onInput={(e) => handlePriceChange(e,"min")}
                       onChange={(e) => handlePriceChange(e,"min")}
                />
                <div className={"to"}>to</div>
                <input type="text"
                       pattern="[0-9]*"
                       maxLength="6"
                       className={"price-feild"}
                       value={values[1]}
                       onInput={(e) => handlePriceChange(e,"max")}
                       onChange={(e) => handlePriceChange(e,"max")}
                />
            </div>
            <Range
                step={0.100}
                min={0}
                max={100000}
                values={values}
                onChange={(values) => setValues(values)}
                renderTrack={({ props, children }) => (
                    <div
                        onMouseDown={props.onMouseDown}
                        onTouchStart={props.onTouchStart}
                        style={{
                            ...props.style,
                            height: '36px',
                            display: 'flex',
                            width: '90%'
                        }}
                    >
                        <div
                            ref={props.ref}
                            style={{
                                height: '5px',
                                width: '100%',
                                borderRadius: '4px',
                                background: getTrackBackground({
                                    values,
                                    colors: ['rgba(233, 181, 28, 0.3)', '#E9B51C', 'rgba(233, 181, 28, 0.3)'],
                                    min:0,
                                    max:100
                                }),
                                alignSelf: 'center'
                            }}
                        >
                            {children}
                        </div>
                    </div>
                )}
                renderThumb={({ props }) => (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            border:"none",
                            outline:"none"
                        }}
                    >
                        <img src={PriceRangeIcon} alt={"PriceRangeIcon"} className={"img-fluid"}/>
                    </div>
                )}
            />
        </div>
    );
}

