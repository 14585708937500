import './PaymentHistory.scss'
import {RoundedButton} from "../../../Components/Buttons/RoundedButton";
import {FlatButton} from "../../../Components/Buttons/FlatButton";
import {LayoutContainer} from "../../../Components/LayoutContainer/LayoutContainer";
import useMediaQuery from "../../../util/useMediaQuery";
import React, {useEffect, useState} from "react";
import {fetchQuery} from "relay-runtime";
import environment from "../../../Environment";
import PaymentHistoryListQuery from "../../../queries/PaymentHistoryListQuery";
import left_yellowarrow from "../../../assets/images/left_yellowarrow.png";
import dateFormat from "dateformat";
import uuid from 'react-uuid'
import ProfileNavbar from "../ProfileNavbar/ProfileNavbar";
import {useHistory} from "react-router-dom";
import {Link} from "react-router-dom";
import Header from "../../../Containers/Header/Header";
import {Footer} from "../../../Containers/Footer/Footer";
import cogoToast from "cogo-toast";
import ApplyVoucherMutation from "../../../mutations/ApplyVoucherMutation";
import GenerateInvoiceMutation from "../../../mutations/GenerateInvoiceMutation";

export const PaymentHistory = () => {
    const isDesktop = useMediaQuery("(min-width: 770px)");
    const [orderList, setOrderList] = useState([]);
    useEffect(() => {
        fetchOrderList()
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const fetchOrderList = () => {
        fetchQuery(environment, PaymentHistoryListQuery)
            .toPromise()
            .then(response => {
                if (response.orders !== null && response.orders.edges.length > 0) {
                    setOrderList(response.orders.edges);
                } else {
                    setOrderList([]);
                }
            }, err => {
                // console.log(err);
                setOrderList([]);
            });
    }







    const getInvoice = (id) => {
        if (id===""){
            cogoToast.error("Some Thing Went Wrong", {position: 'top-center'});
        }
        let variables = {
            orderId: id
        }
        cogoToast.info("Please wait a moment", {position: 'top-center'});
        GenerateInvoiceMutation(variables,function (response){
            if (response.generateInvoice.invoice.invoiceUrl !== null) {
                const fileURL = response.generateInvoice.invoice.invoiceUrl
                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL;
            } else {
                cogoToast.info("Some Thing Went Wrong", {position: 'top-center'});
            }
        },function (err){
            cogoToast.error("Some Thing Went Wrong", {position: 'top-center'});
        })

    }

    return (
        <>
            <Header page={"payment-history"}/>
            <ProfileNavbar active_page={"Payment History"}>
            <div className={'PaymentHistory'}>
                <div className={'title'}>Payment History</div>
                {
                    orderList.length > 0 && orderList.map((item) => {
                        return (
                            <div className={'main_container'} key={uuid()}>
                                <div className={'d-flex top_flex'}>
                                    <div className={'orderNo'}>
                                        <p className="heda m-0">Order No:
                                            <span className="orderid">
                                                {
                                                    isDesktop ? <></> : <>#</>
                                                }
                                                {item.node.orderId}
                                            </span>
                                        </p>
                                    </div>
                                    {
                                        isDesktop ?
                                            <div>
                                                <Link to={"/myprofile/payment-history/view-details/"+item.node.id}>
                                                    <RoundedButton>View Details</RoundedButton>
                                                </Link>                                            </div> :
                                            <div>
                                                <FlatButton>Download Invoice</FlatButton>
                                            </div>
                                    }
                                </div>
                                <hr/>
                                <div className={'d-flex bottom_flex'}>
                                    <div>
                                        <p className="orders orders_1  m-0">Purchase Date <br/> <span
                                            className="orderid pl-0 mt-3">{dateFormat(item.node.updatedAt, "dd, mmmm yyyy")}</span>
                                        </p>
                                    </div>
                                    <div>
                                        <p className="orders m-0">Purchase Total <br/> <span
                                            className="orderid pl-0 mt-1">₹ {item.node.totalPayable}</span></p>
                                    </div>
                                    {
                                        isDesktop ?
                                            <div onClick={()=>getInvoice(item.node.id)} >
                                                <FlatButton>Download Invoice</FlatButton>
                                            </div> :
                                            <div>
                                                <Link to={"/myprofile/payment-history/view-details/"+item.node.id}>
                                                    <RoundedButton>View Details</RoundedButton>
                                                </Link>
                                            </div>
                                    }
                                </div>
                            </div>
                        );
                    })

                }
                {
                    orderList.length === 0 && <div className={'cusdataerror'}>NO data founded</div>
                }
            </div>
        </ProfileNavbar>
            <Footer/>
        </>
    );

}


