import "./CourseAccordian.scss";
import { MDBBox } from "mdbreact";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import AccordianArrow from "../../assets/images/accordian-arrow.png";
import VideoIcon from "../../assets/images/ant-design_play-circle-filled.png";
import CompletedIcon from "../../assets/icons/akar-icons_circle-check-fill-green.svg";
import PlayIcon from "../../assets/icons/bi_pause-fill.svg";
import StudyIcon from "../../assets/icons/study-img.svg";


import DocumentIcon from "../../assets/images/document-icon.png";
import LockIcon from "../../assets/icons/bx_bxs-lock-open-alt.svg"
import { RoundedButton } from "../../Components/Buttons/RoundedButton";
import { withRouter } from "react-router-dom";
import uuid from 'react-uuid'
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const CourseAccordian = (props) => {
    const { preview, modules, courseData, activeModules, isExpandAll } = props;
    const goToDetail = (courseId, moduleId, lessonId) => {
        props.history.push("/course-detail/" + courseId + "/" + moduleId + "/" + lessonId);
    }

    const params = useParams();
    return (
        <MDBBox className={"course-accordian-component"}>
            <Accordion preExpanded={isExpandAll ? [] : [`module-${params.moduleId}`]} allowMultipleExpanded={isExpandAll} allowZeroExpanded={isExpandAll}>
                {modules.map((item, index) => {
                    let module_id = item.node.id;
                    let module_active = props.activeModules && props.activeModules.module.id === item.node.id;
                    return (
                        <AccordionItem key={uuid()} uuid={`module-${module_id}`}>
                            <AccordionItemHeading>
                                <AccordionItemButton aria-disabled="ture">
                                    <MDBBox className={"lesson-heading-component"}>
                                        <MDBBox
                                            className={"align-items-center justify-content-between large-device-heading"}>
                                            <MDBBox
                                                className={"d-flex align-items-center justify-content-center left-section"}>
                                                <img src={AccordianArrow} alt={"arrow"}
                                                    className={"img-fluid accordian-arrow"} />
                                                <p className={"module-name"}>{item.node.name}</p>
                                            </MDBBox>
                                            <MDBBox className={"duration-section"}>
                                                <p className={"duration"}>{item.node.lessonCount} lectures  •  {item.node.totalDuration} mins</p>
                                            </MDBBox>
                                        </MDBBox>
                                        <MDBBox
                                            className={"align-items-center justify-content-between small-device-heading"}>
                                            <MDBBox
                                                className={"d-flex  left-section"}>

                                                <p className={"module-name"}>{item.node.name}</p>
                                                <p className={"duration"}>{item.node.lessonCount} lectures  •  {item.node.totalDuration} mins</p>
                                            </MDBBox>
                                            <MDBBox>
                                                <img src={AccordianArrow} alt={"arrow"}
                                                    className={"img-fluid accordian-arrow"} />
                                            </MDBBox>
                                        </MDBBox>

                                    </MDBBox>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                {
                                    item.node.lessonCount > 0 && item.node.lessons.edges.map((lessonItem, lessonIndex) => {
                                        let lesson_status;
                                        if (lessonItem.node.isWatching === true) {
                                            lesson_status = "resumenow"
                                        } else if (lessonItem.node.isWatched === true) {
                                            lesson_status = "completed"
                                        } else if (lessonItem.node.type === "PDF") {
                                            lesson_status = "readenow"
                                        } else {
                                            lesson_status = "watchnow"
                                        }
                                        let isWatching = props.activeModules && props.activeModules.lesson.isWatching === true && props.activeModules.lesson.id === lessonItem.node.id;
                                        let isWatched = props.activeModules && props.activeModules.lesson.isWatched === true && props.activeModules.lesson.id === lessonItem.node.id;
                                        // debugger
                                        return (
                                            <div key={uuid()}>
                                                <CustomlessionItem
                                                    doctype={lessonItem.node.type === "VIDEO" ? "video" : "pdf"}
                                                    // preview={lessonItem.node.isFree}
                                                    status={lesson_status}
                                                    lessonData={lessonItem.node}
                                                    courseId={courseData.id}
                                                    moduleId={module_id}
                                                    Purchased={courseData.viewerPurchased}
                                                    lessonId={lessonItem.node.id}
                                                    goToDetail={goToDetail}
                                                    watching={isWatching}
                                                    watched={isWatched}
                                                    // moveToDetail={props.moveToDetail}
                                                    // moveToDetail={true}
                                                    isActive={activeModules?.lesson?.id === lessonItem?.node?.id}
                                                />
                                            </div>
                                        )
                                    })
                                }
                                {/*<CustomlessionItem doctype={"video"} preview={preview} status={"watchnow"}/>*/}
                                {/*<CustomlessionItem doctype={"video"} preview={preview} status={"resumenow"}/>*/}
                                {/*<CustomlessionItem doctype={"document"} preview={preview} status={"readenow"}/>*/}
                            </AccordionItemPanel>
                        </AccordionItem>


                    );

                })

                }

            </Accordion>

        </MDBBox>
    );
}

export default withRouter(CourseAccordian)


const CustomlessionItem = (props) => {

    const { lessonData, courseId, moduleId, lessonId, goToDetail, watching, watched, Purchased, isActive } = props;

    const isUserLoggedIn = useSelector((state) => state.UserReducer.logged_in)

    const handleClick = () => {
        if (Purchased === true) {
            goToDetail(courseId, moduleId, lessonId);
        }
    }

    console.log(props.status)

    return (
        <MDBBox className={`d-flex justify-content-between align-items-start lesson-item-component py-2`} onClick={() => handleClick()}>
            <MDBBox className={"d-flex justify-content-between align-items-start left-section"} >
                {
                    props.status === "completed" || watched === true ?
                        <img src={CompletedIcon} alt={"video"}
                            className={"img-fluid"} /> :
                        <>
                            {
                                isActive === true ?
                                    <img src={StudyIcon} alt={"video"}
                                        className={"img-fluid"} /> :
                                    <img src={props.doctype === "video" ? VideoIcon : DocumentIcon} alt={"video"}
                                        className={"img-fluid"} />
                            }
                        </>

                }
                <span className={`lesson-name ${isActive ? "active" : ""}`}>{lessonData.name}</span>
            </MDBBox>
            {/* {
                props.preview === true ?
                    <MDBBox className={"d-flex justify-content-between align-items-center right-section"}>
                        <span className={"preview"}>Preview</span>
                        <img src={LockIcon} alt={"video"} className={"img-fluid"}/>
                    </MDBBox>
                     : */}
            <MDBBox className={"paid-right-section"}>
                {
                    props.status === "completed" &&
                    <div className={"lesson-completed"}
                        onClick={() => { goToDetail(courseId, moduleId, lessonId) }}
                    >Completed</div>
                }
                {
                    (Purchased && props.status === "watchnow" || props.status === "resumenow") &&
                    <div className={"lesson-watchnow"} onClick={() => {
                        goToDetail(courseId, moduleId, lessonId);
                    }
                    }>Watch</div>
                }
                {/* {
                    props.status === "resumenow" &&
                    <div className="resume" handleClick={() => {
                        goToDetail(courseId, moduleId, lessonId);
                    }} >Resume Now</div>
                } */}
                {
                    props.status === "readenow" &&
                    <div className={"lesson-readenow"}>Read Now</div>
                }

            </MDBBox>
            {/* } */}
        </MDBBox>
    );
}


// export const LessonHeading = (props) => {
//     return(
//         <MDBBox className={"lesson-heading-component"}>
//             <MDBBox className={"d-flex align-items-center justify-content-between large-device-heading"}>
//                 <MDBBox className={"d-flex align-items-center justify-content-center left-section"}>
//                     <img src={AccordianArrow} alt={"arrow"} className={"img-fluid accordian-arrow"}/>
//                     <p className={"module-name"}>Course Lesson 1</p>
//                 </MDBBox>
//                 <MDBBox>
//                     <p className={"duration"}>7 lectures  •  19mins</p>
//                 </MDBBox>
//             </MDBBox>
//             <MDBBox className={"d-flex align-items-center justify-content-between small-device-heading"}>
//                 <MDBBox className={"d-flex align-items-center justify-content-center left-section"}>
//                     <img src={AccordianArrow} alt={"arrow"} className={"img-fluid accordian-arrow"}/>
//                     <p className={"module-name"}>Course Lesson 1</p>
//                 </MDBBox>
//                 <MDBBox>
//                     <p className={"duration"}>7 lectures  •  19mins</p>
//                 </MDBBox>
//             </MDBBox>
//         </MDBBox>
//     );
// }
