import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'

const mutation = graphql`
    mutation ContactUsMutation(
        $email: String!
        $name: String!
        $message: String!
        $number: String!
    ) {
        contactUs(email:$email,mobileNumber:$number,name:$name,message:$message){
            message
            status
        }
    }
`

export default (userData, callback,errCallback) => {
    const variables = {
        email: userData.email,
        name:userData.name,
        number:userData.number,
        message:userData.message
    }

    // 5
    commitMutation(
        environment,
        {
            mutation,
            variables,
            // 6
            onCompleted: (response,err) => {

                if(response.contactUs !== null){
                    callback(response)
                } else{
                    errCallback(err[0].message);
                }

            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}
