import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'

const mutation = graphql`
  mutation LoginMutation(
    $emailOrMobile: String!
    $password: String!
  ) {
     login(emailOrMobile:$emailOrMobile,password:$password){
        token
        status
        message
        refreshToken
        user{
          id
          lastLogin
          email
          mobileNumber
          name
          dob
          gender
          referalCode
          pincode
            profile {
                profilePic
            }
          isActive
          isAdmin
          isInstructor   
          wishlist{
            edges{
              node{
                id
              }
            }
          }   
        }
     }
  }
`

export default (userData, callback,errCallback) => {
    const variables = {
        emailOrMobile: userData.emailOrMobile,
        password: userData.password
    }

    // 5
    commitMutation(
        environment,
        {
            mutation,
            variables,
            // 6
            onCompleted: (response,err) => {

                if(response.login !== null){
                    callback(response)
                } else{
                    errCallback(err[0].message);
                }

            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}
