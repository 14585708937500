import "./Dashboard.scss";
import {MDBBox, MDBTypography} from "mdbreact";
import ProfessorPanelIndex from "../ProfessorPanelIndex/ProfessorPanelIndex";
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';

import LauncherIcon from "../../../assets/images/launch.png";
import PlusIcon from "../../../assets/icons/plus-icon.svg";
import CustomChart from "../../../Components/CustomChart/CustomChart";
import cogoToast from "cogo-toast";
import {useEffect, useState} from "react";
import InstructorDashboardMutation from "../../../mutations/InstructorDashboardMutation";
import left from "../../../assets/icons/leftwhite.svg";
import rightarr from "../../../assets/icons/right-white.svg";
import dateFormat from "dateformat";
import {BasicButton} from "../../../Components/Buttons/BasicButton";
import emptystateimg from "../../../assets/icons/emptystate.svg"
import filledimage from "../../../assets/icons/filledthick.svg"
import bottomarrow from "../../../assets/images/yellodownarrow.png"
import uuid from "react-uuid";
import {withRouter} from "react-router-dom";
import {compose} from "redux";
import {connect} from "react-redux";


const Dashboard = (props) => {
    useEffect(()=>{
        if (props.user_details.isInstructor===false){
            props.history.push('/')
        }
    },[])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const new_user = false;
    const [dashdata,setdashdata]=useState({})
    const [getyear,setyear]=useState(2021)
    const [fulldate,setfulldate]=useState({})
    const [showMonthFilter,setShowMonthFilter]=useState(false)
    const [customMonthFilter,setCustomMonthFilter]=useState(false)
    const [markmonth,setmarkmonth]=useState([])
    const monthFilterOptions = [
        {
            value:"LAST_MONTH",
            name:"Last Month"
        },
        {
            value:"LAST_THREE_MONTHS",
            name:"Last Three Months"
        },
        {
            value:"LAST_SIX_MONTHS",
            name:"Last Six Months"
        },
        {
            value:"LAST_YEAR",
            name:"Last Year"
        }
    ];
    const [monthFilter,setMonthFilter]=useState(monthFilterOptions[0])
    const [count,setcount]=useState(0)
    const [showCourseFilter,setShowCourseFilter]=useState(false)
    const [courseList,setCourseList]=useState([]);
    const [selectedCourse,setSelectedCourse]=useState({});
    const [enrollmentData,setEnrollmentData]=useState([]);
    const [consultationData,setConsultationData]=useState([]);
    let startmonth
    let startyear
    let endmonth
    let endyear
    let monthorderbynumber;

    useEffect(()=>{
        getDashboardData(monthFilter.value,null,null,null,null,null);
    },[]);

    const getDashboardData = (month_filter,startMonth,startYear,endMonth,endYear,courseId) => {
      let  userData = {
            monthFilter:customMonthFilter?"CUSTOM":month_filter,
            startMonth:startMonth,
            startYear:startYear,
            endMonth:endMonth,
            endYear:endYear,
            courseId:courseId
      };
        InstructorDashboardMutation(userData, function (response) {
            if (response.instructorDashboard !== null) {
                setdashdata(response.instructorDashboard)
                setCourseList(response.instructorDashboard.courses);
                setEnrollmentData(response.instructorDashboard.enrollmentGraphData);
                setConsultationData(response.instructorDashboard.consultationGraphData);
            } else {
                cogoToast.error("something went wrong", {position: 'top-center'});
                setdashdata({})
                setCourseList([]);
                setEnrollmentData([]);
                setConsultationData([]);
            }
        }, function (err) {
            cogoToast.error(err, {position: 'top-center'});
            setdashdata({})
            setCourseList([]);
            setEnrollmentData([]);
            setConsultationData([]);
        });
    }

    function addDateFun(month){
        // debugger
        let temp_fulldate=fulldate
        let size=Object.entries(temp_fulldate).length
        let recentdate={month,getyear}
        let firstdate
        let lastdate
        temp_fulldate[size]=recentdate
        setfulldate(temp_fulldate)
        // console.log(fulldate,temp_fulldate)
        // debugger
        if (Object.entries(fulldate).length>2){
            firstdate=fulldate[size-1]
            lastdate=fulldate[size]
            tostore(firstdate,lastdate)
        }else if(Object.entries(fulldate).length>1){
            firstdate=fulldate[0]
            lastdate=fulldate[1]
            tostore(firstdate,lastdate)
        }

        function tostore(firstdate,lastdate) {
             // debugger
            if ((firstdate.getyear) - (lastdate.getyear)<0){
                startmonth=firstdate.month
                startyear=firstdate.getyear
                endmonth=lastdate.month
                endyear=lastdate.getyear
            }else if((firstdate.getyear) - (lastdate.getyear)>0){
                startmonth=lastdate.month
                startyear=lastdate.getyear
                endmonth=firstdate.month
                endyear=firstdate.getyear
            }else if(firstdate.month-lastdate.month<0){
                startmonth=firstdate.month
                startyear=firstdate.getyear
                endmonth=lastdate.month
                endyear=lastdate.getyear
            }else if(firstdate.month-lastdate.month>0){
                startmonth=lastdate.month
                startyear=lastdate.getyear
                endmonth=firstdate.month
                endyear=firstdate.getyear
            }else {
                startmonth=lastdate.month
                startyear=lastdate.getyear
                endmonth=firstdate.month
                endyear=firstdate.getyear
            }
            let temp_finalarr=[startmonth,startyear,endmonth,endyear]
            // console.log(temp_finalarr)
            setmarkmonth(temp_finalarr)
            if (startmonth<endmonth){
                monthorderbynumber=[endmonth,startmonth]
            }else if(startmonth>endmonth){
                monthorderbynumber=[startmonth,endmonth]
            }else {
                monthorderbynumber=[startmonth,endmonth]
            }
        }



    }

    function doublecustomdate(passmonth){
        addDateFun(passmonth)
        setcount(prevState => count+1)

    }
    useEffect(()=>{

        if (count!==0&&count%2===0){
            // getDashboardData()
            getDashboardData(monthFilter.value,markmonth[0],markmonth[1],markmonth[2],markmonth[3],selectedCourse.id);


            setShowMonthFilter(!showMonthFilter)
        }
    },[count])

    const handleMonthFilterChange = (item) => {
        setMonthFilter(item);
        setShowMonthFilter(false);
        setCustomMonthFilter(false);
        getDashboardData(item.value,markmonth[0],markmonth[1],markmonth[2],markmonth[3],selectedCourse.id);
    }

    const FilterEnrollByCourse = (course) => {
        setSelectedCourse(course);
        setShowCourseFilter(false);
        getDashboardData(monthFilter.value,markmonth[0],markmonth[1],markmonth[2],markmonth[3],course.id);

    }

    return(
        <ProfessorPanelIndex activePage={"dashboard"}>
            <MDBBox className={"dashboard-component"}>
                {
                    new_user ?
                        <MDBBox className={"new-user-card"}>
                            <img src={LauncherIcon} alt={"launcher icon"} className={"img-fluid"}/>
                            <MDBBox className={"content-section"}>
                                <MDBTypography tag={"p"} className={"title"}>Create a course and Start tracking!</MDBTypography>
                                <button type={"button"} className={"new-course-btn"}>
                                    <img src={PlusIcon} alt={"plus"} className={"img-fluid"}/>
                                    New Course
                                </button>
                            </MDBBox>
                        </MDBBox>:
                        <MDBBox className={"existing-user-section"}>
                            <MDBBox className={"top-section"}>
                                <MDBBox className={"left-section"}>
                                    <p className={"title"} >Dashboard</p>
                                    <MDBBox className={"current-month"}>November</MDBBox>
                                </MDBBox>
                                <MDBBox className={"right-section"}>
                                    <div className={'dashboardfilter'} >
                                        <BasicButton handleClick={()=>setShowMonthFilter(!showMonthFilter)}>
                                            {monthFilter.name}
                                            <img src={bottomarrow}/>
                                        </BasicButton>
                                    </div>
                                    {
                                        showMonthFilter &&
                                        <div className={'d-flex filterdropdown'}>
                                            {
                                                monthFilterOptions.map((item,index)=>{
                                                    return(
                                                        <>
                                                            <div
                                                                onClick={()=>handleMonthFilterChange(item)}
                                                            >
                                                                {item.name}
                                                            </div>
                                                            <hr className={'yelloline'}/>
                                                        </>
                                                    );
                                                })
                                            }
                                            <div onClick={()=>setCustomMonthFilter(!customMonthFilter)}>
                                                <img  src={!customMonthFilter?emptystateimg:filledimage} className={'pr-2'}/>
                                                Select particular range
                                            </div>
                                            {
                                                customMonthFilter &&
                                                <div className={'date-select'}>
                                                    <div className={'inside-select'}>
                                                        <div className={'row mx-0'}>
                                                            <div className={'col-12 d-flex'}>
                                                                <div className={'leftarr'} onClick={()=>setyear(prevState =>getyear-1)}> <img src={left}/> </div>
                                                                <div className={' year'}>{getyear}</div>
                                                                <div className={'rightarr'} onClick={()=>setyear(prevState => getyear+1)}> <img src={rightarr}/> </div>
                                                            </div>
                                                            <div className={markmonth[0]===1&&markmonth[1]===getyear?'col-4 startingdate'
                                                                :`${markmonth[2]===1&&markmonth[3]===getyear?'endingdate col-4'
                                                                    :`${markmonth[1]===getyear&&markmonth[3]===getyear&&markmonth[0]<1&&markmonth[2]>1?`middledate col-4`
                                                                        :`${markmonth[1]===getyear&&markmonth[3]===getyear?'col-4'
                                                                        :`${markmonth[1]===getyear&&markmonth[0]<1||markmonth[3]>getyear&&markmonth[1]<getyear||markmonth[3]===getyear&&markmonth[2]>1 ? "middledate col-4":"col-4"}`}`
                                                                }`
                                                                    }`}
                                                                 onClick={()=>doublecustomdate(1)}>Jan</div>

                                                            <div className={markmonth[0]===2&&markmonth[1]===getyear?'col-4 startingdate'
                                                                :`${markmonth[2]===2&&markmonth[3]===getyear?'endingdate col-4'
                                                                    :`${markmonth[1]===getyear&&markmonth[3]===getyear&&markmonth[0]<2&&markmonth[2]>2?`middledate col-4`
                                                                        :`${markmonth[1]===getyear&&markmonth[3]===getyear?'col-4'
                                                                         :`${markmonth[1]===getyear&&markmonth[0]<2||markmonth[3]>getyear&&markmonth[1]<getyear||markmonth[3]===getyear&&markmonth[2]>2 ? "middledate col-4":"col-4"}`}`
                                                                }`
                                                                }`}                                                             onClick={()=>doublecustomdate(2)}>Feb</div>

                                                            <div className={markmonth[0]===3&&markmonth[1]===getyear?'col-4 startingdate'
                                                                :`${markmonth[2]===3&&markmonth[3]===getyear?'endingdate col-4'
                                                                    :`${markmonth[1]===getyear&&markmonth[3]===getyear&&markmonth[0]<3&&markmonth[2]>3?`middledate col-4`
                                                                        :`${markmonth[1]===getyear&&markmonth[3]===getyear?'col-4'
                                                                        :`${markmonth[1]===getyear&&markmonth[0]<3||markmonth[3]>getyear&&markmonth[1]<getyear||markmonth[3]===getyear&&markmonth[2]>3? "middledate col-4":"col-4"}`}`
                                                                        }`
                                                                }`}                                                             onClick={()=>doublecustomdate(3)}>Mar</div>

                                                            <div className={markmonth[0]===4&&markmonth[1]===getyear?'col-4 startingdate'
                                                                :`${markmonth[2]===4&&markmonth[3]===getyear?'endingdate col-4'
                                                                    :`${markmonth[1]===getyear&&markmonth[3]===getyear&&markmonth[0]<5&&markmonth[2]>4?`middledate col-4`
                                                                        :`${markmonth[1]===getyear&&markmonth[3]===getyear?'col-4'
                                                                        :`${markmonth[1]===getyear&&markmonth[0]<4||markmonth[3]>getyear&&markmonth[1]<getyear||markmonth[3]===getyear&&markmonth[2]>4 ? "middledate col-4":"col-4"}`}`
                                                                }`
                                                                }`}                                                             onClick={()=>doublecustomdate(4)}>Apr</div>

                                                            <div className={markmonth[0]===5&&markmonth[1]===getyear?'col-4 startingdate'
                                                                :`${markmonth[2]===5&&markmonth[3]===getyear?'endingdate col-4'
                                                                    :`${markmonth[1]===getyear&&markmonth[3]===getyear&&markmonth[0]<5&&markmonth[2]>5?`middledate col-4`
                                                                        :`${markmonth[1]===getyear&&markmonth[3]===getyear?'col-4'
                                                                         :`${markmonth[1]===getyear&&markmonth[0]<5||markmonth[3]>getyear&&markmonth[1]<getyear||markmonth[3]===getyear&&markmonth[2]>5 ? "middledate col-4":"col-4"}`}`
                                                                }`
                                                                }`}                                                             onClick={()=>doublecustomdate(5) }>May</div>

                                                            <div className={markmonth[0]===6&&markmonth[1]===getyear?'col-4 startingdate'
                                                                :`${markmonth[2]===6&&markmonth[3]===getyear?'endingdate col-4'
                                                                    :`${markmonth[1]===getyear&&markmonth[3]===getyear&&markmonth[0]<6&&markmonth[2]>6?`middledate col-4`
                                                                        :`${markmonth[1]===getyear&&markmonth[3]===getyear?'col-4'
                                                                         :`${markmonth[1]===getyear&&markmonth[0]<6||markmonth[3]>getyear&&markmonth[1]<getyear||markmonth[3]===getyear&&markmonth[2]>6 ? "middledate col-4":"col-4"}`}`
                                                                }`
                                                                }`}                                                             onClick={()=>doublecustomdate(6) }>Jun</div>

                                                            <div className={markmonth[0]===7&&markmonth[1]===getyear?'col-4 startingdate'
                                                                :`${markmonth[2]===7&&markmonth[3]===getyear?'endingdate col-4'
                                                                    :`${markmonth[1]===getyear&&markmonth[3]===getyear&&markmonth[0]<7&&markmonth[2]>7?`middledate col-4`
                                                                        :`${markmonth[1]===getyear&&markmonth[3]===getyear?'col-4'
                                                                         :`${markmonth[1]===getyear&&markmonth[0]<7||markmonth[3]>getyear&&markmonth[1]<getyear||markmonth[3]===getyear&&markmonth[2]>7 ? "middledate col-4":"col-4"}`}`
                                                                }`
                                                                }`}                                                             onClick={()=>doublecustomdate(7) }>Jul</div>

                                                            <div className={markmonth[0]===8&&markmonth[1]===getyear?'col-4 startingdate'
                                                                :`${markmonth[2]===8&&markmonth[3]===getyear?'endingdate col-4'
                                                                    :`${markmonth[1]===getyear&&markmonth[3]===getyear&&markmonth[0]<8&&markmonth[2]>8?`middledate col-4`
                                                                        :`${markmonth[1]===getyear&&markmonth[3]===getyear?'col-4'
                                                                        :`${markmonth[1]===getyear&&markmonth[0]<8||markmonth[3]>getyear&&markmonth[1]<getyear||markmonth[3]===getyear&&markmonth[2]>8 ? "middledate col-4":"col-4"}`}`
                                                                }`
                                                                }`}                                                             onClick={()=>doublecustomdate(8) }>Aug</div>

                                                            <div className={markmonth[0]===9&&markmonth[1]===getyear?'col-4 startingdate'
                                                                :`${markmonth[2]===9&&markmonth[3]===getyear?'endingdate col-4'
                                                                    :`${markmonth[1]===getyear&&markmonth[3]===getyear&&markmonth[0]<9&&markmonth[2]>9?`middledate col-4`
                                                                        :`${markmonth[1]===getyear&&markmonth[3]===getyear?'col-4'
                                                                        :`${markmonth[1]===getyear&&markmonth[0]<9||markmonth[3]>getyear&&markmonth[1]<getyear||markmonth[3]===getyear&&markmonth[2]>9 ? "middledate col-4":"col-4"}`}`
                                                                }`
                                                                }`}                                                             onClick={()=>doublecustomdate(9) }>Sep</div>

                                                            <div className={markmonth[0]===10&&markmonth[1]===getyear?'col-4 startingdate'
                                                                :`${markmonth[2]===10&&markmonth[3]===getyear?'endingdate col-4'
                                                                    :`${markmonth[1]===getyear&&markmonth[3]===getyear&&markmonth[0]<10&&markmonth[2]>10?`middledate col-4`
                                                                        :`${markmonth[1]===getyear&&markmonth[3]===getyear?'col-4'
                                                                         :`${markmonth[1]===getyear&&markmonth[0]<10||markmonth[3]>getyear&&markmonth[1]<getyear||markmonth[3]===getyear&&markmonth[2]>10 ? "middledate col-4":"col-4"}`}`
                                                                }`
                                                                }`}                                                             onClick={()=>doublecustomdate(10)}>Oct</div>

                                                            <div className={markmonth[0]===11&&markmonth[1]===getyear?'col-4 startingdate'
                                                                :`${markmonth[2]===11&&markmonth[3]===getyear?'endingdate col-4'
                                                                    :`${markmonth[1]===getyear&&markmonth[3]===getyear&&markmonth[0]<11&&markmonth[2]>11?`middledate col-4`
                                                                        :`${markmonth[1]===getyear&&markmonth[3]===getyear?'col-4'
                                                                         :`${markmonth[1]===getyear&&markmonth[0]<11||markmonth[3]>getyear&&markmonth[1]<getyear||markmonth[3]===getyear&&markmonth[2]>11 ? "middledate col-4":"col-4"}`}`
                                                                }`
                                                                }`}                                                             onClick={()=>doublecustomdate(11)}>Nov</div>

                                                            <div className={markmonth[0]===12&&markmonth[1]===getyear?'col-4 startingdate'
                                                                :`${markmonth[2]===12&&markmonth[3]===getyear?'endingdate col-4'
                                                                    :`${markmonth[1]===getyear&&markmonth[3]===getyear&&markmonth[0]<12&&markmonth[2]>12?`middledate col-4`
                                                                        :`${markmonth[1]===getyear&&markmonth[3]===getyear?'col-4'
                                                                         :`${markmonth[1]===getyear&&markmonth[0]<12||markmonth[3]>getyear&&markmonth[1]<getyear||markmonth[3]===getyear&&markmonth[2]>12 ? "middledate col-4":"col-4"}`}`
                                                                }`
                                                                }`}                                                             onClick={()=>doublecustomdate(12)}>Dec</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }
                            </MDBBox>
                            </MDBBox>
                            <MDBBox className={"charts-section"}>
                                <MDBBox className={"chart-card enrollment"}>
                                    <MDBBox className={"chart-header"}>
                                        <p className={"chart-title"}>Enrollments</p>
                                        <MDBBox className={"chart-filter"}>

                                            <div className={'dashboardfilter'} >
                                                <BasicButton handleClick={()=>setShowCourseFilter(!showCourseFilter)}>

                                                    {
                                                        Object.keys(selectedCourse).length > 0 ? selectedCourse.name:"--Select Course--"
                                                    }
                                                    <img src={bottomarrow}/>
                                                </BasicButton>
                                            </div>
                                            {
                                                showCourseFilter &&
                                                <div className={'d-flex filterdropdown'}>
                                                    {courseList.map((item,index)=>{
                                                        return(
                                                            <div key={uuid()}>
                                                                <div onClick={()=>FilterEnrollByCourse(item)}>{item.name}</div>
                                                                <hr className={'yelloline'}/>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            }
                                        </MDBBox>
                                    </MDBBox>
                                    <CustomChart
                                        strokeColor={"#EC3D3D"}
                                        gradient={[
                                            {
                                                color:"#EC3D3D",offset:"10%",opacity:0.1
                                            },
                                            {
                                                color:"#EC3D3D",offset:"0%",opacity:0
                                            }
                                        ]
                                        }
                                        values={enrollmentData}
                                    />
                                    <p className={"chart-count enrollment"}>{dashdata.totalEnrollment}</p>
                                </MDBBox>
                                <MDBBox className={"chart-card consultation"}>
                                    <MDBBox className={"chart-header"}>
                                        <p className={"chart-title"}>Consultation</p>
                                    </MDBBox>
                                    <CustomChart
                                        strokeColor={"#46874C"}
                                        gradient={[
                                            {
                                                color:"#70AB6E",offset:"10%",opacity:0.1
                                            },
                                            {
                                                color:"#57C146",offset:"0%",opacity:0
                                            }
                                            ]

                                        }
                                        values={consultationData}
                                    />
                                    <p className={"chart-count consultation"}>{dashdata.totalConsultations}</p>
                                </MDBBox>
                            </MDBBox>
                            <MDBBox className={"course-overview-section"}>
                                <p className={"title"}>Courses Overview</p>
                                <MDBTable responsive borderless>
                                    <MDBTableHead>
                                        <tr>
                                            <th>Course</th>
                                            <th>Last Modified</th>
                                            <th className={"text-center"}>Total Enrollments</th>
                                            <th className={"text-center"}>Overall Rating</th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {courseList.map((item,index)=>{
                                            return(
                                                <tr key={uuid()}>
                                                    <td>{item.name}</td>
                                                    <td>{dateFormat(item.updatedAt, "mmmm dS, yyyy")}</td>
                                                    <td className={"text-center"}>{item.enrolledUsers}</td>
                                                    <td className={"text-center"}>{item.averageRating}</td>
                                                </tr>
                                            )
                                        })
                                        }
                                    </MDBTableBody>
                                </MDBTable>
                            </MDBBox>
                        </MDBBox>
                }
            </MDBBox>
        </ProfessorPanelIndex>
    );
}

// export default Dashboard;

const mapStateToProps = state => ({
    user_details: state.UserReducer.user_details,
});

export default compose(
    withRouter,connect(mapStateToProps, null)
)(Dashboard);
