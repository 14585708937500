import "./ModulesInfo.scss";
import {MDBBox} from "mdbreact";
import edit from "../../../../assets/icons/edit_white_btn.svg";
import PlusIcon from "../../../../assets/icons/plus-icon.svg";
import {
    AccordionWithHeader,
    AccordionNode,
    AccordionHeader,
    AccordionPanel
} from 'react-accordion-with-header';
import {MDBModal, MDBModalBody} from "mdbreact";

import Arrow from "../../../../assets/icons/white-down-arrow.svg";
import DeleteIcon from "../../../../assets/icons/delete-icon.svg";
import PlayCircleIcon from "../../../../assets/icons/play-circle-filled.svg";
import DocumentIcon from "../../../../assets/icons/document-bullet-list.svg";
import GreenTick from "../../../../assets/icons/green-tick.svg";
import DragIcon from "../../../../assets/icons/drag-icon.svg";
import RedDeleteIcon from "../../../../assets/icons/red-delete.svg";
import ModalCloseIcon from "../../../../assets/images/modal-close.png";
import InfoIcon from "../../../../assets/icons/info-icon.svg";
import UploadIcon from "../../../../assets/icons/upload-icon.svg";
import EmptyState from "../../../../assets/icons/emptystate.svg";
import FilledState from "../../../../assets/icons/filledthick.svg"
import RedStar from "../../../../assets/icons/_.svg"
import upload from "../../../../assets/icons/upload.svg"
import close_btn from "../../../../assets/icons/close-icon.svg"
import yellow_plus from "../../../../assets/icons/yellow_plus.svg"
import gray_close_icon from "../../../../assets/icons/gray_close_icon.svg"


import React, {useEffect, useState} from "react";
import {BasicButton} from "../../../../Components/Buttons/BasicButton";
import ReactDragListView from "react-drag-listview";
import cogoToast from "cogo-toast";
import DeleteModuleMutation from "../../../../mutations/DeleteModuleMutation";
import UpdateModuleMutation from "../../../../mutations/UpdateModuleMutation";
import DeleteLessonsMutation from "../../../../mutations/DeleteLessonsMutation";
import UpdateLessonMutation from "../../../../mutations/UpdateLessonMutation";
import CreateModuleMutation from "../../../../mutations/CreateModuleMutation";
import {useParams} from "react-router-dom";
import axios from "axios";
import {api} from "../../../../serviceApi";
import UpdateAllModuleMutation from "../../../../mutations/UpdateAllModuleMutation";
import ModuleOrderUpdateMutation from "../../../../mutations/ModuleOrderUpdateMutation";
import LessonOrderUpdateMutation from "../../../../mutations/LessonOrderUpdateMutation";
import Loader from "../../../../Components/Loader/Loader";
import uuid from "react-uuid";
import DeleteAttachmentsMutation from "../../../../mutations/DeleteAttachmentsMutation";
import {TrunkString} from "../../../../util/TrunkString";



const ModulesInfo = (props) => {
    const [activeIndex, setActiveIndex] = useState([0]);
    const [editModule, setEditModule] = useState(false);
    const [modelOpen, setmodelOpen] = useState(false);
    const [moduleList,setModuleList] = useState([]);
    const [selectAllModules,setSelectAllModules] = useState(false);
    const [loading,setLoading] = useState(false);
    const params = useParams();
    // const [count,setcount]=useState(0)


    // useEffect(()=>{
    //
    // },[props.count])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(function (){
        // debugger
        let tmp_modules = props.modules;
        let modules_list = [];
        tmp_modules.map((item, moduleIndex) => {
            modules_list[moduleIndex] = {
                ...item.node,
                edit:false,
                select:false,
            };
        });

        modules_list = modules_list.sort(function(a, b) {
            return parseInt(a.order) - parseInt(b.order);
        });
        setModuleList(modules_list);
    },[props.modules]);
    const handleAccordianChange = (index) => {
        setActiveIndex([index]);
    }
    const toggleModal = () => {
        setmodelOpen(!modelOpen);
    }
    const openDialog = () => {
        toggleModal();
    }
    const selectAllModule = (e) => {
        let checked = e.target.checked;
        let tmp_module_list = moduleList.slice();
        tmp_module_list.map((moduleItem,index)=>{
            tmp_module_list[index]['select'] = checked;
        });
        setModuleList(tmp_module_list);
        setSelectAllModules(checked);
    }
    const handleModuleNameChange = (e,moduleItem) => {
        let selected_module_index = moduleList.findIndex(module => module.id === moduleItem.id);
        let tmp_module_list = moduleList.slice();
        if(selected_module_index > -1){
            tmp_module_list[selected_module_index]['name'] = e.target.value;
            setModuleList(tmp_module_list);
        }
    }
    const selectModule = (e,moduleItem) => {
        let selected_module_index = moduleList.findIndex(module => module.id === moduleItem.id);
        let tmp_module_list = moduleList.slice();
        if(selected_module_index > -1){
            tmp_module_list[selected_module_index]['select'] = e.target.checked;
            setModuleList(tmp_module_list);
        }
    }
    const editSingleModule = (moduleItem) => {
        let selected_module_index = moduleList.findIndex(module => module.id === moduleItem.id);
        let tmp_module_list = moduleList.slice();
        if(selected_module_index > -1){
            tmp_module_list[selected_module_index]['edit'] = true;
            setModuleList(tmp_module_list);
        }
    }
    const deleteSingleModule = (moduleItem) => {
        let selected_module_index = moduleList.findIndex(module => module.id === moduleItem.id);
        let tmp_module_list = moduleList.slice();
        if(selected_module_index > -1){
            tmp_module_list[selected_module_index]['select'] = true;
            setModuleList(tmp_module_list);
            setTimeout(function() {
                deleteModules();
            }, 500);
        }
    }
    const moduleOrderUpdate = (fromIndex, toIndex) => {
        const data = [...moduleList];
        const item = data.splice(fromIndex, 1)[0];
        let variables = {
            moduleId:item.id,
            order:toIndex
        }
        ModuleOrderUpdateMutation(variables, function (response) {
            if (response.moduleOrderUpdate !== null) {
                data.splice(toIndex, 0, item);
                setModuleList(data);
            } else {
                cogoToast.error("something went wrong", {position: 'top-center'});
            }
        }, function (err) {
            cogoToast.error(err, {position: 'top-center'});
        });

    }
    const updateModule = (moduleItem) => {
        let variables = {
            description: moduleItem.description,
            id: moduleItem.id,
            isActive: moduleItem.isActive,
            name: moduleItem.name,
            order: moduleItem.order
        }
        setLoading(true);
        UpdateModuleMutation(variables, function (response) {
            setLoading(false);
            if (response.updateModuleForCourse !== null) {
                cogoToast.success(response.updateModuleForCourse.message, {position: 'top-center'});
                updateModuleList(response.updateModuleForCourse.modules);
            } else {
                cogoToast.error("something went wrong", {position: 'top-center'});
            }
        }, function (err) {
            setLoading(false);
            cogoToast.error(err, {position: 'top-center'});
        });

    }
    const updateAllModule = () => {
        let tmp_ids=[];
        moduleList.map((item,index)=>{
            // if(item.select === true){
                tmp_ids.push({
                    moduleId: item.id,
                    name: item.name,
                    isActive: item.isActive,
                    order: item.order
                });
            // }
        })
        if(tmp_ids.length > 0){
            let variables = {
                data:tmp_ids,
                courseId: params.id
            }
            setLoading(true);
            UpdateAllModuleMutation(variables, function (response) {
                setLoading(false);
                if (response.updateModule !== null) {
                    cogoToast.success(response.updateModule.message, {position: 'top-center'});
                    let module_list = [];
                    response.updateModule.modules.map((moduleItem,moduleIndex)=>{
                        module_list[moduleIndex] = {
                            ...moduleItem,
                            edit:false,
                            select:false,
                        }
                    });
                    setModuleList(module_list);
                    setEditModule(false);

                } else {
                    cogoToast.error("something went wrong", {position: 'top-center'});
                }
            }, function (err) {
                setLoading(false);
                cogoToast.error(err, {position: 'top-center'});
            });
        } else {
            cogoToast.info("please select modules to save", {position: 'top-center'});
        }
    }
    const updateModuleList = (list) => {
        let module_list = [];
        list.map((moduleItem,moduleIndex)=>{
            module_list[moduleIndex] = {
                ...moduleItem,
                edit:false,
                select:false,
            }
        });
        setModuleList(module_list);
    }
    const toggleEditModule = (moduleItem) => {
        let tmp_module_list = moduleList.slice();
        let module_index = tmp_module_list.findIndex(module => module.id === moduleItem.id);
        if(module_index > -1){
            tmp_module_list[module_index]['edit'] = false;
            tmp_module_list[module_index]['select'] = false;
        }
        setModuleList(tmp_module_list);
        // updateModule(moduleItem);
    }
    const deleteModules = () => {
        let tmp_ids=[];
        let tmp_module_list = moduleList.slice();
        moduleList.map((item,index)=>{
            if(item.select === true){
                tmp_ids.push(item.id);
            }
        })
        if(tmp_ids.length > 0){
            let variables = {
                ids:tmp_ids
            }
            setLoading(true);
            DeleteModuleMutation(variables, function (response) {
                if (response.deleteModuleForCourse !== null) {
                    setLoading(false);
                    cogoToast.success(response.deleteModuleForCourse.message, {position: 'top-center'});
                    tmp_ids.map((id,index)=>{
                        let module_index = tmp_module_list.findIndex(module => module.id === id);
                        if(module_index > -1){
                            tmp_module_list.splice(module_index, 1);
                        }
                    })
                    setModuleList(tmp_module_list);
                } else {
                    cogoToast.error("something went wrong", {position: 'top-center'});
                }
            }, function (err) {
                setLoading(false);
                cogoToast.error(err, {position: 'top-center'});
            });
        } else {
            cogoToast.info("select module to perform delete", {position: 'top-center'});
        }
    }


    const dragProps = {
        onDragEnd(fromIndex, toIndex) {
            moduleOrderUpdate(fromIndex, toIndex);
        },
        nodeSelector: '.edit-module-card',
        handleSelector: '#drag-icon'
    };


    return(
        <MDBBox className={"modules-info-component"}>
            {
                loading === true &&
                <div>
                    <Loader full={true} />
                </div>
            }
            <MDBBox className={"header-section d-flex justify-content-between align-items-start"}>
                <MDBBox className={"info"}>
                    {props.course_info.totalModules} modules  •  {props.course_info.totalLectures} lectures
                </MDBBox>
                {
                    editModule === false ?
                        <button type={"button"} className={"edit-module-btn"} onClick={()=>setEditModule(!editModule)}>
                            Edit the Modules
                            <img src={edit} className={'icon'}/>
                        </button>:
                        <MDBBox className={"save-changes-section"}>
                            <MDBBox className={"save-changes-btn"} onClick={()=>updateAllModule()}>
                                Save Changes
                                <img src={GreenTick} alt={"GreenTick"}/>
                            </MDBBox>
                            <MDBBox className={"action-btn-section"}>
                                <MDBBox className={"select-all-btn"}>
                                    <div className="form-group">
                                        <input type="checkbox" id={"select-all-module"}
                                               checked={selectAllModules}
                                               onChange={(e)=>selectAllModule(e)}/>
                                        <label htmlFor="select-all-module">Select all</label>
                                    </div>
                                </MDBBox>
                                <button type={"button"} className={"btn delete-selected-btn"}
                                        onClick={deleteModules}
                                >
                                    <img src={DeleteIcon} alt={"DeleteIcon"}/>
                                    Delete selected
                                </button>
                            </MDBBox>
                        </MDBBox>
                }
            </MDBBox>
            {
                editModule === false ?
                    <>
                        <MDBBox className={"new-module-card d-flex justify-content-between align-items-center"}>
                            <button type={"button"} className={"btn new-module-btn"} onClick={()=>openDialog()}>
                                <img src={PlusIcon} alt={"plus"} className={"plus-icon"}/>
                                New Module
                            </button>
                        </MDBBox>
                        {
                            moduleList.length > 0 ?
                                <AccordionWithHeader active={activeIndex} >
                                    {
                                        moduleList.map((moduleItem,i)=>{
                                            return (
                                                <AccordionNode key={i} className={`accordian-node ${activeIndex[0] === i?"active":""}`}>
                                                    <AccordionHeader
                                                        className={"accordian-header"}
                                                        template={
                                                            <>
                                                            {
                                                                moduleItem.edit === true ?
                                                                    <MDBBox className={"edit-module-header-component"} >
                                                                        <MDBBox className={" d-flex justify-content-between align-items-center header-section"}>
                                                                            <input type={"text"} className={"module-input"}
                                                                                   value={moduleItem.name}
                                                                                   onChange={(e)=>handleModuleNameChange(e,moduleItem)}
                                                                            />
                                                                            <MDBBox className={"save-changes-btn"} onClick={()=>updateModule(moduleItem)}>
                                                                                Save Changes
                                                                                <img src={GreenTick} alt={"GreenTick"}/>
                                                                            </MDBBox>
                                                                        </MDBBox>
                                                                        <hr className={"module-divider"}/>
                                                                    </MDBBox>
                                                                    :
                                                                    <MDBBox className={"module-header-component"} >
                                                                        <MDBBox className={" d-flex justify-content-between align-items-center"}>
                                                                            <span className={"title"}>{moduleItem.name}</span>
                                                                            <MDBBox className={"d-flex justify-content-center align-items-center icon-section"}>
                                                                                {
                                                                                    activeIndex[0] === i &&
                                                                                    <>
                                                                                        <MDBBox
                                                                                            className={"edit-icon-section d-flex justify-content-center align-items-center"}
                                                                                            onClick={()=>editSingleModule(moduleItem)}
                                                                                        >
                                                                                            <img src={edit} alt={"arrow"}/>
                                                                                        </MDBBox>
                                                                                        <MDBBox className={"delete-icon-section d-flex justify-content-center align-items-center"}
                                                                                                onClick={()=>deleteSingleModule(moduleItem)}
                                                                                        >
                                                                                            <img src={DeleteIcon} alt={"arrow"}/>
                                                                                        </MDBBox>
                                                                                    </>
                                                                                }

                                                                                <MDBBox className={"arrow-icon-section d-flex justify-content-center align-items-center"}
                                                                                        onClick={()=>handleAccordianChange(i)}>
                                                                                    <img src={Arrow} alt={"arrow"}/>
                                                                                </MDBBox>
                                                                            </MDBBox>
                                                                        </MDBBox>
                                                                        {
                                                                            activeIndex[0] === i  &&
                                                                            <hr className={"module-divider"}/>
                                                                        }
                                                                    </MDBBox>
                                                            }
                                                            </>
                                                        }
                                                    >

                                                    </AccordionHeader>
                                                    <AccordionPanel
                                                        className={"accordian-panel"}
                                                        template={
                                                            <>
                                                                <ModuleBody
                                                                    moduleData={moduleItem}
                                                                    lessons={moduleItem.lessons}
                                                                    moduleList={moduleList}
                                                                    setcount={props.setcount}
                                                                />
                                                            </>
                                                        }
                                                    >
                                                    </AccordionPanel>
                                                </AccordionNode>
                                            )
                                        })
                                    }
                                </AccordionWithHeader>
                                :
                                <MDBBox className={"text-center"}>
                                    No Modules Found
                                </MDBBox>
                        }

                    </>:
                    <>
                        <ReactDragListView {...dragProps}>
                            <MDBBox className={"edit-module-list"}>
                                {
                                    moduleList.map((moduleItem,index)=>{
                                        return(
                                            <MDBBox className={"edit-module-card"} key={index}>
                                                <img src={DragIcon} alt={"drag icon"} id={"drag-icon"}/>
                                                <div className="form-group mb-0">
                                                    <input type="checkbox" id={`checkbox${index}`}
                                                           checked={moduleItem.select}
                                                           onChange={(e)=>selectModule(e,moduleItem)}
                                                    />
                                                    <label htmlFor={`checkbox${index}`}/>
                                                </div>
                                                <input type={"text"}
                                                       value={moduleItem.name}
                                                       className={"module-input"}
                                                       onChange={(e)=>handleModuleNameChange(e,moduleItem)}
                                                />
                                                <button type={"button"} className={"module-delete-btn btn"}
                                                        onClick={()=>deleteSingleModule(moduleItem)}

                                                >
                                                    <img src={DeleteIcon} alt={"DeleteIcon"}/>
                                                </button>
                                            </MDBBox>
                                        );
                                    })
                                }
                            </MDBBox>
                        </ReactDragListView>
                    </>
            }
            <MDBModal isOpen={modelOpen} toggle={toggleModal} centered size="lg">
                <MDBModalBody>
                    <MDBBox className={"text-right"}>
                        <img src={ModalCloseIcon} alt={"Close"} className={"modal-close-btn"} onClick={toggleModal}/>
                    </MDBBox>
                    <CreateModuleForm toggleModal={toggleModal} updateModuleList={updateModuleList}/>
                </MDBModalBody>
            </MDBModal>

        </MDBBox>
    );
}

export default ModulesInfo;

const ModuleHeader = (props) => {
    const {active,handleClick,index,moduleData,editSingleModule,deleteSingleModule} = props;
    return(
        <>
            <MDBBox className={"module-header-component"} >
                <MDBBox className={" d-flex justify-content-between align-items-center"}>
                    <span className={"title"}>{moduleData.name}</span>
                    <MDBBox className={"d-flex justify-content-center align-items-center icon-section"}>
                        {
                            active === true &&
                            <>
                                <MDBBox
                                    className={"edit-icon-section d-flex justify-content-center align-items-center"}
                                    onClick={()=>editSingleModule(moduleData)}
                                >
                                    <img src={edit} alt={"arrow"}/>
                                </MDBBox>
                                <MDBBox className={"delete-icon-section d-flex justify-content-center align-items-center"}
                                        onClick={()=>deleteSingleModule(moduleData)}
                                >
                                    <img src={DeleteIcon} alt={"arrow"}/>
                                </MDBBox>
                            </>
                        }

                        <MDBBox className={"arrow-icon-section d-flex justify-content-center align-items-center"}
                                onClick={()=>handleClick(index)}>
                            <img src={Arrow} alt={"arrow"}/>
                        </MDBBox>
                    </MDBBox>
                </MDBBox>
                {
                    active === true &&
                    <hr className={"module-divider"}/>
                }
            </MDBBox>
        </>

    );
}

const ModuleBody = (props) => {
    const [lessonsList,setLessonsList] = useState([]);
    const [selectAlllessons,setSelectAlllessons] = useState(false);
    const [lessonModelOpen, setLessonModelOpen] = useState(false);
    const [documentFile,setDocumentFile] = useState([]);
    const [videoFile,setvideoFile] = useState(null);
    const [loading,setLoading] = useState(false);
    const [isEditVideoPdfUrls,setEditVideoPdfUrls]=useState(false)
    const [isEditIndex,setisEditIndex]=useState(-1)


    const [VideoName,setVideoName]=useState('')
    const [pdfName,setPdfName]=useState('')
    const [lessonName,setLessonName] = useState("");
    const [getLinkArrays,setlinkArrays]=useState([])
    const [getISPreviewVideo,setISPreviewVideo]=useState(false)
    const [deletePdfsList,setDeletePdfsList]=useState([])
    const [createPdfsList,setCreatePdfsList]=useState([])


    function editStateAndIndex(index){
        setEditVideoPdfUrls(true)
        setisEditIndex(index)
        // console.log('index',index)
    }

    useEffect(function (){
        fetchlessonDta()
    },[props.lessons,props.moduleList]);

    function fetchlessonDta(){
        // debugger
        let tmp_lessons = props.lessons.edges;
        let lesson_list = [];
        tmp_lessons.map((lessonItem,lessonIndex)=>{
            lesson_list[lessonIndex] = {
                ...lessonItem.node,
                edit:false,
                select:false,
            }
        });
        lesson_list = lesson_list.sort(function(a, b) {
            return parseInt(a.order) - parseInt(b.order);
        });
        setLessonsList(lesson_list);
    }

    const selectAllLesson = (e) => {
        let checked = e.target.checked;
        let tmp_lesson_list = lessonsList.slice();
        tmp_lesson_list.map((lessonItem,index)=>{
            tmp_lesson_list[index]['select'] = checked;
        });
        setLessonsList(tmp_lesson_list);
        setSelectAlllessons(checked);
    }

    const deleteSingleLesson = (lessonItem) => {
        let selected_lesson_index;
        let tmp_lesson_list = lessonsList.slice();
        selected_lesson_index = lessonsList.findIndex(lesson => lesson.id === lessonItem.id);
        if(selected_lesson_index > -1){
            tmp_lesson_list[selected_lesson_index]['select'] = true;
            setLessonsList(tmp_lesson_list);
            setTimeout(function() {
                deleteLessons();
            }, 500);
        }
    }

    const deleteLessons = () => {
        let tmp_ids=[];
        let tmp_lesson_list = lessonsList.slice();
        lessonsList.map((item,index)=>{
                if(item.select === true){
                    tmp_ids.push(item.id);
                }
        })
        if(tmp_ids.length > 0){
            let variables = {
                ids:tmp_ids
            }
            setLoading(true);
            DeleteLessonsMutation(variables, function (response) {
                setLoading(false);
                if (response.deleteLessons !== null) {
                    cogoToast.success(response.deleteLessons.message, {position: 'top-center'});

                    tmp_ids.map((id,index)=>{
                        let lesson_index = lessonsList.findIndex(lesson => lesson.id === id);
                        if(lesson_index > -1){
                            tmp_lesson_list.splice(lesson_index, 1);
                        }
                    })
                    setLessonsList(tmp_lesson_list);
                } else {
                    cogoToast.error("something went wrong", {position: 'top-center'});
                }
            }, function (err) {
                setLoading(false);
                cogoToast.error(err, {position: 'top-center'});
            });
        } else {
            cogoToast.info("select lessons to perform delete", {position: 'top-center'});
        }

    }

    const selectLesson = (e,lessonItem) => {
        let selected_lesson_index;
        let tmp_lesson_list = lessonsList.slice();
        selected_lesson_index = lessonsList.findIndex(lesson => lesson.id === lessonItem.id);
        if(selected_lesson_index > -1){
            tmp_lesson_list[selected_lesson_index]['select'] = e.target.checked;
            setLessonsList(tmp_lesson_list);
        }
    }

    const handleLessonNameChange = (e,lessonItem) => {
        let selected_lesson_index;
        let tmp_lesson_list = lessonsList.slice();
        selected_lesson_index = lessonsList.findIndex(lesson => lesson.id === lessonItem.id);
        if(selected_lesson_index > -1){
            tmp_lesson_list[selected_lesson_index]['name'] = e.target.value;
            setLessonsList(tmp_lesson_list);
        }
    }

    const toggleLessonModal = () => {
        setLessonModelOpen(!lessonModelOpen);
    }

    const updateLessonList = (list) => {
        let lesson_list = [];
        list.map((lessonItem,lessonIndex)=>{
            lesson_list[lessonIndex] = {
                ...lessonItem,
                edit:false,
                select:false,
            }
        });
        setLessonsList(lesson_list);
        setTimeout(function() {
            toggleLessonModal();
        }, 500);

    }

    const updateLesson = (lessonItem) => {
        let tmp_ids=[];
        // lessonsList.map((item,index)=>{
        //     if(item.select === true){
        //         tmp_ids.push({
        //             lessonId: item.id,
        //             name: item.name,
        //             isActive: item.isActive,
        //             order: item.order
        //         });
        //     }
        // })
        tmp_ids.push({
            lessonId: lessonItem.id,
            name: lessonItem.name,
            isActive: lessonItem.isActive,
            order: lessonItem.order
        });
        if(tmp_ids.length > 0){
            let variables = {
                data:tmp_ids
            }
            setLoading(true);
            UpdateLessonMutation(variables, function (response) {
                setLoading(false);
                if (response.updateLesson !== null) {
                    cogoToast.success(response.updateLesson.message, {position: 'top-center'});
                    // props.toggleEditModule(props.moduleData);
                    let lesson_list = [];
                    response.updateLesson.lessons.map((lessonItem,lessonIndex)=>{
                        lesson_list[lessonIndex] = {
                            ...lessonItem,
                            edit:false,
                            select:false,
                        }
                    });
                    setLessonsList(lesson_list);
                } else {
                    cogoToast.error("something went wrong", {position: 'top-center'});
                }
            }, function (err) {
                setLoading(false);
                cogoToast.error(err, {position: 'top-center'});
            });
        } else {
            cogoToast.info("please select lessons to save", {position: 'top-center'});
        }



    }

    const {moduleData} = props;

    const handleFileChange = (e,feild,lessonItem) => {
        // debugger
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.addEventListener("load", () => {
            if(feild === "VIDEO"){
                setvideoFile(file);
            }
            if(feild === "PDF"){
                setDocumentFile(file);
            }
            updateVideoOrPdf(feild,lessonItem,file);
        });
        reader.readAsDataURL(file);
    }

    const updateVideoOrPdf = (lessonItem) => {
        // debugger
        let tempcreatePdfsId=[]
            documentFile.map((item,index)=>{
                tempcreatePdfsId.push(item.id)
            })
            let variables = {
                lessonId: lessonItem.id,
                attachmentKeys: tempcreatePdfsId,
                isActive: true,
                isFree: false,
                urls:JSON.stringify((getLinkArrays))
            }
            setLoading(true);
            var data = new FormData();
        // data.append("query", " mutation CreateLessonMutation(\n    $isActive: Boolean!\n    $isFree: Boolean\n    $attachmentKeys:[String]\n $urls: JSONString\n ) {\n     addLesson(\n        isActive: $isActive\n        isFree: $isFree\n        moduleId: $moduleId\n        name: $name\n        order: $order    \n  attachmentKeys:$attachmentKeys \n urls:$urls  ){   \n        lesson{\n            id\n            name\n            video\n            type\n       pdf\n     currentTime\n            isWatched\n            isWatching\n            transcodedVideo\n            isTranscoded\n            duration\n            transcript\n            isActive\n            order\n        }     \n        message\n        status\n     }\n  }");

        data.append("query", `mutation UpdateLessonAndPdfMutation(\n  $isFree: Boolean!\n  $lessonId: String!\n $attachmentKeys:[String]\n  $urls: JSONString\n    ) {\n     updateLessonAndPdf(\n     isFree:$isFree\n    lessonId: $lessonId\n attachmentKeys:$attachmentKeys\n urls:$urls\n   ){        \n        status\n  message\n    }\n  }`);
            data.append("variables", JSON.stringify(variables));
            data.append("video", videoFile);
            documentFile.map((item,index)=>{
                data.append(`${item.id}`,item.filedoc)
            })


            // if(type === "PDF"){
            //     data.append("file", file);
            // }
            // if(type === "VIDEO"){
            //     data.append("video", file);
            // }
            let token = localStorage.getItem('user_token');

            if (token !== null && token !== undefined && token !== "") {

                var config = {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'JWT ' + token
                    },
                };

            } else {

                var config = {
                    headers: {
                        'Accept': 'application/json'
                    },
                };
            }
            axios.post(api, data, config)
                .then(function (response) {
                    setLoading(false);
                    if (response.data.data.updateLessonAndPdf) {
                        // debugger
                        cogoToast.success("File uploaded successfully", {position: 'top-center'});
                        // setLessonsList(response.lesson);
                        props.setcount(props.count+1)
                        fetchlessonDta()
                    }
                })
                .catch(function (err) {
                    setLoading(false);
                    cogoToast.error(err, {position: 'top-center'});
                });

    }

    const lessonOrderUpdate = (fromIndex, toIndex) => {
        const data = [...lessonsList];
        const item = data.splice(fromIndex, 1)[0];
        let variables = {
            lessonId:item.id,
            order:toIndex
        }
        LessonOrderUpdateMutation(variables, function (response) {
            if (response.lessonOrderUpdate !== null) {
                data.splice(toIndex, 0, item);
                setLessonsList(data);
            } else {
                cogoToast.error("something went wrong", {position: 'top-center'});
            }
        }, function (err) {
            cogoToast.error(err, {position: 'top-center'});
        });
    }

    const dragProps = {
        onDragEnd(fromIndex, toIndex) {
            lessonOrderUpdate(fromIndex, toIndex);
        },
        nodeSelector: '.edit-lesson-card',
        handleSelector: '#drag-lesson-icon'
    };

    function deletePdfsbyList(){
        debugger
        if (deletePdfsList.length === 0){
           return
        }else {
            let variables = {
                attachmentIds:deletePdfsList
            }
            DeleteAttachmentsMutation(variables, function (response) {
                if (response.deleteAttachments !== null)
                {
                    cogoToast.success(response.deleteAttachments.message, {position: 'top-center'});
                    // setLessonsList(response.lesson);
                    fetchlessonDta()
                }else {
                    cogoToast.error("something went wrong", {position: 'top-center'});
                }
            },function (err) {

                cogoToast.error(err, {position: 'top-center'});
            });
        }
    }

    function deleteAndUpdate(lessonItem){
        deletePdfsbyList()
        updateVideoOrPdf(lessonItem)
    }


console.log('documentFile',documentFile)
    return(
        <div>
            {
                loading === true &&
                <div>
                    <Loader full={true} />
                </div>
            }
            {
                moduleData.edit === true?
                    <MDBBox className={"edit-module-body-component"}>
                            <MDBBox className={"action-btn-section"}>
                                <MDBBox className={"select-all-btn"}>
                                    <div className="form-group">
                                        <input type="checkbox" id={"select-all-lesson"}
                                               onChange={(e)=>selectAllLesson(e)}
                                               checked={selectAlllessons}
                                        />
                                        <label htmlFor="select-all-lesson">Select all</label>
                                    </div>
                                </MDBBox>
                                <button type={"button"} className={"btn delete-selected-btn"} onClick={()=>deleteLessons()}>
                                    <img src={DeleteIcon} alt={"DeleteIcon"}/>
                                    Delete selected
                                </button>
                            </MDBBox>
                            <ReactDragListView {...dragProps}>
                                <MDBBox className={"edit-lesson-list"}>
                                {
                                    lessonsList.map((lessonItem,index)=>{
                                        // debugger
                                        // console.log(`lessonItem ${index}`,lessonsList[index])
                                        return(
                                            <MDBBox className={"edit-lesson-card"} key={index}>
                                                <img src={DragIcon} alt={"drag icon"} id={"drag-lesson-icon"}/>
                                                <div className="form-group mb-0">
                                                    <input type="checkbox" id={`checkbox${index}`}
                                                           checked={lessonItem.select}
                                                           onChange={(e)=>selectLesson(e,lessonItem)}
                                                    />
                                                    <label htmlFor={`checkbox${index}`}/>
                                                </div>
                                                <input type={"text"} className={"lesson-input"}
                                                       value={lessonItem.name}
                                                       onChange={(e)=>handleLessonNameChange(e,lessonItem)}
                                                />

                                                {/*<div className="document-upload">*/}
                                                    {/*<label htmlFor={`document-input${index}`}>*/}
                                                    {/*    <MDBBox className={"document-card"}>*/}
                                                    {/*        <img src={DocumentIcon} alt={"DocumentIcon"}/>*/}
                                                    {/*        <MDBBox className={"edit-bar text-center"}>*/}
                                                    {/*            <img src={edit} alt={"edit"}/>*/}
                                                    {/*        </MDBBox>*/}
                                                    {/*    </MDBBox>*/}
                                                    {/*</label>*/}
                                                    {/*<input id={`document-input${index}`} type="file"*/}
                                                    {/*       accept="application/pdf"*/}
                                                    {/*       onChange={(e)=>handleFileChange(e,"PDF",lessonItem)}*/}
                                                    {/*/>*/}
                                                {/*</div>*/}
                                                {/*<div className="video-upload">*/}
                                                {/*    <label htmlFor={`video-input${index}`}>*/}
                                                {/*        <MDBBox className={"video-card"}>*/}
                                                {/*            <img src={PlayCircleIcon} alt={"DocumentIcon"}/>*/}
                                                {/*            <MDBBox className={"edit-bar text-center"}>*/}
                                                {/*                <img src={edit} alt={"edit"}/>*/}
                                                {/*            </MDBBox>*/}
                                                {/*        </MDBBox>*/}
                                                {/*    </label>*/}
                                                {/*    <input id={`video-input${index}`} type="file"*/}
                                                {/*           accept="video/mp4,video/x-m4v,video/*"*/}
                                                {/*           onChange={(e)=>handleFileChange(e,"VIDEO",lessonItem)}*/}
                                                {/*    />*/}
                                                {/*</div>*/}
                                                <button type={"button"} className={"lesson-edit-btn-for-all btn"} style={{background:'#E9B51C'}} onClick={()=>editStateAndIndex(index)}>
                                                    <img src={edit} alt={"edit button"}/>
                                                </button>
                                                {
                                                    index=== isEditIndex &&

                                                    <MDBModal isOpen={isEditVideoPdfUrls} toggle={()=>setEditVideoPdfUrls(false)} centered size="lg">
                                                        <MDBModalBody>
                                                            <MDBBox className={"text-right"}>
                                                                <img src={ModalCloseIcon} alt={"Close"} className={"modal-close-btn"} onClick={()=>setEditVideoPdfUrls(false)}/>
                                                                <UploadVideoPdfContainer setDeletePdfsLists={setDeletePdfsList} deletePdfsLists={deletePdfsList} pargetLinkArrays={getLinkArrays} setparlinkArrays={setlinkArrays} setLessonName={setLessonName}  documentFile={documentFile} setDocumentFile={setDocumentFile} setvideoFile={setvideoFile} setPdfName={setPdfName} setVideoName={setVideoName} lessonItem={lessonsList[isEditIndex]}/>
                                                            </MDBBox>

                                                            <div className={'d-flex justify-content-between mt-5'}>
                                                                {/* <div onClick={()=>setISPreviewVideo(!getISPreviewVideo)} className={'d-flex preview-video-section'}>
                                                                    <p className={'mt--3'}>
                                                                        <img src={getISPreviewVideo?FilledState:EmptyState} alt={'ispreview video'} className={'mr-2'}/>
                                                                    </p> Set this lesson as preview
                                                                </div> */}
                                                                <div className={'important-mark-text d-flex'}>
                                                                    <p className={'mr-2mt--6'}>
                                                                        <img src={RedStar} alt={'important'} />
                                                                    </p>
                                                                    Marked are important
                                                                </div>
                                                            </div>
                                                            <MDBBox className={"submit-btn"}>
                                                                <BasicButton showIcon={false} handleClick={()=>deleteAndUpdate(lessonItem)}
                                                                             disabled={loading}
                                                                >Update Lesson</BasicButton>
                                                            </MDBBox>
                                                        </MDBModalBody>
                                                    </MDBModal>
                                                }







                                                <button type={"button"} className={"lesson-delete-btn btn"}
                                                        onClick={()=>updateLesson(lessonItem)}>
                                                    <img src={GreenTick} alt={"GreenTick"}/>
                                                </button>

                                                <button type={"button"} className={"lesson-delete-btn btn"}
                                                        onClick={()=>deleteSingleLesson(lessonItem)}>
                                                    <img src={RedDeleteIcon} alt={"DeleteIcon"}/>
                                                </button>
                                            </MDBBox>
                                        );
                                    })
                                }
                            </MDBBox>
                            </ReactDragListView>
                        </MDBBox>
                    :
                    <MDBBox className={"module-body-component"}>
                        <MDBBox className={"new-lesson-card d-flex justify-content-between align-items-center"}>
                            <button type={"button"} className={"btn new-lesson-btn"} onClick={()=>toggleLessonModal()}>
                                <img src={PlusIcon} alt={"plus"} className={"plus-icon"}/>
                                New Lesson
                            </button>
                        </MDBBox>
                        {
                            lessonsList.map((lessonItem,index)=>{
                                return(
                                    <MDBBox key={index} className={"lesson-card d-flex justify-content-between align-items-center"}>
                                        <span className={"title"}>{lessonItem.name}</span>
                                        <MDBBox className={"lesson-card-action-section d-flex flex-column"}>
                                            {
                                                lessonItem.type==="VIDEO" ?
                                                    <button type={"button"} className={"lesson-card-action-btn"}
                                                            onClick={()=>{
                                                                window.open(lessonItem.video,"_blank");
                                                            }}
                                                    >
                                                        <img src={PlayCircleIcon} alt={"play icon"}/>
                                                    </button>
                                                    :
                                                    <button type={"button"} className={"lesson-card-action-btn"}
                                                            onClick={()=>{
                                                                window.open(lessonItem.pdf,"_blank");
                                                            }}
                                                    >
                                                        <img src={DocumentIcon} alt={"play icon"}/>
                                                    </button>
                                            }
                                        </MDBBox>
                                    </MDBBox>
                                );
                            })
                        }

                    </MDBBox>
            }

            <MDBModal isOpen={lessonModelOpen} toggle={toggleLessonModal} centered size="lg">
                <MDBModalBody>
                    <MDBBox className={"text-right"}>
                        <img src={ModalCloseIcon} alt={"Close"} className={"modal-close-btn"} onClick={toggleLessonModal}/>
                    </MDBBox>
                    <CreateLessonForm selectedModule={moduleData}  updateLessonList={updateLessonList} />
                </MDBModalBody>
            </MDBModal>
        </div>

    );
}

const CreateModuleForm = (props) => {
    const [moduleName,setModuleName] = useState("");
    const [valueError,setValueError] = useState({});
    const params = useParams();
    const [loading,setLoading] = useState(false);


    const createModule = () => {
        let error = {}
        if(moduleName === ""){
            error = {
                feild:"name",
                message:"please fill module name"
            }
            setValueError(error);
        } else {
            setLoading(true);
            setValueError({});

            let variables = {
                courseId: params.id,
                isActive: true,
                name: moduleName,
                order: 1
            }
            CreateModuleMutation(variables, function (response) {
                setLoading(false);
                if (response.addModuleForCourse !== null) {
                    cogoToast.success(response.addModuleForCourse.message, {position: 'top-center'});
                    props.updateModuleList(response.addModuleForCourse.modules);
                    setModuleName("");
                    props.toggleModal();
                } else {
                    cogoToast.error("something went wrong", {position: 'top-center'});
                }
            }, function (err) {
                setLoading(false);
                cogoToast.error(err, {position: 'top-center'});
            });
        }
    }
    return(
        <MDBBox className={"create-module-form"}>
            <div className={"title text-center"}>Module Name</div>
            <MDBBox className={"form-section"}>
                <div className={"form-label"}>What’s your module name?</div>
                <input type={"text"} className={"form-input"}
                       value={moduleName}
                       onChange={(e)=>setModuleName(e.target.value)}
                />
                {
                    valueError.feild === "name" &&
                        <p className={"error-msg my-2 text-danger"}>{valueError.message}</p>
                }
                <MDBBox className={"submit-btn"}>
                    <BasicButton showIcon={false} handleClick={createModule} disabled={loading}>
                        Next Step
                    </BasicButton>
                </MDBBox>
            </MDBBox>
        </MDBBox>
    );
}

const CreateLessonForm = (props) => {
    const [valueError,setValueError] = useState({});
    const [loading,setLoading] = useState(false);
    const [getISPreviewVideo,setISPreviewVideo]=useState(false)


    const [VideoName,setVideoName]=useState('')
    const [pdfName,setPdfName]=useState('')
    const [videoFile,setvideoFile] = useState(null);
    const [documentFile,setDocumentFile] = useState([]);
    const [lessonName,setLessonName] = useState("");
    const [getLinkArray,setlinkArray]=useState([])

    const [step,setStep] = useState(1);
    const setNextStep = () => {
        let error = {}
        if(lessonName === ""){
            error = {
                feild:"name",
                message:"please fill lesson name"
            }
            setValueError(error);
        } else {
            setValueError({});
            setStep(2);
        }
    }

    const handleFileChange = (e,feild) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.addEventListener("load", () => {
            if(feild === "video"){
                setvideoFile(file);
                setVideoName(file.name)
            }
            if(feild === "pdf"){
                setDocumentFile(file);
                setPdfName(file.name)
            }
            setValueError({});
        });
        reader.readAsDataURL(file);
    }

    const createLesson = () => {
        // debugger
        let error = {}
        if(lessonName === ""){
            error = {
                feild:"name",
                message:"please fill lesson name"
            }
            setValueError(error);
        } else if(videoFile === null){
            error = {
                feild:"videoFile",
                message:"please choose lesson video"
            }
            setValueError(error);
        }
        // else if(documentFile === null){
        //     error = {
        //         feild:"documentFile",
        //         message:"please choose lesson pdf"
        //     }
        //     setValueError(error);
        // }
        else {
            setValueError({});
            setLoading(true);
            let tempkeylist=[]
            documentFile.map((item,index)=>{
                tempkeylist.push(item.id)
            })
            let variables = {
                isActive: true,
                isFree: false,
                moduleId: props.selectedModule.id,
                name: lessonName,
                order: 1,
                attachmentKeys:tempkeylist,
                urls:JSON.stringify((getLinkArray))
            }
            var data = new FormData();
            data.append("query", " mutation CreateLessonMutation(\n    $isActive: Boolean!\n    $isFree: Boolean\n    $moduleId: String!\n    $name: String!\n    $order: Int!\n  $attachmentKeys:[String]\n $urls: JSONString\n ) {\n     addLesson(\n        isActive: $isActive\n        isFree: $isFree\n        moduleId: $moduleId\n        name: $name\n        order: $order    \n  attachmentKeys:$attachmentKeys \n urls:$urls  ){   \n        lesson{\n            id\n            name\n            video\n            type\n       pdf\n     currentTime\n            isWatched\n            isWatching\n            transcodedVideo\n            isTranscoded\n            duration\n            transcript\n            isActive\n            order\n        }     \n        message\n        status\n     }\n  }");
            data.append("variables", JSON.stringify(variables));
            // data.append('urls',);
            data.append("video", videoFile);
            documentFile.map((item,index)=>{
                // debugger
                data.append(`${item.id}`,item.filedoc)
            })
            // data.append("pdf", documentFile);
            let token = localStorage.getItem('user_token');

            if (token !== null && token !== undefined && token !== "") {

                var config = {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'JWT ' + token
                    },
                };

            } else {

                var config = {
                    headers: {
                        'Accept': 'application/json'
                    },
                };
            }
            axios.post(api, data, config)
                .then(function (response) {
                    setLoading(false);
                    if (response.data.data.addLesson) {
                        cogoToast.success(response.data.data.addLesson.message, {position: 'top-center'});
                        props.updateLessonList(response.data.data.addLesson.lesson);
                        setLessonName("");
                        setDocumentFile(null);
                        setvideoFile(null);
                    }
                })
                .catch(function (err) {
                    setLoading(false);
                    cogoToast.error(err, {position: 'top-center'});
                });
        }
    }


    return(
        <div> 
           { loading &&
            <div>
                <Loader full={true} />
                <div  style={{height:window.innerHeight}}/>
            </div>
            
          }
         <MDBBox className={"create-module-form"}>
            <div className={"title text-center"}>Lesson Name</div>
            <MDBBox className={"form-section"}>
                {
                    step === 1 &&
                        <>
                            <MDBBox>
                                <div className={"form-label"}> What’s your lesson name?</div>
                            </MDBBox>
                            <input type={"text"} className={"form-input"}
                                   value={lessonName}
                                   onChange={(e)=>setLessonName(e.target.value)}
                            />
                            {
                                valueError.feild === "name" &&
                                <p className={"error-msg my-2 text-danger"}>{valueError.message}</p>
                            }
                            <MDBBox className={"submit-btn"}>
                                <BasicButton showIcon={false} handleClick={setNextStep} >Next Step</BasicButton>
                            </MDBBox>
                        </>

                }
                {
                    step === 2 &&
                    <>
                        <UploadVideoPdfContainer pdfName={pdfName} pargetLinkArray={getLinkArray} setparlinkArray={setlinkArray} setLessonName={setLessonName}  documentFile={documentFile} setDocumentFile={setDocumentFile} setvideoFiles={setvideoFile} setPdfName={setPdfName} setVideoName={setVideoName} lessonItem={' '} />

                        {/*<MDBBox className={"d-flex align-items-center justify-content-between mb-3"}>*/}
                        {/*    <div className={"form-label d-flex align-items-center"}>*/}
                        {/*        <img src={InfoIcon} alt={"info"} className={"mr-1"}/>*/}
                        {/*        <span>Upload the Video</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="lesson-document-upload">*/}
                        {/*        <label htmlFor="lesson-document">*/}
                        {/*            <MDBBox className={"upload-card d-flex flex-column align-items-center justify-content-center"}>*/}
                        {/*                {*/}
                        {/*                    videoFile &&*/}
                        {/*                        <div className={"text-center text-success p-3"}>*/}
                        {/*                            Your {VideoName} file is ready to upload*/}
                        {/*                        </div>*/}
                        {/*                }*/}
                        {/*                <div  className={"upload-btn"}>*/}
                        {/*                    <img src={UploadIcon} alt={"upload"}/>*/}
                        {/*                    Upload here*/}
                        {/*                </div>*/}


                        {/*            </MDBBox>*/}
                        {/*        </label>*/}
                        {/*        <input id="lesson-document" type="file"*/}
                        {/*               accept="video/mp4,video/x-m4v,video/*"*/}
                        {/*               onChange={(e)=>handleFileChange(e,"video")}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</MDBBox>*/}
                        {/*{*/}
                        {/*    valueError.feild === "videoFile" &&*/}
                        {/*    <p className={"error-msg my-2 text-danger"}>{valueError.message}</p>*/}
                        {/*}*/}
                        {/*<MDBBox className={"d-flex align-items-center justify-content-between"}>*/}
                        {/*    <div className={"form-label d-flex align-items-center"}>*/}
                        {/*        <img src={InfoIcon} alt={"info"} className={"mr-1"}/>*/}
                        {/*        <span>Upload the PDF</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="lesson-video-upload">*/}
                        {/*        <label htmlFor="lesson-video">*/}
                        {/*            <MDBBox className={"upload-card d-flex flex-column align-items-center justify-content-center"}>*/}
                        {/*                {*/}
                        {/*                    documentFile &&*/}
                        {/*                        <div className={"text-center text-success p-3"}>Your {pdfName} file is ready to upload</div>*/}
                        {/*                }*/}
                        {/*                <div className={"upload-btn"}>*/}
                        {/*                    <img src={UploadIcon} alt={"upload"}/>*/}
                        {/*                    Upload here*/}
                        {/*                </div>*/}
                        {/*            </MDBBox>*/}
                        {/*        </label>*/}
                        {/*        <input id="lesson-video" type="file"*/}
                        {/*               accept="application/pdf"*/}
                        {/*               onChange={(e)=>handleFileChange(e,"pdf")}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</MDBBox>*/}
                        {/*{*/}
                        {/*    valueError.feild === "documentFile" &&*/}
                        {/*    <p className={"error-msg my-2 text-danger"}>{valueError.message}</p>*/}
                        {/*}*/}


                        <div className={'d-flex justify-content-between mt-5'}>
                            {/* <div onClick={()=>setISPreviewVideo(!getISPreviewVideo)} className={'d-flex preview-video-section'}>
                               <p className={'mt--3'}>
                                   <img src={getISPreviewVideo?FilledState:EmptyState} alt={'ispreview video'} className={'mr-2'}/>
                               </p> Set this lesson as preview
                            </div> */}
                            <div className={'important-mark-text d-flex'}>
                                <p className={'mr-2mt--6'}>
                                    <img src={RedStar} alt={'important'} />
                                </p>
                                Marked are important
                            </div>
                        </div>
                        <MDBBox className={"submit-btn"}>
                            <BasicButton showIcon={false} handleClick={createLesson}
                                         disabled={loading}
                            >Create Lesson</BasicButton>
                        </MDBBox>
                    </>

                }
            </MDBBox>
         </MDBBox>
      </div>
    );
}

export const UploadVideoPdfContainer=(props)=>{
    const {documentFile,setDocumentFile,setparlinkArray,parlinkArray,lessonItem,deletePdfsLists,setDeletePdfsLists,pargetLinkArrays,setparlinkArrays}=props
    // const {lessonItem}=props
    const [documentFiles,setDocumentFiles] = useState([]);
    const [videoFile,setvideoFile] = useState(null);
    const [valueError,setValueError] = useState({});
    const [pdfNames,setPdfNames]=useState('')
    const [VideoName,setVideoName]=useState('')
    const [count,setcount]=useState(0)

    const [deletePdfsList,setDeletePdfsList]=useState([])
    const [documentAddList,setdocumentAddList]=useState([])
    let TempdataFromBackEnd=[]

// console.log(lessonItem)


    const initialLinkValue={
        id:uuid(),
        title:"",
        link:""
    }
    const [getLinkArray,setlinkArray]=useState([initialLinkValue])

    console.log('lessonItem',lessonItem)


    useEffect(()=>{
        // debugger
        lessonItem && lessonItem.attachments && lessonItem.attachments.edges.length >0 && lessonItem.attachments.edges.map((item,index)=>{
            TempdataFromBackEnd.push({
                id:item.node.id,
                filedoc:item.node.file,
                name:item.node.name
            })
            // console.log('itemitem',TempdataFromBackEnd)
            setDocumentFiles(TempdataFromBackEnd)
        })
    },[ lessonItem])

    useEffect(()=>{

        if (lessonItem && lessonItem !==' ' && lessonItem.urls !== "{}" ){
            // debugger
            const bachendUrls=JSON.parse(lessonItem && lessonItem.urls)
            // console.log('urlsss',typeof(JSON.parse(urlsss)))
            // console.log('bachendUrls',bachendUrls.length)
            setlinkArray(bachendUrls)
            setparlinkArrays(bachendUrls)
        }
    },[lessonItem])

    function addOneExtraLink(){
        let updatelinkArray=[
            ...getLinkArray,
            {
                id:uuid(),
                title:``,
                link:``
            }
        ]
        setlinkArray(updatelinkArray)
        // setparlinkArrays(updatelinkArray)

            lessonItem!==' ' && props.setparlinkArrays(updatelinkArray)


    }

    function removeOneExtraLink(index){
        // debugger
        let filteredArray = getLinkArray.filter(item => item.id !==index)
        setlinkArray(filteredArray)
        // setparlinkArrays(filteredArray)
        lessonItem!==' ' &&  props.setparlinkArrays(filteredArray)
    }

    function handlelinkchange(e,index,fieldname){
        let filterArray=getLinkArray
        if (filterArray[index].title===fieldname){
            filterArray[index].title=e.target.value
        }else if (filterArray[index].link===fieldname){
            filterArray[index].link=e.target.value
        }
        // console.log(filterArray)
        setlinkArray(filterArray)
        lessonItem=== ' ' && setparlinkArray(filterArray)
        lessonItem!==' ' && props.setparlinkArrays(filterArray)
        setcount(count+1)
    }

    const handleFileChange = (e,feild) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        
        reader.addEventListener("load", () => {
            if(feild === "video"){
                setvideoFile(file);
                 lessonItem ===' '&&   props.setvideoFiles(file)
                lessonItem!==' ' &&  props.setvideoFile(file)
                setVideoName(file.name)
                lessonItem!==' ' &&  props.setVideoName(file.name)
            }
            if(feild === "pdf" && file.type ==='application/pdf' ){
                let doclist=[uuid(),file]
                // setDocumentFile(documentFile=>[...documentFile,doclist]);

                // setExampleState({...exampleState,  masterField2: {
                //         fieldOne: "a",
                //         fieldTwo: {
                //             fieldTwoOne: "b",
                //             fieldTwoTwo: "c"
                //         }
                //     },
                // })
                setDocumentFiles([...documentFiles,{
                    id:uuid(),
                        filedoc:file,
                    name:file.name
                    },]);

                let temp=props.documentFile

                temp.push({
                    id:uuid(),
                    filedoc:file,
                    name:file.name
                })

                lessonItem!==' ' &&   props.setDocumentFile(temp)
                lessonItem!==' ' &&   setdocumentAddList(temp[0].id)

                // setDocumentFile([...documentFile,{
                //     key:uuid(),
                //     filedoc:file
                // },]);
                //
                // props.setDocumentFile(documentFile=>[...documentFile,doclist])
                setPdfNames(pdfNames=>[...pdfNames,file.name])

                lessonItem!==' ' &&   props.setPdfName(pdfNames=>[...pdfNames,file.name])
                // console.log('doc',documentFiles)
            }
            else{
                cogoToast.info('Only PDF are allowed', {position: 'top-center'}); 
            }
            setValueError({});
        });
        reader.readAsDataURL(file);
    }

    function removeOneDocument(index,name){
        // debugger
        let filteredArray = documentFiles.filter(item => item.id !==index)
        // let filteredArrayName = pdfNames.filter(item => item !==name)
        setDocumentFiles(filteredArray)
        // lessonItem!==' ' &&  props.setDocumentFile(filteredArray)
        setDeletePdfsLists(prevState =>[...prevState,index] )
        // setPdfNames(filteredArrayName)
        // lessonItem!==' ' &&  props.setPdfName(filteredArrayName)
    }

// console.log('deletePdfsList',deletePdfsList)




    return(
        <div className={'Upload-Video-Pdf-link-Container'}>
            <div className={'Upload-Video-Pdf-Container d-flex'}>
                <div className={'upload-video-section'}>
                    <div className={'title d-flex'}>
                        <div className={'text'}>
                            <p>Upload the Video <img src={RedStar} alt={'important'} className={'redStar'} /></p>
                            <p className={'limit'}>Max limit only 1 video</p>
                        </div>
                        <div className={'custome-button'}>
                            <div className="lesson-document-upload">
                                <label htmlFor="lesson-document-video" className={'label-video-upload'} >
                                    <img src={upload}  alt={'upload'} className={'upload-btn'}/> Upload here
                                    {/*<div  className={"upload-card d-flex flex-column align-items-center justify-content-center"}>*/}
                                    {/*     <BasicButton > <img src={upload}  alt={'upload'} className={'upload-btn'}/> Upload here</BasicButton>*/}
                                    {/*</div>*/}
                                </label>
                                <input id="lesson-document-video" type="file"
                                       accept="video/mp4,video/x-m4v,video/*"
                                    onChange={(e)=>handleFileChange(e,"video")}
                                    className={'testtest'}
                                />
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className={'main-video-section'}>
                        {/*<img src={close_btn} alt={'close_btn'} className={'close_btn'}/>*/}
                        {videoFile ?
                            <div className={'lesson-name'}>
                                Your {videoFile.name} file is ready to upload
                            </div>:
                            lessonItem !==' '&&    <div className={'lesson-name'}>
                                {lessonItem.name}
                            </div>
                        }
                    </div>
                </div>
                <div className={'upload-pdf-section'}>
                    <div className={'title d-flex'}>
                        <div className={'text'}>
                            <p>Upload the PDF</p>
                            <p className={'limit'}>Multiple files can <br/> be uploaded</p>
                        </div>

                        <div className={'custome-button'}>
                            <div className="lesson-document-upload">
                                <label htmlFor="lesson-document-pdf" className={'label-video-upload'} >
                                    <img src={upload}  alt={'upload'} className={'upload-btn'}/> Upload here
                                    {/*<div  className={"upload-card d-flex flex-column align-items-center justify-content-center"}>*/}
                                    {/*     <BasicButton > <img src={upload}  alt={'upload'} className={'upload-btn'}/> Upload here</BasicButton>*/}
                                    {/*</div>*/}
                                </label>
                                <input id="lesson-document-pdf" type="file"
                                       accept="application/pdf"
                                       onChange={(e)=>handleFileChange(e,"pdf")}
                                       className={'testtest'}
                                />
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className={'d-flex documents-container'}>
                        {
                           documentFiles .map((item,index)=>{
                          // Array(3).map((item,index)=>{
                          //      console.log('item.name',item)
                               // debugger
                                return(
                                    <div className={'inside-card'}>
                                        <img src={close_btn} alt={'close_btn'} className={'close_btn'} onClick={()=>removeOneDocument(item.id,pdfNames[index])}/>
                                        {
                                            documentFiles &&
                                            <div className={'inside-text'}>
                                                {/*Your  file is ready to upload*/}
                                                {/*{item.name}*/}
                                            <TrunkString name={item.name} length={20}/>
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div className={'external-link'}>
                <div className={'row '}>
                    <div className={'col-6 title-text'}>
                        External Links
                    </div>
                    <div className={'col-6 title-link'}>
                        <span className={'add-extra-link'} onClick={()=>addOneExtraLink()}>
                            <img src={yellow_plus} alt={'add icon'} className={'mr-2'}/>
                            Add Another Link
                        </span>

                    </div>
                </div>
                    {
                        getLinkArray.map((item,index)=>{
                            // console.log(item)
                            let temp=item.link
                            // debugger
                            return(
                                <div className={'row'} key={index}>
                                    <div className={'col-4 pr-0'}>
                                        <input type={'text'} onChange={(e)=>handlelinkchange(e,index,item.title)} value={item.title} className={'input-text input-text-title'} placeholder={'Add Title'}/>
                                    </div>
                                    <div className={'col-8 pl-0'}>
                                        <input type={'text'} onChange={(e)=>handlelinkchange(e,index,item.link)} value={temp} className={'input-text input-text-link'} placeholder={'Paste the link here'}/>
                                        <img onClick={()=>removeOneExtraLink(item.id)} src={gray_close_icon} alt={'delete button'} className={'link-close-btn'}/>
                                    </div>
                                </div>
                            )
                        })
                    }
            </div>
        </div>
    )
}


