import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'

const mutation = graphql`
    mutation UpdateBackgroundDetailsMutation(
        $collegeName:String,
        $degreeName:String,
        $degreeType:DegreeTypeEnum,
        $employementStatus:EmployementStatusEnum,
        $experienceLevel:ExperienceLevelEnum,
        $organizationName:String,
        $yearOfGraduation:String
    )
    {
        updateBackgroundDetails(
            collegeName:$collegeName,
            degreeName:$degreeName,
            degreeType:$degreeType,
            employementStatus:$employementStatus,
            experienceLevel:$experienceLevel,
            organizationName:$organizationName,
            yearOfGraduation:$yearOfGraduation,
        ){
            status
            message
        }
    }
`


export default (userData, callback,errCallback) => {
    const variables = {
        collegeName:userData.collegeName,
        degreeName:userData.degreeName,
        degreeType: userData.degreeType,
        employementStatus: userData.employementStatus,
        experienceLevel: userData.experienceLevel,
        organizationName: userData.organizationName,
        yearOfGraduation: userData.yearOfGraduation,
    }
    commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response,err) => {
                if(response.UpdateBackgroundDetailsMutation!== null){
                    callback(response)
                } else{
                    errCallback(err[0].message);
                }
            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}