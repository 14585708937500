import React, { Fragment, useEffect, useRef, useState } from "react";
import { LayoutContainer } from "../../Components/LayoutContainer/LayoutContainer";
import "./Modules.scss";
import ReactStars from "react-rating-stars-component";
import Output from 'editorjs-react-renderer';
import completedinpre from "../../assets/images/completedinpre.png";
import timeto from "../../assets/images/timeto.png";
import NotePad from "../../assets/images/NotePad.png";
import CourseAccordian from "../../Containers/CourseAccordian/CourseAccordian";
import { CourseInstructorCard } from "../../Containers/CourseInstructorCard/CourseInstructorCard";
import { RatingCircle } from "../../Containers/RatingCircle/RatingCircle";
import expert from "../../assets/images/professorpic.svg";
import ActiveIllustrator from "../../assets/Illustrations/Polygon-Dark.png";
import Instructor from "../../assets/images/professorpic.svg";
import Badge from "../../assets/icons/carbon_badge.svg";
import Group19880 from "../../assets/images/Group19880.png";
import LeftArrow from "../../assets/images/left-Arrow.png";
import yellowrightArrow from "../../assets/images/Right-Arrow.png";
import user from "../../assets/images/user1.png";
import { MDBBox } from "mdbreact";
import { RoundedButton } from "../../Components/Buttons/RoundedButton";
import ArrowDown from "../../assets/icons/dashicons_arrow-down.svg";
import LoginMutation from "../../mutations/LoginMutation";
import cogoToast from "cogo-toast";
import CreateReviewMutation from "../../mutations/CreateReviewMutation";
import uuid from 'react-uuid'

export const Modules = (props) => {
    const [activeTab, setActiveTab] = useState("modules");
    const [courseDeatil, setCourseDetail] = useState({});
    const [modules, setModules] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [relatedCourses, setRelatedCourses] = useState([]);
    const [aboutCourse, setAboutCourse] = useState({});
    const [noteCourse, setNoteCourse] = useState({});
    const [circle1Data, setCircle1Data] = useState({});
    const [circle2Data, setCircle2Data] = useState({});
    const [circle3Data, setCircle3Data] = useState({});

    useEffect(function () {
        let tmp_course_deatil = props.courseDeatil;
        if (Object.keys(tmp_course_deatil).length > 0) {
            setCourseDetail(tmp_course_deatil);
            if (tmp_course_deatil.modules && tmp_course_deatil.modules.edges && tmp_course_deatil.modules.edges.length > 0) {
                setModules(tmp_course_deatil.modules.edges);
            }
            if (tmp_course_deatil.reviews && tmp_course_deatil.reviews.edges && tmp_course_deatil.reviews.edges.length > 0) {
                setReviews(tmp_course_deatil.reviews.edges);
            }
            if (tmp_course_deatil.instructor && tmp_course_deatil.instructor.courses && tmp_course_deatil.instructor.courses.edges.length > 0) {
                setRelatedCourses(tmp_course_deatil.instructor.courses.edges);
            }
            if (tmp_course_deatil.about && tmp_course_deatil.about !== "") {
                let course_description = tmp_course_deatil.about;
                let aboutCourse = JSON.parse(course_description);
                let aboutDataFormate = {
                    course_name: tmp_course_deatil.name,
                    course_description: aboutCourse.blocks && aboutCourse.blocks[0] ? aboutCourse.blocks[0].data.text : ""
                }
                setAboutCourse(aboutDataFormate);
            }
            if (tmp_course_deatil.Note !== "" && tmp_course_deatil.Note !== null) {
                let course_note_description = tmp_course_deatil.Note;
                let noteCourse = JSON.parse(course_note_description);
                let noteDataFormate = {

                    course_note: noteCourse.blocks && noteCourse.blocks[0] ? noteCourse.blocks[0].data.text : ""
                }
                setNoteCourse(noteDataFormate);
            }
            setCircle1Data({
                count: tmp_course_deatil.totalModules ? tmp_course_deatil.totalModules : 0,
                content: "Modules"
            });
            setCircle2Data({
                count: tmp_course_deatil.totalLectures,
                content: "Lectures"
            });
            setCircle3Data({
                count: tmp_course_deatil.totalDuration,
                content: "Duration"
            });

        }
    }, [props.courseDeatil]);

    return (
        <div className="CourseDetails">
            {
                Object.keys(courseDeatil).length > 0 &&
                <>
                    <CourseDetails courseData={courseDeatil} />
                    <Tab activeTab={activeTab} setActiveTab={setActiveTab} />
                    <div className={"tab-content-section"}>
                        {activeTab === "modules" ?
                            <Fragment>
                                <CardPro courseData={courseDeatil} />
                                <MainTab courseData={courseDeatil} modules={modules} />
                            </Fragment> :
                            <Fragment>
                                <Info courseData={courseDeatil} circle1Data={circle1Data} circle2Data={circle2Data} circle3Data={circle3Data} />
                                {
                                    Object.keys(aboutCourse).length > 0 &&
                                    <Details aboutCourse={aboutCourse} noteCourse={noteCourse} aboutCoursedetails={props?.courseDeatil} />
                                }


                                <OurStudentsWords
                                    reviewData={reviews}
                                    courseData={courseDeatil}
                                    refreshRequest={props.refreshRequest}
                                />



                            </Fragment>
                        }
                    </div>
                </>
            }
        </div>
    );
};
const CourseDetails = (props) => {
    const { courseData } = props;
    const date_time_array = courseData.expiryDate.split(" ");
    return (
        <div className="top-head">
            <LayoutContainer>
                <h2 className="topice">
                    {courseData.name} <span className="level">({courseData.level.name})</span>{" "}
                </h2>
                <h4 className="Name">By  {courseData.instructor.user.name}</h4>
                <p className="Expirimg m-0">Expiring on {date_time_array[0]}</p>
            </LayoutContainer>
        </div>
    );
};

const Tab = (props) => {
    return (
        <div className="Tab">
            <LayoutContainer>
                <div className="d-flex align-items-center tab-flex-container">
                    <div
                        className={props.activeTab === "modules" ? "topic-1 active" : "topic-1"}
                        onClick={() => {
                            props.setActiveTab("modules");
                        }}
                    >
                        Modules
                    </div>
                    <div
                        className={props.activeTab === "courseinfo" ? "topic-2 active" : "topic-2"}
                        onClick={() => {
                            props.setActiveTab("courseinfo");
                        }}
                    >
                        Course Info

                    </div>
                </div>
            </LayoutContainer>
        </div>
    );
};

const CardPro = (props) => {
    const { courseData } = props;
    return (
        <div className="cardPro">
            <LayoutContainer>
                <div className="d-flex main justify-content-between">
                    <div className="mainCard">
                        <h3 className="title">
                            <img src={completedinpre} className="img" alt="details" style={{ marginRight: "8px" }} />
                            {courseData.percentageCompleted}
                        </h3>
                        <p className="para">You’ve completed {courseData.percentageCompleted} of the course!</p>
                    </div>
                    <div className="mainCard">
                        <h3 className="title">
                            <img src={timeto} className="img" alt="details" style={{ marginRight: "8px" }} />
                            {courseData.weeklyWatchHours} Hours!
                        </h3>
                        <p className="para">You’ve spent {courseData.weeklyWatchHours} hours studying this week!</p>

                    </div>
                    <div className="mainCard">
                        <h3 className="title">
                            <img src={NotePad} className="img" alt="details" style={{ marginRight: "8px" }} />
                            {courseData.lessonsLeftToComplete} Lessons
                        </h3>
                        <p className="para">You’ve  {courseData.lessonsLeftToComplete} more lessons left to complete!</p>
                    </div>
                </div>
            </LayoutContainer>
        </div>
    );
};
const MainTab = (props) => {
    const { courseData, modules } = props;

    return (
        <div className="MainTab">
            <LayoutContainer>
                <CourseAccordian isExpandAll={false} preview={false} modules={modules} courseData={courseData} />
            </LayoutContainer>
        </div>
    );
};

const Info = (props) => {
    const imageEl = useRef(null);
    const { courseData, circle1Data, circle2Data, circle3Data } = props;
    const instructorData = courseData.instructor;
    // console.log(instructorData)
    return (
        <div className="Info">
            <LayoutContainer>
                <div className='mb-cardd'>
                    <div className='title-main pl-3'>Course Instructor</div>
                    {/*<CourseInstructorCard/>*/}
                </div>
                <div className="row w-100 newrow">
                    <div className="col-12 ratei col-sm-12 col-lg-6">
                        <RatingCircle circle1Data={circle1Data} circle2Data={circle2Data} circle3Data={circle3Data} />
                    </div>
                    <div className="col-12 col-sm-12 col-lg-6 coursecontent">
                        <div className="card1 p-3">
                            <h3 className="title">Course Instructor</h3>
                            <div className="d-flex myflex">
                                <MDBBox className={"instructor-image-section"}>
                                    <img
                                        src={ActiveIllustrator}
                                        alt={"expertbg"}
                                        className={"img-fluid instructor-bg"}
                                    />
                                    <img
                                        src={instructorData.profilePic ? instructorData.profilePic : Instructor}
                                        alt={"expert"}
                                        className={"img-fluid instructor-profile"}
                                        ref={imageEl}
                                        onError={() => {
                                            imageEl.current.src = Instructor;
                                        }}
                                    />
                                </MDBBox>
                                <MDBBox className={"instructor-detail"}>
                                    <p className={"instructor-name"}> {instructorData.user.name} </p>
                                    <p className={"instructor-role"}>
                                        {instructorData.designation}{" "}
                                    </p>
                                    <p className={"instructor-institute"}>
                                        {instructorData.qualification}
                                    </p>
                                    <p className={"instructor-address"}>Bengaluru, Karnataka</p>
                                    <MDBBox
                                        className={
                                            "align-items-start justify-content-center large-device-experiance"
                                        }
                                    >
                                        <img src={Badge} alt={"Badge"} className={"img-fluid"} />
                                        <p className={"instructor-experience mb-0"}>
                                            {instructorData.experience !== null ? instructorData.experience : '0'}+ Years of Working Experience
                                        </p>
                                    </MDBBox>
                                </MDBBox>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutContainer>
        </div>
    );
};

const Details = (props) => {

    const [showExpand, setShowExpand] = useState(false);
    const [showNotes, setShowNotes] = useState(false);

    const expandDescription = (description, readMore, readLess) => {
        let readMoreRef = document.getElementById(readMore);
        let readLessRef = document.getElementById(readLess);
        let discriptionRef = document.getElementById(description);

        if (readMoreRef && readLessRef && discriptionRef) {
            readMoreRef.style.display = "none";
            readLessRef.style.display = "flex";
            discriptionRef.style.height = discriptionRef.scrollHeight + "px";
        }

    }

    const closeDescription = (description, readMore, readLess) => {
        let readMoreRef = document.getElementById(readMore);
        let readLessRef = document.getElementById(readLess);
        let discriptionRef = document.getElementById(description);

        if (readMoreRef && readLessRef && discriptionRef) {
            readMoreRef.style.display = "flex";
            readLessRef.style.display = "none";
            discriptionRef.style.height = "250px";
            discriptionRef?.scrollIntoView({
                behavior
                    :
                    'smooth'
            });
        }

    }

    useEffect(() => {
        let discriptionNotesRef = document.getElementById("discriptionNotesRef");
        let discriptionRef = document.getElementById("discriptionRef");
        if (discriptionNotesRef) {
            if (discriptionNotesRef.scrollHeight > 250) {
                setShowNotes(true);
                discriptionNotesRef.style.height = "250px";
            }
        }
        if (discriptionRef) {
            if (discriptionRef.scrollHeight > 250) {
                setShowExpand(true);
                discriptionRef.style.height = "250px";
            }
        }
    }, []);


    const convertEditorJsTexToHtml = (data) => {
        if (data) {
            return (JSON?.parse(data))
        } else {
            return ("")
        }
    }

    return (
        <div className="details">
            <LayoutContainer>
                <div className={"about-course"}>
                    <p className={"title"}>About the Course</p>
                    <MDBBox className={"description-wrapper"}>
                        <div className={"description"} id={"discriptionRef"}>
                            <Output data={convertEditorJsTexToHtml(props?.aboutCoursedetails?.about)} />
                        </div>
                        {
                            showExpand && (
                                <>
                                    <MDBBox className={"read-more-content"} id={"readMoreRef"} onClick={() => expandDescription('discriptionRef', 'readMoreRef', 'readLessRef')}>
                                        <span>Read More</span>
                                        <img src={ArrowDown} alt={"arrow-down"} className={"img-fluid arrow-down"} />
                                    </MDBBox>
                                    <MDBBox className={"read-less-content"} id={"readLessRef"} onClick={() => closeDescription('discriptionRef', 'readMoreRef', 'readLessRef')}>
                                        <span>Read Less</span>
                                        <img src={ArrowDown} alt={"arrow-down"} className={"img-fluid arrow-down"} />
                                    </MDBBox>
                                </>
                            )
                        }
                    </MDBBox>
                </div>
                <hr />
                <div className={"about-course"}>
                    <p className={"title"}>Instructor’s Note</p>
                    <MDBBox className={"description-wrapper"}>
                        <div className={"description"} id={"discriptionNotesRef"}>
                            <Output data={convertEditorJsTexToHtml(props?.aboutCoursedetails?.Note)} />
                        </div>
                        {
                            showNotes && <>
                                <MDBBox className={"read-more-content"} id={"readMoreNotesRef"} onClick={() => expandDescription('discriptionNotesRef', 'readMoreNotesRef', 'readLessNotesRef')}>
                                    <span>Read More</span>
                                    <img src={ArrowDown} alt={"arrow-down"} className={"img-fluid arrow-down"} />
                                </MDBBox>
                                <MDBBox className={"read-less-content"} id={"readLessNotesRef"} onClick={() => closeDescription('discriptionNotesRef', 'readMoreNotesRef', 'readLessNotesRef')}>
                                    <span>Read Less</span>
                                    <img src={ArrowDown} alt={"arrow-down"} className={"img-fluid arrow-down"} />
                                </MDBBox>
                            </>
                        }

                    </MDBBox>
                </div>

            </LayoutContainer>
        </div>
    );
};
const OurStudentsWords = (props) => {
    const [activeReview, setActiveReview] = useState(0);
    const [rating, setRating] = useState(0);
    const [content, setContent] = useState("");

    const { reviewData, courseData } = props;
    const fourthExample = {
        size: 30,
        count: 5,
        color: "rgba(233, 181, 28, 1)",
        activeColor: "rgba(233, 181, 28, 1)",
        value: 5,
        a11y: true,
        isHalf: true,
        emptyIcon: <i className="far fa-star" />,
        halfIcon: <i className="fa fa-star-half-alt" />,
        filledIcon: <i className="fa fa-star" />,
        onChange: newValue => {
            setRating(newValue);
        }
    };

    const submitReview = () => {
        let variables = {
            content: content, courseId: courseData.id, rating: rating
        }
        CreateReviewMutation(variables, function (response) {
            if (response.createReview !== null) {
                cogoToast.success("Thankyou for your feedback", { position: 'top-center' });
                setContent("");
                setRating(0);
                props.refreshRequest();
            } else {
                cogoToast.error("something went wrong", { position: 'top-center' });
            }
        }, function (err) {
            cogoToast.error(err, { position: 'top-center' });
        });
    }


    return (
        <div className={"review-section"}>
            <LayoutContainer>
                <div className="Reviews">
                    <h4 className="title">Reviews</h4>
                </div>
                <div className="OurStudentsWords">
                    <div className="row">

                        <div className="col-md-6 col-12 order-md-2  order-sm-1 mycard">
                            <p className='title p-0'>Rate your experience on this course</p>
                            <ReactStars {...fourthExample} />
                            <hr />
                            <p className='title p-0'>Your Feedback Matters! Type something...</p>
                            <textarea className='p-3' rows="4" placeholder='Tell us more..' value={content}
                                onChange={(e) => setContent(e.target.value)}
                            >

                            </textarea>
                            <RoundedButton handleClick={submitReview}>Submit</RoundedButton>
                        </div>
                        {reviewData.length > 0 &&
                            <div className="col-md-6 order-sm-2 order-md-1 col-12">
                                <p className="title p-0">Our Students Words</p>
                                {
                                    reviewData.map((item, index) => {
                                        return (
                                            <div className="card" key={uuid()} style={{ display: activeReview === index ? "block" : "none" }}>
                                                <div className="row">
                                                    <img src={Group19880} alt="Group19880" className="Group19880" />
                                                    <div className="col-12 Details">
                                                        <div
                                                            className="StudentWordscard"
                                                            data-aos="zoom-in"
                                                            data-aos-duration="800"
                                                        >
                                                            <img
                                                                src={user}
                                                                className="style1"
                                                                alt="style for student tag"
                                                            />
                                                            <span className="span-studentName">
                                                                {item.node.user.name}
                                                            </span>
                                                            <p className="studentWords">
                                                                {item.node.content}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }


                                <div className="d-flex leftrightArrow">
                                    <div
                                        className="nextstudentbtn"
                                        onClick={() => (activeReview > 0 ? setActiveReview(activeReview - 1) : setActiveReview(0))}
                                    >
                                        <img src={LeftArrow} className="LeftArrow" alt="LeftArrow" />
                                    </div>
                                    <div
                                        className="nextstudentbtn"
                                        onClick={() => {
                                            if (activeReview < reviewData.length - 1) {
                                                setActiveReview(activeReview + 1)
                                            }
                                        }}
                                    >
                                        <img
                                            src={yellowrightArrow}
                                            className="yellowrightArrow"
                                            alt="yellowrightArrow"
                                        />
                                    </div>
                                </div>
                            </div>

                        }
                    </div>
                </div>
            </LayoutContainer>
        </div>

    );
};

// const CreateReviews=()=>{
//
//     const [getTextValue,setTextValue]=useState('')
//     const [getStartValue,setStarValue]=useState()
//     const textfunction=(e)=>{
//         setStarValue(prevState => e.target.value)
//     }
//     const ratingChanged=(e)=>{
//         setTextValue(e)
//     }
//
//     return(
//         <div className={'create-reviews'}>
//             <div className={'reviews-header'}>
//                 Rate your experience on this course
//             </div>
//             <ReactStars
//                 count={5}
//                 onChange={newValue => {
//                     ratingChanged(newValue);
//                 }}
//                 classNames={'custome-star'}
//                 size={20}
//                 color={"rgba(233, 181, 28, 1)"}
//                 activeColor={"rgba(233, 181, 28, 1)"}
//                 // value={reviewItem.rating}
//                 // edit={editdetails}
//                 a11y={true}
//                 isHalf={true}
//                 emptyIcon={<i className="far fa-star"/>}
//                 halfIcon={<i className="fa fa-star-half-alt"/>}
//                 filledIcon={<i className="fa fa-star"/>}
//             />
//             <hr className={'custom-hr-line'}/>
//             <div className={'sub-text'}>
//                 Your Feedback Matters! Type something...
//             </div>
//             <textarea onChange={(e)=>textfunction(e)} placeholder={'Tell us more..'} className={'text-area-reviews'}>
//
//             </textarea>
//
//             <RoundedButton>
//                 Submit
//             </RoundedButton>
//         </div>
//     )
// }