import graphql from "babel-plugin-relay/macro";

const CourseListQuery=graphql`

 query CourseListQuery{
   courses {
      edges {
        node {
          id
          averageRating
          isWishlist
          name
          price
          image
          discountPrice
          enrolledUsers
          instructor{
            user{
              name
            }
          }
        }
      }
    }
}




`

export default CourseListQuery;
