import graphql from "babel-plugin-relay/macro";

const CategoryListQuery=graphql`

 query CategoryListQuery{
  categories {
    edges {
      node {
        id
        name
        isActive
      }
    }
  }
}



`

export default CategoryListQuery
