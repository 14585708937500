import {
    FETCH_CARTDATA_BEGIN,
    FETCH_CARTDATA_SUCCESS,
    FETCH_CARTDATA_FAILURE,
    CLEARCART
} from '../Actions/CartAction';
import {
    FETCH_PROFESSOR_COURSESPAGE_STATE_COURSE_INFO,
    FETCH_PROFESSOR_COURSESPAGE_STATE_MODULES,
    FETCH_PROFESSOR_COURSESPAGE_STATE_REVIEWS
} from "../Actions/ProfessorCoursesPageStateAction";



const initialState = {
    ProfessorCoursesActiveClass:"course-info",
    loading: false,
    error: null
}


const ProfessorCoursespageState =  (state = initialState,action) => {
    switch (action.type) {


        case FETCH_PROFESSOR_COURSESPAGE_STATE_REVIEWS:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                loading: true,
                error: null,
                ProfessorCoursesActiveClass:action.payload
            };
        // case FETCH_PROFESSOR_COURSESPAGE_STATE_MODULES:
        //     // All done: set loading "false".
        //     // Also, replace the items with the ones from the server
        //     return {
        //         state:"modules",
        //         loading: false,
        //         cart_data: action.payload.cart_data
        //     };
        //
        // case FETCH_PROFESSOR_COURSESPAGE_STATE_COURSE_INFO:
        //     // All done: set loading "false".
        //     // Also, replace the items with the ones from the server
        //     return {
        //         state:"course-info",
        //         loading: false,
        //         cart_data: action.payload.cart_data
        //     };

        default:
            return {  ...state,
                loading: true,
                error: null,
            };
    }

}

export default ProfessorCoursespageState;
