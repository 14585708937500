import './ChnagePassword.scss'
import {FlatButton} from "../../../Components/Buttons/FlatButton";
import {BasicButton} from "../../../Components/Buttons/BasicButton";
import cogoToast from "cogo-toast";
import React, {useEffect, useState} from "react";
import ChangePasswordMutation from "../../../mutations/ChangePasswordMutation";
import ProfileNavbar from "../ProfileNavbar/ProfileNavbar";
import Header from "../../../Containers/Header/Header";
import {Footer} from "../../../Containers/Footer/Footer";

export const ChangePassWord = () => {
    const [valueErrors, setValueErrors] = useState({});
    const [oldpassowrd, setOldpassword] = useState('');
    const [newpassowrd, setNewpassword] = useState('');
    const [confirmpassowrd, setConfirmpassword] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const submitPasswordChange = () => {
        let error = {};
        if (oldpassowrd === "") {
            error = {
                field: "old-password",
                message: "Please fill current password"
            };
            setValueErrors(error);
        } else if (newpassowrd === "") {
            error = {
                field: "new-password",
                message: "Please fill new password"
            };
        } else if (confirmpassowrd === "") {
            error = {
                field: "confirm-password",
                message: "Please fill confirm password"
            };
        } else if (newpassowrd !== confirmpassowrd) {
            error = {
                field: "confirm-password",
                message: "password not match"
            };
        } else {
            let userData = {
                oldPassword: oldpassowrd,
                newPassword: newpassowrd,
                confirmPassword: confirmpassowrd,
            };
            error = {};
            setValueErrors(error);
            ChangePasswordMutation(userData, function (response) {
                if (response.changePassword !== null) {
                    cogoToast.success(response.changePassword.message, {position: 'top-center'});
                    setOldpassword('');
                    setNewpassword('');
                    setConfirmpassword('');
                } else {
                    cogoToast.error("something went wrong", {position: 'top-center'});
                }
            }, function (err) {
                cogoToast.error(err, {position: 'top-center'});
            });
        }
    }

    return (
        <>
            <Header page={""}/>
            <ProfileNavbar active_page={"Change Password"}>
            <div className={'ChangePassword'}>
                <div className="flex1 container_start">
                    <div className="Create">Current Password
                    </div>
                    <input
                        type="password"
                        placeholder="Type your current password"
                        className="code code_1"
                        required
                        value={oldpassowrd}
                        onChange={(e) => setOldpassword(e.target.value)}
                    />
                    {
                        valueErrors.field === "old-password" &&
                        <p className={"error-message"}>{valueErrors.message}</p>
                    }
                </div>
                <FlatButton>Forgot current Password?</FlatButton>
                <div className="flex1">
                    <div className="Create">New Password
                    </div>
                    <input
                        type="password"
                        placeholder="Type your new password"
                        className="code first_password"
                        required
                        value={newpassowrd}
                        onChange={(e) => setNewpassword(e.target.value)}
                    />
                    {
                        valueErrors.field === "new-password" &&
                        <p className={"error-message"}>{valueErrors.message}</p>
                    }
                </div>

                <div className="flex1">
                    <div className="Create">Confirm New Password
                    </div>
                    <input
                        type="password"
                        placeholder="Re-Type your new password"
                        className="code password"
                        required
                        value={confirmpassowrd}
                        onChange={(e) => setConfirmpassword(e.target.value)}
                    />
                    {
                        valueErrors.field === "confirm-password" &&
                        <p className={"error-message"}>{valueErrors.message}</p>
                    }
                </div>
                <div className={'buttons'}>
                    <div className={'test'}>
                        <BasicButton>Cancel</BasicButton>
                    </div>
                    <div onClick={() => submitPasswordChange()}>
                        <BasicButton>Change Password</BasicButton>
                    </div>
                </div>
            </div>
        </ProfileNavbar>
            <Footer/>
        </>
    );
}
