/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type NotificationType = "CONSULTATION" | "ENROLLMENT" | "REMINDER" | "REVIEW" | "%future added value";
export type GetNotificationsQueryVariables = {||};
export type GetNotificationsQueryResponse = {|
  +getNotifications: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +message: string,
        +createdAt: any,
        +id: string,
        +isRead: boolean,
        +updatedAt: any,
        +type: NotificationType,
        +relatedId: ?string,
      |}
    |}>
  |}
|};
export type GetNotificationsQuery = {|
  variables: GetNotificationsQueryVariables,
  response: GetNotificationsQueryResponse,
|};
*/


/*
query GetNotificationsQuery {
  getNotifications {
    edges {
      node {
        message
        createdAt
        id
        isRead
        updatedAt
        type
        relatedId
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "NotificationNodeConnection",
    "kind": "LinkedField",
    "name": "getNotifications",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NotificationNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NotificationNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isRead",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "relatedId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetNotificationsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GetNotificationsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "6df183528c6d67edd6e962326ed6325d",
    "id": null,
    "metadata": {},
    "name": "GetNotificationsQuery",
    "operationKind": "query",
    "text": "query GetNotificationsQuery {\n  getNotifications {\n    edges {\n      node {\n        message\n        createdAt\n        id\n        isRead\n        updatedAt\n        type\n        relatedId\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '86cb9739314c9d8606e912e753e18d43';

module.exports = node;
