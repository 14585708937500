import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'

const mutation = graphql`
    mutation DeleteAttachmentsMutation(
        $attachmentIds:[ID]
    ) {
        deleteAttachments(attachmentIds:$attachmentIds){
            status
            message
        }
    }
`

export default (data,callback,errCallback) => {
    const variables = {
        attachmentIds:data.attachmentIds
    }
    // 5
    commitMutation(
        environment,
        {
            mutation,
            variables,
            // 6
            onCompleted: (response,err) => {

                if(response.deleteAttachments !== null){
                    callback(response)
                } else{
                    errCallback(err[0].message);
                }

            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}
