import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import'bootstrap-css-only/css/bootstrap.min.css';
import'mdbreact/dist/css/mdb.css';
import "video-react/dist/video-react.css";
import "react-datetime/css/react-datetime.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {TransitionGroup,CSSTransition} from "react-transition-group";
import {withRouter} from "react-router-dom";

import {Provider} from 'react-redux'
import {createStore,applyMiddleware } from 'redux'
import rootReducer from './Reducers'
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {PersistGate} from 'redux-persist/integration/react';
import { devToolsEnhancer} from 'redux-devtools-extension';
import thunk from "redux-thunk";

// import 'react-accessible-accordion/dist/fancy-example.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// fonts
import './assets/fonts/Gilroy/Gilroy-Bold.ttf';
import './assets/fonts/Gilroy/Gilroy-Medium.ttf';
import './assets/fonts/Gilroy/Gilroy-SemiBold.ttf';
import './assets/fonts/Gilroy/Gilroy-Regular.ttf';

import './assets/fonts/Inter/Inter-Light.ttf';
import './assets/fonts/Inter/Inter-Regular.ttf';
import './assets/fonts/Inter/Inter-Medium.ttf';
import './assets/fonts/Inter/Inter-SemiBold.ttf';

import './assets/fonts/Recoleta/Recoleta-Bold.ttf';

import './assets/fonts/Poppins/Poppins-Medium.ttf';
import './assets/fonts/Poppins/Poppins-Black.ttf';






import reportWebVitals from './reportWebVitals';
import Header from "./Containers/Header/Header";
import {Course} from "./Pages/CoursePage/Course";
import Homepage from "./Pages/Homepage/Homepage";
import {Blog} from "./Pages/BlogPage/Blog";
import Wishlist from "./Pages/wishlist/Wishlist";
import {Contact} from "./Pages/Contact/Contact";
import {ProfessorList} from "./Pages/ProfessorList/ProfessorList";
import {Footer} from "./Containers/Footer/Footer"
import ProfessorsDetail from "./Pages/ProfessorsDetail/ProfessorsDetail";
import CartComponent from "./Pages/Cart/Cart";
import {AboutUs} from "./Pages/AboutUs/AboutUs";


import {Consultation} from "./Pages/Consultation/Consultation";
import CourseIndex from "./Pages/Courses/CourseIndex";
import Payment from "./Pages/Cart/Payment";
import OrderConfirmation from "./Pages/OrderConfirmation/OrderConfirmation";
import CourseList from "./Pages/CourseList/CourseList";
import {EditProfile} from "./Pages/profilePages/EditProfile/EditProfiles";
import MyCourses from "./Pages/profilePages/MyCourses/MyCourses";
import {PaymentHistory} from "./Pages/profilePages/PaymentHistory/PaymentHistory";
import {Reviews} from "./Pages/profilePages/Reviews/Reviews";
import {ReferalsRedeem} from "./Pages/profilePages/ReferalsRedeem/ReferalsRedeem";
import {ChangePassWord} from "./Pages/profilePages/ChangePassword/ChangePassword";
import {UpComingConsultations} from "./Pages/profilePages/UpCommingConsultations/UpComingConsultations";
import {ViewDetails} from "./Pages/profilePages/ViewDetails/ViewDetails";
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import ProfessorProfile from "./Pages/ProfessorsPanel/Profile/ProfessorProfile";
import Consultations from "./Pages/ProfessorsPanel/Consulations/Consultations";
import ProfessorsCourses from "./Pages/ProfessorsPanel/Courses/ProfessorsCourses";
import Dashboard from "./Pages/ProfessorsPanel/Dashboard/Dashboard";
import CourseDetail from "./Pages/ProfessorsPanel/Courses/CourseDetail/CourseDetail";
import {Test} from "./Pages/Test";
import TopHeader from "./Pages/ProfessorsPanel/TopHeader/TopHeader";
import {UploadVideoPdfContainer} from "./Pages/ProfessorsPanel/Courses/CourseDetail/ModulesInfo";


const persistConfig = {
    key: 'root',
    blacklist: ['ModalReducer'],
    storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer,applyMiddleware(thunk));

const persistor = persistStore(store);


const AnimatedSwitch = withRouter(({ location }) => (
    <>
        <div>
            <TransitionGroup>
                <CSSTransition
                    key={location.key}
                    classNames="fade"
                    timeout={450}
                >
                    <Switch location={location}>
                        <PublicRoute restricted={false} component={Homepage} path="/" exact />
                        <PublicRoute restricted={false} component={Homepage} path="/home" exact />
                        <PublicRoute restricted={false} component={CourseIndex} path="/course-preview/:id" exact />
                        <PrivateRoute component={Course} path="/course-detail/:courseId/:moduleId/:lessonId" exact />
                        <PublicRoute restricted={false} component={Blog} path="/blogs" exact />
                        <PrivateRoute restricted={false} component={Wishlist} path="/wishlist" exact />
                        <PublicRoute restricted={false} component={CourseList} path="/course-list" exact />
                        <PublicRoute restricted={false} component={Contact} path="/contactus" exact />
                        <PublicRoute restricted={false} component={ProfessorList} path="/professor-list" exact />
                        <PublicRoute restricted={false} component={ProfessorsDetail} path="/professor-detail/edit/:id" exact />
                        <PublicRoute restricted={false} component={CartComponent} path="/cart" exact />
                        <PublicRoute restricted={false} component={AboutUs} path="/aboutus" exact />
                        <PublicRoute restricted={false} component={Consultation} path="/consultation" exact />
                        <PublicRoute restricted={false} component={Consultation} path="/consultation/:id" exact />
                        <PrivateRoute component={Payment} path="/payment" exact />
                        <PrivateRoute component={OrderConfirmation} path="/order-confirmation" exact />
                        <PrivateRoute component={EditProfile} path="/myprofile" exact />
                        <PrivateRoute component={MyCourses} path="/myprofile/mycourses" exact />
                        <PrivateRoute component={PaymentHistory} path="/myprofile/payment-history" exact />
                        <PrivateRoute component={UpComingConsultations} path="/myprofile/consultations" exact />
                        <PrivateRoute component={Reviews} path="/myprofile/reviews" exact />
                        <PrivateRoute component={ReferalsRedeem} path="/myprofile/referral" exact />
                        <PrivateRoute component={ChangePassWord} path="/myprofile/change-password" exact />
                        <PrivateRoute component={ViewDetails} path="/myprofile/payment-history/view-details/:id" exact />


                        <PrivateRoute component={ProfessorProfile} path="/professor/profile" exact />
                        <PrivateRoute component={Consultations} path="/professor/consultations" exact />
                        <PrivateRoute component={ProfessorsCourses} path="/professor/courses" exact />
                        <PrivateRoute component={CourseDetail} path="/professor/courses/:id" exact />
                        <PrivateRoute component={Dashboard} path="/professor/dashboard" exact />
                        <PrivateRoute component={UploadVideoPdfContainer} path="/test" exact />
                    </Switch>
                </CSSTransition>
            </TransitionGroup>
        </div>

    </>

));

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router>
                <AnimatedSwitch/>
            </Router>
        </PersistGate>
    </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
