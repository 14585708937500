import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'

const mutation = graphql`
  mutation CreateReviewMutation(
    $content: String!
    $courseId: String!
    $rating: Int!
  ) {
     createReview(content:$content,courseId:$courseId,rating:$rating){
        status
      }
  }
`

export default (input, callback,errCallback) => {
    const variables = {
        content:input.content,courseId:input.courseId,rating:input.rating
    }

    // 5
    commitMutation(
        environment,
        {
            mutation,
            variables,
            // 6
            onCompleted: (response,err) => {

                if(response.createReview !== null){
                    callback(response)
                } else{
                    errCallback(err[0].message);
                }

            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}
