import {
    OPEN_MODAL, CLOSE_MODAL, SET_MODAL_TYPE
} from '../Actions';

const initialState = {
    isOpen: false,
    modal_type: 'login'
};

const ModalReducer = (state = initialState, action) => {
    switch (action.type) {

        case OPEN_MODAL:
            return {
                ...state,
                isOpen: true,
                modal_type: 'login'
            };
        case CLOSE_MODAL:
            return {
                ...state,
                isOpen: false,
                modal_type: 'login'
            };
        case SET_MODAL_TYPE:
            return {
                ...state,
                modal_type: action.payload.modal_type
            };
        default:
            return state
    }

};

export default ModalReducer;

