import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'

const mutation = graphql`
    mutation UpdateReviewMutation(
        $content:String!,
        $rating:Int!,
        $reviewId:String!
    )
    {
        updateReview(
            content:$content,
            rating:$rating,
            reviewId:$reviewId
        ){
            status
        }
    }
`

export default (userData, callback,errCallback) => {
    const variables = {
        content:userData.content,
        rating:userData.rating,
        reviewId: userData.reviewId
    }

    commitMutation(
        environment,

        {
            mutation,
            variables,
            onCompleted: (response,err) => {
                if(response.updateReview !== null){
                    callback(response)
                } else{
                    errCallback(err[0].message);
                }
            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}
