import { combineReducers } from 'redux'
import UserReducer from "./UserReducer";
import CartReducer from "./CartReducer";
import WishListReducer from "./WishListReducer";
import OrderReducer from "./OrderReducer";
import ProfessorCoursespageState from "./ProfessorCoursespageStateReducer"
import ModalReducer from './ModalReducer';


export default combineReducers({
    UserReducer,
    CartReducer,
    WishListReducer,
    OrderReducer,
    ProfessorCoursespageState,
    ModalReducer,
})
