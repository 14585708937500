import graphql from "babel-plugin-relay/macro";
const ProfileReviewsQuery=graphql`

    query ProfileReviewsQuery{
        myProfile{
            reviews{
                edges{
                    node{
                        rating
                        content
                        id
                        course{
                            name
                            instructor{
                                user{
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }

`
export default ProfileReviewsQuery