import {
  FETCH_CARTDATA_BEGIN,
    FETCH_CARTDATA_SUCCESS,
    FETCH_CARTDATA_FAILURE,
    CLEARCART
} from '../Actions/CartAction';



const initialState = {
    cart_data:{
        id:"",
        total:0,
        discount:0,
        discountName:null,
        voucherCode:null,
        checkoutitems:[]
    },
    loading: false,
    error: null
}


const CartReducer =  (state = initialState,action) => {
    switch (action.type) {


        case FETCH_CARTDATA_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_CARTDATA_SUCCESS:
            // All done: set loading "false".
            // Also, replace the items with the ones from the server
            return {
                ...state,
                loading: false,
                cart_data: action.payload.cart_data
            };
        case FETCH_CARTDATA_FAILURE:
            // The request failed. It's done. So set loading to "false".
            // Save the error, so we can display it somewhere.
            // Since it failed, we don't have items to display anymore, so set `items` empty.
            //
            // This is all up to you and your app though:
            // maybe you want to keep the items around!
            // Do whatever seems right for your use case.
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                cart_data: {}
            };
        case CLEARCART:
            return {
                cart_data:{
                    id:"",
                    total:0,
                    discount:0,
                    discountName:null,
                    voucherCode:null,
                    checkoutitems:[]
                },
                loading: false,
                error: null
            }
        default:
            return state;
    }

}

export default CartReducer;
