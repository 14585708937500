/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type VerifyOtpMutationVariables = {|
  mobileNumber?: ?string,
  otp?: ?string,
|};
export type VerifyOtpMutationResponse = {|
  +verifyOtp: ?{|
    +status: ?string,
    +message: ?string,
  |}
|};
export type VerifyOtpMutation = {|
  variables: VerifyOtpMutationVariables,
  response: VerifyOtpMutationResponse,
|};
*/


/*
mutation VerifyOtpMutation(
  $mobileNumber: String
  $otp: String
) {
  verifyOtp(mobileNumber: $mobileNumber, otp: $otp) {
    status
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "mobileNumber"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "otp"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "mobileNumber",
        "variableName": "mobileNumber"
      },
      {
        "kind": "Variable",
        "name": "otp",
        "variableName": "otp"
      }
    ],
    "concreteType": "VerifyOtpMutation",
    "kind": "LinkedField",
    "name": "verifyOtp",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VerifyOtpMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VerifyOtpMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fd61c939c1a7d0fdc5f707d3192af0e5",
    "id": null,
    "metadata": {},
    "name": "VerifyOtpMutation",
    "operationKind": "mutation",
    "text": "mutation VerifyOtpMutation(\n  $mobileNumber: String\n  $otp: String\n) {\n  verifyOtp(mobileNumber: $mobileNumber, otp: $otp) {\n    status\n    message\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c6beab90ef70ce49168b74a05f67cd16';

module.exports = node;
