/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactUsMutationVariables = {|
  email: string,
  name: string,
  message: string,
  number: string,
|};
export type ContactUsMutationResponse = {|
  +contactUs: ?{|
    +message: ?string,
    +status: ?boolean,
  |}
|};
export type ContactUsMutation = {|
  variables: ContactUsMutationVariables,
  response: ContactUsMutationResponse,
|};
*/


/*
mutation ContactUsMutation(
  $email: String!
  $name: String!
  $message: String!
  $number: String!
) {
  contactUs(email: $email, mobileNumber: $number, name: $name, message: $message) {
    message
    status
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "message"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "number"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "message",
        "variableName": "message"
      },
      {
        "kind": "Variable",
        "name": "mobileNumber",
        "variableName": "number"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      }
    ],
    "concreteType": "ContactUsMutation",
    "kind": "LinkedField",
    "name": "contactUs",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ContactUsMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "ContactUsMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "e87bbff4b0fe27e5da282f8018044a4d",
    "id": null,
    "metadata": {},
    "name": "ContactUsMutation",
    "operationKind": "mutation",
    "text": "mutation ContactUsMutation(\n  $email: String!\n  $name: String!\n  $message: String!\n  $number: String!\n) {\n  contactUs(email: $email, mobileNumber: $number, name: $name, message: $message) {\n    message\n    status\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0f0ff69cfd2ba8fe17ee2cc4ae7bd925';

module.exports = node;
