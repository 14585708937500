import graphql from "babel-plugin-relay/macro";
const ProfessorListQuery=graphql`

 query ProfessorListQuery{
  allInstructors{
    edges {
      node {
        id
        profilePic
        designation
        organization
        user{
          id
          name
        }
      }
    }
  }
}

`
export default ProfessorListQuery