import "./CoursePage.scss";
import CoursePreviewCard from "../../Containers/CoursePreviewCard/CoursePreviewCard";
import {VideoPlayer} from "../../Containers/VideoPlayer/VideoPlayer";
import {AboutCourse} from "../../Containers/AboutCourse/AboutCourse";
import {LayoutContainer} from "../../Components/LayoutContainer/LayoutContainer";
import CourseAccordian from "../../Containers/CourseAccordian/CourseAccordian";
import Group19880 from "../../assets/images/Group19880.png";
import LeftArrow from "../../assets/images/left-Arrow.png";
import yellowrightArrow from "../../assets/images/Right-Arrow.png";
import user from "../../assets/images/user1.png";
import {useEffect, useState} from "react";
import {RoundedButton} from "../../Components/Buttons/RoundedButton";
import smallUser from "../../assets/images/smallUser.png";
import Cart1 from "../../assets/images/cart.png";
import whiteTopArrow from '../../assets/images/whiteTopArrow.png'
import leftSmallarrow from '../../assets/images/left-Small-arrow.png'
import CourseCard from "../../Containers/CourseCard/CourseCard";
import AddToCartMutation from "../../mutations/AddToCartMutation";
import cogoToast from "cogo-toast";
import {fetchCartListSuccess} from "../../Actions/CartAction";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {connect, useSelector} from "react-redux";
import uuid from 'react-uuid'
import { MDBModal, MDBModalBody } from "mdbreact";
import Login from "../login/Login";
import Singup from "../singup/Singup";

const CoursePage = (props) => {
    const [courseDeatil,setCourseDetail] = useState({});
    const [modules,setModules] = useState([]);
    const [reviews,setReviews] = useState([]);
    const [relatedCourses,setRelatedCourses] = useState([]);
    const [aboutCourse,setAboutCourse] = useState({});
    const [getPreviewVideo,setPreviewVideo]=useState([])

// console.log(relatedCourses)
    useEffect(function (){
        let tmp_course_deatil = props.courseDeatil;
        if (Object.keys(tmp_course_deatil).length > 0){
            // debugger
            setCourseDetail(tmp_course_deatil);
            if(tmp_course_deatil.modules && tmp_course_deatil.modules.edges && tmp_course_deatil.modules.edges.length > 0){
                setModules(tmp_course_deatil.modules.edges);
            }
            if(tmp_course_deatil.reviews && tmp_course_deatil.reviews.edges && tmp_course_deatil.reviews.edges.length > 0){
                setReviews(tmp_course_deatil.reviews.edges);
            }
            if(tmp_course_deatil.instructor && tmp_course_deatil.instructor.courses && tmp_course_deatil.instructor.courses.edges.length > 0){
                setRelatedCourses(tmp_course_deatil.instructor.courses.edges);
            }
            if(tmp_course_deatil.about !== ""){
                let course_description = tmp_course_deatil.about;
                let aboutCourse = JSON.parse(course_description);
                let aboutDataFormate = {
                    course_name:tmp_course_deatil.name,
                    course_description:aboutCourse.blocks && aboutCourse.blocks[0] ? aboutCourse.blocks[0].data.text:""
                }
                setAboutCourse(aboutDataFormate);
            }

        }
    },[props.courseDeatil]);

    const updateCourseList = (course,booleanValue) => {
        let tmp_course_detail = {
            ...courseDeatil
        };
        tmp_course_detail.isWishlist =  booleanValue;
        setCourseDetail(tmp_course_detail);
    }

    const addToCart = () => {
        let variables = {
            courseId: courseDeatil.id
        }
        AddToCartMutation(variables,function (response){
            if (response.addToCart !== null) {
                let dataFormate = {
                    id:response.addToCart.cart.id,
                    total:parseInt(response.addToCart.cart.total),
                    discount:parseInt(response.addToCart.cart.discount),
                    discountName:response.addToCart.cart.discountName,
                    checkoutitems:response.addToCart.cart.checkoutitems.edges
                }
                props.cartListSuccess(dataFormate);
                cogoToast.success("Course Added To cart successfully", {position: 'top-center'});
            } else {
                cogoToast.success("Course Not Added To cart", {position: 'top-center'});
            }
        },function (err){
            cogoToast.error("Course Not Added To cart", {position: 'top-center'});
        })

    }

// if (true){
//     debugger
//    console.log(courseDeatil.modules)
// }

    // tmp_ongoing_courses = response.myEnrolls.edges.filter(function (course) {
    //     return course.node.status === "NOT_STARTED" || course.node.status === "STARTED";
    // });

useEffect(()=>{
    if (courseDeatil.modules) {
        // debugger
        // const NoOfModules=courseDeatil.modules.edges.length
        courseDeatil.modules.edges.map((item, index) => {
            // debugger
         item.node.lessons.edges.map((items,indexs)=>{
            if (items.node.isFree){
                setPreviewVideo((prevState )=>[...prevState,items.node.video] )

                // console.log(items.node.video)
            }
             return
         })
        })
    }
},[courseDeatil])
    return (
        <div className="CoursePage">
            {
                courseDeatil.modules &&
                <div className="video1">
                    <VideoPlayer videoUrl={courseDeatil.previewVideo} hidePreviewContent={true} />
                </div>
            }
            <div className="fixed1" id={'previewcard'}>
                <CoursePreviewCard courseData={courseDeatil} updateCourseList={updateCourseList}  />
            </div>
            {
                Object.keys(aboutCourse).length > 0 &&
                <About aboutCourseTitle={aboutCourse} aboutCourse={props?.courseDeatil?.about}/>
            }
            {
                modules.length > 0 &&
                <CourseAccordian1 courseData={courseDeatil} modules={modules}/>
            }
            {
                reviews.length > 0 &&
                <OurStudentsWords
                    reviewData={reviews}
                />
            }
            {
                relatedCourses.length > 0 &&
                <RelatedCourses courses={relatedCourses} onClick={()=>props.history.push('/course-list')}/>
            }

            <MobilAddCart courseData={courseDeatil} addToCart={addToCart}/>
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    cartListSuccess: (data) => dispatch(fetchCartListSuccess(data)),

})
export default compose(
    withRouter,
    connect(null, mapDispatchToProps)
)(CoursePage);


const About = (props) => {
    const {aboutCourseTitle ,aboutCourse} = props;
    return (
        <div className="About">
            <LayoutContainer>
                <p className="mainTitle">{aboutCourseTitle?.course_name}</p>
                <AboutCourse course_description={aboutCourse}/>
            </LayoutContainer>
        </div>
    );
};

const OurStudentsWords = (props) => {
    const [activeReview, setActiveReview] = useState(0);
    const {reviewData} = props;
    return (
        <LayoutContainer>
            <div className="OurStudentsWords">
                <p className="title">Our Students Words</p>
                {
                    reviewData.map((item,index)=>{
                        return(
                            <div className="card" key={uuid()} style={{display:activeReview===index?"block":"none"}}>
                                <div className="row">
                                    <img src={Group19880} alt="Group19880" className="Group19880"/>
                                    <div className="col-12 Details">
                                        <div
                                            className="StudentWordscard"
                                            data-aos="zoom-in"
                                            data-aos-duration="800"
                                        >
                                            <img
                                                src={item?.node?.user?.profile?.profilePic}
                                                className="style1"
                                                alt="style for student tag"
                                                style={{width:'50px',height:'50px',borderRadius:'100%'}}
                                            />
                                            <span className="span-studentName">
                                              {item.node.user.name}
                                            </span>
                                            <p className="studentWords">
                                                {item.node.content}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
                <div className="d-flex leftrightArrow">
                    <div
                        className="nextstudentbtn"
                        onClick={() => (activeReview > 0 ? setActiveReview(activeReview - 1) : setActiveReview(0))}
                    >
                        <img src={LeftArrow} className="LeftArrow" alt="LeftArrow"/>
                    </div>
                    <div className="nextstudentbtn" onClick={() => {
                        if(activeReview<reviewData.length-1){
                            setActiveReview(activeReview + 1)
                        }
                    }}>
                        <img
                            src={yellowrightArrow}
                            className="yellowrightArrow"
                            alt="yellowrightArrow"
                        />
                    </div>
                </div>
            </div>
        </LayoutContainer>
    );
};

const RelatedCourses = (props) => {
    const {courses,onClick} = props;
    return (
        <LayoutContainer>
            <div className="RelatedCourses">
                <div className="d-flex justify-content-between">
                    <div className="main-title">

                        <p className="title">Related Courses</p>
                    </div>
                    <RoundedButton handleClick={onClick}>
                        View All
                        <img src={leftSmallarrow} className='rightArrow pl-2' alt='rightArrow'/>
                    </RoundedButton>
                </div>

                <div className="row CourseCardall pb-3">
                    {
                        courses.map((item, index) => {
                            return (
                                <div className="col-6" key={uuid()}>
                                    <CourseCard courseData={item.node}/>
                                </div>
                            );
                        })
                    }


                </div>

            </div>
        </LayoutContainer>
    );
};



const MobilAddCart = (props) => {
    const {courseData,addToCart} = props;
    const logged_in = useSelector((state)=>state.UserReducer.logged_in)
    const [modelOpen, setmodelOpen] = useState(false);
    const [modalContent, setModalContent] = useState('login');

    const toggleModal = () => {
        setmodelOpen(!modelOpen);
    }

    return (
        <div className="mobile-add-to-cart">
        <div className="mobilAddCart d-flex">
          {/*  <div className="name">*/}
          {/*      <p className="m-0 in-name">{courseData.name}</p>*/}
          {/*      <p className="m-0 pb-2">*/}
          {/*<span>*/}
          {/*  <img src={smallUser} className="smallUser" alt="smallUser"/>*/}
          {/*</span>{" "}*/}
          {/*          {courseData.instructor && courseData.instructor.user ?courseData.instructor.user.name:""}*/}
          {/*      </p>*/}
          {/*  </div>*/}
            <div className="cart"  onClick={()=>logged_in?addToCart(): setmodelOpen(true)}>
                <p>
                  <span>
                    <img src={Cart1} className="inCart pr-2" alt="Cart"/>
                  </span>
                    Add to Cart
                </p>
            </div>
        </div>
        <MDBModal isOpen={modelOpen} toggle={toggleModal} centered size="fluid">
                <MDBModalBody>
                    {
                        modalContent === "login" ?
                            <Login setlogin={setModalContent} closeLogin={toggleModal} /> :
                            <Singup setlogin={setModalContent} closeLogin={toggleModal} />
                    }
                </MDBModalBody>
            </MDBModal>
            </div>
    );
};


const CourseAccordian1 = (props) => {
    const {courseData,modules} = props;


    const handleOnChange = () => {        
        let element=document.getElementsByClassName("accordion__button");
        for (let i = 0; i < element.length; i++) {
            element[i].click()
        }
    };
    return (
        <LayoutContainer>
            <div className='CourseAccordian1'>
                <div className='title d-flex'>
                    <div>
                        Course Content
                        <p className='pt-2 para'>{courseData.totalModules} modules • {courseData.totalLectures} lectures • {courseData.totalDuration} m total length</p>
                    </div>
                    <div className='button1'>
                        <RoundedButton handleClick={handleOnChange}>
                            Expand / Collapse All
                            {/* Collapse All modules */}
                            <img src={whiteTopArrow}/>
                        </RoundedButton>
                    </div>
                </div>
                <CourseAccordian isExpandAll={true} preview={true} modules={modules} courseData={courseData} />
            </div>
        </LayoutContainer>
    );
}
