import React from "react";
import "./BasicButton.scss"
import { MDBBox } from 'mdbreact';
import PropTypes from "prop-types";
import {FlatButton} from "./FlatButton";


export const BasicButton = (props) => {
    const {handleClick,children,disabled} = props;
    return(
        <MDBBox className={"basic-button-component"}>
            <button type={"button"} className={"btn"} onClick={handleClick} disabled={disabled}>
                {children}
            </button>
        </MDBBox>
    );
}

BasicButton.propTypes = {
    handleClick: PropTypes.func,
    children: PropTypes.string,
};



