import "./Wishlist.scss"
import React, {useEffect, useState} from "react";
// import {CourseCard} from "../Homepage/Homepage";
import star from "../../assets/images/Star.png";
import redheart from "../../assets/images/redheart.png";
import heart from "../../assets/images/heart.png";

import smallUser from "../../assets/images/smallUser.svg";
import CartIcon from "../../assets/icons/white-cart-icon.svg";
import useMediaQuery from "../../util/useMediaQuery";
import {LayoutContainer} from "../../Components/LayoutContainer/LayoutContainer";
import {withRouter} from "react-router-dom";
import {compose} from 'redux';
import {fetchQuery} from "relay-runtime";
import environment from "../../Environment";
import MyWishListQuery from "../../queries/MyWishListQuery";
import {connect} from 'react-redux'
import {fetchWishListBegin, fetchWishListSuccess, fetchWishListFailure} from "../../Actions/wishListAction";
import RemoveFromWishListQuery from "../../queries/RemoveFromWishListQuery";
import cogoToast from "cogo-toast";
import Loader from "../../Components/Loader/Loader";
import ErrorMessage from "../../Components/ErrorMessage/ErrorMessage";
import AddToCartMutation from "../../mutations/AddToCartMutation";
import {fetchCartListSuccess} from "../../Actions/CartAction";
import uuid from 'react-uuid'
import Header from "../../Containers/Header/Header";
import {Footer} from "../../Containers/Footer/Footer";
import emptycart_img from "../../assets/icons/emptyCart.svg";
import CourseCardBg from "../../assets/images/coursecard-bg.png";

const Wishlist = (props) => {

    useEffect(() => {
        getMyWishList();
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const getMyWishList = () => {
        props.beginWishList();
        fetchQuery(environment, MyWishListQuery)
            .toPromise()
            .then(response => {
                if (response.myWishList !== null) {
                    props.wishListSuccess(response.myWishList);
                } else {
                    let error = {
                        message: "No Data Found"
                    }
                    props.wishListFailure(error);
                }
            }, err => {
                let error = {
                    message: "No Data Found"
                }
                props.wishListFailure(error);
            });
    }

    const removeFromWishList = (e, course) => {
        e.stopPropagation();
        let variables = {
            courseId: course.id
        }
        fetchQuery(environment, RemoveFromWishListQuery, variables)
            .toPromise()
            .then(response => {
                if (response.removeFromWishList !== null) {
                    props.wishListSuccess(response.removeFromWishList);
                    cogoToast.success("Course Removed From wishlist successfully", {position: 'top-center'});
                } else {
                    cogoToast.info("Course Not Removed From wishlist", {position: 'top-center'});
                }
            }, err => {
                cogoToast.error("Course Not Removed From wishlist", {position: 'top-center'});
            });
    }
    const addToCart = (e, course) => {
        e.stopPropagation();
        let variables = {
            courseId: course.id
        }
        AddToCartMutation(variables,function (response){
            if (response.addToCart !== null) {
                let dataFormate = {
                    id:response.addToCart.cart.id,
                    total:parseInt(response.addToCart.cart.total),
                    discount:parseInt(response.addToCart.cart.discount),
                    discountName:response.addToCart.cart.discountName,
                    checkoutitems:response.addToCart.cart.checkoutitems.edges
                }
                props.cartListSuccess(dataFormate);
                cogoToast.success("Course Added To cart successfully", {position: 'top-center'});
            } else {
                cogoToast.info("Course Not Added To cart", {position: 'top-center'});
            }
        },function (err){
            cogoToast.error("Course Not Added To cart", {position: 'top-center'});
        })

    }

    const goToPreview = (courseId) => {
        props.history.push("/course-preview/"+courseId);
    }

    const {error, loading, wishlist_data} = props;



    if (loading) {
        return (
            <div>
                <Loader full={true} />
                <div  style={{height:window.innerHeight}}/>
            </div>
        );
    }

    return (
        <>
            <Header page={"wishlist"}/>
            <div className={'wishlist-component'}>
            <div className={'header header-font'}>
                Wishlist
            </div>
            <LayoutContainer>
                {
                    wishlist_data.length > 0 ? (
                        <div className="row CourseCardall w-100">        
                            {
                                wishlist_data.map((item, index) => {
                                    return (
                                        <div className="col-md-6 col-lg-4 col-xl-4  grid-column" key={uuid()}>
                                            <CourseCard
                                                courseData={item}
                                                removeFromWishList={removeFromWishList}
                                                goToPreview={goToPreview}
                                                addToCart={addToCart}
                                            />
                                        </div>
                                    );
                                })
                            }                      
                        </div>
                    ) : (
                        <ErrorMessage img={emptycart_img} title={"Your Whislist is empty!"} text={"Looks like you haven’t added anything to your wishlist yet!"} onclickhandeler={`/course-list`}/>
                    )
                }
                
                {/*{*/}
                {/*    wishlist_data.length === 0 &&*/}
                {/*    <div className={"empty-message"}>*/}
                {/*        No Courses Added in Wish List*/}
                {/*    </div>*/}
                {/*}*/}
            </LayoutContainer>
        </div>
            <Footer/>
        </>
    );
}

const mapStateToProps = state => ({
    wishlist_data: state.WishListReducer.wishlist_data,
    loading: state.WishListReducer.loading,
    error: state.WishListReducer.error
});

const mapDispatchToProps = dispatch => ({
    beginWishList: () => dispatch(fetchWishListBegin()),
    wishListSuccess: (data) => dispatch(fetchWishListSuccess(data)),
    wishListFailure: (error) => dispatch(fetchWishListFailure(error)),
    cartListSuccess: (data) => dispatch(fetchCartListSuccess(data)),

});


export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Wishlist);

const CourseCard = (props) => {
    const isDesktop = useMediaQuery("(min-width: 770px)");
    const {courseData, removeFromWishList, goToPreview,addToCart} = props;
    return (
        // <Link to={"/course-preview"}>
        <div className="CourseCard p-2" onClick={()=>goToPreview(courseData.id)}>
            <div className="bg" style={{backgroundImage: `url(${courseData.image?courseData.image:CourseCardBg})`}}>
                <div className="star1 py-2 m-2">
                    <img src={star} className="Star" alt="Star"/>
                    {courseData.averageRating}
                </div>
                {/* {isDesktop ? <></> : <span className={'mb_saler'}>
        Best Seller
                    </span>} */}

                <div className="heart1">
                    {isDesktop ? <img src={courseData.isWishlist ? redheart : heart}
                                      onClick={(e) => removeFromWishList(e, courseData)}
                                      className="heart" alt="heart"
                    /> : <></>}
                </div>
            </div>
            <div className={"content-section"}>
                <div className="courseName">{courseData.name}
                </div>
                <div className="parentUser">
                    <img src={smallUser} className="smallUser" alt="smallUser"/>
                    {courseData.instructor && courseData.instructor.user ? courseData.instructor.user.name : ""}
                </div>
                <div className={"price-section"}>
                    <div className="RateTage">
                        {
                            courseData.discountPrice
                        }
                        <del className="realRate">
                            {
                                courseData.price
                            }
                        </del>
                        {" "}
                        {/* {isDesktop ? <span className="tag">Best Seller</span> : <></>} */}
                    </div>
                    {
                        !isDesktop  &&
                        <button type={"button"} className={'mb_cart'}
                                onClick={(e) => removeFromWishList(e, courseData)}
                        >
                            <u>Remove from Wishlist</u>
                        </button>
                    }
                </div>
                {
                    isDesktop &&
                    <button type={"button"} className={"btn cart-btn"} onClick={(e)=>addToCart(e, courseData)}>
                        <img src={CartIcon} className="inCart" alt="Cart"/>
                    </button>

                }

            </div>
        </div>
        // </Link>
    );
};
