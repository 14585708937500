import "./Singup.scss";
import yellow_crt_btn from "../../assets/images/yellow_crt_btn.png";
import VerificationInActive from "../../assets/images/verification-inactive.png";
import BasicDeatilsActive from "../../assets/images/basic-details-active.png";
import BackgroundProfileActive from "../../assets/images/background-profile-active.png";

import green_crt_btn from "../../assets/icons/akar-icons_circle-check-fill-green.svg";
import Detail from "../../assets/images/Details.png";
import profile from "../../assets/images/profile.png";
import rightArrow from "../../assets/images/rightArrow.png";
// import filled_profile from "../../assets/images/filled_profile.png";
// import stroke from "../assets/images/stroke.png";
import close_bt from "../../assets/images/close_bt.png";
// import filled_Details from "../assets/images/filled_Details.png";
import singnup_created_img from "../../assets/images/singnup_created_img.png";
import redStar from "../../assets/icons/_.svg";
import {Fragment, useContext, useEffect, useState} from "react";
import useMediaQuery from "../../util/useMediaQuery";
import * as EmailValidator from 'email-validator';
import SentOtpMutation from "../../mutations/SentOtpMutation";
import cogoToast from 'cogo-toast';
import VerifyOtpMutation from "../../mutations/VerifyOtpMutation";
import RegisterUserMutation from "../../mutations/RegisterUserMutation";
// import LoadingScreen from "react-loading-screen";
import CompleteProfileMutation from "../../mutations/CompleteProfileMutation";
import { connect } from 'react-redux'
// import AuthContext from "../../Providers/AuthProvider/auth-context";
import {UserLoggedin} from "../../Actions";

const Singup = (props) => {
    const [loginStep, setLoginstep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [completed_profile, setCompletedProfile] = useState(false);
    const [step2Data,setStep2Data] = useState({});
    const [userID,setUserID] = useState('');
    const [getStep1,setStep1]=useState(false);
    const [getStep2,setStep2]=useState(false);
    // const [getStep3,setStep3]=useState(false);



    return (
        // <LoadingScreen
        //     loading={loading}
        //     bgColor='#ffffffbf'
        //     spinnerColor='#000'
        //     textColor='#676767'
        //     // logoSrc='https://upload.wikimedia.org/wikipedia/en/thumb/4/41/ITC_Classmate_logo.png/613px-ITC_Classmate_logo.png'
        //     text="Loading"
        // >
            <div className="Singup">
            {loginStep === 1 &&
            <Step1
                setlogin={props.setlogin}
                setLoginstep={setLoginstep}
                closeLogin={props.closeLogin}
                setLoading={setLoading}
                setUserID={setUserID}
                getStep1={getStep1}
                setStep1={setStep1}
            />
            }
            {
                loginStep === 2 &&
                    <Step2
                        setLoginstep={setLoginstep}
                        closeLogin={props.closeLogin}
                        setLoading={setLoading}
                        setStep2Data={setStep2Data}
                        getStep1={getStep1}
                        getStep2={getStep2}
                        setStep2={setStep2}
                    />



            }
            {
                loginStep === 3 && completed_profile === false &&
                <Step3
                    setLoginstep={setLoginstep}
                    closeLogin={props.closeLogin}
                    setLoading={setLoading}
                    setCompletedProfile={setCompletedProfile}
                    step2Data={step2Data}
                    userID={userID}
                    sendUserDatasToStore={props.sendUserDatasToStore}
                    getStep2={getStep2}
                    getStep1={getStep1}
                    // setStep3={setStep3}
                />
            }
                {
                    completed_profile === true &&
                    <Final
                        closeLogin={props.closeLogin}
                    />

                }
        </div>
        // </LoadingScreen>
    );
};

const Step1 = (props) => {
    const isDesktop = useMediaQuery("(min-width: 765px)");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [otp, setOtp] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [mobileNumberverified, setMobileNumberverified] = useState(false);

    const [codeverified, setCodeverified] = useState(false);
    const [valueErrors, setValueErrors] = useState({});


    const registerUser = () => {
        let error = {};
        if (EmailValidator.validate(email) === false) {
            error = {
                field: "email",
                message: "Please fill valid email"
            };
            setValueErrors(error);
        } else if (mobileNumber.length < 10) {
            error = {
                field: "mobileNumber",
                message: "Mobile number should be 10 digits"
            };
            setValueErrors(error);
        } else if (mobileNumber === "") {
            error = {
                field: "mobileNumber",
                message: "Mobile number can't be empty"
            };
            setValueErrors(error);
        } else if (otp.length < 4) {
            error = {
                field: "otp",
                message: "OTP number should be 4 digits"
            };
            setValueErrors(error);
        } else if (otp === "") {
            error = {
                field: "otp",
                message: "OTP number can't be empty"
            };
            setValueErrors(error);
        }  else if (password === "") {
            error = {
                field: "password",
                message: "password can't be empty"
            };
            setValueErrors(error);
        } else if (confirmPassword === "") {
            error = {
                field: "confirmPassword",
                message: "confirm password can't be empty"
            };
            setValueErrors(error);
        }
        else if (confirmPassword !== password) {
            error = {
                field: "confirmPassword",
                message: "password not matching"
            };
            setValueErrors(error);
        } else if(mobileNumberverified === false) {
            error = {
                field: "mobileNumber",
                message: "please verify your Mobile Number to continue"
            };
            setValueErrors(error);
        }
        else if(codeverified === false) {
            error = {
                field: "otp",
                message: "please verify your Mobile Number & OTP to continue"
            };
            setValueErrors(error);
        } else {
            let userData = {
                email: email,
                mobileNumber: mobileNumber,
                password: password,
                passwordConfirm: confirmPassword,
                referedBy: ""
            };
            error = {};
            setValueErrors(error);
            // props.setLoading(true);
            RegisterUserMutation(userData, function (response) {
                // props.setLoading(false);
                if (response.registerUser !== null && response.registerUser.user !== null) {
                    props.setStep1(true)
                    props.setLoginstep(2);
                    props.setLoginstep(2);

                    props.setUserID(response.registerUser.user.id);
                    cogoToast.success(response.registerUser.message, {position: 'top-center'});
                } else {
                    cogoToast.error("something went wrong", {position: 'top-center'});
                }
            }, function (err) {
                // props.setLoading(false);
                cogoToast.error(err, {position: 'top-center'});
            });
        }
    };

    const verifyOtp = () => {
        let error = {};
        if (EmailValidator.validate(email) === false) {
            error = {
                field: "email",
                message: "Please fill valid email"
            };
            setValueErrors(error);
        } else if (mobileNumber.length < 10) {
            error = {
                field: "mobileNumber",
                message: "Mobile number should be 10 digits"
            };
            setValueErrors(error);
        } else if (mobileNumber === "") {
            error = {
                field: "mobileNumber",
                message: "Mobile number can't be empty"
            };
            setValueErrors(error);
        } else if (otp.length < 4) {
            error = {
                field: "otp",
                message: "OTP number should be 4 digits"
            };
            setValueErrors(error);
        } else if (otp === "") {
            error = {
                field: "otp",
                message: "OTP number can't be empty"
            };
            setValueErrors(error);
        } else {
            let userData = {
                'mobileNumber': mobileNumber,
                'otp': otp
            };
            error = {};
            setValueErrors(error);
            // props.setLoading(true);
            VerifyOtpMutation(userData, function (response) {
                // props.setLoading(false);
                if (response.verifyOtp !== null) {
                    setCodeverified(true);
                    cogoToast.success(response.verifyOtp.message, {position: 'top-center'});

                } else {
                    setCodeverified(false);
                    cogoToast.error("something went wrong", {position: 'top-center'});
                }
            }, function (err) {
                // props.setLoading(false);
                setCodeverified(false);
                cogoToast.error(err, {position: 'top-center'});
            });
        }
    };

    const verifyMobileNumber = () => {
        let error = {};
        if (EmailValidator.validate(email) === false) {
            error = {
                field: "email",
                message: "Please fill valid email"
            };
            setValueErrors(error);
        } else if (mobileNumber.length < 10) {
            error = {
                field: "mobileNumber",
                message: "Mobile number should be 10 digits"
            };
            setValueErrors(error);
        } else if (mobileNumber === "") {
            error = {
                field: "mobileNumber",
                message: "Mobile number can't be empty"
            };
            setValueErrors(error);
        } else {
            let userData = {
                'mobileNumber': mobileNumber,
            };
            error = {};
            setValueErrors(error);
            // props.setLoading(true);
            SentOtpMutation(userData, function (response) {
                // props.setLoading(false);
                if (response.sentOtp !== null && response.sentOtp.otp) {
                    setMobileNumberverified(true);
                    cogoToast.success(response.sentOtp.otp, {position: 'top-center'});

                } else {
                    setMobileNumberverified(false);
                    cogoToast.error("something went wrong", {position: 'top-center'});
                }
            }, function (err) {
                // props.setLoading(false);
                setMobileNumberverified(false);
                cogoToast.error(err, {position: 'top-center'});
            });

        }
    }

    const handleMobileNumberChange = (e) => {
        let value = (e.target.validity.valid || e.target.value === "") ? e.target.value : mobileNumber;
        setMobileNumber(value);
    }

    const handleOTPChange = (e) => {
        let value = (e.target.validity.valid || e.target.value === "") ? e.target.value : otp;
        setOtp(value);
    }

    return (
        <div
            className="step1 row"
            // data-aos="fade-left"
            // data-aos-anchor="#example-anchor"
            // data-aos-offset="500"
            // data-aos-duration="500"
        >
            <img src={close_bt} className="close_bt" alt="close_bt" onClick={props.closeLogin}/>
            <div className="col-md-2 col-12 p-0">
                <FormStepper activeStep={1} handleClick={props.setLoginstep}/>
            </div>
            <div className="col-12 col-md-8 inputfield">
                <div className="title">Sign up & Start learning!</div>
                {/* <span className="span-ref">From Referral: Rakesh mehra</span> */}
                <form>
                    <div className={"form-feild-item"}>
                        <div className="Email form-label">Email ID
                            <span>
                              <img src={redStar} className="redStar" alt="redStar"/>
                            </span>
                        </div>
                        <input
                            type="email"
                            placeholder="Type your Email ID"
                            className="email"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {
                            valueErrors.field === "email" &&
                            <p className={"error-message"}>{valueErrors.message}</p>
                        }

                    </div>
                    <div className={"form-feild-item"}>
                        <div className="Mobile form-label">Mobile Number
                            <span>
                              <img src={redStar} className="redStar" alt="redStar"/>
                            </span>
                        </div>
                        <div className="conf_phone">
                            <input
                                type="text"
                                pattern="[0-9]*"
                                maxLength="10"
                                placeholder="10-Digit Number"
                                className="phone"
                                required
                                style={codeverified ? {border: '0.5px solid #58A882'} : {}}
                                value={mobileNumber}
                                onInput={(e) => handleMobileNumberChange(e)}
                                onChange={(e) => handleMobileNumberChange(e)}
                            />
                            <p className={"to_login text-right pt-3 mb-0"} onClick={() => verifyMobileNumber()}>
                                <span
                                    className="login-sapn"
                                >
                                    <u>Resend Otp</u>
                                </span>
                            </p>
                            {isDesktop ?
                                <Fragment>
                                    <button
                                        type="button"
                                        className="conf-btn w-62"
                                        style={mobileNumberverified ? {display: "none"} : {}}
                                        onClick={() => verifyMobileNumber()}
                                    >
                                        Verify
                                    </button>

                                    <div style={mobileNumberverified ? {} : {display: "none"}}>

                                        <img
                                            src={green_crt_btn}
                                            className="yellow_crt_btn1"
                                            alt="yellow_crt_btn"
                                        />
                                    </div>
                                </Fragment>
                                :
                                <Fragment></Fragment>
                            }
                        </div>
                        {
                            valueErrors.field === "mobileNumber" &&
                            <p className={"error-message"}>{valueErrors.message}</p>
                        }
                    </div>
                    {mobileNumberverified  ?
                        <Fragment>
                            <div className={"form-feild-item"}>
                                <div className="code1 form-label">Verification Code
                                    <span>
                                      <img src={redStar} className="redStar" alt="redStar"/>
                                    </span>
                                </div>
                                <div className="conf_phone">
                                    <input
                                        type="text"
                                        pattern="[0-9]*"
                                        maxLength="4"
                                        placeholder="X-X-X-X"
                                        className="code"
                                        required
                                        style={codeverified ? {border: '0.5px solid #58A882'} : {}}
                                        value={otp}
                                        onInput={(e) => handleOTPChange(e)}
                                        onChange={(e) => handleOTPChange(e)}                                    />
                                    {isDesktop ? <Fragment>
                                        <button
                                            type="button"
                                            className="conf-btn"
                                            onClick={() => verifyOtp()}
                                            style={codeverified ? {display: "none"} : {}}
                                        >
                                            Confirm
                                        </button>
                                    </Fragment> : <Fragment></Fragment>}


                                    <div style={codeverified ? {} : {display: "none"}}>

                                        <img
                                            src={green_crt_btn}
                                            className="yellow_crt_btn1"
                                            alt="yellow_crt_btn"
                                        />


                                    </div>
                                </div>
                                {
                                    valueErrors.field === "otp" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                                <div className='SenReCode'>
                                    <u> Resend Verification Code</u>
                                </div>
                            </div>
                        </Fragment>
                        : <Fragment></Fragment>

                    }
                    {mobileNumberverified && codeverified ?
                        <Fragment>
                            <div className="d-flex no-flex w-100">
                                <div className="flex1">
                                    <div className={"form-feild-item"}>
                                        <div className="Create form-label">Create Password
                                            <span>
                                          <img src={redStar} className="redStar" alt="redStar"/>
                                        </span>
                                        </div>
                                        <input
                                            type="password"
                                            placeholder="Type a password"
                                            className="code"
                                            required
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}/>
                                    </div>
                                    {
                                        valueErrors.field === "password" &&
                                        <p className={"error-message"}>{valueErrors.message}</p>
                                    }
                                </div>
                                <div className="flex2">
                                    <div className={"form-feild-item"}>
                                        <div className="Confirm form-label">Confirm Password
                                            <span>
                                          <img src={redStar} className="redStar" alt="redStar"/>
                                        </span>
                                        </div>
                                        <input
                                            type="password"
                                            placeholder="Type a password"
                                            className="code"
                                            required
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}/>
                                    </div>
                                    {
                                        valueErrors.field === "confirmPassword" &&
                                        <p className={"error-message"}>{valueErrors.message}</p>
                                    }
                                </div>
                            </div>
                        </Fragment>
                        :
                        <Fragment></Fragment>
                    }
                </form>
                {!isDesktop && !mobileNumberverified &&
                    <Fragment>
                        <div className='mb_ver_btn' onClick={() => verifyMobileNumber()}>
                            Verify
                        </div>
                    </Fragment>
                }
                {
                    !isDesktop && !codeverified && mobileNumberverified &&
                        <Fragment>
                            <div className='mb_ver_btn' onClick={() => verifyOtp()}>
                                Confirm
                            </div>
                        </Fragment>
                }
                {
                    codeverified && mobileNumberverified && !isDesktop &&
                    <Fragment>
                        <div className='mb_ver_btn' onClick={() => {
                            registerUser();
                        }}>
                            Create an account
                        </div>
                    </Fragment>
                }


                {isDesktop ?
                    <Fragment>
                    <p className="to_login text-center">
                        Already have an account?
                        <span
                            className="login-sapn"
                            onClick={() => props.setlogin("login")}
                        >
                            <u>Login In here</u>
                        </span>
                    </p>
                </Fragment> : <Fragment></Fragment>

                }
            </div>

            {!isDesktop ? <Fragment>
                <div className='mdconhelpmain mx-3'>
                    <div className='mdconhelpleft'>
        <span>
                  <img src={redStar} className="redStar" alt="redStar"/>
                </span>Marked are important
                    </div>
                    {/*<div className='mdconhelpright'>*/}
                    {/*    <u>Need help?</u>*/}
                    {/*</div>*/}
                </div>
            </Fragment> : <Fragment></Fragment>}


            <button
                type="submit"
                className="col-12 col-md-2 next"
                onClick={() => {
                    registerUser();
                }}
            >
                <img className="rightArrow" src={rightArrow} alt="rightArrow"/>
            </button>
        </div>
    );
};

const Step2 = (props) => {
    const [name, setName] = useState("");
    const [date_of_birth, setDob] = useState("");
    const [gender, setGender] = useState("");
    const [pincode, setPincode] = useState("");
    const [valueErrors, setValueErrors] = useState({});
    //
    if (props.getStep1===false){
        // debugger
        cogoToast.error("please complete Verification page first ",{position: 'top-center'})
        props.setLoginstep(1)
    }

    const handlePincodeChange = (e) => {
        let value = (e.target.validity.valid || e.target.value === "") ? e.target.value : pincode;
        setPincode(value);
    }


    const submitForm = () => {
        let error = {};
        if (name === "") {
            error = {
                field: "name",
                message: "name can't be empty"
            };
            setValueErrors(error);
        } else if (date_of_birth === "") {
            error = {
                field: "date_of_birth",
                message: "date of birth can't be empty"
            };
            setValueErrors(error);
        } else if (gender === "") {
            error = {
                field: "gender",
                message: "gender can't be empty"
            };
            setValueErrors(error);
        } else if (pincode.length < 6) {
            error = {
                field: "pincode",
                message: "pincode should be 6 digits"
            };
            setValueErrors(error);
        } else if (pincode === "") {
            error = {
                field: "pincode",
                message: "pincode can't be empty"
            };
            setValueErrors(error);
        } else {
            error = {};
            setValueErrors(error);
            props.setStep2Data({
                name:name,
                date_of_birth:date_of_birth,
                gender:gender,
                pincode:pincode
            });
            props.setStep2(true)
            props.setLoginstep(3);
        }

    };
    return (
        <div
            className="Step2 row"
            // data-aos="fade-left"
            // data-aos-anchor="#example-anchor"
            // data-aos-offset="500"
            // data-aos-duration="500"
        >
            <img src={close_bt} className="close_bt" alt="close_bt" onClick={props.closeLogin}/>
            <div className="col-md-2 col-12 p-0">
                <FormStepper activeStep={2} handleClick={props.setLoginstep}/>
            </div>

            <div className="col-8 inputfield">
                {/* <div className="span-ref">From Referral: Rakesh mehra</div> */}
                <div className="title">Let’s get to know you more!</div>
                <form>
                    <div className={"form-feild-item"}>
                        <div className="Email form-label">
                            Name
                            <span>
                              <img src={redStar} className="redStar" alt="redStar"/>
                            </span>
                        </div>
                        <input
                            type="text"
                            placeholder="Your full name"
                            className="email"
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        {
                            valueErrors.field === "name" &&
                            <p className={"error-message"}>{valueErrors.message}</p>
                        }
                    </div>
                    <div className="d-flex no-flex w-100">
                        <div className="flex1">
                            <div className={"form-feild-item"}>
                                <div className="Create form-label">
                                    Date of Birth
                                    <span>
                                      <img src={redStar} className="redStar" alt="redStar"/>
                                    </span>
                                </div>
                                <input
                                    type="date"
                                    className="code"
                                    required
                                    value={date_of_birth}
                                    onChange={(e) => setDob(e.target.value)}
                                />
                                {
                                    valueErrors.field === "date_of_birth" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>
                        </div>
                        <div className="flex2">
                            <div className={"form-feild-item"}>
                                <div className="Confirm form-label">
                                    Gender
                                    <span>
                                      <img src={redStar} className="redStar" alt="redStar"/>
                                    </span>
                                </div>
                                <select
                                    className="select1"
                                    name="gender"
                                    required
                                    id="gender"
                                    value={gender}
                                    onChange={(e) => setGender(e.target.value)}
                                >
                                    <option value="" disabled selected>
                                        Select
                                    </option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Others">Others</option>
                                </select>
                                {
                                    valueErrors.field === "gender" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={"form-feild-item"}>
                        <div className="Mobile form-label">
                            Pincode
                            <span>
                              <img src={redStar} className="redStar" alt="redStar"/>
                            </span>
                        </div>
                        <input
                            type="text"
                            pattern="[0-9]*"
                            maxLength="6"
                            placeholder="Pincode"
                            className="phone"
                            required
                            value={pincode}
                            onInput={(e) => handlePincodeChange(e)}
                            onChange={(e) => handlePincodeChange(e)}
                        />
                        {
                            valueErrors.field === "pincode" &&
                            <p className={"error-message"}>{valueErrors.message}</p>
                        }
                    </div>
                </form>
                <div className="ProceedBtn" onClick={() => submitForm()}>
                    Proceed
                </div>
                <div className={"d-flex align-items-center justify-content-between"}>
                    <p className="impo">
                      <span>
                        <img src={redStar} className="redStar" alt="redStar"/>
                      </span>
                        Marked are important
                    </p>
                    {/*<p className="help">*/}
                    {/*    <u>Need help?</u>*/}
                    {/*</p>*/}
                </div>
            </div>
            <button
                type="submit"
                className="col-2 next"
                onClick={() => submitForm()}
            >
                <img className="rightArrow" src={rightArrow} alt="rightArrow"/>
            </button>
        </div>
    );
};

const Step3 = (props) => {
    const [collegeName, setCollegeName] = useState("");
    const [yearOfGraduation, setYearOfGraduation] = useState("");
    const [degreeType, setDegreeType] = useState("");
    const [courseName, setCourseName] = useState("");
    const [workHistory, setWorkHistory] = useState("");
    const [NameOfOrg, setNameOfOrg] = useState("");
    const [totalYearExp, setTotalYearExp] = useState("");
    const [valueErrors, setValueErrors] = useState({});
    // const authContext = useContext(AuthContext);
    //
    if (props.getStep1===false){
        cogoToast.error("please complete verification page first ",{position: 'top-center'})
        props.setLoginstep(1)
        // console.log('test')
    }else if(props.getStep1===true && props.getStep2===false){
        cogoToast.error("please complete Basic Details page first ",{position: 'top-center'})
        props.setLoginstep(2)
    }

    const submitForm = () => {
        let error = {};
        if (collegeName === "") {
            error = {
                field: "collegeName",
                message: "college name can't be empty"
            };
            setValueErrors(error);
        } else if (yearOfGraduation === "") {
            error = {
                field: "yearOfGraduation",
                message: "year of graduation can't be empty"
            };
            setValueErrors(error);
        } else if (degreeType === "") {
            error = {
                field: "degreeType",
                message: "degree type can't be empty"
            };
            setValueErrors(error);
        } else if (courseName === "") {
            error = {
                field: "courseName",
                message: "course name can't be empty"
            };
            setValueErrors(error);
        } else if (workHistory === "") {
            error = {
                field: "workHistory",
                message: "work History can't be empty"
            };
            setValueErrors(error);
        } else if (workHistory==='Yes' && NameOfOrg === "") {
            error = {
                field: "NameOfOrg",
                message: "organization name can't be empty"
            };
            setValueErrors(error);

        } else if (workHistory==='Yes' && totalYearExp === "") {
            error = {
                field: "totalYearExp",
                message: "Year of experience can't be empty"
            };
            setValueErrors(error);

        }




        else {
            let userData = {
                collegeName: collegeName,
                courseName: courseName,
                dateOfBirth: props.step2Data.date_of_birth,
                degreeType: degreeType,
                gender: props.step2Data.gender,
                name: props.step2Data.name,
                organizationName: NameOfOrg,
                pincode: props.step2Data.pincode,
                userId: props.userID,
                workExperience: totalYearExp,
                workHistory: workHistory === "Yes",
                yearOfGraduation: yearOfGraduation
            };
            error = {};
            setValueErrors(error);
            // props.setLoading(true);
            CompleteProfileMutation(userData, function (response) {
                // props.setLoading(false);
                if (response.completeProfile !== null && response.completeProfile.user !== null) {
                    props.setCompletedProfile(true);
                    cogoToast.success(response.completeProfile.message, {position: 'top-center'});
                    props.sendUserDatasToStore(response.completeProfile.user);
                    // authContext.addUserData(response.completeProfile.user);
                    localStorage.setItem('user_token',response.completeProfile.token);
                } else {
                    cogoToast.error("something went wrong", {position: 'top-center'});
                }
            }, function (err) {
                // props.setLoading(false);
                cogoToast.error(err, {position: 'top-center'});
            });
        }
    };
    return (
        <div
            className="Step3 row"
            // data-aos="fade-left"
            // data-aos-anchor="#example-anchor"
            // data-aos-offset="500"
            // data-aos-duration="500"
        >
            <img src={close_bt} className="close_bt" alt="close_bt" onClick={props.closeLogin}/>

            <div className="col-md-2 col-12 p-0">
                <FormStepper activeStep={3} handleClick={props.setLoginstep}/>
            </div>
            <div className="col-8 inputfield">
                <div className="title">Final Step!</div>
                {/* <div className="span-ref">From Referral: Rakesh mehra</div> */}
                <form>
                    <div className={"form-feild-item"}>
                        <div className="Email form-label">
                            University/College Name
                            <span>
                              <img src={redStar} className="redStar" alt="redStar"/>
                            </span>
                        </div>
                        <input
                            type="text"
                            placeholder="Type the name"
                            className="email"
                            required
                            value={collegeName}
                            onChange={(e) => setCollegeName(e.target.value)}
                        />
                        {
                            valueErrors.field === "collegeName" &&
                            <p className={"error-message"}>{valueErrors.message}</p>
                        }
                    </div>

                    <div className="d-flex no-flex w-100">
                        <div className="flex1">
                            <div className={"form-feild-item"}>
                                <div className="Create form-label">
                                    Year of Graduation
                                    <span>
                                      <img src={redStar} className="redStar" alt="redStar"/>
                                    </span>
                                </div>
                                <input
                                    type="date"
                                    className="code"
                                    required
                                    value={yearOfGraduation}
                                    onChange={(e) => setYearOfGraduation(e.target.value)}
                                />
                            </div>
                            {
                                valueErrors.field === "yearOfGraduation" &&
                                <p className={"error-message"}>{valueErrors.message}</p>
                            }
                        </div>
                        <div className="flex2">
                            <div className={"form-feild-item"}>
                                <div className="Confirm form-label">
                                    Degree Type
                                    <span>
                                      <img src={redStar} className="redStar" alt="redStar"/>
                                    </span>
                                </div>
                                <select
                                    className="select1"
                                    name="gender"
                                    id="gender"
                                    value={degreeType}
                                    onChange={(e) => setDegreeType(e.target.value)}                                >
                                    <option value="" disabled selected>
                                        Select
                                    </option>
                                    <option value="UG">UG</option>
                                    <option value="PG">PG</option>
                                    <option value="Masters">Masters</option>
                                    <option value="PhD">PhD</option>
                                    <option value="Others">Others</option>
                                </select>
                            </div>
                            {
                                valueErrors.field === "degreeType" &&
                                <p className={"error-message"}>{valueErrors.message}</p>
                            }
                        </div>
                    </div>

                    <div className="d-flex no-flex w-100">
                        <div className="flex1">
                            <div className={"form-feild-item"}>
                                <div className="Create form-label">
                                    Name of the course
                                    <span>
                                      <img src={redStar} className="redStar" alt="redStar"/>
                                    </span>
                                </div>
                                <input
                                    type="text"
                                    className="code"
                                    placeholder="Type the name"
                                    required
                                    value={courseName}
                                    onChange={(e) => setCourseName(e.target.value)}                                      />
                                {
                                    valueErrors.field === "courseName" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>
                        </div>
                        <div className="flex2">
                            <div className={"form-feild-item"}>
                                <div className="Confirm form-label">
                                    Do you have any work history?
                                    <span>
                                      <img src={redStar} className="redStar" alt="redStar"/>
                                    </span>
                                </div>
                                <select
                                    className="select1"
                                    name="gender"
                                    id="gender"
                                    value={workHistory}
                                    onChange={(e) => setWorkHistory(e.target.value)}                                      >
                                    <option value="" disabled selected>
                                        Select
                                    </option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                            {
                                valueErrors.field === "workHistory" &&
                                <p className={"error-message"}>{valueErrors.message}</p>
                            }
                        </div>
                    </div>

                    <div className="d-flex no-flex w-100">
                        <div className="flex1">
                            <div className={"form-feild-item"}>
                                <div className="Create form-label">
                                    If yes, Name of the organisation?
                                    {
                                        workHistory==='Yes' && <span>
                                      <img src={redStar} className="redStar" alt="redStar"/>
                                    </span>
                                    }

                                </div>
                                <input
                                    type="text"
                                    className="code"
                                    required
                                    value={NameOfOrg}
                                    onChange={(e) => setNameOfOrg(e.target.value)}                                />
                            </div>
                            {
                                valueErrors.field === "NameOfOrg" &&
                                <p className={"error-message"}>{valueErrors.message}</p>
                            }
                        </div>
                        <div className="flex2">
                            <div className={"form-feild-item"}>
                                <div className="Confirm form-label">
                                    Total years of work
                                    {
                                        workHistory==='Yes' && <span>
                                      <img src={redStar} className="redStar" alt="redStar"/>
                                    </span>
                                    }
                                    {/*<span>*/}
                                    {/*  <img src={redStar} className="redStar" alt="redStar"/>*/}
                                    {/*</span>*/}
                                </div>
                                <select
                                    className="select1"
                                    name="gender"
                                    id="gender"
                                    value={totalYearExp}
                                    onChange={(e) => setTotalYearExp(e.target.value)}
                                >
                                    <option value="" disabled selected>
                                        Select
                                    </option>
                                    <option value="1-5">1-5 Years</option>
                                    <option value="6-10">6-10 Years </option>
                                    <option value="10+">10+ years</option>
                                </select>
                            </div>
                            {
                                valueErrors.field === "totalYearExp" &&
                                <p className={"error-message"}>{valueErrors.message}</p>
                            }
                        </div>
                    </div>
                </form>
                <div className="ProceedBtn" onClick={() => submitForm()}>
                    Proceed
                </div>
                <div className={"d-flex align-items-center justify-content-between"}>
                    <p className="impo">
                      <span>
                        <img src={redStar} className="redStar" alt="redStar"/>
                      </span>
                        Marked are important
                    </p>
                    {/*<p className="help">*/}
                    {/*    {" "}*/}
                    {/*    <u>Need help?</u>*/}
                    {/*</p>*/}
                </div>

            </div>

            <div className="col-2 next" onClick={() => submitForm()}>
                <img className="rightArrow" src={rightArrow} alt="rightArrow"/>
            </div>
        </div>
    );
};

const Final = (props) => {
    return (
        <div className="Final" >
            <img src={close_bt} className="close_bt" alt="close_bt" onClick={props.closeLogin}/>
            <div className='finalCreate'> Account successfully created!</div>
            <img
                src={singnup_created_img}
                className="singnup_created_img"
                alt="singnup_created_img"
            />
            <div className='welcome'> Welcome to Vertical Limit!</div>
        </div>
    );
};


export const FormStepper = (props) => {
    const {activeStep, handleClick} = props;
    const isDesktop = useMediaQuery("(min-width: 765px)");


    return (
        <div className={"form-stepper-component"}>
            <div className={"flow"}>
                <div className="text-center inner">
                    <div className="mb-on" onClick={() => handleClick(1)}>
                        <img
                            src={activeStep === 1 ? yellow_crt_btn : VerificationInActive}
                            className="yellow_crt_btn"
                            alt="yellow_crt_btn"
                        />
                        <p className="process">Verification</p>
                        {isDesktop?"":<hr className={'mobile_hr'}/>}
                    </div>
                    <hr className="mx-auto my-0"/>
                    <div className="mb-on" onClick={() => handleClick(2)}>
                        <img src={activeStep === 2 ? BasicDeatilsActive : Detail} className="Detail" alt="Detail"/>
                        <p className="process">Basic Details</p>
                    </div>
                    <hr className="mx-auto my-0"/>
                    <div className="mb-on" onClick={() => handleClick(3)}>
                        <img src={activeStep === 3 ? BackgroundProfileActive : profile} className="profile"
                             alt="profile"/>
                        <p className="process">{isDesktop? "Background Profile" : "Profile Info" } </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    user_details:state.UserReducer.user_details,
});

const mapDispatchToProps = dispatch => ({
    sendUserDatasToStore: user_data => dispatch(UserLoggedin(user_data)),
});


export default connect(mapStateToProps,mapDispatchToProps)(Singup);
