import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'

const mutation = graphql`
    mutation GenerateInvoiceMutation(
        $orderId: ID!
    ) {
        generateInvoice(orderId: $orderId){
            invoice {
                invoiceUrl
            }
        }
    }
`

export default (data, callback,errCallback) => {
    const variables = {
        orderId: data.orderId,
    }

    // 5
    commitMutation(
        environment,
        {
            mutation,
            variables,
            // 6
            onCompleted: (response,err) => {

                if(response.generateInvoice !== null){
                    callback(response)
                } else{
                    errCallback(err[0].message);
                }

            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}
