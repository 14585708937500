import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'

const mutation = graphql`
  mutation DeleteModuleMutation(
    $ids: [String]!
  ) {
     deleteModuleForCourse(
        ids:$ids
     ){        
        message
        status
     }
  }
`

export default (data, callback,errCallback) => {
    const variables = {
        ids: data.ids
    }
    // 5
    commitMutation(
        environment,
        {
            mutation,
            variables,
            // 6
            onCompleted: (response,err) => {

                if(response.deleteModuleForCourse !== null){
                    callback(response)
                } else{
                    errCallback(err[0].message);
                }

            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}
