import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'

const mutation = graphql`
  mutation CheckoutCompleteMutation(
    $id: String!
  ) {
     checkoutComplete(id:$id){
        status      
        payment{
            order{
                id
                totalPayable
                status
                orderId 
                user{
                    name
                    email
                    mobileNumber
                }
            }
            rzpOrderId  
        }
     }
  }
`

export default (cartId, callback,errCallback) => {
    const variables = {
        id: cartId,
    }

    // 5
    commitMutation(
        environment,
        {
            mutation,
            variables,
            // 6
            onCompleted: (response,err) => {

                if(response.checkoutComplete !== null){
                    callback(response)
                } else{
                    errCallback(err[0].message);
                }

            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}
