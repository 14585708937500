/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateReviewMutationVariables = {|
  content: string,
  rating: number,
  reviewId: string,
|};
export type UpdateReviewMutationResponse = {|
  +updateReview: ?{|
    +status: ?string
  |}
|};
export type UpdateReviewMutation = {|
  variables: UpdateReviewMutationVariables,
  response: UpdateReviewMutationResponse,
|};
*/


/*
mutation UpdateReviewMutation(
  $content: String!
  $rating: Int!
  $reviewId: String!
) {
  updateReview(content: $content, rating: $rating, reviewId: $reviewId) {
    status
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "content"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "rating"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "reviewId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "content",
        "variableName": "content"
      },
      {
        "kind": "Variable",
        "name": "rating",
        "variableName": "rating"
      },
      {
        "kind": "Variable",
        "name": "reviewId",
        "variableName": "reviewId"
      }
    ],
    "concreteType": "UpdateReviewMutation",
    "kind": "LinkedField",
    "name": "updateReview",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateReviewMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateReviewMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "96488649c6c6cd6d1aa4d974605a3cf0",
    "id": null,
    "metadata": {},
    "name": "UpdateReviewMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateReviewMutation(\n  $content: String!\n  $rating: Int!\n  $reviewId: String!\n) {\n  updateReview(content: $content, rating: $rating, reviewId: $reviewId) {\n    status\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e8b98a7cb9bfedd4ad64b4ceaaa4abf1';

module.exports = node;
