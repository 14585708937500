import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'

const mutation = graphql`
  mutation UpdateLessonMutation(
    $data: [LessonCustomInputType]
  ) {
     updateLesson(
        data: $data      
     ){   
        lessons{
            id
            name
            video
            type
            pdf
            currentTime
            isWatched
            isWatching
            transcodedVideo
            isTranscoded
            duration
            transcript
            isActive
            order
        }      
        message
        status
     }
  }
`

export default (lessons, callback,errCallback) => {
    const variables = {
        data:lessons.data
    }
    // 5
    commitMutation(
        environment,
        {
            mutation,
            variables,
            // 6
            onCompleted: (response,err) => {

                if(response.updateLesson !== null){
                    callback(response)
                } else{
                    errCallback(err[0].message);
                }

            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}
