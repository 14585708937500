import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'

const mutation = graphql`
    mutation UpdateInstructorAboutMutation(
    $about:JSONString
    )
    {
        updateInstructorAbout(
            about:$about,
        ){
            status
            message
        }
    }
`

export default (userData, callback,errCallback) => {
    const variables = {
        about:userData.about
    }
    commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response,err) => {
                if(response.UpdateInstructorAboutMutation !== null){
                    callback(response)
                } else{
                    errCallback(err[0].message);
                }
            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}
