import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'

const mutation = graphql`
  mutation RegisterUserMutation(
    $email: String!
    $mobileNumber: String!
    $password: String!
    $passwordConfirm: String!
    $referedBy: String
  ) {
      registerUser(
        mobileNumber:$mobileNumber,
        email:$email,
        password:$password,
        passwordConfirm:$passwordConfirm
        referedBy:$referedBy
      ){
        status
        message 
        user{      
              id
              lastLogin
              email
              mobileNumber
              name
              dob
              gender
              referalCode
              pincode
              isActive
              isAdmin
              isInstructor 
              wishlist{
                edges{
                  node{
                    id
                  }
                }
              }
        }
      }
  }
`

export default (userData, callback,errCallback) => {
    const variables = {
        email: userData.email,
        mobileNumber: userData.mobileNumber,
        password: userData.password,
        passwordConfirm: userData.passwordConfirm,
        referedBy: userData.referedBy
    }

    // 5
    commitMutation(
        environment,
        {
            mutation,
            variables,
            // 6
            onCompleted: (response,err) => {

                if(response.registerUser !== null){
                    callback(response)
                } else{
                    errCallback(err[0].message);
                }

            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}
