import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'

const mutation = graphql`
  mutation LessonOrderUpdateMutation(
    $lessonId: String
    $order: Int
  ) {
       lessonOrderUpdate(lessonId:$lessonId,order:$order){
        status
        message
      }
  }
`

export default (data, callback,errCallback) => {
    const variables = {
        lessonId:data.lessonId,
        order:data.order
    }
    // 5
    commitMutation(
        environment,
        {
            mutation,
            variables,
            // 6
            onCompleted: (response,err) => {

                if(response.lessonOrderUpdate !== null){
                    callback(response)
                } else{
                    errCallback(err[0].message);
                }

            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}
