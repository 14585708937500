import './AboutUs.scss'
import {LayoutContainer} from "../../Components/LayoutContainer/LayoutContainer";

import university_list from "../../assets/images/university_list.png"
import aboutus_img_1 from "../../assets/images/aboutus_img1.png"
import aboutus_img_2 from "../../assets/images/aboutus_img2.png"
import aboutus_img_3 from "../../assets/images/aboutus_img3.png"
import our_storey_1 from "../../assets/images/OUR STORY.png"
import yellowrightArrow from "../../assets/images/yellowrightArrow.png"
import LeftArrow from "../../assets/images/LeftArrow.png"
import Group19880 from "../../assets/images/Group19880.png"



import shap from  "../../assets/images/shap.png"
import React, {useEffect, useState} from "react";
import useMediaQuery from "../../util/useMediaQuery";
import Header from "../../Containers/Header/Header";
import {Footer} from "../../Containers/Footer/Footer";
export const AboutUs=()=>{
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return(
        <>
            <Header page={"aboutus"}/>
            <div className={'AboutUs'}>
            <Top_header/>
            <LayoutContainer>
                <UniversityProfessors/>
                <Our_vission/>

            </LayoutContainer>
            <OurStory/>
        </div>
            <Footer/>
        </>
    );
}

const Top_header=()=>{
    return(
        <div className={'AboutUs_top_header'}>
            Scale your summit
    </div>
    );
}

const  UniversityProfessors=()=>{
    return(
        <div className={'AboutUsUniversityProfessors'}>
        <div className={'row m-0'}>
            <div className={'col-md-6 col-12 px-0'}>
                <h3 className={'title'}>University Professors</h3>
                <p className={'para text-start'}>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</p>
            </div>
            <div className={'col-md-6 col-12 px-0'}>
                <img src={university_list} className={'university_list img-fluid'} alt={'university_list'}/>
            </div>
        </div>
        </div>
    )
}


const Our_vission=()=>{
    return(
        <div className={'Our_vission'}>
            <h3 className={'Our_vission_title'}>Our Vision</h3>
            <p className={'Our_vission_para para'}>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</p>
        </div>
    )
}

const OurStory=()=>{
    const [count, setCount] = useState(0)
    const isDesktop = useMediaQuery("(min-width: 768px)");
    const text = '{"students":[' +
        '{"Name":"John","words":"Our mission here Amet minim mollit non des dolor do amet sint.","College":"IIT chennai" },' +
        '{"Name":"Anna","words":"Our mission here Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.","College":"Professor, IIT"},' +
        '{"Name":"Peter","words":"Our mission here Amet minim mololor do amet sint.","College":"IIT chennai" },' +
        '{"Name":"Alex","words":"Our mission here Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.","College":"Professor, IIT"}]}';
    const studentwordscontent = JSON.parse(text);
    return(
        <div className={'OurStory'}>
            <div className={'row m-0 w-100'}>
                <div className={'col-md-6 px-0'}>
                    <div className={'d-flex full_left_row'}>
                        <div className={'d-flex flex-column justify-content-between ml-3'}>
                            <div className={'align-self-center'}>
                                <img src={our_storey_1} alt={'our_storey_1'} className={'our_storey_1 img-fluid'}/>
                            </div>

                            <div className={'d-flex images_left'}>
                                <div>
                                    <img src={aboutus_img_3} alt={'aboutus_img_3'} className={'aboutus_img_3 img-fluid'}/>
                                </div>
                                <div>
                                    <img src={aboutus_img_2} alt={'aboutus_img_2'} className={'aboutus_img_2 img-fluid'}/>
                                </div>
                            </div>
                        </div>
                        <div> <img src={aboutus_img_1} className={'aboutus_img_1 img-fluid'} alt={'aboutus_img_3 '}/> </div>
                    </div>
                </div>

                <div className={'col-md-6 px-0 right_side row m-0'}>
                    <img src={shap} className={'shap'} alt={'shap'}/>

                    <div className={'card order-md-1 order-2'}>
                        {isDesktop?<></>:<img src={Group19880} className={'mb_shap'} alt={'Group19880'}/>}
                        <h3 className={'title'}>{studentwordscontent.students[(count % 4)].words}</h3>
                        <p className={'para_1'}>{studentwordscontent.students[(count % 4)].Name}</p>
                        <p className={'para_2'}>{studentwordscontent.students[(count % 4)].College}</p>
                    </div>

                    <div className={'d-flex mt-4 justify-content-center order-md-2 order-1 story_main_tag w-100'}>
                      <div className={'story'}>Our Story</div>
                        <div className="d-flex mb_view_img view_img">
                            {isDesktop?<></>:<div>
                                <img src={aboutus_img_1} className={'aboutus_img_1 img-fluid'} alt={'aboutus_img_3 '}/>
                            </div>}
                            <div className="nextstudentbtn" onClick={() => (count > 0 ? setCount(count - 1) : setCount(0))}>
                                <img src={LeftArrow} className="LeftArrow" alt="LeftArrow"/>
                            </div>
                            <div className="nextstudentbtn" onClick={() => setCount(count + 1)}>
                                <img
                                    src={yellowrightArrow}
                                    className="yellowrightArrow"
                                    alt="yellowrightArrow"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
