// export const FETCH_PROFESSOR_COURSESPAGE_STATE_COURSE_INFO   = 'FETCH_PROFESSOR_COURSESPAGE_STATE_COURSE_INFO';
// export const FETCH_PROFESSOR_COURSESPAGE_STATE_MODULES   = 'FETCH_PROFESSOR_COURSESPAGE_STATE_MODULES';
export const FETCH_PROFESSOR_COURSESPAGE_STATE_REVIEWS   = 'FETCH_PROFESSOR_COURSESPAGE_STATE_REVIEWS';

export const fetchProfessorCoursespageStateReviews = StateActiveData => ({
    type: FETCH_PROFESSOR_COURSESPAGE_STATE_REVIEWS,
    payload:StateActiveData
});

// export const fetchProfessorCoursespageStateModules= () => ({
//     type: FETCH_PROFESSOR_COURSESPAGE_STATE_MODULES
// });
//
// export const fetchProfessorCoursespageStateCourseInfo = () => ({
//     type: FETCH_PROFESSOR_COURSESPAGE_STATE_COURSE_INFO
// });