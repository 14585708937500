import {Fragment, useEffect, useRef, useState} from "react";
import React from "react";
import "./Homepage.scss";
import {useHistory} from "react-router-dom";
import {withRouter} from "react-router-dom";


import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel
} from 'react-accessible-accordion';


import useMediaQuery from "../../util/useMediaQuery";
import {LayoutContainer} from "../../Components/LayoutContainer/LayoutContainer";
import {RoundedButton} from "../../Components/Buttons/RoundedButton";

import Group19880 from "../../assets/images/Group19880.png";
import LeftArrow from "../../assets/images/LeftArrow.png";
import yellowrightArrow from "../../assets/images/yellowrightArrow.png";
import wave from "../../assets/images/wave-group.png";
import wavebackgroung from "../../assets/images/wave-backgroung.png";
import user1 from "../../assets/images/user1.png";
import invitebg from "../../assets/images/invitebg.png";

import leftSmallarrow from '../../assets/images/leftSmallarrow.svg';
import {Swiper, SwiperSlide} from 'swiper/react';
import mobile_Expert from '../../assets/images/mobile_Expert.png'
import poster from '../../assets/images/Exper_Guid.png'
import leftsmallarrow from '../../assets/images/leftSmallarrow.svg'
import Slider from "react-slick";
import topyellowarrow from '../../assets/images/topyellowarrow.png'
import bigBanner from '../../assets/images/bigBannerpng'
import smallbanner from '../../assets/images/smallbanner.png'
import banner from '../../assets/images/banner.png'
import yellodownarrow from '../../assets/images/yellodownarrow.png';
import mbsmallbanner from '../../assets/images/mbsmallbanner.png'
import dot from '../../assets/images/dot.png'
import green from '../../assets/images/green.png'
import red from '../../assets/images/red.png'
import round_man from '../../assets/images/round_man.png'
import violet from '../../assets/images/violet.png'
import yellow_start from '../../assets/images/yellow star.png'
import yellow_boy from '../../assets/images/yellow_boy.png'
import yellow_girl from '../../assets/images/yellow_girl.png';
import video_play_btn from "../../assets/icons/el_play.svg"
import homepage_side_img from "../../assets/icons/Homepage_Polygon 6.svg"

import SliderRightArrowIcon from "../../assets/images/carousel_right.png";
import SliderLeftArrowIcon from "../../assets/images/carousel_left.png";


import environment from "../../Environment"
import CategoryListQuery from "../../queries/CategoryListQuery"
import {fetchQuery} from "relay-runtime";

import SwiperCore, {
    Pagination, Navigation
} from 'swiper';
import {BasicButton} from "../../Components/Buttons/BasicButton";
import {ProfessorCard} from "../../Containers/ProfessorCard/ProfessorCard";
import CourseCard from "../../Containers/CourseCard/CourseCard";
import ProfessorListQuery from "../../queries/ProfessorListQuery";
import CourseListByCategoryQuery from "../../queries/CourseListByCategory";
import CourseListQuery from "../../queries/CourseListQuery";
import uuid from 'react-uuid'
import IndustrialSpeakersQuery from "../../queries/IndustrialSpeakersQuery";
import StudentWordsQuery from "../../queries/StudentWordsQuery";
import Header from "../../Containers/Header/Header";
import {Footer} from "../../Containers/Footer/Footer";
import smallUser from "../../assets/images/smallUser.svg";
import MyCoursesListQuery from "../../queries/MyCoursesListQuery";
import {compose} from "redux";
import Loader from "../../Components/Loader/Loader";
import {UserLoggedout} from "../../Actions";
import {clearCart} from "../../Actions/CartAction";
import {clearWishList} from "../../Actions/wishListAction";
import {connect} from "react-redux";
import {TrunkString} from "../../util/TrunkString";


SwiperCore.use([Pagination, Navigation]);


const Homepage = (props) => {

    const [isPaidUser,setIsPaidUser]=useState(false)

    const isDesktop = useMediaQuery("(min-width: 600px)");

    // const [completedCourses, setCompletedCourses] = useState([]);
    const [onGoingcourses, setOnGoingcourses] = useState([]);
    const [loading,setLoading]=useState(false);


    useEffect(()=>{
        window.scrollTo(0,0)
    },)

    useEffect(() => {
        fetchMyCrouseList()
        if (  onGoingcourses.length > 0){
            setIsPaidUser(true)
        }
    }, []);


    const fetchMyCrouseList = () => {
        setLoading(true)
        fetchQuery(environment, MyCoursesListQuery)
            .toPromise()
            .then(response => {
                setLoading(false)
                if (response.myEnrolls !== null) {
                    let tmp_ongoing_courses = [];
                    // let tmp_completed_courses = [];
                    // tmp_completed_courses = response.myEnrolls.edges.filter(function (course) {
                    //     return course.node.status === "COMPLETED";
                    // });
                    // debugger
                    tmp_ongoing_courses = response.myEnrolls.edges.filter(function (course) {
                        return course.node.status === "NOT_STARTED" || course.node.status === "STARTED";
                    });
                    setOnGoingcourses(tmp_ongoing_courses);
                    // setCompletedCourses(tmp_completed_courses);
                } else {

                    setOnGoingcourses([]);
                    // setCompletedCourses([]);
                }
            }, err =>{
                setLoading(false)
                // console.log(err);
                setOnGoingcourses([]);
                // setCompletedCourses([]);
            });
    }


    useEffect(()=>{
        if (  onGoingcourses.length > 0){
            setIsPaidUser(true)
        }
    },[onGoingcourses])

    if (loading) {
        return (
            <div>
                <Loader full={true} />
                <div  style={{height:window.innerHeight}}/>
            </div>
        );
    }

    return (
        <>
            <Header page={"home"}/>
            <div className="Homepage">
                {isPaidUser?
                    <>
                        <StartLearnyng onGoingcourses={onGoingcourses} sub_props={props}/>
                        <ScaleYourSummit/>
                        <RelatedCourses sub_props={props}/>

                    </>
                    :
                    <>
                        <BannerCarousel/>
                        <Qualified/>
                        <Coursetopic history={props.history}/>
                    </>

                }



                <OurExperts/>
                {isDesktop ?
                    <img src={bigBanner} className={'bigBanner'} alt={'bigBanner'}/> : <></>
                }
                <TrendingCourses sub_props={props}/>
                {isDesktop ?
                    <></> : <img src={smallbanner} className={'smallBanner'} alt={'smallBanner'}/>
                }
                <Carrer/>
                <Carousel_IndustrialSpeakers/>
                <StudentsWords/>
                {/* <Invite/> */}


            </div>
            <Footer/>
        </>
    );
};

const StartLearnyng=(props)=>{

    const onGoingcourses=props.onGoingcourses
    // const histopry=props.sub_props.history

    const isDesktop = useMediaQuery("(min-width: 600px)");
    const [getUserNAme,setUserName]=useState('')

    const multiple_slider_settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1.25,
        slidesToScroll: 1,
        arrows: false
    };

    const goToPreview = (courseId) => {
        props.sub_props.history.push("/course-preview/"+courseId);
    }
    return(
        <div className={'startslearning'}>
            <div className={'row heading-section'}>
                <div className={'col-md-8 col-12'}>
                    <h3 className={'main-title'}>Let’s Start Learning, {props.sub_props.user_details && props.sub_props.user_details.name ?props.sub_props.user_details.name:"User"}!</h3>
                    <p className={'sub-title'}>Your On-going courses! </p>
                </div>
                {
                    isDesktop?
                        <div className={'col-md-4 col-12 text-right'}>
                            <RoundedButton handleClick={()=>props.sub_props.history.push('/myprofile/mycourses')}>
                                View All
                                <img src={leftSmallarrow} className='rightArrow' alt='rightArrow'/>
                            </RoundedButton>
                        </div>
                        :
                        <></>
                }


            </div>
            {
                isDesktop ? (
                    <div className={'row course-list'}>                   
                        {
                            onGoingcourses.slice(0,3).map((item,index)=>{
                                return(
                                    <div className={'col-4 courses px-0 mx-0'} onClick={()=>goToPreview(item.node.course.id)}>
                                        <div className={'row mx-0'}>
                                            <div className={'col-4 px-0 image_parent'}>
                                                <img src={item.node.course.image} className={'image'}/>
                                                <img src={video_play_btn} className={'video_play_btn'}   />
                                            </div>
                                            <div className={'col-8 px-0'}>
                                                <p className={'title'}>
                                                    <TrunkString name={item.node.course.name} length={30}/>
                                                </p>
                                                <p className={'lesson'}>Lesson 1: Human Resources</p>
                                                <div className={'name'}>
                                                    <img src={smallUser} className="smallUser" alt="smallUser"/>
                                                    {item.node.course.instructor.user.name}
                                                </div>
                                                <progress className={'progress'} id="file" value={(`${item.node.course.percentageCompleted}`).slice(0, -1)} max="100">  </progress>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                ) : (
                    <div className={'ongoing-courses-slider-container'}>
                        <Swiper slidesPerView={'auto'} slidesOffsetBefore={24} slidesOffsetAfter={24}>
                            {onGoingcourses.slice(0,3).map((item,index)=>{
                                return(
                                    <SwiperSlide key={index}>
                                        <div className={'courses px-0'} onClick={()=>goToPreview(item.node.course.id)}>
                                            <div className={'row inside-courses mx-0'}>
                                                <div className={'col-4 px-0 image_parent'}>
                                                    <img src={item.node.course.image} className={'image'}/>
                                                    <img src={video_play_btn} className={'video_play_btn'}   />
                                                </div>
                                                <div className={'col-8 px-0'}>
                                                    <p className={'title'}>
                                                        <TrunkString name={item.node.course.name} length={15} />
                                                    </p>
                                                    <p className={'lesson'}>Lesson 1: Human Resources</p>
                                                    <div className={'name d-flex'}>

                                                        <img src={smallUser} className="smallUser" alt="smallUser"/>
                                                        {item.node.course.instructor.user.name}



                                                    </div>
                                                    <progress className={'progress'} id="file" value={(`${item.node.course.percentageCompleted}`).slice(0, -1)} max="100">  </progress>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                );
                            })

                            }
                        </Swiper>
                    </div>                    
                )
            } 
            {
                !isDesktop?
                    <div className={'col-md-4 col-12 mt-5 text-center'}>
                        <RoundedButton handleClick={()=>props.sub_props.history.push('/myprofile/mycourses')} >
                            My Learnings
                            <img src={leftSmallarrow} className='rightArrow' alt='rightArrow'/>
                        </RoundedButton>
                    </div>
                    :
                    <></>
            }
        </div>
    );
}

const ScaleYourSummit=()=>{
    const isDesktop = useMediaQuery("(min-width: 600px)");
    const settings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true,
        appendDots: dots => <ul>{dots}</ul>,
        customPaging: i => (
            <div className="ft-slick__dots--custom">
                <div className="loading"/>
            </div>
        )
    };
    return(
        <div className={'ScaleYourSummit'}>
            <div className={'row'}>
                <div className={'col-md-8 col-12 order-md-1 order-2 m-auto'}>
                    <h3 className={'title'}>Scale Your Summit</h3>
                    <p className={'para'}>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</p>
                </div>
                <div className={'col-md-4 order-md-2 order-1 bannercarousel'}>
                    <Slider {...settings}>
                        <div className="testimoni--wrapper">
                            <img src={isDesktop ? homepage_side_img : homepage_side_img} alt={'poster'} className={'poster'}/>
                        </div>
                        <div className="testimoni--wrapper">
                            <img src={isDesktop ? homepage_side_img : homepage_side_img} alt={'poster'} className={'poster'}/>
                        </div>
                        <div className="testimoni--wrapper">
                            <img src={isDesktop ? homepage_side_img : homepage_side_img} alt={'poster'} className={'poster'}/>
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
    );
}

const RelatedCourses=(props)=>{
    const [courseList,setCourseList] = useState([]);
    const isDesktopOrLapTop = useMediaQuery("(min-width: 1400px)");

    const multiple_slider_settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1.25,
        slidesToScroll: 1,
        arrows: false
    };
    useEffect(function (){
        getTrendingCourses();
    },[]);
    const getTrendingCourses = () => {
        fetchQuery(environment, CourseListQuery)
            .toPromise()
            .then(response => {
                if (response.courses !== null) {
                    setCourseList(response.courses.edges.slice(0,5));
                } else {
                    setCourseList([]);
                }
            }, err => {
                setCourseList([]);
            });
    }
    const updateCourseList = (course,booleanValue) => {
        let tmp_course_list = courseList.slice();
        let index = courseList.findIndex(course_item => course_item.node.id === course.id);
        if(index > -1){
            tmp_course_list[index] = {
                ...tmp_course_list[index],
                node: {
                    ...tmp_course_list[index].node,
                    isWishlist: booleanValue
                }
            };
        }
        setCourseList(tmp_course_list);
    }
    return (
        <div className={'TrendingCourses'}>
            <LayoutContainer>
                <div className={'d-flex ownflex'}>
                    <h3 className={'title m-0'}>RelatedCourses</h3>
                    <RoundedButton handleClick={()=>props.sub_props.history.push("/course-list")}>
                        View All
                        <img src={leftSmallarrow} className='rightArrow' alt='rightArrow'/>
                    </RoundedButton>
                </div>
                <div className="row CourseCardall large-device-trending-courses">
                    {
                        courseList.map((item, index) => {
                            return (
                                <div className={isDesktopOrLapTop?'col-md-6 col-lg-4 col-xl-3':'col-md-6 col-lg-4 col-xl-4'} key={uuid()}>
                                    <div className={"mb-4"}>
                                        <CourseCard courseData={item.node} updateCourseList={updateCourseList}/>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
                <div className={"small-device-trending-courses"}>
                    <Slider {...multiple_slider_settings} className={"course-slider"}>
                        {
                            courseList.map((item, index) => {
                                return (
                                    <div key={uuid()}>
                                        <CourseCard courseData={item.node} updateCourseList={updateCourseList}/>
                                    </div>
                                );
                            })
                        }
                    </Slider>
                </div>
            </LayoutContainer>
        </div>
    )
}

const BannerCarousel = () => {
    const isDesktop = useMediaQuery("(min-width: 600px)");
    const settings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true,
        // appendDots: dots => <ul>{dots}</ul>,
        // customPaging: i => (
        //     <div className="ft-slick__dots--custom">
        //         <div className="loading"/>
        //     </div>
        // )
        appendDots: dots => <ul>{dots}</ul>,
        customPaging: i => (
            <div className="ft-slick__dots--custom"
            >
                <div className="test" style={{visibility:"hidden"}}>
                    {i + 1}
                </div>

            </div>
        )
    };


    return (<div className={'bannercarousel'}>
        <Slider {...settings}>
            <div className="testimoni--wrapper">
                <img src={isDesktop ? banner : mbsmallbanner} alt={'poster'} className={'poster'}/>
            </div>
            <div className="testimoni--wrapper">
                <img src={isDesktop ? banner : mbsmallbanner} alt={'poster'} className={'poster'}/>
            </div>
            <div className="testimoni--wrapper">
                <img src={isDesktop ? banner : mbsmallbanner} alt={'poster'} className={'poster'}/>
            </div>
        </Slider>

    </div>);
}

const Qualified = () => {
    const isDesktop = useMediaQuery("(min-width: 600px)");
    return (<div className={'qualified'}>
        <LayoutContainer>
            <div className={'title'}>
                {isDesktop ?
                    <Fragment> Qualified lessons for students </Fragment>
                    : <Fragment>
                        Scale Your Summit </Fragment>
                }

            </div>
            <div className={'para'}>
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis
                enim
                velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco
                est
                sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam
                consequat
                sunt nostrud amet.
            </div>
        </LayoutContainer>
    </div>)
}

const Coursetopic = (props) => {
    // console.log()
    const [activeCategory, setActiveCategory] = useState({});
    const multiple_slider_settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1.25,
        slidesToScroll: 1
    };
    const [courseList, setCourseList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const isDesktopOrLapTop = useMediaQuery("(min-width: 1400px)");

    useEffect(() => {
        getCategoryList();
    }, []);

    const getCategoryList = () => {
        fetchQuery(environment, CategoryListQuery)
            .toPromise()
            .then(response => {
                if (response.categories !== null && response.categories.edges.length > 0) {
                    setActiveCategory(response.categories.edges[0].node);
                    setCategoryList(response.categories.edges);
                    getCoursesByCategory(response.categories.edges[0].node);
                } else {
                    setCategoryList([])
                }
            }, err => {
                setCategoryList([])
            });
    }

    const getCoursesByCategory = (category) => {
        setActiveCategory(category)
        let variables = {
            id: category.id
        }
        fetchQuery(environment, CourseListByCategoryQuery, variables)
            .toPromise()
            .then(response => {
                if (response.categoryById.courses !== null && response.categoryById.courses.edges.length > 0) {
                    setCourseList(response.categoryById.courses.edges);
                } else {
                    setCourseList([])
                }
            }, err => {
                // console.log(err);
                setCourseList([])
            });
    }

    const updateCourseList = (course,booleanValue) => {
        // debugger
        let tmp_course_list = courseList.slice();
        let index = courseList.findIndex(course_item => course_item.node.id === course.id);
        if(index > -1){
            tmp_course_list[index] = {
                ...tmp_course_list[index],
                node: {
                    ...tmp_course_list[index].node,
                    isWishlist: booleanValue
                }
            };
        }
        setCourseList(tmp_course_list);
    }

    const isDesktop = useMediaQuery("(min-width: 600px)");
    // console.log(isDesktopOrLapTop)

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SliderNextArrow/>,
        prevArrow: <SliderPrevArrow/>,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,

                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,

                }
            },
            {
                breakpoint: 850,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className={'coursetopic'}>

            {isDesktop ?
                <Fragment>
                    <LayoutContainer>
                        <div id={"category-slider"}>
                            <Slider  {...settings}>
                                {
                                    categoryList.length > 0 && categoryList.map((item, index) => {

                                        return (
                                            <div key={uuid()}
                                                 className={activeCategory.id === item.node.id ? 'active title' : 'title'}
                                                 onClick={() => getCoursesByCategory(item.node)}>{item.node.name}
                                            </div>
                                        )
                                    })
                                }
                            </Slider>
                        </div>
                        <div className={"fullcourses"}>
                            <div className="row">
                                {
                                    courseList.slice(0,6).map((item, index) => {
// debugger
                                            return (
                                                <div className={isDesktopOrLapTop?'col-md-6 col-lg-4 col-xl-3':'col-md-6 col-lg-4 col-xl-4'} key={uuid()}>
                                                    <div className={"mb-4"}>
                                                        <CourseCard courseData={item.node} updateCourseList={updateCourseList} />
                                                    </div>
                                                </div>
                                            );
                                    })
                                }
                            </div>
                            <div className={'coursetopicBtn'}>
                                <BasicButton handleClick={()=>props.history.push('/course-list')}>Load More</BasicButton>
                            </div>
                        </div>
                    </LayoutContainer>
                </Fragment>
                :
                <div className={"mbcoursetopic"}>
                    <Accordion>
                        {
                            categoryList.map((item, index) => {
                                const expanded = activeCategory.id === item.node.id;
                                return (
                                    <AccordionItem key={uuid()} uuid={item.node.id}
                                                   dangerouslySetExpanded={expanded}
                                                   onClick={() => getCoursesByCategory(item.node)}>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <div
                                                    className={`accordian__header ${expanded ? 'expanded' : 'collapsed'}`}>
                                                    <p className={'accordian__title'}>{item.node.name}</p>
                                                    <img src={expanded ? yellodownarrow : topyellowarrow}
                                                         className={'accordian__arrow'}
                                                         alt={'arrow'}/>
                                                </div>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <div className={`accordian__body ${expanded ? 'expanded' : 'collapsed'}`}>
                                                <Slider {...multiple_slider_settings} className={"course-slider"}>
                                                    {
                                                       courseList.map((item, index) => {
                                                            return (
                                                                <div key={uuid()}>
                                                                    <CourseCard courseData={item.node} updateCourseList={updateCourseList}/>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </Slider>
                                            </div>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                );
                            })
                        }
                    </Accordion>
                </div>
            }
        </div>
    );
}

function SliderNextArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div
            className={className}
            style={{...style, display: "block"}}
            onClick={onClick}
        >
            <img src={SliderRightArrowIcon} alt={"next"} className={"img-fluid"}/>
        </div>
    );
}

function SliderPrevArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div
            className={className}
            style={{...style, display: "block"}}
            onClick={onClick}
        >
            <img src={SliderLeftArrowIcon} alt={"prev"} className={"img-fluid"}/>
        </div>
    );
}

const OurExperts = () => {
    const isDesktop = useMediaQuery("(min-width: 600px)");

    const [professorList, setProfessorList] = useState([])

    useEffect(() => {
        getProfessorList()
    }, []);

    const getProfessorList = () => {

        fetchQuery(environment, ProfessorListQuery)
            .toPromise()
            .then(response => {
                if (response.allInstructors !== null && response.allInstructors.edges.length > 0) {
                    setProfessorList(response.allInstructors.edges);
                } else {
                    setProfessorList([]);                }
            }, err => {
                setProfessorList([]);
            });
    }

    let settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: professorList.length>2?3:2,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SliderNextArrow/>,
        prevArrow: <SliderPrevArrow/>,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: professorList.length>2?3:2,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: professorList.length>2?2.25:2,
                    slidesToScroll: 2,
                    infinite: false,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: professorList.length>2?1.5:1,
                    slidesToScroll: 1,
                    infinite: false,
                    arrows: false
                }
            }
        ]
    };


    return (
        <div className={'ourExperts'}>
            <LayoutContainer>
                <div className={"section-title"}>
                    <div className={'main-title'}>Our Experts</div>
                    <div className={"view-all"}>
                        <RoundedButton>
                            View All
                            <img src={leftsmallarrow} className={'leftsmallarrow'} alt={'leftsmallarrow'} style={{marginLeft:'8px'}}/>
                        </RoundedButton>
                    </div>
                </div>
                {
                    professorList.length > 0 && (
                        <div id={"experts-slider"}>
                            <Slider {...settings}>

                                {professorList.map((item, index) => {
                                    return (
                                        <div key={uuid()}>
                                            <ProfessorCard professorData={item.node}/>
                                        </div>
                                    );
                                })}
                            </Slider>
                        </div>
                    )
                }
                
            </LayoutContainer>
        </div>

    );
}


const Carousel_IndustrialSpeakers = () => {
    const [industrialSpeakersstate,setindustrialSpeakersstate]=useState([]);
    const fetchIndustrialSpeakers=()=>{
        fetchQuery(environment,IndustrialSpeakersQuery)
            .toPromise()
            .then(response => {
                if (response.industrialSpeakers !== null) {
                    setindustrialSpeakersstate(response.industrialSpeakers.edges);
                } else {
                    setindustrialSpeakersstate([]);
                }
            }, err => {
                setindustrialSpeakersstate([])
            });
    }
    useEffect(() => {
        fetchIndustrialSpeakers()
    }, []);
    return (
        <div className={'Carousel_IndustrialSpeakers'}>
            <LayoutContainer>
                <div className={'main-title'}>Industrial Speakers</div>
                {
                    industrialSpeakersstate.length !== 0 &&
                    <Swiper slidesPerView={1} spaceBetween={30} slidesPerGroup={1} loop={true}
                            loopFillGroupWithBlank={true}
                            pagination={{
                                "clickable": true
                            }} navigation={true} className="mySwiper">
                        {industrialSpeakersstate.map((item) => {
                            return (
                                <SwiperSlide>
                                    <div className={'IndustrialSpeakers'}>
                                        <div className={'main-poster'}>
                                            <img src={item.node.image} className={'poster w-100'}
                                                 alt={'poster'}/>
                                            <div className={'sub-main'}>
                                                <h3 className={'title'}>{item.node.title}</h3>
                                                <p className={'para'}>{item.node.description} </p>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            );
                        })
                        }
                    </Swiper>
                }
                {
                    industrialSpeakersstate.length === 0 &&
                    <div className={"empty-message"}>
                        No Data Found
                    </div>
                }
            </LayoutContainer>
        </div>
    );
}

const StudentsWords = () => {
    const [count, setCount] = useState(0);
    const [studentWordsstate,setstudentWordsstate]=useState([]);

    const fetchStudentWords=()=>{
        fetchQuery(environment,StudentWordsQuery)
            .toPromise()
            .then(response => {
                if (response.studentWords !== null && response.studentWords.edges.length > 0) {
                    setstudentWordsstate(response.studentWords.edges);
                } else {
                    setstudentWordsstate([]);
                }
            }, err => {
                setstudentWordsstate([])
            });
    }
    useEffect(() => {
        fetchStudentWords()
    }, []);

    return (
        <div className="StudentsWords">
            <LayoutContainer>
                <div className="title">Our Students Words</div>
                {
                    studentWordsstate.length !== 0 &&
                    <div className="row m-0">
                        <div className='col-12 col-sm-6 Details p-0'>
                            {studentWordsstate.map((item, index) => {
                                // console.log()
                                if (index === (count % studentWordsstate.length)) {
                                    return (
                                        <div className="StudentWordscard" data-aos="zoom-in" data-aos-duration="800">
                                            <img src={user1} alt={'user'} className={'my_user'}/>
                                            <img
                                                src={Group19880}
                                                className="style1"
                                                alt="style for student tag"
                                            />
                                            <span
                                                // className="span-studentName">{studentwordscontent.students[(count % 4)].Name}</span>
                                                className="span-studentName">{item.node.name}</span>
                                            <p className="studentWords">
                                                {item.node.description}
                                            </p>
                                        </div>
                                    );
                                }
                            })}


                            <div className="d-flex">
                                <div className="nextstudentbtn"
                                     onClick={() => (count > 0 ? setCount(count - 1) : setCount(0))}>
                                    <img src={LeftArrow} className="LeftArrow" alt="LeftArrow"/>
                                </div>
                                <div className="nextstudentbtn" onClick={() => setCount(count + 1)}>
                                    <img
                                        src={yellowrightArrow}
                                        className="yellowrightArrow"
                                        alt="yellowrightArrow"
                                    />
                                </div>
                            </div>

                        </div>

                        <div className='col-12 col-sm-6 col-wave'>
                            <div className="top-wave">
                                <img src={wave} alt="wave" className="wave img-fluid "/>
                                {studentWordsstate.map((item, index) => {
                                    if (index == 0) {
                                        return (
                                            <div className='wave-outer1'>
                                                <div className='wave-inner1' style={(count % 4 == 1) ? {
                                                    transform: 'scale(.6)',
                                                    transformOrigin: '100% 100%',
                                                    transitionDuration: '4s'
                                                } : {
                                                    transform: 'scale(.2)',
                                                    transformOrigin: '100% 100%',
                                                    transitionDuration: '4s'
                                                }}>
                                                    <img
                                                        src={wavebackgroung}
                                                        alt="wavebackgroung"
                                                        className="wavebackgroung1"
                                                    />
                                                    <img src={item.node.image} alt="user1" className="user1"/>
                                                </div>
                                            </div>
                                        );
                                    }
                                    if (index == 1) {
                                        return (
                                            <div className='wave-outer2'>
                                                <div className='wave-inner2' style={(count % 4 == 2) ? {
                                                    transform: 'scale(.6)',
                                                    transformOrigin: '0% 50%',
                                                    transitionDuration: '4s'
                                                } : {
                                                    transform: 'scale(.2)',
                                                    transformOrigin: '100% 100%',
                                                    transitionDuration: '4s'
                                                }}>
                                                    <img
                                                        src={wavebackgroung}
                                                        alt="wavebackgroung"
                                                        className="wavebackgroung2"
                                                    />
                                                    <img src={item.node.image} alt="user1" className="user2"/>
                                                </div>
                                            </div>
                                        );
                                    }
                                    if (index == 2) {
                                        return (
                                            <div className='wave-outer3'>
                                                <div className='wave-inner3' style={(count % 4 == 3) ? {
                                                    transform: 'scale(.6)',
                                                    transformOrigin: '100% 100%',
                                                    transitionDuration: '4s'
                                                } : {
                                                    transform: 'scale(.2)',
                                                    transformOrigin: '100% 100%',
                                                    transitionDuration: '4s'
                                                }}>
                                                    <img
                                                        src={wavebackgroung}
                                                        alt="wavebackgroung"
                                                        className="wavebackgroung3"
                                                    />
                                                    <img src={item.node.image} alt="user1" className="user3"/>
                                                </div>
                                            </div>
                                        );
                                    }
                                    if (index == 3) {
                                        return (
                                            <div className='wave-outer4'>
                                                <div className='wave-inner4' style={(count % 4 == 0) ? {
                                                    transform: 'scale(.6)',
                                                    transformOrigin: '100% 100%',
                                                    transitionDuration: '4s'
                                                } : {
                                                    transform: 'scale(0.2)',
                                                    transformOrigin: '100% 100%',
                                                    transitionDuration: '4s'
                                                }}>
                                                    <img
                                                        src={wavebackgroung}
                                                        alt="wavebackgroung"
                                                        className="wavebackgroung4"
                                                    />
                                                    <img src={item.node.image} alt="user1" className="user4"/>
                                                </div>
                                            </div>
                                        );
                                    }
                                })
                                }
                            </div>
                        </div>
                    </div>
                }
                {
                    studentWordsstate.length === 0 &&
                    <div className={"empty-message"}>
                        No Data Found
                    </div>
                }
            </LayoutContainer>
        </div>
    );
};

export const Invite = () => {
    return (
        <div className='invite'>
            <LayoutContainer>
                <div className={"content-section"}>
                    <div className={"form-section"}>
                        <h3 className={'title'}>Invite a Friend!</h3>
                        <p className={'para'}>Amet minim mollit non deserunt ullamco est sit aliqua dolor do Amet minim
                            ullamco est
                            sit aliqua dolor do Amet </p>
                        <img src={invitebg} className={'mobile-invitebg'} alt={'invitebg'}/>
                        <div className={'par-email '}>
                            <input type={'email'} className={'Email'} placeholder={'Referral Email'}/>
                            <button className={'send'}>Send Invite</button>
                        </div>
                        {/*<div className={'par-email m-24'}>*/}
                        {/*    <input type={'email'} className={'Email'} placeholder={'1XVI091SUCHITHA'}/>*/}
                        {/*    <button className={'send'}>Copy Code</button>*/}
                        {/*</div>*/}
                    </div>
                    <div className={"image-section"}>
                        <img src={invitebg} className={'invitebg'} alt={'invitebg'}/>
                    </div>
                </div>
            </LayoutContainer>
        </div>);
}
const TrendingCourses = (props) => {
    const [courseList,setCourseList] = useState([]);
    const isDesktopOrLapTop = useMediaQuery("(min-width: 1400px)");

    const multiple_slider_settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1.25,
        slidesToScroll: 1,
        arrows: false
    };
    useEffect(function (){
        getTrendingCourses();
    },[]);
    const getTrendingCourses = () => {
        fetchQuery(environment, CourseListQuery)
            .toPromise()
            .then(response => {
                if (response.courses !== null) {
                    setCourseList(response.courses.edges.slice(0,5));
                } else {
                    setCourseList([]);
                }
            }, err => {
                setCourseList([]);
            });
    }
    const updateCourseList = (course,booleanValue) => {
        let tmp_course_list = courseList.slice();
        let index = courseList.findIndex(course_item => course_item.node.id === course.id);
        if(index > -1){
            tmp_course_list[index] = {
                ...tmp_course_list[index],
                node: {
                    ...tmp_course_list[index].node,
                    isWishlist: booleanValue
                }
            };
        }
        setCourseList(tmp_course_list);
    }
    return (
        <div className={'TrendingCourses'}>
            <LayoutContainer>
                <div className={'d-flex ownflex'}>
                    <h3 className={'title m-0'}>Trending Courses</h3>
                    <RoundedButton handleClick={()=>props.sub_props.history.push("/course-list")}>
                        View All
                        <img src={leftSmallarrow} className='rightArrow' alt='rightArrow'/>
                    </RoundedButton>
                </div>
                <div className="row CourseCardall large-device-trending-courses">
                    {
                        courseList.map((item, index) => {
                            return (
                                <div className={isDesktopOrLapTop?'col-md-6 col-lg-4 col-xl-3':'col-md-6 col-lg-4 col-xl-4'} key={uuid()}>
                                    <div className={"mb-4"}>
                                        <CourseCard courseData={item.node} updateCourseList={updateCourseList}/>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
                <div className={"small-device-trending-courses"}>
                    <Slider {...multiple_slider_settings} className={"course-slider"}>
                        {
                            courseList.map((item, index) => {
                                return (
                                    <div key={uuid()}>
                                        <CourseCard courseData={item.node} updateCourseList={updateCourseList}/>
                                    </div>
                                );
                            })
                        }
                    </Slider>
                </div>
            </LayoutContainer>
        </div>
    )
}


const Carrer = (props) => {
    const isDesktop = useMediaQuery("(min-width: 600px)");
   const history = useHistory();
    return (<div className={'carrer'}>
            {isDesktop ? <></> : <div className="heading">Take <br/>
                <div className={'cross_bg'}>
                    <div className="consult">
                        Carrer Consultation
                    </div>
                </div>
                Now !</div>}
            <div className="card_material">
                <div className="container ">
                    <div className="row main_row">
                        <div className="col-lg-7 col-md-12 col-12 px-0 mask_section my-5">
                            <div className="d-flex justify-content-center ">
                                <div className="particular_flex my-2">
                                    <div className="card sector card_value_size">
                                        <div className="card-body">
                                            <img src={round_man} alt={"...."} className="person_img"/>
                                            <h4 className="card-title">Wade Warren</h4>
                                            <p className="card-text">"It's all good. It was worth a fortune to my
                                                company.
                                                It is the most valuable business resource we have EVER purchased."</p>
                                        </div>
                                    </div>
                                    <div className="card sector card_value my-4 mx-4">
                                        <div className="card-body">
                                            <p className="card-text">"It's all good. It was worth a fortune to my
                                                company.
                                                It is the most valuable business resource we have EVER purchased."</p>
                                        </div>
                                    </div>
                                    <div className="violet">
                                        <img src={violet} alt="..."/>
                                    </div>
                                    <div className="dot">
                                        <img src={dot}/>
                                    </div>
                                </div>
                                <div className="particular_flex">

                                    <div className="card sector card_sector_values ">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-center">
                                                <img src={yellow_start} alt="..." className="yellow_star"/>
                                                <img src={yellow_start} alt="..." className="yellow_star"/>
                                                <img src={yellow_start} alt="..." className="yellow_star"/>
                                                <img src={yellow_start} alt="..." className="yellow_star"/>
                                                <img src={yellow_start} alt="..." className="yellow_star"/>
                                            </div>
                                            <img src={yellow_boy} alt="..." className="yellow_boy"
                                                 className="yellow_boy"/>
                                            <p className="card_titles my-3">Theresa Webb</p>
                                        </div>
                                    </div>
                                    <div className="card sector card_value_size_part my-2">
                                        <div className="card-body card-body1">
                                            <img src={yellow_girl} alt="...." className="girl_img"/>
                                            <h4 className="card-title">Bessie Cooper</h4>
                                            <p className="card-text">"It's all good. It was worth a fortune to my
                                                company.
                                                It is the most valuable business resource we have EVER purchased."</p>
                                        </div>
                                    </div>
                                    <div className="imagesss">
                                        <img src={green}/>
                                    </div>
                                    <div className="red_bg">
                                        <img src={red}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12 col-12 force_flex ">

                            {isDesktop ? <div className="heading">Take <br/>
                                <div className={'cross_bg'}>
                                    <div className="consult">
                                        Carrer Consultation
                                    </div>
                                </div>
                                Now !</div> : <></>}
                            <p className="sub_text">Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt
                                qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim. Elit aute irure
                                tempor cupidatat incididunt sint deserunt ut voluptate aute id deserunt nisi.Aliqua id
                                fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt
                                mollit dolore cillum minim </p>
                            <div onClick={() => history.push('/consultation')}
                                 // onClick={() => handlePageChange("consultation")}
                            ><BasicButton>Consult Now</BasicButton></div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    user_details: state.UserReducer.user_details,
});



export default compose(
    withRouter,connect(mapStateToProps, null)
)(Homepage);

