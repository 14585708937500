import graphql from "babel-plugin-relay/macro";

const CourseLevelListQuery=graphql`

 query CourseLevelListQuery{
  levels {
    edges {
      node {
        id
        name
      }
    }
  }
}
`

export default CourseLevelListQuery;
