import "./Course.scss";
import {MDBBox, MDBCol, MDBContainer, MDBRow} from "mdbreact";
import {LayoutContainer} from "../../Components/LayoutContainer/LayoutContainer";
import CourseAccordian from "../../Containers/CourseAccordian/CourseAccordian";
import PlayIcon from "../../assets/images/ant-design_play-circle-filled.svg";
import React, {useEffect, useState} from "react";
import ArrowDown from "../../assets/icons/dashicons_arrow-down.svg";
import {useParams} from "react-router-dom";
import {fetchQuery} from "relay-runtime";
import environment from "../../Environment";
import CourseByIdQuery from "../../queries/CourseByIdQuery";
import Loader from "../../Components/Loader/Loader";
import VideoPlayer from 'react-video-js-player';
import cogoToast from "cogo-toast";
import VideoEventMutation from "../../mutations/VideoEventMutation";
import Header from "../../Containers/Header/Header";
import {Footer} from "../../Containers/Footer/Footer";
import pdf_icon from "../../assets/icons/pdf.svg"
import link_icon from "../../assets/icons/akar-icons_link-chain.svg"


export const Course = (props) => {
    const [courseDeatil,setCourseDetail] = useState({});
    const [modules,setModules] = useState([]);
    const [activeModules,setActiveModules] = useState({});
    // const [player,setPlayer] = useState({});
    let playerObj = {};
    const [loading,setLoading] = useState(false);
    const [isplaying,setIsPlaying]=useState(false)
    const [listofpdfs,setListOfPdf]=useState([])
    const [listoflink,setListOflink]=useState([])
    const [getLinkArray,setlinkArray]=useState([])
    const params = useParams();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(function (){
        getCourseById();
    },[]);
    const getCourseById = () => {
        let variables = {
            id: params.courseId
        }
        setLoading(true);
        fetchQuery(environment, CourseByIdQuery, variables)
            .toPromise()
            .then(response => {
                setLoading(false);
                if (response.courseById !== null) {

                    let tmp_course_deatil = response.courseById;

                    setCourseDetail(response.courseById);
                    if(tmp_course_deatil.modules && tmp_course_deatil.modules.edges && tmp_course_deatil.modules.edges.length > 0){
                            let first_module = tmp_course_deatil.modules.edges[0];
                            let active_module = {};
                            let active_lesson = {};

                            active_module = tmp_course_deatil.modules.edges.filter( function (module) {
                                return module.node.id === params.moduleId
                            });
                            if(active_module.length > 0){
                                let tmp_lessons = active_module[0].node.lessons.edges;
                                active_lesson = tmp_lessons.filter( function (lesson) {
                                    return lesson.node.id === params.lessonId
                                });
                            }

                            setActiveModules({
                                module:active_module[0]?active_module[0].node:first_module.node,
                                lesson:active_lesson[0]?active_lesson[0].node:first_module.node.lessons.edges[0].node,
                                isWatched:active_lesson[0]?active_lesson[0].node.isWatched:first_module.node.lessons.edges[0].node.isWatched,
                                isWatching:false
                            });

                            setModules(tmp_course_deatil.modules.edges);
                    }
                } else {
                    setCourseDetail({});
                }
            }, err => {
                setLoading(false);
                setCourseDetail({});
            });
    }

    const onPlayerReady = (player) => {
        // console.log("Player is ready: ", player);
        playerObj = player;
        playerObj.currentTime(getCurrentTime());
        // setPlayer(player);

    }

    const onVideoPlay = (duration) => {
        // console.log("Video played at: ", duration);
        // debugger
        // console.log('play')
        setIsPlaying(true)
        let tmp_active_module = activeModules;
        tmp_active_module.isWatching = true;
        setActiveModules(tmp_active_module);

        updateVideoEvent(duration,playerObj.duration());
    }

    const onVideoPause = (duration) => {
        // console.log("Video paused at: ", duration);
        setIsPlaying(false)
        if(duration !== playerObj.duration()){
            updateVideoEvent(duration,playerObj.duration());
        }
    }

    const onVideoTimeUpdate = (duration) => {
        // console.log("Time updated: ", duration);
    }

    const onVideoSeeking = (duration) => {
        // console.log("Video seeking: ", duration);
    }

    const onVideoSeeked = (from, to) => {
        // console.log(`Video seeked from ${from} to ${to}`);
    }

    const onVideoEnd = () => {
        // console.log("Video ended");
        let tmp_active_module = activeModules;
        tmp_active_module.isWatching = false;
        tmp_active_module.isWatched = true;
        setActiveModules(tmp_active_module);

        updateVideoEvent(playerObj.duration(),playerObj.duration());
    }

    const expandDescription = () => {
        let readMoreRef = document.getElementById("readMoreRef");
        let readLessRef = document.getElementById("readLessRef");
        let discriptionRef = document.getElementById("discriptionRef");

        if(readMoreRef && readLessRef && discriptionRef){
            readMoreRef.style.display = "none";
            readLessRef.style.display = "flex";
            discriptionRef.style.height =   discriptionRef.scrollHeight + "px";
        }

    }

    const closeDescription = () => {

        let readMoreRef = document.getElementById("readMoreRef");
        let readLessRef = document.getElementById("readLessRef");
        let discriptionRef = document.getElementById("discriptionRef");

        if(readMoreRef && readLessRef && discriptionRef){
            readMoreRef.style.display = "flex";
            readLessRef.style.display = "none";
            discriptionRef.style.height =   "250px";
            discriptionRef?.scrollIntoView({
                behavior
                    :
                    'smooth'
            });
        }

    }

    const getActiveLesson = () => {
        if(Object.keys(activeModules).length > 0){
            return activeModules.lesson.video
        } else {
            return "https://sgp1.digitaloceanspaces.com/deepsense/verticallimit/dev/media/lessons/videos/file_example_MP4_640_3MG_1tu6QSP.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=B7SCWWVGLMSNH24KXXDW%2F20211224%2Fsgp1%2Fs3%2Faws4_request&X-Amz-Date=20211224T074535Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=efc20baea4e8f4fefe6dc1b7e3f733bdf8d5672317517c2a019936d8bf2d0ca1";
        }
    }

    const getActiveName=()=>{
        if (Object.keys(activeModules).length>0){
            return activeModules.lesson.name
        } else {
            return "";
        }
    }

    const getPdfLink=()=>{
        // debugger
        console.log(activeModules)
        if (Object.keys(activeModules).length>0){
            // return activeModules.lesson.pdf
            const fileURL = activeModules.lesson.pdf
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;
        }else {
            return ""
        }
    }
    const listofPdf=()=>{
        Object.keys(activeModules).length>0 && activeModules.lesson.attachments.edges.map((item,index)=>{
            // debugger
            setListOfPdf(prevState => [...prevState,item.node.file])
        })
    }
    useEffect(()=>{
        listofPdf()
    },[activeModules])


    const isRdfTrue=()=>{
        if (Object.keys(activeModules).length>0 && activeModules.lesson.attachments.edges.length > 0 ){
            return true
        }
        else {
            return false
        }
    }

    const isLinkTrue=()=>{
        if (Object.keys(activeModules).length>0 && activeModules.lesson.urls !== "{}"){

            return true
        }
        else {
            return false
        }
    }
    useEffect(()=>{
        if (Object.keys(activeModules).length>0 && activeModules.lesson.urls !== "{}"){
            const bachendUrls=JSON.parse(activeModules.lesson.urls)
            // console.log('urlsss',typeof(JSON.parse(urlsss)))
            // console.log('bachendUrls',bachendUrls.length)
            setlinkArray(bachendUrls)
        }
    },[activeModules])


    const getCurrentTime = () => {
        if(Object.keys(activeModules).length > 0){
            return parseInt(activeModules.lesson.currentTime);
        } else {
            return 0;
        }
    }
    const getTranscript = () => {
        if(Object.keys(activeModules).length > 0){
            let lesson_detail = activeModules.lesson.transcript;
            if(lesson_detail) {
                let lesson_transcript = JSON.parse(lesson_detail);
                return lesson_transcript.blocks && lesson_transcript.blocks[0] ? lesson_transcript.blocks[0].data.text:"";
            } else {
                return "";
            }
        } else {
            return "";
        }
    }

    const updateVideoEvent = (currentTime,total_duration) => {
        let variables = {
            currentTime:currentTime,
            duration:total_duration,
            lessonId:activeModules.lesson.id
        }
        VideoEventMutation(variables, function (response) {
            if (response.event !== null) {

            } else {
                // cogoToast.error("something went wrong", {position: 'top-center'});
            }
        }, function (err) {
            cogoToast.error(err, {position: 'top-center'});
        });
    }

    if (loading) {
        return (
            <div>
                <Loader full={true} />
                <div  style={{height:window.innerHeight}}/>
            </div>
        );
    }

    if (Object.keys(courseDeatil).length > 0){
        // debugger
        // console.log(courseDeatil.modules.edges)
    }

    function openPdf(link){
        const fileURL = link
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
    }
    return (
        <>
            <Header page={""}/>
            <MDBBox className={"course-component"}>
                {
                    Object.keys(courseDeatil).length > 0 &&

                    <>
                        <MDBBox className={"professor-detail"}>
                            <LayoutContainer>
                                <p className={"course-name d-flex align-items-end"}>
                                    {courseDeatil.name}
                                    <span className={"course-level"}>({courseDeatil.level.name})</span>
                                </p>
                                <p className={"professor-name"}>By {courseDeatil.instructor.user.name}</p>
                                <p className={"course-expiry-date"}>Expiring on 12th, March 2021</p>
                            </LayoutContainer>
                        </MDBBox>
                        <MDBBox className={"video-section"}>
                            <LayoutContainer>
                                <MDBContainer fluid={true} className={"m-0 p-0"}>
                                    <MDBRow className={"m-0"}>
                                        <MDBCol size={12} sm={8} className={"grid-columns"}>
                                            {/*<Player*/}
                                            {/*    fluid={false}*/}
                                            {/*    // width={window.innerWidth} height={window.innerHeight * 0.85}*/}
                                            {/*    playsInline*/}
                                            {/*    poster={PlayIcon}*/}
                                            {/*    src={getActiveLesson()}*/}
                                            {/*    startTime={getCurrentTime()}*/}
                                            {/*/>*/}
                                            <p className="active-video">{activeModules?.lesson?.name}</p>
                                            {
                                                Object.keys(activeModules).length > 0 &&
                                                <VideoPlayer
                                                    controls={true}
                                                    src={getActiveLesson()}
                                                    width="720"
                                                    height="420"
                                                    onReady={onPlayerReady}
                                                    onPlay={onVideoPlay}
                                                    // onPlay={()=>console.log('play')}
                                                    onPause={onVideoPause}
                                                    onTimeUpdate={onVideoTimeUpdate}
                                                    onSeeking={onVideoSeeking}
                                                    onSeeked={onVideoSeeked}
                                                    onEnd={onVideoEnd}
                                                />
                                            }


                                        </MDBCol>
                                        <MDBCol size={12} sm={4} className={"grid-columns"}>
                                            <CourseAccordian isExpandAll={false} preview={false} courseData={courseDeatil} modules={modules} activeModules={activeModules} moveToDetail={true}/>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>
                            </LayoutContainer>
                        </MDBBox>
                        { isRdfTrue() &&
                            <MDBBox>
                                <LayoutContainer>
                                    <MDBContainer fluid={true} className={"m-0 p-0"}>
                                        <MDBRow className={"m-0"}>
                                            <MDBCol size={12} sm={8} className={"grid-columns"} >

                                                <p className={"title-pdf"}>Read Document</p>
                                                { Object.keys(activeModules).length>0 && activeModules.lesson.attachments.edges.map((item,index)=>{

                                                    return(
                                                        <MDBBox className={"pdf-wrapper"}>
                                                            <div className={'inside-pdf-wrapper'} onClick={()=>openPdf(item.node.file)}>
                                                                <div>
                                                                    <img src={pdf_icon} className={'pdf_icon'} alt={'pdf icon'} />
                                                                    {item.node.name}
                                                                </div>

                                                                <p className={'text-view'}>
                                                                    <u>
                                                                        Preview PDF
                                                                    </u>
                                                                </p>

                                                            </div>
                                                        </MDBBox>
                                                        )

                                                })}



                                            </MDBCol>
                                        </MDBRow>
                                    </MDBContainer>
                                </LayoutContainer>
                            </MDBBox>
                        }
                        { isLinkTrue() &&
                            <MDBBox>
                                <LayoutContainer>
                                    <MDBContainer fluid={true} className={"m-0 p-0"}>
                                        <MDBRow className={"m-0"}>
                                            <MDBCol size={12} sm={8} className={"grid-columns"} >
                                                <p className={"title-pdf"}>Read Document</p>
                                                { getLinkArray.map((item,index)=>{

                                                    return(
                                                        <MDBBox className={"pdf-wrapper"}>
                                                            <div className={'inside-pdf-wrapper'} onClick={()=>openPdf(item.link)}>
                                                                <div>
                                                                    <img src={link_icon} className={'pdf_icon'} alt={'pdf icon'} />
                                                                    {item.title}
                                                                </div>

                                                                <p className={'text-view'}>
                                                                    <u>
                                                                        Click Here
                                                                    </u>
                                                                </p>

                                                            </div>
                                                        </MDBBox>
                                                    )

                                                })}



                                            </MDBCol>
                                        </MDBRow>
                                    </MDBContainer>
                                </LayoutContainer>
                            </MDBBox>
                        }

                        {/* <MDBBox className={"auto-transcript-section"}>
                            <LayoutContainer>
                                <MDBContainer fluid={true} className={"m-0 p-0"}>
                                    <MDBRow className={"m-0"}>
                                        <MDBCol size={12} sm={8} className={"grid-columns"}>
                                            <p className={"title"}>Audio Transcript</p>
                                            <MDBBox className={"description-wrapper"}>
                                                <p className={"description"} id={"discriptionRef"}>
                                                    {getTranscript()}
                                                </p>
                                                <MDBBox className={"read-more-content"} id={"readMoreRef"} onClick={expandDescription}>
                                                    <span>Read More</span>
                                                    <img src={ArrowDown} alt={"arrow-down"} className={"img-fluid arrow-down"}/>
                                                </MDBBox>
                                                <MDBBox className={"read-less-content"} id={"readLessRef"} onClick={closeDescription}>
                                                    <span>Read Less</span>
                                                    <img src={ArrowDown} alt={"arrow-down"} className={"img-fluid arrow-down"}/>
                                                </MDBBox>
                                            </MDBBox>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>
                            </LayoutContainer>
                        </MDBBox> */}
                    </>
                }



            </MDBBox>
            <Footer/>
        </>

    );
}
