import graphql from "babel-plugin-relay/macro";


const InstructorProfileQuery=graphql`
    query InstructorProfileQuery{
        myProfile{
            id
            email
            mobileNumber
            name
            dob
            gender
            pincode
            instructor{
                aboutMe
                profilePic
                state{
                    id
                    name
                }
                nationality
                workHistory
                education
                employeeStatus
                experience
                district{
                    id
                    name
                }
            }
        }
    }

`


export default InstructorProfileQuery;






