/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MyCartQueryVariables = {||};
export type MyCartQueryResponse = {|
  +myCart: ?{|
    +id: string,
    +total: ?string,
    +discount: any,
    +discountName: ?string,
    +voucherCode: ?string,
    +checkoutitems: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +course: ?{|
            +id: string,
            +name: string,
            +instructor: ?{|
              +user: {|
                +name: ?string
              |}
            |},
            +image: ?string,
            +price: any,
            +discountPrice: any,
            +averageRating: ?number,
            +isWishlist: ?boolean,
          |},
        |}
      |}>
    |},
  |}
|};
export type MyCartQuery = {|
  variables: MyCartQueryVariables,
  response: MyCartQueryResponse,
|};
*/


/*
query MyCartQuery {
  myCart {
    id
    total
    discount
    discountName
    voucherCode
    checkoutitems {
      edges {
        node {
          id
          course {
            id
            name
            instructor {
              user {
                name
                id
              }
              id
            }
            image
            price
            discountPrice
            averageRating
            isWishlist
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voucherCode",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountPrice",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "averageRating",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isWishlist",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MyCartQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CheckoutNode",
        "kind": "LinkedField",
        "name": "myCart",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CheckoutItemNodeConnection",
            "kind": "LinkedField",
            "name": "checkoutitems",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CheckoutItemNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CheckoutItemNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CourseType",
                        "kind": "LinkedField",
                        "name": "course",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "InstructorType",
                            "kind": "LinkedField",
                            "name": "instructor",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "UserType",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MyCartQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CheckoutNode",
        "kind": "LinkedField",
        "name": "myCart",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CheckoutItemNodeConnection",
            "kind": "LinkedField",
            "name": "checkoutitems",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CheckoutItemNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CheckoutItemNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CourseType",
                        "kind": "LinkedField",
                        "name": "course",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "InstructorType",
                            "kind": "LinkedField",
                            "name": "instructor",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "UserType",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v0/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v0/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4530916d512c6909d3f79af5ab8627c4",
    "id": null,
    "metadata": {},
    "name": "MyCartQuery",
    "operationKind": "query",
    "text": "query MyCartQuery {\n  myCart {\n    id\n    total\n    discount\n    discountName\n    voucherCode\n    checkoutitems {\n      edges {\n        node {\n          id\n          course {\n            id\n            name\n            instructor {\n              user {\n                name\n                id\n              }\n              id\n            }\n            image\n            price\n            discountPrice\n            averageRating\n            isWishlist\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '677eb258fd1fdd71ef2ee24c05778e29';

module.exports = node;
