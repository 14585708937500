/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveFromCartMutationVariables = {|
  cartItemId: string
|};
export type RemoveFromCartMutationResponse = {|
  +removeFromCart: ?{|
    +status: ?string,
    +cart: ?{|
      +id: string,
      +total: ?string,
      +discount: any,
      +discountName: ?string,
      +voucherCode: ?string,
      +checkoutitems: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +course: ?{|
              +id: string,
              +name: string,
              +instructor: ?{|
                +user: {|
                  +name: ?string
                |}
              |},
              +image: ?string,
              +price: any,
              +discountPrice: any,
              +averageRating: ?number,
              +isWishlist: ?boolean,
            |},
          |}
        |}>
      |},
    |},
  |}
|};
export type RemoveFromCartMutation = {|
  variables: RemoveFromCartMutationVariables,
  response: RemoveFromCartMutationResponse,
|};
*/


/*
mutation RemoveFromCartMutation(
  $cartItemId: String!
) {
  removeFromCart(cartItemId: $cartItemId) {
    status
    cart {
      id
      total
      discount
      discountName
      voucherCode
      checkoutitems {
        edges {
          node {
            id
            course {
              id
              name
              instructor {
                user {
                  name
                  id
                }
                id
              }
              image
              price
              discountPrice
              averageRating
              isWishlist
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cartItemId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cartItemId",
    "variableName": "cartItemId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voucherCode",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountPrice",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "averageRating",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isWishlist",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveFromCartMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveFromCartMutation",
        "kind": "LinkedField",
        "name": "removeFromCart",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CheckoutNode",
            "kind": "LinkedField",
            "name": "cart",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CheckoutItemNodeConnection",
                "kind": "LinkedField",
                "name": "checkoutitems",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CheckoutItemNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CheckoutItemNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CourseType",
                            "kind": "LinkedField",
                            "name": "course",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "InstructorType",
                                "kind": "LinkedField",
                                "name": "instructor",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "UserType",
                                    "kind": "LinkedField",
                                    "name": "user",
                                    "plural": false,
                                    "selections": [
                                      (v8/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v9/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/),
                              (v13/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveFromCartMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveFromCartMutation",
        "kind": "LinkedField",
        "name": "removeFromCart",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CheckoutNode",
            "kind": "LinkedField",
            "name": "cart",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CheckoutItemNodeConnection",
                "kind": "LinkedField",
                "name": "checkoutitems",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CheckoutItemNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CheckoutItemNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CourseType",
                            "kind": "LinkedField",
                            "name": "course",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "InstructorType",
                                "kind": "LinkedField",
                                "name": "instructor",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "UserType",
                                    "kind": "LinkedField",
                                    "name": "user",
                                    "plural": false,
                                    "selections": [
                                      (v8/*: any*/),
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v9/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/),
                              (v13/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4ed67eb1b0fc214e8cc9b35cec6ced3d",
    "id": null,
    "metadata": {},
    "name": "RemoveFromCartMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveFromCartMutation(\n  $cartItemId: String!\n) {\n  removeFromCart(cartItemId: $cartItemId) {\n    status\n    cart {\n      id\n      total\n      discount\n      discountName\n      voucherCode\n      checkoutitems {\n        edges {\n          node {\n            id\n            course {\n              id\n              name\n              instructor {\n                user {\n                  name\n                  id\n                }\n                id\n              }\n              image\n              price\n              discountPrice\n              averageRating\n              isWishlist\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f37b86f87bbbbadf33630b9d01e719a7';

module.exports = node;
