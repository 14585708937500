import "./ErrorMessage.scss";
import emptycart_img from "../../assets/icons/emptyCart.svg";
import {BasicButton} from "../Buttons/BasicButton";
import React from "react";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import Header from "../../Containers/Header/Header";
import {Footer} from "../../Containers/Footer/Footer";


 const ErrorMessage = (props) => {

    return(
        <>
            {/* <Header page={"wishlist"}/> */}
            <div className={'cart-error-message'}>
                <img src={props.img} alt={'No Courses Found in Your Cart'} className={'cart-error-img'}/>
                <div className={'cart-error-text'} >
                    <h3 className={'cart-error-header'}>{props.title}</h3>
                    <p className={'cart-error-para'}>{props.text}</p>
                    <BasicButton handleClick={()=> props.history.push(`${props.onclickhandeler}`)}>Explore Courses</BasicButton>
                </div>
            </div>
            {/* <Footer/> */}
        </>

    );
}
export default compose(withRouter)(ErrorMessage);