import graphql from "babel-plugin-relay/macro";

const AddToWishListQuery=graphql`

 query AddToWishListQuery($courseId:String){
      addToWishList(courseId:$courseId){
        id
        name
        instructor{
            user{
                name
            }
        }
        image
        price
        discountPrice
        averageRating
        isWishlist
      }
}



`

export default AddToWishListQuery;
