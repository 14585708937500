import './Blog.scss';
import blog_page_img_1 from '../../assets/images/blog_page_img_1.png'
import blog_page_img_2 from '../../assets/images/blog_page_img_2.png'
import blog_page_img_3 from '../../assets/images/blog_page_img_3.png'
import {LayoutContainer} from "../../Components/LayoutContainer/LayoutContainer";
import {BlogList} from "../CourseList/CourseList";
import useMediaQuery from "../../util/useMediaQuery";
import React, {useEffect, useState} from "react";
import {fetchQuery} from "relay-runtime";
import environment from "../../Environment";
// import PaymentHistoryListQuery from "../../queries/PaymentHistoryListQuery";
import BlogListQuery from "../../queries/BlogListQuery";
import parse from "html-react-parser";
import uuid from 'react-uuid'
import Header from "../../Containers/Header/Header";
import {Footer} from "../../Containers/Footer/Footer";

export const Blog = () => {
    const isDesktop = useMediaQuery("(min-width: 765px)");
    const [blogList,setBlogList]=useState([])
     const [afterjson,setAfterjson]=useState("")
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const fetchBlogList=()=>{
        fetchQuery(environment,BlogListQuery)
            .toPromise()
            .then(response => {
                if (response.allBlog !== null && response.allBlog.edges.length > 0) {
                    setBlogList(response.allBlog.edges);
                    const text=(response.allBlog.edges[5].node.body)
                    const parseJson=  JSON.parse(text)
                    setAfterjson(parseJson.blocks[0].data.text)
                } else {
                    setBlogList([]);
                }
            }, err => {
                setBlogList([])
            });
    }
    useEffect(() => {
        fetchBlogList()
    }, []);

    function truncateString(str, num) {
        if (str.length <= num) {
            return str
        }
        return str.slice(0, num) + '...'
    }


    return (
        <>
            <Header page={"blogs"}/>
            <div className={'blog1'}>
            <div className={'title1'}>
                Blogs
            </div>
            <LayoutContainer>

                {isDesktop ?

                    <div className={'main-center'}>
                        <div className={'row'}>
                            <div className={'col-md-7'}>

                                <div className={'row h-100'}>

                                    {blogList.map((item,index)=>{

                                        const tern=(item.node.updatedAt)
                                        const arrdatetime = (item.node.updatedAt).split("-");
                                        const arrdate=(arrdatetime[2]).split("T")
                                        const date=arrdate[0]
                                        const fullmonthname = ["January","February","March","April","May","June","July","August","September","October","November","December"];
                                        const month=arrdatetime[1]
                                        let monthname = fullmonthname[month-1];
                                        const year=arrdatetime[0]

                                        if(index==0) {

                                            return (
                                                <div className={'col-md-12 fist-container'} key={uuid()}>
                                                    <div className={'d-flex gap_24'}>
                                                        <div><img src={item.node.featuredMobileImage} className={'blog_page_img_1'}
                                                                  alt={'blog_page_img_1'}/></div>
                                                        <div className={'content'}>
                                                            <p className={'date'}>{date}th, {monthname} {year}</p>
                                                            <h3 className={'question'}>{item.node.title}</h3>
                                                            {/*{parse(props.afterjson) }*/}
                                                            <h5 className={'answer'}>{afterjson && truncateString((parse(afterjson)).replace(/['&nbsp;]+/gi,' '),150) }</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        }
                                    )}

                                    {blogList.map((item,index)=>{

                                        const tern=(item.node.updatedAt)
                                        const arrdatetime = (item.node.updatedAt).split("-");
                                        const arrdate=(arrdatetime[2]).split("T")
                                        const date=arrdate[0]
                                        const fullmonthname = ["January","February","March","April","May","June","July","August","September","October","November","December"];
                                        const month=arrdatetime[1]
                                        let monthname = fullmonthname[month-1];
                                        const year=arrdatetime[0]

                                        if(index==1) {
                                            return (
                                    <div className={'col-md-12 secondary-container'} key={uuid()}>
                                        <div className={'d-flex gap_24'}>
                                            <div><img src={item.node.featuredMobileImage} className={'blog_page_img_2'}
                                                      alt={'blog_page_img_2'}/></div>
                                            <div className={'content'}>
                                                <p className={'date'}>{date}th, {monthname} {year}</p>
                                                <h3 className={'question'}>{item.node.title}</h3>
                                                <h5 className={'answer'}>{afterjson && truncateString(parse(afterjson).replace(/['&nbsp;]+/gi,' '),35) }</h5>
                                            </div>
                                        </div>
                                    </div>
                                            )
                                        }
                                        }
                                    )}

                                </div>
                            </div>
                            {blogList.map((item,index)=>{

                                const tern=(item.node.updatedAt)
                                const arrdatetime = (item.node.updatedAt).split("-");
                                const arrdate=(arrdatetime[2]).split("T")
                                const date=arrdate[0]
                                const fullmonthname = ["January","February","March","April","May","June","July","August","September","October","November","December"];
                                const month=arrdatetime[1]
                                let monthname = fullmonthname[month-1];
                                const year=arrdatetime[0]

                                if(index==2) {
                                    return (

                            <div className={'col-md-5'} key={uuid()}>
                                <img src={item.node.featuredImage} className={'blog_page_img_3'} alt={'blog_page_img_3'}/>
                                <p className={'date'}>{date}th, {monthname} {year}</p>
                                <h3 className={'question'}>{item.node.title}</h3>
                                <h5 className={'answer'}>{afterjson && truncateString(parse(afterjson).replace(/['&nbsp;]+/gi,' '),150) }</h5>
                            </div>
                                    )
                                }
                                }
                            )}


                        </div>
                    </div>
                    :
                    <></>
                }


                <div className={'Blog_content_content'}>

                                {/*<Blog_content blogList={blogList} afterjson={parse(afterjson)}/>*/}


                </div>


            </LayoutContainer>
        </div>
            <Footer/>
        </>
    );
}
