import graphql from "babel-plugin-relay/macro";

const GetInstructorConsultationsQuery=graphql`

    query GetInstructorConsultationsQuery($orderBy:String){
        getInstructorConsultations(orderBy: $orderBy){
            edges{
                node{
                    id
                    name
                    email
                    interest
                    mobileNumber
                    status
                    collegeName
                    organization
                    degreeType
                    yearOfGraduation
                    designation
                    location
                    fieldOrMajor
                    message
                }
            }
        }
    }
`
export default GetInstructorConsultationsQuery