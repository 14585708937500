import "./OrderConfirmation.scss";
import {BasicButton} from "../../Components/Buttons/BasicButton";
import React, {useEffect} from "react";
import {withRouter} from "react-router-dom";
import {fetchCartListSuccess} from "../../Actions/CartAction";
import {clearCheckout} from "../../Actions/OrderAction";
import {compose} from "redux";
import {connect} from "react-redux";
import Header from "../../Containers/Header/Header";
import {Footer} from "../../Containers/Footer/Footer";

const OrderConfirmation = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(function (){
        if(Object.keys(props.checkout_data).length > 0){
        } else {
            props.history.push('/');
        }
    },[]);
    const GotoHomePage = () => {
        props.clearCheckout();
        props.history.push('/');
    };
    const {checkout_data} = props;
    return(
        <div>
            <Header page={""}/>
            <div className="order-confirmation">
                <h3 className={"text-center"}>
                    Thank you <br />  for placing an order!
                </h3>
                <p>Your Order ID : {checkout_data.order_data.orderId}</p>
                <div className="order-confirmation__actions">
                    <BasicButton handleClick={GotoHomePage}>Continue Shopping</BasicButton>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

const mapStateToProps = state => ({
    checkout_data: state.OrderReducer.checkout_data,
});

const mapDispatchToProps = dispatch => ({
    clearCheckout: () => dispatch(clearCheckout()),
});


export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(OrderConfirmation);
