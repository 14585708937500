import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'

const mutation = graphql`
  mutation CompleteProfileMutation(
    $collegeName: String!
    $courseName: String!
    $dateOfBirth: Date!
    $degreeType: DegreeTypeEnum!
    $gender: GenderTypeEnum!
    $name: String!
    $organizationName: String
    $pincode: String
    $userId: String!
    $workExperience: String
    $workHistory: Boolean!
    $yearOfGraduation: Date!
  ) {
       completeProfile(name:$name,dateOfBirth:$dateOfBirth,gender:$gender,pincode:$pincode,
          collegeName:$collegeName,yearOfGraduation:$yearOfGraduation,degreeType:$degreeType,courseName:$courseName,workHistory:$workHistory,
          organizationName:$organizationName,workExperience:$workExperience,userId:$userId){
            status
            message
            token
            refreshToken
            user{
              id
              lastLogin
              email
              mobileNumber
              name
              dob
              gender
              referalCode
              pincode
              isActive
              isAdmin
              isInstructor   
              wishlist{
                edges{
                  node{
                    id
                  }
                }
              }   
            }
       }
  }
`

export default (userData, callback,errCallback) => {
    const variables = {
        collegeName: userData.collegeName,
        courseName: userData.courseName,
        dateOfBirth: userData.dateOfBirth,
        degreeType: userData.degreeType,
        gender: userData.gender,
        name: userData.name,
        organizationName: userData.organizationName,
        pincode: userData.pincode,
        userId: userData.userId,
        workExperience: userData.workExperience,
        workHistory: userData.workHistory,
        yearOfGraduation: userData.yearOfGraduation
    }

    // 5
    commitMutation(
        environment,
        {
            mutation,
            variables,
            // 6
            onCompleted: (response,err) => {

                if(response.completeProfile !== null){
                    callback(response)
                } else{
                    errCallback(err[0].message);
                }

            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}
