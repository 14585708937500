import './EditProfile.scss';
import {RoundedButton} from "../../../Components/Buttons/RoundedButton";
import useMediaQuery from "../../../util/useMediaQuery";
import {FlatButton} from "../../../Components/Buttons/FlatButton";
import yellow_edit from "../../../assets/images/yelow_edit.png";
import profile_photo from "../../../assets/images/professorpic.svg"
import white_edit from "../../../assets/images/white_edit.png"
import {fetchQuery} from "relay-runtime";
import environment from "../../../Environment";
import React, {useEffect, useState} from "react";
import MyProfileQuery from "../../../queries/MyProfileQuery";
import ProfileNavbar from "../ProfileNavbar/ProfileNavbar";
import cogoToast from "cogo-toast";
import UpdateBackgroundDetailsMutation from "../../../mutations/UpdateBackgroundDetailsMutation";
import axios from "axios";
import {api} from "../../../serviceApi";
import AllStatesQuery from "../../../queries/AllStatesQuery";
import GetDistrictByStateQuery from "../../../queries/GetDistrictByStateQuery";
import uuid from "react-uuid";
import * as EmailValidator from 'email-validator';
import {capitalizeFirstLetter} from "../../../util/MinorUtility";
import Header from "../../../Containers/Header/Header";
import {Footer} from "../../../Containers/Footer/Footer";

export const EditProfile = () => {
    //options
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [ProfileEmployementStatus, setProfileEmployementStatus] = useState([
        {
            "name": "EMPLOYED"
        },
        {
            "name": "UNEMPLOYED"
        },
        {
            "name": "SELF_EMPLOYED"
        },
        {
            "name": "STUDEN"
        }]);
    const [ProfileExperienceLevel, setProfileExperienceLevel] = useState([
        {
            "name": "JUNIOR"
        },
        {
            "name": "MID_LEVEL"
        },
        {
            "name": "SENIOR"
        },
        {
            "name": "OTHERS"
        },

    ]);
    const [ProfileDegreeType, setProfileDegreeType] = useState([
        {
            "name": "UG"
        },
        {
            "name": "PG"
        },
        {
            "name": "PHD"
        },
        {
            "name": "MASTERS"
        },
        {
            "name": "OTHERS"
        }
    ]);
    const [ProfileNationality, setProfileNationality] = useState([
        {
            "name": "AF"
        },
        {
            "name": "AX"
        },
        {
            "name": "AL"
        },
        {
            "name": "DZ"
        },
        {
            "name": "AS"
        },
        {
            "name": "AD"
        },
        {
            "name": "AO"
        },
        {
            "name": "AI"
        },
        {
            "name": "AQ"
        },
        {
            "name": "AG"
        },
        {
            "name": "AR"
        },
        {
            "name": "AM"
        },
        {
            "name": "AW"
        },
        {
            "name": "AU"
        },
        {
            "name": "AT"
        },
        {
            "name": "AZ"
        },
        {
            "name": "BS"
        },
        {
            "name": "BH"
        },
        {
            "name": "BD"
        },
        {
            "name": "BB"
        },
        {
            "name": "BY"
        },
        {
            "name": "BE"
        },
        {
            "name": "BZ"
        },
        {
            "name": "BJ"
        },
        {
            "name": "BM"
        },
        {
            "name": "BT"
        },
        {
            "name": "BO"
        },
        {
            "name": "BQ"
        },
        {
            "name": "BA"
        },
        {
            "name": "BW"
        },
        {
            "name": "BV"
        },
        {
            "name": "BR"
        },
        {
            "name": "IO"
        },
        {
            "name": "BN"
        },
        {
            "name": "BG"
        },
        {
            "name": "BF"
        },
        {
            "name": "BI"
        },
        {
            "name": "CV"
        },
        {
            "name": "KH"
        },
        {
            "name": "CM"
        },
        {
            "name": "CA"
        },
        {
            "name": "KY"
        },
        {
            "name": "CF"
        },
        {
            "name": "TD"
        },
        {
            "name": "CL"
        },
        {
            "name": "CN"
        },
        {
            "name": "CX"
        },
        {
            "name": "CC"
        },
        {
            "name": "CO"
        },
        {
            "name": "KM"
        },
        {
            "name": "CG"
        },
        {
            "name": "CD"
        },
        {
            "name": "CK"
        },
        {
            "name": "CR"
        },
        {
            "name": "CI"
        },
        {
            "name": "HR"
        },
        {
            "name": "CU"
        },
        {
            "name": "CW"
        },
        {
            "name": "CY"
        },
        {
            "name": "CZ"
        },
        {
            "name": "DK"
        },
        {
            "name": "DJ"
        },
        {
            "name": "DM"
        },
        {
            "name": "DO"
        },
        {
            "name": "EC"
        },
        {
            "name": "EG"
        },
        {
            "name": "SV"
        },
        {
            "name": "GQ"
        },
        {
            "name": "ER"
        },
        {
            "name": "EE"
        },
        {
            "name": "SZ"
        },
        {
            "name": "ET"
        },
        {
            "name": "FK"
        },
        {
            "name": "FO"
        },
        {
            "name": "FJ"
        },
        {
            "name": "FI"
        },
        {
            "name": "FR"
        },
        {
            "name": "GF"
        },
        {
            "name": "PF"
        },
        {
            "name": "TF"
        },
        {
            "name": "GA"
        },
        {
            "name": "GM"
        },
        {
            "name": "GE"
        },
        {
            "name": "DE"
        },
        {
            "name": "GH"
        },
        {
            "name": "GI"
        },
        {
            "name": "GR"
        },
        {
            "name": "GL"
        },
        {
            "name": "GD"
        },
        {
            "name": "GP"
        },
        {
            "name": "GU"
        },
        {
            "name": "GT"
        },
        {
            "name": "GG"
        },
        {
            "name": "GN"
        },
        {
            "name": "GW"
        },
        {
            "name": "GY"
        },
        {
            "name": "HT"
        },
        {
            "name": "HM"
        },
        {
            "name": "VA"
        },
        {
            "name": "HN"
        },
        {
            "name": "HK"
        },
        {
            "name": "HU"
        },
        {
            "name": "IS"
        },
        {
            "name": "IN"
        },
        {
            "name": "ID"
        },
        {
            "name": "IR"
        },
        {
            "name": "IQ"
        },
        {
            "name": "IE"
        },
        {
            "name": "IM"
        },
        {
            "name": "IL"
        },
        {
            "name": "IT"
        },
        {
            "name": "JM"
        },
        {
            "name": "JP"
        },
        {
            "name": "JE"
        },
        {
            "name": "JO"
        },
        {
            "name": "KZ"
        },
        {
            "name": "KE"
        },
        {
            "name": "KI"
        },
        {
            "name": "KW"
        },
        {
            "name": "KG"
        },
        {
            "name": "LA"
        },
        {
            "name": "LV"
        },
        {
            "name": "LB"
        },
        {
            "name": "LS"
        },
        {
            "name": "LR"
        },
        {
            "name": "LY"
        },
        {
            "name": "LI"
        },
        {
            "name": "LT"
        },
        {
            "name": "LU"
        },
        {
            "name": "MO"
        },
        {
            "name": "MG"
        },
        {
            "name": "MW"
        },
        {
            "name": "MY"
        },
        {
            "name": "MV"
        },
        {
            "name": "ML"
        },
        {
            "name": "MT"
        },
        {
            "name": "MH"
        },
        {
            "name": "MQ"
        },
        {
            "name": "MR"
        },
        {
            "name": "MU"
        },
        {
            "name": "YT"
        },
        {
            "name": "MX"
        },
        {
            "name": "FM"
        },
        {
            "name": "MD"
        },
        {
            "name": "MC"
        },
        {
            "name": "MN"
        },
        {
            "name": "ME"
        },
        {
            "name": "MS"
        },
        {
            "name": "MA"
        },
        {
            "name": "MZ"
        },
        {
            "name": "MM"
        },
        {
            "name": "NA"
        },
        {
            "name": "NR"
        },
        {
            "name": "NP"
        },
        {
            "name": "NL"
        },
        {
            "name": "NC"
        },
        {
            "name": "NZ"
        },
        {
            "name": "NI"
        },
        {
            "name": "NE"
        },
        {
            "name": "NG"
        },
        {
            "name": "NU"
        },
        {
            "name": "NF"
        },
        {
            "name": "KP"
        },
        {
            "name": "MK"
        },
        {
            "name": "MP"
        },
        {
            "name": "NO"
        },
        {
            "name": "OM"
        },
        {
            "name": "PK"
        },
        {
            "name": "PW"
        },
        {
            "name": "PS"
        },
        {
            "name": "PA"
        },
        {
            "name": "PG"
        },
        {
            "name": "PY"
        },
        {
            "name": "PE"
        },
        {
            "name": "PH"
        },
        {
            "name": "PN"
        },
        {
            "name": "PL"
        },
        {
            "name": "PT"
        },
        {
            "name": "PR"
        },
        {
            "name": "QA"
        },
        {
            "name": "RE"
        },
        {
            "name": "RO"
        },
        {
            "name": "RU"
        },
        {
            "name": "RW"
        },
        {
            "name": "BL"
        },
        {
            "name": "SH"
        },
        {
            "name": "KN"
        },
        {
            "name": "LC"
        },
        {
            "name": "MF"
        },
        {
            "name": "PM"
        },
        {
            "name": "VC"
        },
        {
            "name": "WS"
        },
        {
            "name": "SM"
        },
        {
            "name": "ST"
        },
        {
            "name": "SA"
        },
        {
            "name": "SN"
        },
        {
            "name": "RS"
        },
        {
            "name": "SC"
        },
        {
            "name": "SL"
        },
        {
            "name": "SG"
        },
        {
            "name": "SX"
        },
        {
            "name": "SK"
        },
        {
            "name": "SI"
        },
        {
            "name": "SB"
        },
        {
            "name": "SO"
        },
        {
            "name": "ZA"
        },
        {
            "name": "GS"
        },
        {
            "name": "KR"
        },
        {
            "name": "SS"
        },
        {
            "name": "ES"
        },
        {
            "name": "LK"
        },
        {
            "name": "SD"
        },
        {
            "name": "SR"
        },
        {
            "name": "SJ"
        },
        {
            "name": "SE"
        },
        {
            "name": "CH"
        },
        {
            "name": "SY"
        },
        {
            "name": "TW"
        },
        {
            "name": "TJ"
        },
        {
            "name": "TZ"
        },
        {
            "name": "TH"
        },
        {
            "name": "TL"
        },
        {
            "name": "TG"
        },
        {
            "name": "TK"
        },
        {
            "name": "TO"
        },
        {
            "name": "TT"
        },
        {
            "name": "TN"
        },
        {
            "name": "TR"
        },
        {
            "name": "TM"
        },
        {
            "name": "TC"
        },
        {
            "name": "TV"
        },
        {
            "name": "UG"
        },
        {
            "name": "UA"
        },
        {
            "name": "AE"
        },
        {
            "name": "GB"
        },
        {
            "name": "UM"
        },
        {
            "name": "US"
        },
        {
            "name": "UY"
        },
        {
            "name": "UZ"
        },
        {
            "name": "VU"
        },
        {
            "name": "VE"
        },
        {
            "name": "VN"
        },
        {
            "name": "VG"
        },
        {
            "name": "VI"
        },
        {
            "name": "WF"
        },
        {
            "name": "EH"
        },
        {
            "name": "YE"
        },
        {
            "name": "ZM"
        },
        {
            "name": "ZW"
        }
    ]);
    const [UserGender, setUserGender] = useState([
        {
            "name": "Male"
        },
        {
            "name": "Female"
        },
        {
            "name": "Others"
        }
    ]);
    const isDesktop = useMediaQuery("(min-width: 900px)");
    const [profileDetail, setProfileDetail] = useState({});
    const [insideprofile, setinsideprofile] = useState([])
    const [readonlydata, setReadonlydata] = useState(true);
    const [readonlydata1, setReadonlydata1] = useState(true);
    const [valueErrors, setValueErrors] = useState({});
    const [stateList, setStateList] = useState([]);
    const [districtList, setDistrictList] = useState([]);
    const [nationalitystate, setnationalitystate] = useState([]);
    const [degreeType, setdegreeType] = useState([]);
    const [selectedProfilePic, setSelectedProfilePic] = useState(null);
    const [selectedState, setSelectedState] = useState({});
    const [selectedDistrict, setSelectedDistrict] = useState({});


    useEffect(() => {
        fetchProfilDetails();
        fetchStateDetails();
    }, []);

    const fetchProfilDetails = () => {
        fetchQuery(environment, MyProfileQuery)
            .toPromise()
            .then(response => {
                if (response.myProfile !== null) {
                    setProfileDetail(response.myProfile)
                } else {
                    setProfileDetail({});
                }
            }, err => {
                // console.log(err);
                setProfileDetail({});
            });
    }

    const fetchStateDetails = () => {
        fetchQuery(environment, AllStatesQuery)
            .toPromise()
            .then(response => {
                if (response.allStates !== null) {
                    setStateList(response.allStates)
                } else {
                    setStateList([]);
                }
            }, err => {
                // console.log(err);
                setStateList([]);
            });
    }

    const handleInputChanges = (e, feild, nested_object_key,input_type) => {

        if (!readonlydata || !readonlydata1) {
            let tmp_profile_data = {...profileDetail};
            if (nested_object_key) {
                let value;
                if(input_type === "number"){
                    value = (e.target.validity.valid || e.target.value === "") ? e.target.value : tmp_profile_data[nested_object_key][feild];
                } else {
                    value = e.target.value;
                }
                tmp_profile_data[nested_object_key] = {
                    ...tmp_profile_data[nested_object_key],
                    [feild]: value
                }
            } else {
                let value;
                if(input_type === "number"){
                    value = (e.target.validity.valid || e.target.value === "") ? e.target.value : tmp_profile_data[feild];
                } else {
                    value = e.target.value;
                }
                tmp_profile_data[feild] = value;
            }
            setProfileDetail(tmp_profile_data);
        }
    }

    const handleStateChange = (e, feild, nested_object_key) => {
        if (!readonlydata) {
            let tmp_profile_data = {...profileDetail};
            let tmp_selected_state = stateList.find(d => d["id"] === e.target.value);
            if (nested_object_key) {
                tmp_profile_data[nested_object_key] = {
                    ...tmp_profile_data[nested_object_key],
                    [feild]: tmp_selected_state
                }
            } else {
                tmp_profile_data[feild] = tmp_selected_state;
            }
            setSelectedState(tmp_selected_state);
            setProfileDetail(tmp_profile_data);
            fetchDistrictByState(tmp_selected_state);
        }
    }

    const handleDistrictChange = (e, feild, nested_object_key) => {
        if (!readonlydata) {
            let tmp_profile_data = {...profileDetail};
            let tmp_selected_district = districtList.find(d => d["id"] === e.target.value);
            if (nested_object_key) {
                tmp_profile_data[nested_object_key] = {
                    ...tmp_profile_data[nested_object_key],
                    [feild]: tmp_selected_district
                }
            } else {
                tmp_profile_data[feild] = tmp_selected_district;
            }
            setSelectedDistrict(tmp_selected_district);
            setProfileDetail(tmp_profile_data);
        }
    }

    const submitBasicForm = () => {
        let error = {};
        if (profileDetail.name === "") {
            error = {
                field: "name",
                message: "Please fill name"
            };
            setValueErrors(error);
        } else if (profileDetail.dob === "") {
            error = {
                field: "dob",
                message: "Please choose Date of Birth"
            };
            setValueErrors(error);
        } else if (profileDetail.gender === "") {
            error = {
                field: "gender",
                message: "Please choose gender"
            };
            setValueErrors(error);
        } else if (EmailValidator.validate(profileDetail.email) === false) {
            error = {
                field: "email",
                message: "Please fill valid email"
            };
            setValueErrors(error);
        }else if (profileDetail.mobileNumber === "") {
            error = {
                field: "mobileNumber",
                message: "Please fill mobile number"
            };
            setValueErrors(error);
        }else if (profileDetail.mobileNumber.length < 10) {
            error = {
                field: "mobileNumber",
                message: "mobile number should be 10 digits"
            };
            setValueErrors(error);
        }else if (profileDetail.profile.nationality === "" || profileDetail.profile.nationality === null || profileDetail.profile.nationality === undefined) {
            error = {
                field: "nationality",
                message: "Please choose nationality"
            };
            setValueErrors(error);
        }else if (profileDetail.profile.state === "" || profileDetail.profile.state === null || profileDetail.profile.state === undefined) {
            error = {
                field: "state",
                message: "Please choose state"
            };
            setValueErrors(error);
        }else if (profileDetail.profile.district === "" || profileDetail.profile.district === null || profileDetail.profile.district === undefined) {
            error = {
                field: "district",
                message: "Please choose district"
            };
            setValueErrors(error);
        } else if (profileDetail.pincode === "") {
            error = {
                field: "pincode",
                message: "Please fill pincode"
            };
            setValueErrors(error);
        } else if (profileDetail.pincode.length < 6||profileDetail.pincode.length > 6) {
            error = {
                field: "pincode",
                message: "pincode should be 6 digits"
            };
            setValueErrors(error);
        } else {
            let userData = {
                dateOfBirth: profileDetail.dob,
                districtId:profileDetail.profile.district.id,
                email: profileDetail.email,
                gender: profileDetail.gender.toLowerCase().replace(/./, c => c.toUpperCase()),
                mobileNumber: profileDetail.mobileNumber,
                name: profileDetail.name,
                nationality: profileDetail.profile.nationality,
                pincode: profileDetail.pincode,
                stateId: profileDetail.profile.state.id,
            };
            error = {};
            setValueErrors(error);
            let formData = new FormData();
            formData.append("query", "mutation UpdateBasicDetailsMutation(\n $dateOfBirth:Date!\n $districtId:String\n $email:String!\n $gender:GenderTypeEnum!\n $mobileNumber:String!\n  $name:String!\n $nationality:String!\n $pincode:String!\n $stateId:String\n ) {\n     updateBasicDetails( dateOfBirth:$dateOfBirth districtId:$districtId email:$email gender:$gender mobileNumber:$mobileNumber  name:$name nationality:$nationality pincode:$pincode stateId:$stateId){       \n   status \n   message    }\n  }");
            formData.append("variables", JSON.stringify(userData));
            if (selectedProfilePic) {
                formData.append("image", selectedProfilePic);
            }
            let token = localStorage.getItem('user_token');
            let config;
            if (token !== null && token !== undefined && token !== "") {
                config = {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'JWT ' + token
                    }
                };
            } else {
                config = {
                    headers: {
                        'Accept': 'application/json'
                    }
                };
            }
            axios.post(api, formData, config)
                .then(function (response) {
                    // debugger
                    if (response.data.data.updateBasicDetails !== null) {
                        setReadonlydata(!readonlydata);
                        cogoToast.success(response.data.data.updateBasicDetails.message, {position: 'top-center'});
                    } else {
                        cogoToast.success("Your Details Not Saved", {position: 'top-center'});
                    }
                })
                .catch(function (err) {
                    cogoToast.error(err, {position: 'top-center'});
                });

        }
    }

    const submitBackgroundForm = () => {
        let error = {};
        if (profileDetail.profile.college === "") {
            error = {
                field: "college",
                message: "Please fill college"
            };
            setValueErrors(error);
        } else if (profileDetail.profile.yearOfGraduation === "") {
            error = {
                field: "yearOfGraduation",
                message: "Please choose year of graduation"
            };
            setValueErrors(error);
        } else if (profileDetail.profile.yearOfGraduation.length <4||profileDetail.profile.yearOfGraduation.length >4) {
            // console.log(profileDetail.profile.yearOfGraduation.length)
            error = {
                field: "yearOfGraduation",
                message: "year of graduation should be 4 digits"
            };
            setValueErrors(error);
        }else if (profileDetail.profile.degreeType === "") {
            error = {
                field: "degreeType",
                message: "Please choose degree Type"
            };
            setValueErrors(error);
        } else if (profileDetail.profile.courseName === "") {
            error = {
                field: "courseName",
                message: "Please fill course name"
            };
            setValueErrors(error);
        } else if (profileDetail.profile.employementStatus === "") {
            error = {
                field: "employementStatus",
                message: "Please choose employement status"
            };
            setValueErrors(error);
        } else if (profileDetail.profile.organizationName === "") {
            error = {
                field: "organizationName",
                message: "Please fill organization name"
            };
            setValueErrors(error);
        }else if (profileDetail.profile.experienceLevel === "") {
            error = {
                field: "experienceLevel",
                message: "Please choose experience level"
            };
            setValueErrors(error);
        } else {

            let userData = {
                collegeName: profileDetail.profile.college,
                degreeName: profileDetail.profile.courseName,
                degreeType: profileDetail.profile.degreeType,
                employementStatus:capitalizeFirstLetter(profileDetail.profile.employementStatus),
                experienceLevel:capitalizeFirstLetter(profileDetail.profile.experienceLevel),
                organizationName: profileDetail.profile.organizationName,
                yearOfGraduation: profileDetail.profile.yearOfGraduation,
            };
            error = {};
            setValueErrors(error);
            UpdateBackgroundDetailsMutation(userData, function (response) {
                if (response.updateBackgroundDetails !== null) {
                    setReadonlydata1(!readonlydata1);
                    cogoToast.success(response.updateBackgroundDetails.message, {position: 'top-center'});
                } else {
                    cogoToast.error("something went wrong", {position: 'top-center'});
                }
            }, function (err) {
                cogoToast.error(err, {position: 'top-center'});
            });
        }
    }
    const fetchDistrictByState = (selectedState) => {
        if (selectedState===undefined||selectedState===null){
            return
        }
       let variables = {

            stateId: selectedState.id
        }
        fetchQuery(environment, GetDistrictByStateQuery, variables)
            .toPromise()
            .then(response => {
                if (response.getDistrictByState !== null) {
                    setDistrictList(response.getDistrictByState)
                } else {
                    setDistrictList([]);
                }
            }, err => {
                setDistrictList([]);
            });
    }

    useEffect(() => {
        if (profileDetail.profile){
            fetchDistrictByState(profileDetail.profile.state)
        }

    }, [profileDetail]);

    const handleImageChange = (e) => {
        let tmp_profile_data = {...profileDetail};
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            tmp_profile_data['profile'] = {
                ...tmp_profile_data['profile'],
                profilePic: reader.result
            }
            setSelectedProfilePic(file);
            setProfileDetail(tmp_profile_data);
        }
        reader.readAsDataURL(file);
    }

    return (
        <>
            <Header page={"myprofile"}/>
                <ProfileNavbar active_page={"My Profile"}>
            <div className={'my-profile-component'}>
                <div className={'title'}>Information about yourself</div>
                <div className={'input_field_top'}>
                    <div className={'top_container'}>
                        {isDesktop ?
                            <>
                                <h3 className={'sub_heading'}>Basic Details</h3>
                                {readonlydata ?
                                    <div onClick={() => setReadonlydata(!readonlydata)}><RoundedButton>Edit
                                        Changes</RoundedButton></div> :
                                    <div onClick={() => submitBasicForm()}><RoundedButton>save Changes</RoundedButton>
                                    </div>
                                }
                            </>
                            :
                            <>
                                <h3 className={'sub_heading'}>Personal Details</h3>
                                {readonlydata ?
                                    <div onClick={() => setReadonlydata(!readonlydata)}>
                                        <FlatButton>
                                            Edit
                                            <img src={yellow_edit} className={'pl-2'}/>
                                        </FlatButton>
                                    </div>
                                    :
                                    <div onClick={() => submitBasicForm()}>
                                        <FlatButton>
                                            Save Edit
                                            <img src={yellow_edit} className={'pl-2'}/>
                                        </FlatButton>
                                    </div>

                                }
                            </>
                        }
                    </div>
                    <div className={'row mx-0 align-items-baseline'}>
                        <div className={'col-md-6 col-12 order-md-1 order-2 input-feild-section'}>
                            <div className={'w-40'}>
                                <p className={'m-0'}>Full Name</p>
                            </div>
                            <div className={'w-60'}>
                                <input
                                    type="name"
                                    className={readonlydata ? "code" : "editable-input"}
                                    required
                                    value={profileDetail.name}
                                    onChange={(e) => handleInputChanges(e, "name")}
                                />
                                {
                                    valueErrors.field === "name" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>
                        </div>
                        <div className={'col-md-6 col-12 order-md-2 order-1 Profile_head'}>
                            <div className={'w-40 profile_to'}><p className={'m-0'}>Profile Photo</p></div>
                            <div className={'w-60 img_profile'}>
                                <label htmlFor="file-input">
                                    <img
                                        src={profileDetail.profile && profileDetail.profile.profilePic ? profileDetail.profile.profilePic : profile_photo}
                                        className={'profile_photo'} alt={'profile_photo'}/>
                                    {
                                        readonlydata === false &&
                                        <div className={'change_button'}>
                                            <img src={white_edit} alt={'white_edit'} className={'white_edit'}/>
                                        </div>
                                    }
                                </label>
                                {
                                    readonlydata === false &&
                                    <input className={"code uploadprofileimage"} id="file-input" type="file"
                                           onChange={(e) => handleImageChange(e)}/>

                                }

                            </div>
                        </div>
                    </div>
                    <div className={'row mx-0'}>
                        <div className={'col-md-6 col-12 input-feild-section'}>
                            <div className={'w-40'}><p className={'m-0'}>Date of Birth</p></div>
                            <div className={'w-60'}>
                                <input
                                    type="date"
                                    className={readonlydata ? "code max210 w-100" : "max210 w-100 editable-input"}
                                    required
                                    value={profileDetail.dob}
                                    onChange={(e) => handleInputChanges(e, "dob")}
                                />
                                {
                                    valueErrors.field === "dob" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>
                        </div>
                        <div className={'col-md-6 col-12 pr-0 input-feild-section'}>
                            <div className={'w-40'}><p className={'m-0'}>Gender</p></div>
                            <div className={'w-60'}>
                                <select
                                    className={readonlydata ? "code select1 w-100 max210" : "select1 w-100 max210 editable-input"}
                                    name="gender"
                                    required
                                    value={profileDetail.gender && profileDetail.gender.toLowerCase()}
                                    onChange={(e) => handleInputChanges(e, "gender")}
                                    id="gender"
                                >
                                    <option value={""}>--select--</option>
                                    {
                                        UserGender.map((item) => {
                                            return (
                                                <option disabled={readonlydata}
                                                        value={`${item.name.toLowerCase()}`}
                                                        key={uuid()}>
                                                    {item.name}
                                                </option>
                                            );
                                        })
                                    }
                                </select>
                                {
                                    valueErrors.field === "gender" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>
                        </div>
                        <div className={'col-md-6 col-12 input-feild-section'}>
                            <div className={'w-40'}><p className={'m-0'}>Email ID</p></div>
                            <div className={'w-60'}>
                                <input
                                    type="name"
                                    className={readonlydata ? "code" : "editable-input"}
                                    required
                                    value={profileDetail.email}
                                    onChange={(e) => handleInputChanges(e, "email")}/>
                                {
                                    valueErrors.field === "email" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>
                        </div>
                        <div className={'col-md-6 col-12 input-feild-section'}>
                            <div className={'w-40'}><p className={'m-0'}>Phone Number</p></div>
                            <div className={'w-60'}>
                                <input
                                    type="text"
                                    pattern="[0-9]*"
                                    maxLength="10"
                                    className={readonlydata ? "code" : "editable-input"}
                                    required
                                    value={profileDetail.mobileNumber}
                                    onInput={(e) => handleInputChanges(e,"mobileNumber",null,"number")}
                                    onChange={(e) => handleInputChanges(e, "mobileNumber",null,"number")}
                                />
                                {
                                    valueErrors.field === "mobileNumber" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>
                        </div>
                        <div className={'col-md-6 col-12 input-feild-section'}>
                            <div className={'w-40'}><p className={'m-0'}>Nationality</p></div>
                            <div className={'w-60'}>
                                <select
                                    className={readonlydata ? "code select1 w-100 max210" : "select1 w-100 max210 editable-input"}
                                    name="gender"
                                    required
                                    id="gender"
                                    value={profileDetail.profile && profileDetail.profile.nationality}
                                    onChange={(e) => handleInputChanges(e, "nationality", "profile")}>
                                    <option value={""}>--select--</option>
                                    {
                                        ProfileNationality.map((item) => {
                                            return (
                                                <option disabled={readonlydata} value={item.name}
                                                        key={uuid()}>{item.name}</option>
                                            );
                                        })
                                    }
                                </select>
                                {
                                    valueErrors.field === "nationality" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>
                        </div>
                        <div className={'col-md-6 col-12 input-feild-section'}>
                            <div className={'w-40'}><p className={'m-0'}>State</p></div>
                            <div className={'w-60'}>
                                <select
                                    className={readonlydata ? "code select1 w-100 max210" : " select1 w-100 max210 editable-input"}
                                    name="gender"
                                    required
                                    // id="gender"
                                    value={profileDetail.profile && profileDetail.profile.state && profileDetail.profile.state.id}
                                    onChange={(e) => handleStateChange(e, "state", "profile")}
                                >
                                    <option value={""}>--select--</option>
                                    {
                                        stateList.map((item) => {
                                            return (
                                                <option key={uuid()} value={`${item.id}`}>{`${item.name}`}</option>
                                            );
                                        })

                                    }
                                </select>
                                {
                                    valueErrors.field === "state" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>
                        </div>
                        <div className={'col-md-6 col-12 input-feild-section'}>
                            <div className={'w-40'}><p className={'m-0'}>District </p></div>
                            <div className={'w-60'}>
                                <select

                                    className={readonlydata ? "code select1 w-100 max210" : " select1 w-100 max210 editable-input"}
                                    name="gender"
                                    required
                                    id="gender"
                                    value={profileDetail.profile && profileDetail.profile.district && profileDetail.profile.district.id}
                                    onChange={(e) => {
                                        handleDistrictChange(e, "district","profile")}}
                                >
                                    <option value={""}>--select--</option>
                                    {
                                        districtList.map((item, index) => {
                                            return (
                                                <option
                                                    disabled={readonlydata}
                                                    value={`${item.id}`}
                                                    key={uuid()}>{`${item.name}`}
                                                </option>
                                            );

                                        })

                                    }
                                </select>
                                {
                                    valueErrors.field === "district" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>
                        </div>
                        <div className={'col-md-6 col-12 input-feild-section'}>
                            <div className={'w-40'}><p className={'m-0'}>Pincode</p></div>
                            <div className={'w-60'}>
                                <input
                                    type="text"
                                    pattern="[0-9]*"
                                    maxLength="6"
                                    className={readonlydata ? "code cus_code" : " cus_code editable-input"}
                                    required
                                    value={profileDetail.pincode}
                                    onInput={(e) => handleInputChanges(e,"pincode",null,"number")}
                                    onChange={(e) => handleInputChanges(e, "pincode",null,"number")}
                                />
                                {
                                    valueErrors.field === "pincode" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"space-divider"}/>
                <div className={'input_field_top'}>
                    <div className={'top_container second_top_field'}>
                        {isDesktop ?
                            <>
                                <h3 className={'sub_heading'}>Backgroud Details</h3>
                                {readonlydata1 ?
                                    <div onClick={() => setReadonlydata1(!readonlydata1)}><RoundedButton>Edit
                                        Changes</RoundedButton></div> :
                                    <div onClick={() => submitBackgroundForm()}><RoundedButton>save
                                        Changes</RoundedButton></div>
                                }
                            </>
                            :
                            <>
                                <h3 className={'sub_heading'}>Backgroud Details</h3>
                                {readonlydata1 ?
                                    <div onClick={() => setReadonlydata1(!readonlydata1)}>
                                        <FlatButton>
                                            Edit
                                            <img src={yellow_edit} className={'pl-2'}/>
                                        </FlatButton>
                                    </div>
                                    :<div onClick={() => submitBackgroundForm()}>
                                        <FlatButton>
                                            Save Edit
                                            <img src={yellow_edit} className={'pl-2'}/>
                                        </FlatButton>
                                    </div>
                                }
                            </>
                        }
                    </div>
                    {isDesktop &&
                        <div className={'row mx-0'}>
                            <div className={'col-12 input-feild-section'}>
                                <div className={'w-12'}><p className={'m-0'}>College Name</p></div>
                                <div className={'w-87'}>
                                    <input
                                        type="name"
                                        className={readonlydata1 ? "code" : "editable-input"}
                                        required
                                        value={profileDetail.profile && profileDetail.profile.college}
                                        onChange={(e) => handleInputChanges(e, "college","profile")}
                                    />
                                    {
                                        valueErrors.field === "college" &&
                                        <p className={"error-message"}>{valueErrors.message}</p>
                                    }
                                </div>
                            </div>
                        </div>
                    }

                    <div className={'row mx-0'}>
                        {!isDesktop &&
                            <div className={'col-md-6 col-12 input-feild-section'}>
                                <div className={'w-40'}><p className={'m-0'}>College Name</p></div>
                                <div className={'w-60'}>
                                    <input
                                        type="name"
                                        className={readonlydata1 ? "code" : "editable-input"}
                                        required
                                        value={profileDetail.profile && profileDetail.profile.college}
                                        onChange={(e) => handleInputChanges(e, "college","profile")}
                                    />
                                    {
                                        valueErrors.field === "college" &&
                                        <p className={"error-message"}>{valueErrors.message}</p>
                                    }
                                </div>
                            </div>
                        }


                        <div className={'col-md-6 col-12 input-feild-section'}>
                            <div className={'w-40'}><p className={'m-0'}>Year of Graduation</p></div>
                            <div className={'w-60'}>
                                <input
                                    type="text"
                                    pattern="[0-9]*"
                                    maxLength="4"
                                    className={readonlydata1 ? "code max210 w-100" : "max210 w-100 editable-input"}
                                    required
                                    value={profileDetail.profile && profileDetail.profile.yearOfGraduation}
                                    onInput={(e) => handleInputChanges(e, "yearOfGraduation","profile","number")}
                                    onChange={(e) => handleInputChanges(e, "yearOfGraduation","profile","number")}
                                />
                                {
                                    valueErrors.field === "yearOfGraduation" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>
                        </div>
                        <div className={'col-md-6 col-12 input-feild-section'}>
                            <div className={'w-40'}><p className={'m-0'}>Degree Type</p></div>
                            <div className={'w-60'}>
                                <select
                                    type="name"
                                    className={readonlydata1 ? "code" : "editable-input"}
                                    required
                                    value={profileDetail.profile && profileDetail.profile.degreeType}
                                    onChange={(e) => handleInputChanges(e,"degreeType","profile")}
                                    >
                                    <option value={""}>--select--</option>
                                    {
                                        ProfileDegreeType.map((item) => {
                                            return (
                                                <option disabled={readonlydata1} value={`${item.name}`} key={uuid()}>{item.name}</option>
                                            );
                                        })
                                    }
                                </select>
                                {
                                    valueErrors.field === "degreeType" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>
                        </div>
                        <div className={'col-md-6 col-12 input-feild-section'}>
                            <div className={'w-40'}><p className={'m-0'}>Field or Major</p></div>
                            <div className={'w-60'}>
                                <input
                                    type="text"
                                    className={readonlydata1 ? "code" : "editable-input"}
                                    required
                                    value={profileDetail.profile && profileDetail.profile.courseName}
                                    onChange={(e) => handleInputChanges(e, "courseName","profile")}
                                />
                                {
                                    valueErrors.field === "courseName" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>
                        </div>
                        <div className={'col-md-6 col-12 pr-0 input-feild-section'}>
                            <div className={'w-40'}><p className={'m-0'}>Employment Status</p></div>
                            <div className={'w-60'}>
                                <select
                                    className={readonlydata1 ? "code select1 w-100 max210" : "select1 w-100 max210 editable-input"}
                                    name="gender"
                                    required
                                    id="gender"
                                    value={profileDetail.profile && profileDetail.profile.employementStatus}
                                    onChange={(e) => handleInputChanges(e, "employementStatus","profile")}
                                >
                                    {/*<option disabled={true}>--select--</option>*/}
                                    <option value={""}>--select--</option>
                                    {
                                        ProfileEmployementStatus.map((item) => {
                                            return (
                                                <option disabled={readonlydata1} key={uuid()} value={`${item.name}`}>{item.name}</option>
                                            );
                                        })
                                    }
                                </select>
                                {
                                    valueErrors.field === "employementStatus" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>
                        </div>

                        <div className={'col-md-6 col-12 input-feild-section'}>
                            <div className={'w-40'}><p className={'m-0'}>Current
                                Company Name</p></div>
                            <div className={'w-60'}>
                                <input
                                    type="name"
                                    className={readonlydata1 ? "code" : "editable-input"}
                                    required
                                    value={profileDetail.profile && profileDetail.profile.organizationName}
                                    onChange={(e) => handleInputChanges(e, "organizationName","profile")}
                                />
                                {
                                    valueErrors.field === "organizationName" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>
                        </div>
                        <div className={'col-md-6 col-12 input-feild-section'}>
                            <div className={'w-40'}><p className={'m-0'}>Experience Level</p></div>
                            <div className={'w-60'}>
                                <select
                                    className={readonlydata1 ? "code select1 w-100 max210 cus_code" : "select1 w-100 max210 cus_code editable-input"}
                                    name="gender"
                                    required
                                    id="gender"
                                    value={profileDetail.profile && profileDetail.profile.experienceLevel}
                                    onChange={(e) => handleInputChanges(e, "experienceLevel","profile")}
                                >
                                    {/*<option disabled={true}>--select--</option>*/}
                                    <option value={""}>--select--</option>
                                    {
                                        ProfileExperienceLevel.map((item) => {

                                            return (
                                                <option disabled={readonlydata1} value={`${item.name}`} key={uuid()}>{item.name}</option>
                                            );


                                        })
                                    }
                                </select>
                                {
                                    valueErrors.field === "experienceLevel" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ProfileNavbar>
            <Footer/>
        </>
    );
}
