import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'

const mutation = graphql`
  mutation SearchMutation(
    $searchText: String!
    $Categories: [String]
    $Instructors: [String]
    $levels: [String]
    $rating: [String]
    $fromPrice: String
    $toPrice: String
  ) {
     search(searchText:$searchText,Categories:$Categories,Instructors:$Instructors,levels:$levels,rating:$rating,
     fromPrice:$fromPrice,toPrice:$toPrice){
        status
        courses{
          id
          averageRating
          isWishlist
          name
          price
            image
          discountPrice
          instructor{
            user{
              name
            }
          }
        }
        instructors{
           id
           profilePic
           designation
           organization
           user{
             id
             name
           }
        }
        blogs{
           id
           title
           body
           featuredImage
           featuredMobileImage
           thumbnailImage
           updatedAt
        }
     }
  }
`

export default (data, callback,errCallback) => {
    const variables = {
        searchText: data.searchText,
        Categories: data.Categories,
        Instructors: data.Instructors,
        levels: data.levels,
        rating: data.rating,
        fromPrice: data.fromPrice,
        toPrice: data.toPrice
    }

    // 5
    commitMutation(
        environment,
        {
            mutation,
            variables,
            // 6
            onCompleted: (response,err) => {

                if(response.search !== null){
                    callback(response)
                } else{
                    errCallback(err[0].message);
                }

            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}
