import './Cart.scss';

import React, {useEffect, useState} from "react";
import {LayoutContainer} from "../../Components/LayoutContainer/LayoutContainer";

import smallUser from '../../assets/images/smallUser.svg'
import coursecard_img from "../../assets/images/coursecard-bg.png";
import yellow_star from '../../assets/images/yellow star.png';
import {BasicButton} from "../../Components/Buttons/BasicButton";
import useMediaQuery from "../../util/useMediaQuery";
import {fetchQuery} from "relay-runtime";
import environment from "../../Environment";
import {fetchWishListSuccess} from "../../Actions/wishListAction";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {clearCart, fetchCartListBegin, fetchCartListFailure, fetchCartListSuccess} from "../../Actions/CartAction";
import MyCartQuery from "../../queries/MyCartQuery";
import cogoToast from "cogo-toast";
import RemoveFromCartMutation from "../../mutations/RemoveFromCartMutation";
import AddToWishListQuery from "../../queries/AddToWishListQuery";
import ErrorMessage from "../../Components/ErrorMessage/ErrorMessage";
import Loader from "../../Components/Loader/Loader";
import CheckoutCompleteMutation from "../../mutations/CheckoutCompleteMutation";
import {checkoutSuccess} from "../../Actions/OrderAction";
import ApplyVoucherMutation from "../../mutations/ApplyVoucherMutation";
import RemoveCouponMutation from "../../mutations/RemoveCouponMutation";
import uuid from 'react-uuid'
import Header from "../../Containers/Header/Header";
import {Footer} from "../../Containers/Footer/Footer";
import emptycart_img from "../../assets/icons/emptyCart.svg"
import edit_icon from "../../assets/icons/edit-icon.svg"
import tic_mark from "../../assets/icons/akar-icons_circle-check-fill-green.svg"
import red_delete from "../../assets/icons/red-delete.svg"
const CartComponent = (props) => {
    const [toggleCoupon,setToggleCoupon] = useState(false);
    const [couponApplied,setCouponApplied] = useState(false);
    const [getEditCoupon,setEditCoupon] = useState(false);
    const [getCouponCode,setCouponCode] = useState('');
    const [getCouponCodeReadOnly, setCouponCodeReadOnly] = useState(false);



    const isDesktop = useMediaQuery("(min-width: 765px)");
    useEffect(() => {
        getCartData();
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const getCartData = () => {
        props.beginCartList();
        fetchQuery(environment, MyCartQuery)
            .toPromise()
            .then(response => {
                if (response.myCart !== null) {
                    let dataFormate = {
                        id:response.myCart.id,
                        total:parseInt(response.myCart.total),
                        discount:parseInt(response.myCart.discount),
                        discountName:response.myCart.discountName,
                        voucherCode:response.myCart.voucherCode,
                        checkoutitems:response.myCart.checkoutitems.edges
                    }
                    props.cartListSuccess(dataFormate);
                } else  {
                    let error = {
                        message: "No Data Found"
                    }
                    props.cartListFailure(error);
                }
            }, err => {
                let error = {
                    message: "No Data Found"
                }
                props.cartListFailure(error);
            });
    }

    const {error,loading, cart_data} = props;

    const moveToWishList = (course) => {
        let variables = {
            courseId: course.id
        }
        fetchQuery(environment, AddToWishListQuery, variables)
            .toPromise()
            .then(response => {
                if (response.addToWishList !== null) {
                    props.wishListSuccess(response.addToWishList);
                    cogoToast.success("Course Added To Wishlist successfully", {position: 'top-center'});
                } else {
                    cogoToast.info("Course Not Added To Wishlist", {position: 'top-center'});
                }
            }, err => {
                cogoToast.error("Course Not Added To Wishlist", {position: 'top-center'});
            });
    }

    const removeFromCart = (cartItem) => {
        let variables = {
            cartItemId: cartItem.id
        }
        RemoveFromCartMutation(variables,function (response){
            if (response.removeFromCart !== null) {
                let dataFormate = {
                    id:response.removeFromCart.cart.id,
                    total:parseInt(response.removeFromCart.cart.total),
                    discount:parseInt(response.removeFromCart.cart.discount),
                    discountName:response.removeFromCart.cart.discountName,
                    voucherCode:response.removeFromCart.cart.voucherCode,
                    checkoutitems:response.removeFromCart.cart.checkoutitems.edges
                }
                props.cartListSuccess(dataFormate);
                cogoToast.success("Course Removed From cart successfully", {position: 'top-center'});
            } else {
                cogoToast.info("Course Not Removed From cart", {position: 'top-center'});
            }
        },function (err){
            cogoToast.error("Course Not Removed From cart", {position: 'top-center'});
        })
    }

    const completePurchase = () => {

        CheckoutCompleteMutation(props.cart_data.id,function (response){
            if (response.checkoutComplete !== null && response.checkoutComplete.payment) {
                let OrderDataFormate = {
                    order_data:response.checkoutComplete.payment.order,
                    rzpOrderId:response.checkoutComplete.payment.rzpOrderId
                }
                props.clearCart();
                props.checkoutSuccess(OrderDataFormate);
                props.history.push("/payment");
            } else {
                cogoToast.info("Order Not Created", {position: 'top-center'});
            }
        },function (err){
            cogoToast.error("Order Not Created", {position: 'top-center'});
        })
    }

    // const handleToggleCoupon = () => {
    //     if(couponApplied){
    //     } else {
    //     }
    // }

    const removeCouponFunction=()=>{
        removeCoupon();
        setCouponCode('')

    }
    const ApplyCouponFunction=()=>{
        applyCoupon();

    }

    const applyCoupon = () => {
        if (getCouponCode===""){
            cogoToast.error("Coupon COde is empty , please fill that", {position: 'top-center'});
        }
        let variables = {
            voucherCode: getCouponCode
        }
        ApplyVoucherMutation(variables,function (response){
            if (response.applyVoucher !== null) {
                let dataFormate = {
                    id:response.applyVoucher.cart.id,
                    total:parseInt(response.applyVoucher.cart.total),
                    discount:parseInt(response.applyVoucher.cart.discount),
                    discountName:response.applyVoucher.cart.discountName,
                    voucherCode:response.applyVoucher.cart.voucherCode,
                    checkoutitems:response.applyVoucher.cart.checkoutitems.edges
                }
                props.cartListSuccess(dataFormate);
                setCouponApplied(true);
                cogoToast.success("Coupon Applied To Your cart successfully", {position: 'top-center'});
            } else {
                cogoToast.info("Coupon Not Applied To Your cart", {position: 'top-center'});
            }
        },function (err){
            cogoToast.error("Coupon Not Applied To Your cart", {position: 'top-center'});
        })
    }
    const removeCoupon = () => {
        let variables = {
            checkoutId: props.cart_data.id
        }
        RemoveCouponMutation(variables,function (response){
            if (response.removeCoupon !== null) {
                let dataFormate = {
                    id:response.removeCoupon.cart.id,
                    total:parseInt(response.removeCoupon.cart.total),
                    discount:parseInt(response.removeCoupon.cart.discount),
                    discountName:response.removeCoupon.cart.discountName,
                    voucherCode:response.removeCoupon.cart.voucherCode,
                    checkoutitems:response.removeCoupon.cart.checkoutitems.edges
                }
                props.cartListSuccess(dataFormate);
                setCouponApplied(false);
                cogoToast.success("Coupon Removed From Your cart successfully", {position: 'top-center'});
            } else {
                cogoToast.info("Coupon Not Removed From Your cart", {position: 'top-center'});
            }
        },function (err){
            cogoToast.error("Coupon Not Removed From Your cart", {position: 'top-center'});
        })
    }

    // if (error) {
    //     return <ErrorMessage error={error}/>;
    // }

    if (loading) {
        return (
            <div>
                <Loader full={true} />
                <div  style={{height:window.innerHeight}}/>
            </div>
        );
    }


   function couponCode(e){
       setCouponCode(getCouponCode=>e)
   }

    return (
        <>
            <Header page={"cart"}/>
            <div className={'cart-component'}>
                <div className={'top_header'}>
                    <div className={'main_heaedr'}>
                        Shopping Cart
                        <span className={'top_header_span'}>
                            (&nbsp;{cart_data && cart_data.checkoutitems ? cart_data.checkoutitems.length:0} &nbsp;
                            {cart_data && cart_data.checkoutitems && cart_data.checkoutitems.length > 1?"Item":"items"}
                            Selected&nbsp;)
                        </span>
                    </div>
                </div>
                <LayoutContainer>
                    {
                        cart_data.checkoutitems && cart_data.checkoutitems.length > 0 ?
                            <div className={'row main-content w-100'}>
                                <div className={'col-12 col-md-7 order-2 order-md-1 grid-column '}>
                                    {isDesktop ? <></> : <h3 className={'md_title'}>Selected Courses in the Cart</h3>}
                                    {
                                        cart_data && cart_data.checkoutitems && cart_data.checkoutitems.map((item, index) => {
                                            return (
                                                <div key={uuid()}>
                                                    <CartCard
                                                        courseData={item.node}
                                                        moveToWishList={moveToWishList}
                                                        removeFromCart={removeFromCart}
                                                    />
                                                </div>
                                            );
                                        })
                                    }
                                    {isDesktop ? <></> :
                                        <div className={'custome_btn'}>
                                            <BasicButton>Complete the Payment</BasicButton>
                                        </div>

                                    }
                                </div>
                                <div className={'col-12 col-md-5 order-1 order-md-2 grid-column'}>
                                    <div className={'sen_order p-4'}>
                                        <h3 className={'order'}>Order Summary</h3>
                                        <div className={"d-flex justify-content-between align-items-center"}>
                                            <div className={'amount'}>Amount Total</div>
                                            <div className={'value_amount'}>₹{cart_data.total-cart_data.discount}</div>
                                        </div>

                                        <p className={'gst'}>Inclusive of GST*</p>
                                        <div className={"d-flex justify-content-between align-items-center"}>
                                            <p className={'total_cart'}>Cart Total</p>
                                            <p className={'total_cart'}>₹{cart_data.total}</p>
                                        </div>
                                        {
                                            cart_data.discount > 0 &&
                                            <div className={"d-flex justify-content-between align-items-center"}>
                                                <p className={'Coupon_Discount'}>Coupon Discount</p>
                                                <p className={'total_cart'}>₹{cart_data.discount}</p>
                                            </div>
                                        }
                                        {
                                            cart_data.voucherCode && cart_data.voucherCode !== "" ?
                                                <p className={'code'} className={toggleCoupon?'d-none':''} onClick={()=>setToggleCoupon(true)}>
                                                    <u className={'edit_icon_text'}>Edit Code<img src={edit_icon} className={'ml-2 edit_icon'}/></u>
                                                </p>
                                                :

                                            <p className={'code'} className={toggleCoupon?'d-none':''} onClick={()=>setToggleCoupon(true)}>
                                                <u className={'enter_couponcode'}>Got a coupon code?</u>
                                            </p>



                                        }
                                        {
                                            toggleCoupon === true &&
                                            <div className={'voucher-code-delete-remove'}>
                                                <div onClick={()=>removeCouponFunction()}><u className={'red_delete_text'}>Delete code <img src={red_delete} alt={'delete'} className={'red_delete_img ml-2'}/></u></div>
                                                <div onClick={()=>ApplyCouponFunction()}><u className={'tic_mark_text'}>Apply changes <img src={tic_mark} alt={'edit'} className={'tic_mark_img ml-2'}/> </u></div>
                                            </div>
                                        }
                                        {
                                            toggleCoupon === true &&
                                            <input type={'text'} readOnly={getCouponCodeReadOnly} value={getCouponCode} className={couponApplied ? "coupon-card active":"coupon-card"} onChange={(e)=>couponCode(e.target.value)} >
                                                {cart_data.voucherCode}
                                            </input>
                                        }
                                        <BasicButton handleClick={completePurchase}>
                                            Complete Purchase
                                        </BasicButton>
                                    </div>
                                </div>
                            </div>:
                            // <div className={'cart-error-message'}>
                            //     <img src={emptycart_img} alt={'No Courses Found in Your Cart'} className={'cart-error-img'}/>
                            //     <div className={'cart-error-text'} >
                            //         <h3 className={'cart-error-header'}>Your Cart is empty!</h3>
                            //         <p className={'cart-error-para'}>Looks like you haven’t added anything to your cart yet!</p>
                            //         <BasicButton handleClick={()=> props.history.push("/course-list")}>Explore Courses</BasicButton>
                            //     </div>
                            // </div>
                            <ErrorMessage img={emptycart_img} title={"Your Cart is empty!"} text={"Looks like you haven’t added anything to your cart yet!"} onclickhandeler={`/course-list`}/>
                    }
                </LayoutContainer>
        </div>
            <Footer/>
        </>
    );
}

const mapStateToProps = state => ({
    cart_data: state.CartReducer.cart_data,
    loading: state.CartReducer.loading,
    error: state.CartReducer.error
});

const mapDispatchToProps = dispatch => ({
    beginCartList: () => dispatch(fetchCartListBegin()),
    cartListSuccess: (data) => dispatch(fetchCartListSuccess(data)),
    cartListFailure: (error) => dispatch(fetchCartListFailure(error)),
    wishListSuccess: (data) => dispatch(fetchWishListSuccess(data)),
    checkoutSuccess: (data) => dispatch(checkoutSuccess(data)),
    clearCart: () => dispatch(clearCart())

});


export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CartComponent);

const CartCard = (props) => {
    const isDesktop = useMediaQuery("(min-width: 765px)");
    const {courseData, removeFromCart,moveToWishList } = props;

    return (
        <>
            {isDesktop ?

                <div className={'row CartCard_row p-3'}>
                    <div className={'col-sm-4  CartCard_first_row px-0'}>
                        {/* <span className={'BestSeller'}>Best Seller</span> */}
                        <span className={'star'}><img src={yellow_star} className={'yellow_star'}/> {courseData.course.averageRating} </span>
                        <img src={courseData?.course?.image?courseData.course.image:coursecard_img} className={'coursecard_img h-100 w-100'}/>
                    </div>
                    <div className={'col-sm-8'}>
                        <div className={'Card_Carttitle'}>{courseData.course.name}</div>
                        <div className={'username'}><img src={smallUser} className={'smallUser pr-2'}
                                                         alt={'smallUser'}/>
                            {courseData.course.instructor && courseData.course.instructor.user ? courseData.course.instructor.user.name : ""}
                        </div>
                        <div className={'rate'}>₹ {courseData.course.discountPrice} <span className={'full_rate'}> <del>₹{courseData.course.price}</del></span></div>
                        <div className={'d-flex button_flex'}>
                            <button type="button" className="btn p-0 btn-flat btn-lg"
                                    onClick={()=>removeFromCart(courseData)}
                            >
                                <u>Remove</u>
                            </button>
                            <button type="button" className="btn p-0 btn-flat btn-lg"
                                    onClick={()=>moveToWishList(courseData.course)}
                            >
                                <u>Move to Wishlist</u>
                            </button>
                        </div>

                    </div>
                </div>

                :
                <div className={'md_cart_card p-3'}>
                    <div className={'CartCard_first_row'}>
                        {/* <span className={'BestSeller mb_BestSeller'}>Best Seller</span> */}
                        <span className={'star'}><img src={yellow_star} className={'mb_yellow_star'}/> {courseData.course.averageRating} </span>
                        <img src={courseData?.course?.image?courseData.course.image:coursecard_img} className={'mb_coursecard_img w-100'}/>
                    </div>
                    <h3 className={'mb_title'}>{courseData.course.name}</h3>
                    <div className={'mb_username'}><img src={smallUser} className={'mb_smallUser pr-2'}
                                                        alt={'smallUser'}/>
                        {courseData.course.instructor && courseData.course.instructor.user ? courseData.course.instructor.user.name : ""}
                    </div>
                    <div className={'mb_rate'}>₹ {courseData.course.discountPrice} <span className={'mb_full_rate pl-3'}> <del>₹{courseData.course.price}</del></span></div>
                    <div className={'d-flex mb_btn'}>
                        <button type="button" className="btn p-0 btn-flat btn-lg text-left"
                                onClick={()=>removeFromCart(courseData)}
                        >
                            <u>Remove</u>
                        </button>
                        <button type="button" className="btn p-0 btn-flat btn-lg text-left"
                                onClick={()=>moveToWishList(courseData.course)}
                        >
                            <u>Move to Wishlist</u>
                        </button>
                    </div>
                </div>
            }</>
    );


}
