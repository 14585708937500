import "./RatingCircle.scss";
import {MDBBox} from "mdbreact";


export const RatingCircle = (props) => {
    const {circle1Data,circle2Data,circle3Data} = props;
    return(
        <MDBBox className={"rating-circle-component"}>
            <MDBBox className={"d-flex align-items-center justify-content-center rating-circle-list"}>
                <MDBBox className={"circle-item d-flex align-items-center justify-content-center flex-column"}>
                    <p className={"rating-count"}>{circle1Data.count}</p>
                    <p className={"rating-text"}>{circle1Data.content}</p>
                </MDBBox>
                <MDBBox className={"circle-item d-flex align-items-center justify-content-center flex-column"}>
                    <p className={"rating-count"}>{circle2Data.count}</p>
                    <p className={"rating-text"}>{circle2Data.content}</p>
                </MDBBox>
                <MDBBox className={"circle-item d-flex align-items-center justify-content-center flex-column"}>
                    <p className={"rating-count"}>{circle3Data.count}</p>
                    <p className={"rating-text"}>{circle3Data.content}</p>
                </MDBBox>
            </MDBBox>
        </MDBBox>
    );
}
