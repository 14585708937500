import graphql from "babel-plugin-relay/macro";

const GetDistrictByStateQuery=graphql`

    query GetDistrictByStateQuery($stateId:String!){
        getDistrictByState(stateId:$stateId){
            id
            name
        }
    }
`

export default GetDistrictByStateQuery;
