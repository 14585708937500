import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'

const mutation = graphql`
    mutation GenerateCertificateMutation(
        $enrollId: ID!
    ) {
        generateCertificate(enrollId:$enrollId){
            enroll{
                certificateUrl
            }
            message
        }
        }
    
`

export default (data, callback,errCallback) => {
    const variables = {
        enrollId: data.enrollId,
    }

    // 5
    commitMutation(
        environment,
        {
            mutation,
            variables,
            // 6
            onCompleted: (response,err) => {

                if(response.generateCertificate !== null){
                    callback(response)
                } else{
                    errCallback(err[0].message);
                }

            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}
