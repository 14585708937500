import graphql from "babel-plugin-relay/macro";

const GetInstructorConsultationsQuery=graphql`

    query GetNotificationsQuery{
        getNotifications{
            edges{
                node{
                  message
                    createdAt
                    id
                    isRead
                    updatedAt
                    type
                    relatedId
                }
            }
        }
    }
`
export default GetInstructorConsultationsQuery