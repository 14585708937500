import "./CourseIndex.scss";
import React, {useEffect, useState} from "react";
import {useParams, withRouter} from "react-router-dom";
import {fetchQuery} from "relay-runtime";
import environment from "../../Environment";
import CourseByIdQuery from "../../queries/CourseByIdQuery";
import {compose} from "redux";
import {connect} from "react-redux";
import {Modules} from "../CoursePage/Modules";
import CoursePage from "../CoursePage/CoursePage";
import Loader from "../../Components/Loader/Loader";
import Header from "../../Containers/Header/Header";
import {Footer} from "../../Containers/Footer/Footer";

const CourseIndex = (props) => {
    const [courseDeatil,setCourseDetail] = useState({});
    const [loading,setLoading] = useState(false);

    const params = useParams();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(function (){
        getCourseById();
    },[]);
    const getCourseById = () => {
        let variables = {
            id: params.id
        }
        setLoading(true);
        fetchQuery(environment, CourseByIdQuery, variables)
            .toPromise()
            .then(response => {
                setLoading(false);
                if (response.courseById !== null) {
                    setCourseDetail(response.courseById);
                } else {
                    setCourseDetail({});
                }
            }, err => {
                setLoading(false);
                setCourseDetail({});
            });
    }

    if (loading) {
        return (
            <div>
                <Loader full={true} />
                <div  style={{height:window.innerHeight}}/>
            </div>
        );
    }
    return(
        <div>
            <Header page={"course-list"}/>
            <div>
                {
                    Object.keys(courseDeatil).length > 0 ?
                        <div>
                            {
                                props.logged_in  === true && courseDeatil.viewerPurchased === true ?
                                    <Modules courseDeatil={courseDeatil} refreshRequest={getCourseById}/>:
                                    <CoursePage courseDeatil={courseDeatil}/>
                            }
                        </div>:
                        <div></div>
                }
            </div>
            <Footer/>
        </div>
    );
};

const mapStateToProps = state => ({
    user_details: state.UserReducer.user_details,
    logged_in:state.UserReducer.logged_in
});

export default compose(
    withRouter,
    connect(mapStateToProps)
)(CourseIndex);
