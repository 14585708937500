//main-files
import './contact.scss'
import {LayoutContainer} from "../../Components/LayoutContainer/LayoutContainer";

// images
import fb from '../../assets/images/Facebook.png'
import linkedin from '../../assets/images/linkedin.png'
import twitter from '../../assets/images/twitter.png'
import instagram from '../../assets/images/instagram.png'
import redStar from "../../assets/images/redStar.png";
import {RoundedButton} from "../../Components/Buttons/RoundedButton";
import useMediaQuery from "../../util/useMediaQuery";
import React, {useEffect, useState} from "react";
import * as EmailValidator from "email-validator";
import LoginMutation from "../../mutations/LoginMutation";
import cogoToast from "cogo-toast";
import ContactUsMutation from "../../mutations/ContactUsMutation";
import Header from "../../Containers/Header/Header";
import {Footer} from "../../Containers/Footer/Footer";


export const Contact = (props) => {
    const isDesktop = useMediaQuery("(min-width: 720px)");
    const [nameState, setNameState] = useState("");
    const [numberState, setNumberState] = useState("");
    const [emailState, setEmailState] = useState("");
    const [TextAreaState, setTextAreaState] = useState("");
    const [valueErrors, setValueErrors] = useState({});






    const onChangeHandler1 = (e) => {
        e.preventDefault();
        setNameState(e.target.value);
    };
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const onChangeHandler2 = (e) => {
        e.preventDefault();
        let size= (e.target.value).length
        if (e.target.value===""&&size===0){
            setNumberState(numberState=>"")
        }
        if (e.target.value.charCodeAt(size-1)>='48'&&e.target.value.charCodeAt(size-1)<='59'&&size<=10){
            // debugger
            setNumberState(parseInt(e.target.value))
            // console.log(e.target.value)
        }
        // setNumberState(e.target.value);
    };
    const onChangeHandler3 = (e) => {
        e.preventDefault();
        setEmailState(e.target.value);
    };
    const onChangeHandler4 = (e) => {
        e.preventDefault();
        setTextAreaState(e.target.value);
    };

    const submitForm = () => {
        let error = {};
        if (nameState === "") {
            error = {
                field: "name",
                message: "Please fill name"
            };
            setValueErrors(error);
        } else if (EmailValidator.validate(emailState) === false) {
            error = {
                field: "email",
                message: "Please fill valid email"
            };
            setValueErrors(error);
        } else if (numberState === "" ) {
            error = {
                field: "number",
                message: "Number can't be empty"
            };
            setValueErrors(error);
        }
        else if (numberState.toString().length < 10 ) {
            error = {
                field: "number",
                message: "Number should be 10 digits"
            };
            setValueErrors(error);
        }  else if (TextAreaState === "" ) {
            error = {
                field: "textarea",
                message: "Please fill your message"
            };
            setValueErrors(error);
        }  else {
            let userData = {
                name: nameState,
                email: emailState,
                number: numberState,
                message: TextAreaState,
            };
            error = {};
            setValueErrors(error);
            ContactUsMutation(userData, function (response) {
                if (response.contactUs !== null) {
                    cogoToast.success(response.contactUs.message, {position: 'top-center'});
                    setNameState("");
                    setNumberState("");
                    setEmailState("");
                    setTextAreaState("");
                } else {
                    cogoToast.error("something went wrong", {position: 'top-center'});
                }
            }, function (err) {
                cogoToast.error(err, {position: 'top-center'});
            });
        }
    }
    return (
        <>
            <Header page={"contactus"}/>
            <div className={'ContactUs'}>
                <div className={'header header-font'}>
                    Contact us
                </div>
                <LayoutContainer>
                    <div className={'row main-container'}>
                        <div className={'col-sm-12 col-md-6  order-sm-2 order-md-1 order-2'}>
                            <h3 className={'title header-font'}>
                                Get In Touch!
                            </h3>
                            <p className={'para'}>
                                Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
                                consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet
                                minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat
                                duis enim velit mollit.
                            </p>
                            <hr className={'hr_tag'}/>
                            <h5 className={'small_header inquiries'}>For general inquiries:</h5>
                            <p className={'email'}> {isDesktop ? <></> : <span className={'span-mail'}>Mail us @</span>}
                                <u>general@mail.com</u></p>
                            <p className={'small_header follow'}>Follow us on social platforms:</p>
                            <div>
                                <p className={'social-buttons'}><img src={fb} className={'fb  mr-3'}
                                                                     alt={'facebook'}/><u>Facebook</u></p>
                                <p className={'social-buttons'}><img src={twitter} className={'twitter  mr-3 '}
                                                                     alt={'twitter'}/><u>twitter</u></p>
                                <p className={'social-buttons'}><img src={linkedin} className={'linkedin  mr-3'}
                                                                     alt={'linkedin'}/><u>linkedin</u></p>
                                <p className={'social-buttons'}><img src={instagram} className={'instagram  mr-3'}
                                                                     alt={'instagram'}/><u>instagram</u></p>
                            </div>
                        </div>
                        <div className={'col-sm-12 col-md-6 order-1  order-sm-1 order-md-2 right-side'}>
                            <h5 className={'small_header '}>Contact Form</h5>


                            <div className="flex1">
                                <div className="Create">Full name <span>
                      <img src={redStar} className="redStar" alt="redStar"/>
                    </span></div>
                                <input
                                    type="text"
                                    placeholder="Full name"
                                    className="code"
                                    required
                                    value={nameState}
                                    onChange={(e) => onChangeHandler1(e)}

                                />
                                {
                                    valueErrors.field === "name" &&
                                    <p className={"error-message"}>{valueErrors.message}</p>
                                }
                            </div>


                            <div className="d-flex top-form-field">
                                <div className={'form-field'}>
                                    <div className="Create">Mobile Number
                                        <span>
                                        <img src={redStar} className="redStar" alt="redStar"/>
                                    </span>
                                    </div>
                                    <input
                                        type="number"
                                        placeholder="10-digit number"
                                        className="code"
                                        required
                                        value={numberState}
                                        onChange={(e) => onChangeHandler2(e)}
                                    />
                                    {
                                        valueErrors.field === "number" &&
                                        <p className={"error-message"}>{valueErrors.message}</p>
                                    }
                                </div>
                                <div className={'form-field'}>
                                    <div className="Create">Email ID
                                        <span>
                                        <img src={redStar} className="redStar" alt="redStar"/>
                                    </span>
                                    </div>
                                    <input
                                        type="mail"
                                        placeholder="Valid Email Address"
                                        className="code"
                                        required
                                        value={emailState}
                                        onChange={(e) => onChangeHandler3(e)}
                                    />
                                    {
                                        valueErrors.field === "email" &&
                                        <p className={"error-message"}>{valueErrors.message}</p>
                                    }
                                </div>
                            </div>


                            <div className="Create">Tell us your message <span>
                      <img src={redStar} className="redStar" alt="redStar"/>
                    </span></div>
                            <textarea className={''} required placeholder={'Your message...'} value={TextAreaState}
                                      onChange={(e) => onChangeHandler4(e)}
                            >

                          </textarea>
                            {
                                valueErrors.field === "textarea" &&
                                <p className={"error-message mb-40"}>{valueErrors.message}</p>
                            }
                            <div onClick={submitForm}>
                                <RoundedButton>Submit</RoundedButton>
                            </div>

                        </div>
                    </div>
                </LayoutContainer>
            </div>
            <Footer/>
        </>
    )
}

