/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DegreeTypeEnum = "Masters" | "Others" | "PG" | "PhD" | "UG" | "%future added value";
export type CreateConsultationMutationVariables = {|
  collegeName: string,
  degreeType: DegreeTypeEnum,
  email: string,
  instructorId: string,
  name: string,
  mobileNumber: string,
  isWorking: boolean,
  yearOfGraduation: string,
  interest: string,
  message: string,
  fieldOrMajor: string,
  organization?: ?string,
  location?: ?string,
  designation?: ?string,
|};
export type CreateConsultationMutationResponse = {|
  +createConsultation: ?{|
    +status: ?string,
    +message: ?string,
  |}
|};
export type CreateConsultationMutation = {|
  variables: CreateConsultationMutationVariables,
  response: CreateConsultationMutationResponse,
|};
*/


/*
mutation CreateConsultationMutation(
  $collegeName: String!
  $degreeType: DegreeTypeEnum!
  $email: String!
  $instructorId: String!
  $name: String!
  $mobileNumber: String!
  $isWorking: Boolean!
  $yearOfGraduation: String!
  $interest: String!
  $message: String!
  $fieldOrMajor: String!
  $organization: String
  $location: String
  $designation: String
) {
  createConsultation(collegeName: $collegeName, degreeType: $degreeType, email: $email, instructorId: $instructorId, name: $name, mobileNumber: $mobileNumber, isWorking: $isWorking, yearOfGraduation: $yearOfGraduation, interest: $interest, message: $message, fieldOrMajor: $fieldOrMajor, organization: $organization, location: $location, designation: $designation) {
    status
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "collegeName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "degreeType"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "designation"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldOrMajor"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "instructorId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "interest"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isWorking"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "location"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "message"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mobileNumber"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organization"
},
v13 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "yearOfGraduation"
},
v14 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "collegeName",
        "variableName": "collegeName"
      },
      {
        "kind": "Variable",
        "name": "degreeType",
        "variableName": "degreeType"
      },
      {
        "kind": "Variable",
        "name": "designation",
        "variableName": "designation"
      },
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "fieldOrMajor",
        "variableName": "fieldOrMajor"
      },
      {
        "kind": "Variable",
        "name": "instructorId",
        "variableName": "instructorId"
      },
      {
        "kind": "Variable",
        "name": "interest",
        "variableName": "interest"
      },
      {
        "kind": "Variable",
        "name": "isWorking",
        "variableName": "isWorking"
      },
      {
        "kind": "Variable",
        "name": "location",
        "variableName": "location"
      },
      {
        "kind": "Variable",
        "name": "message",
        "variableName": "message"
      },
      {
        "kind": "Variable",
        "name": "mobileNumber",
        "variableName": "mobileNumber"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "organization",
        "variableName": "organization"
      },
      {
        "kind": "Variable",
        "name": "yearOfGraduation",
        "variableName": "yearOfGraduation"
      }
    ],
    "concreteType": "CreateConsultationMutation",
    "kind": "LinkedField",
    "name": "createConsultation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateConsultationMutation",
    "selections": (v14/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v11/*: any*/),
      (v10/*: any*/),
      (v7/*: any*/),
      (v13/*: any*/),
      (v6/*: any*/),
      (v9/*: any*/),
      (v4/*: any*/),
      (v12/*: any*/),
      (v8/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateConsultationMutation",
    "selections": (v14/*: any*/)
  },
  "params": {
    "cacheID": "f82de6428335b90f4bb852991a8a20d2",
    "id": null,
    "metadata": {},
    "name": "CreateConsultationMutation",
    "operationKind": "mutation",
    "text": "mutation CreateConsultationMutation(\n  $collegeName: String!\n  $degreeType: DegreeTypeEnum!\n  $email: String!\n  $instructorId: String!\n  $name: String!\n  $mobileNumber: String!\n  $isWorking: Boolean!\n  $yearOfGraduation: String!\n  $interest: String!\n  $message: String!\n  $fieldOrMajor: String!\n  $organization: String\n  $location: String\n  $designation: String\n) {\n  createConsultation(collegeName: $collegeName, degreeType: $degreeType, email: $email, instructorId: $instructorId, name: $name, mobileNumber: $mobileNumber, isWorking: $isWorking, yearOfGraduation: $yearOfGraduation, interest: $interest, message: $message, fieldOrMajor: $fieldOrMajor, organization: $organization, location: $location, designation: $designation) {\n    status\n    message\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ba77ae2fcbdffd9672be93bcc4c771e1';

module.exports = node;
