/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SentOtpMutationVariables = {|
  mobileNumber?: ?string
|};
export type SentOtpMutationResponse = {|
  +sentOtp: ?{|
    +status: ?string,
    +message: ?string,
    +otp: ?string,
  |}
|};
export type SentOtpMutation = {|
  variables: SentOtpMutationVariables,
  response: SentOtpMutationResponse,
|};
*/


/*
mutation SentOtpMutation(
  $mobileNumber: String
) {
  sentOtp(mobileNumber: $mobileNumber) {
    status
    message
    otp
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "mobileNumber"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "mobileNumber",
        "variableName": "mobileNumber"
      }
    ],
    "concreteType": "SentOtpMutation",
    "kind": "LinkedField",
    "name": "sentOtp",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "otp",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SentOtpMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SentOtpMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2db7c94925e2dfaabe885f686f32f2c6",
    "id": null,
    "metadata": {},
    "name": "SentOtpMutation",
    "operationKind": "mutation",
    "text": "mutation SentOtpMutation(\n  $mobileNumber: String\n) {\n  sentOtp(mobileNumber: $mobileNumber) {\n    status\n    message\n    otp\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3d0bf75166046d556752f12f30b111d6';

module.exports = node;
