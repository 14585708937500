import './MyCourses.scss'
import {RoundedButton} from "../../../Components/Buttons/RoundedButton";
import clock from "../../../assets/icons/ic_baseline-more-time.svg"
import note from "../../../assets/icons/carbon_task-approved.svg"
import React, {useEffect, useState} from "react";
import {fetchQuery} from "relay-runtime";
import environment from "../../../Environment";
import MyCoursesListQuery from "../../../queries/MyCoursesListQuery";
import uuid from 'react-uuid'
import ProfileNavbar from "../ProfileNavbar/ProfileNavbar";
import Header from "../../../Containers/Header/Header";
import {Footer} from "../../../Containers/Footer/Footer";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import courses_not_found from "../../../assets/icons/courses_not_found.svg"

import {connect} from "react-redux";
import ErrorMessage from "../../../Components/ErrorMessage/ErrorMessage";
import emptycart_img from "../../../assets/icons/emptyCart.svg";
import cogoToast from "cogo-toast";
import GenerateInvoiceMutation from "../../../mutations/GenerateInvoiceMutation";
import GenerateCertificateMutation from "../../../mutations/GenerateCertificateMutation";

 const MyCourses = (props) => {
    const [onGoingcourses, setOnGoingcourses] = useState([]);
    const [completedCourses, setCompletedCourses] = useState([]);

    useEffect(() => {
        fetchMyCrouseList()
    }, []);
     useEffect(() => {
         window.scrollTo(0, 0)
     }, [])
    const fetchMyCrouseList = () => {
        fetchQuery(environment, MyCoursesListQuery)
            .toPromise()
            .then(response => {
                if (response.myEnrolls !== null) {
                    let tmp_ongoing_courses = [];
                    let tmp_completed_courses = [];
                    tmp_completed_courses = response.myEnrolls.edges.filter(function (course) {
                        return course.node.status === "COMPLETED";
                    });
                    tmp_ongoing_courses = response.myEnrolls.edges.filter(function (course) {
                        return course.node.status === "NOT_STARTED" || course.node.status === "STARTED";
                    });
                    setOnGoingcourses(tmp_ongoing_courses);
                    setCompletedCourses(tmp_completed_courses);
                } else {
                    setOnGoingcourses([]);
                    setCompletedCourses([]);
                }
            }, err => {
                // console.log(err);
                setOnGoingcourses([]);
                setCompletedCourses([]);
            });
    }
    const goToPreview = (courseId) => {
        props.history.push("/course-preview/"+courseId);
    }

     const getCertificate = (id) => {
         // debugger
         if (id===""){
             cogoToast.error("Some Thing Went Wrong", {position: 'top-center'});
         }
         let variables = {
             enrollId: id
         }
         cogoToast.info("Please wait a moment", {position: 'top-center'});
         GenerateCertificateMutation(variables,function (response){
             if (response.generateCertificate.enroll.certificateUrl !== null) {
                 const fileURL = response.generateCertificate.enroll.certificateUrl
                 const pdfWindow = window.open();
                 pdfWindow.location.href = fileURL;
             } else {
                 cogoToast.info("Some Thing Went Wrong", {position: 'top-center'});
             }
         },function (err){
             cogoToast.error("Some Thing Went Wrong", {position: 'top-center'});
         })

     }


    return (
        <>
            <Header page={"mycourses"}/>
            <ProfileNavbar active_page={"My courses"}>
                {

                    onGoingcourses.length === 0 && completedCourses.length ===0 ?
                    <ErrorMessage img={courses_not_found} title={"Your Cart is empty!"} text={"Looks like you haven’t added anything to your cart yet!"} onclickhandeler={`/course-list`}/>
                    :
                        <div className={'MyCourses container'}>
                            <div className="header">
                                <p className="sub_heading">On-going courses</p>
                            </div>
                            {
                                onGoingcourses.length > 0 && onGoingcourses.map((item) => {
                                    // debugger
                                    let courseItem = item.node.course;
                                    let percentageCompleted = (courseItem.percentageCompleted);
                                    let value =  percentageCompleted.slice(0, 2);
                                    let percent = parseInt(value);
                                    return (
                                        <div className="block_break" key={uuid()}>
                                            <div className="card1 container">
                                                <div className="d-flex justify-content-between customised_container">
                                                    <div className={'container_1'}>
                                                        <p className="card_head">{courseItem.name}</p>
                                                        <p className="card_header">By  {courseItem.instructor && courseItem.instructor.user.name}</p>
                                                        <p className="card_head_part">{courseItem.percentageCompleted} completed </p>
                                                        <progress className={'Progressbar'} id="file" value={percent} max="100"/>

                                                        {
                                                            item.node.status === "NOT_STARTED" ?
                                                                <RoundedButton handleClick={()=>goToPreview(courseItem.id)}>
                                                                    Start Now
                                                                </RoundedButton>
                                                                :
                                                                <RoundedButton handleClick={()=>goToPreview(courseItem.id)} >
                                                                    Resume Now
                                                                </RoundedButton>
                                                        }

                                                    </div>
                                                    <div className="block_text">
                                                        <div className="particular_sector">
                                                            <div className="d-flex align-items-center justify-content-center time-of-courses-completed">
                                                                <img src={clock} className={'clock'} alt={'clock'}/>
                                                                <p className="hours_text my-1">{courseItem.weeklyWatchHours} Hours!</p>
                                                            </div>
                                                            <p className="hour_subtext m-0">You’ve
                                                                spent {courseItem.weeklyWatchHours} hours studying this
                                                                week!</p>
                                                        </div>
                                                        <div className="particular_sectors">
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                <img src={note} alt={'note'} className={'note1'}/>
                                                                <p className="hours_text1 my-1">{courseItem.lessonsLeftToComplete} Lessons</p>
                                                            </div>
                                                            <p className="hour_subtext m-0">You’ve
                                                                only {courseItem.lessonsLeftToComplete} more lessons left
                                                                to
                                                                complete!</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                            {
                                onGoingcourses.length === 0 &&
                                <ErrorMessage img={courses_not_found} title={"Course history is empty!"} text={"Looks like you haven’t purchased any course yet!"} onclickhandeler={`/course-list`}/>

                            }
                            <div className={'CompletedCourses'}>
                                <div className="header">
                                    <p className="sub_heading">Completed Courses</p>
                                </div>
                                {
                                    completedCourses.length > 0 && completedCourses.map((item) => {
                                        // debugger
                                        let courseItem = item.node.course;
                                        return (
                                            <div className="card1 container CompletedCourses_card " key={uuid()}>
                                                <div
                                                    className="d-flex justify-content-between customised_container container_1 mar_0">
                                                    <div>
                                                        <p className="card_head">{courseItem.name}</p>
                                                        <p className="card_header m-0">By {courseItem.instructor && courseItem.instructor.user.name}</p>
                                                    </div>

                                                        <RoundedButton handleClick={()=>getCertificate(item.node.id)}>
                                                            Download Certificate
                                                        </RoundedButton>

                                                </div>
                                            </div>
                                        );
                                    })
                                }
                                {
                                    completedCourses.length === 0 && <div className={'cusdataerror'}>
                                        Looks like you haven’t completed any course yet!
                                    </div>
                                }
                            </div>
                        </div>
                }

        </ProfileNavbar>
            <Footer/>
        </>
    );
}

export default compose(
    withRouter,
)(MyCourses);