import graphql from "babel-plugin-relay/macro";

const MyWishListQuery=graphql`

 query MyWishListQuery{
    myWishList{
        id
        name
        instructor{
            user{
                name
            }
        }
        image
        price
        discountPrice
        averageRating
        isWishlist
    }
}
`

export default MyWishListQuery;
