import "./CourseInstructorCard.scss";
import {MDBBox} from "mdbreact";

import ActiveIllustrator from "../../assets/Illustrations/Polygon-Dark.png";
import Instructor from "../../assets/images/professorpic.svg";
import Badge from "../../assets/icons/carbon_badge.svg";
import {useRef} from "react";


export const CourseInstructorCard = (props) => {
    const imageEl = useRef(null);

    const {professorDetail} = props;
    // console.log(professorDetail)
    // debugger

    // const test = professorDetail.experience

    // if (test===null){
    //     // debugger
    //     // console.log('test')
    // }

    return(
        <MDBBox className={"course-instructor-card-component"}>
            <MDBBox className={"d-flex align-items-center justify-content-center instructor-card"}>
                <MDBBox className={"instructor-image-section"}>
                    {/* <img src={ActiveIllustrator} alt={"expertbg"} className={"img-fluid instructor-bg"}/> */}
                    <img src={professorDetail.profilePic?professorDetail.profilePic:Instructor}
                         alt={"expert"} className={"img-fluid instructor-profile"}
                         ref={imageEl}
                         onError={() => {
                             imageEl.current.src = Instructor;
                         }}
                    />
                </MDBBox>
                <MDBBox className={"instructor-detail"}>
                    <p className={"title"}>Course Instructor</p>
                    <p className={"instructor-name"}> {professorDetail.user && professorDetail.user.name}</p>
                    <p className={"instructor-role"}>{professorDetail.designation} </p>
                    <p className={"instructor-institute"}>{professorDetail.qualification}</p>
                    <p className={"instructor-address"}>{professorDetail.district && professorDetail.district.name} , {professorDetail.state && professorDetail.state.name}</p>
                    <MDBBox className={"align-items-center justify-content-center large-device-experiance"}>
                        <img src={Badge} alt={"Badge"} className={"img-fluid"}/>
                        <p className={"instructor-experience mb-0"}>{professorDetail.experience !== null ? professorDetail.experience : "0" }+ Years of Working Experience</p>
                    </MDBBox>
                </MDBBox>
            </MDBBox>
            <MDBBox className={"align-items-center small-device-experiance"}>
                <img src={Badge} alt={"Badge"} className={"img-fluid"}/>
                <p className={"instructor-experience mb-0"}>{professorDetail.experience !== null ? professorDetail.experience : "0" }+ Years of Working Experience</p>
            </MDBBox>
        </MDBBox>
    );
}
