/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DegreeTypeEnum = "Masters" | "Others" | "PG" | "PhD" | "UG" | "%future added value";
export type EmployementStatusEnum = "Employed" | "Others" | "Retired" | "SelfEmployed" | "Student" | "Unemployed" | "%future added value";
export type ExperienceLevelEnum = "Junior" | "MidLevel" | "Others" | "Senior" | "%future added value";
export type UpdateBackgroundDetailsMutationVariables = {|
  collegeName?: ?string,
  degreeName?: ?string,
  degreeType?: ?DegreeTypeEnum,
  employementStatus?: ?EmployementStatusEnum,
  experienceLevel?: ?ExperienceLevelEnum,
  organizationName?: ?string,
  yearOfGraduation?: ?string,
|};
export type UpdateBackgroundDetailsMutationResponse = {|
  +updateBackgroundDetails: ?{|
    +status: ?string,
    +message: ?string,
  |}
|};
export type UpdateBackgroundDetailsMutation = {|
  variables: UpdateBackgroundDetailsMutationVariables,
  response: UpdateBackgroundDetailsMutationResponse,
|};
*/


/*
mutation UpdateBackgroundDetailsMutation(
  $collegeName: String
  $degreeName: String
  $degreeType: DegreeTypeEnum
  $employementStatus: EmployementStatusEnum
  $experienceLevel: ExperienceLevelEnum
  $organizationName: String
  $yearOfGraduation: String
) {
  updateBackgroundDetails(collegeName: $collegeName, degreeName: $degreeName, degreeType: $degreeType, employementStatus: $employementStatus, experienceLevel: $experienceLevel, organizationName: $organizationName, yearOfGraduation: $yearOfGraduation) {
    status
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "collegeName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "degreeName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "degreeType"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "employementStatus"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "experienceLevel"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "yearOfGraduation"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "collegeName",
        "variableName": "collegeName"
      },
      {
        "kind": "Variable",
        "name": "degreeName",
        "variableName": "degreeName"
      },
      {
        "kind": "Variable",
        "name": "degreeType",
        "variableName": "degreeType"
      },
      {
        "kind": "Variable",
        "name": "employementStatus",
        "variableName": "employementStatus"
      },
      {
        "kind": "Variable",
        "name": "experienceLevel",
        "variableName": "experienceLevel"
      },
      {
        "kind": "Variable",
        "name": "organizationName",
        "variableName": "organizationName"
      },
      {
        "kind": "Variable",
        "name": "yearOfGraduation",
        "variableName": "yearOfGraduation"
      }
    ],
    "concreteType": "UpdateBackgroundDetailsMutation",
    "kind": "LinkedField",
    "name": "updateBackgroundDetails",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateBackgroundDetailsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateBackgroundDetailsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b42818d50afbcd5931d80fdee00bde01",
    "id": null,
    "metadata": {},
    "name": "UpdateBackgroundDetailsMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateBackgroundDetailsMutation(\n  $collegeName: String\n  $degreeName: String\n  $degreeType: DegreeTypeEnum\n  $employementStatus: EmployementStatusEnum\n  $experienceLevel: ExperienceLevelEnum\n  $organizationName: String\n  $yearOfGraduation: String\n) {\n  updateBackgroundDetails(collegeName: $collegeName, degreeName: $degreeName, degreeType: $degreeType, employementStatus: $employementStatus, experienceLevel: $experienceLevel, organizationName: $organizationName, yearOfGraduation: $yearOfGraduation) {\n    status\n    message\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3bc3a779171fa9214b805830fca11d3d';

module.exports = node;
