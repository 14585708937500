import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'

const mutation = graphql`
    mutation ChangePasswordMutation(
    $oldPassword: String!
    $newPassword: String!
    $confirmPassword: String!
       
    ) {
        changePassword(oldPassword:$oldPassword,newPassword:$newPassword,confirmPassword:$confirmPassword){
            message
            status
        }
    }
`

export default (userData, callback,errCallback) => {
    const variables = {
        oldPassword: userData.oldPassword,
        newPassword:userData.newPassword,
        confirmPassword:userData.confirmPassword
    }

    // 5
    commitMutation(
        environment,
        {
            mutation,
            variables,
            // 6
            onCompleted: (response,err) => {

                if(response.changePassword !== null){
                    callback(response)
                } else{
                    errCallback(err[0].message);
                }

            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}
