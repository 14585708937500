/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SearchMutationVariables = {|
  searchText: string,
  Categories?: ?$ReadOnlyArray<?string>,
  Instructors?: ?$ReadOnlyArray<?string>,
  levels?: ?$ReadOnlyArray<?string>,
  rating?: ?$ReadOnlyArray<?string>,
  fromPrice?: ?string,
  toPrice?: ?string,
|};
export type SearchMutationResponse = {|
  +search: ?{|
    +status: ?string,
    +courses: ?$ReadOnlyArray<?{|
      +id: string,
      +averageRating: ?number,
      +isWishlist: ?boolean,
      +name: string,
      +price: any,
      +image: ?string,
      +discountPrice: any,
      +instructor: ?{|
        +user: {|
          +name: ?string
        |}
      |},
    |}>,
    +instructors: ?$ReadOnlyArray<?{|
      +id: string,
      +profilePic: ?string,
      +designation: ?string,
      +organization: ?string,
      +user: {|
        +id: string,
        +name: ?string,
      |},
    |}>,
    +blogs: ?$ReadOnlyArray<?{|
      +id: string,
      +title: string,
      +body: ?any,
      +featuredImage: ?string,
      +featuredMobileImage: ?string,
      +thumbnailImage: ?string,
      +updatedAt: any,
    |}>,
  |}
|};
export type SearchMutation = {|
  variables: SearchMutationVariables,
  response: SearchMutationResponse,
|};
*/


/*
mutation SearchMutation(
  $searchText: String!
  $Categories: [String]
  $Instructors: [String]
  $levels: [String]
  $rating: [String]
  $fromPrice: String
  $toPrice: String
) {
  search(searchText: $searchText, Categories: $Categories, Instructors: $Instructors, levels: $levels, rating: $rating, fromPrice: $fromPrice, toPrice: $toPrice) {
    status
    courses {
      id
      averageRating
      isWishlist
      name
      price
      image
      discountPrice
      instructor {
        user {
          name
          id
        }
        id
      }
    }
    instructors {
      id
      profilePic
      designation
      organization
      user {
        id
        name
      }
    }
    blogs {
      id
      title
      body
      featuredImage
      featuredMobileImage
      thumbnailImage
      updatedAt
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "Categories"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "Instructors"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fromPrice"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "levels"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "rating"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchText"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "toPrice"
},
v7 = [
  {
    "kind": "Variable",
    "name": "Categories",
    "variableName": "Categories"
  },
  {
    "kind": "Variable",
    "name": "Instructors",
    "variableName": "Instructors"
  },
  {
    "kind": "Variable",
    "name": "fromPrice",
    "variableName": "fromPrice"
  },
  {
    "kind": "Variable",
    "name": "levels",
    "variableName": "levels"
  },
  {
    "kind": "Variable",
    "name": "rating",
    "variableName": "rating"
  },
  {
    "kind": "Variable",
    "name": "searchText",
    "variableName": "searchText"
  },
  {
    "kind": "Variable",
    "name": "toPrice",
    "variableName": "toPrice"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "averageRating",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isWishlist",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountPrice",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "InstructorType",
  "kind": "LinkedField",
  "name": "instructors",
  "plural": true,
  "selections": [
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePic",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "designation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organization",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserType",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v9/*: any*/),
        (v12/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "BlogNode",
  "kind": "LinkedField",
  "name": "blogs",
  "plural": true,
  "selections": [
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "featuredImage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "featuredMobileImage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailImage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SearchMutation",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "SearchMutation",
        "kind": "LinkedField",
        "name": "search",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CourseType",
            "kind": "LinkedField",
            "name": "courses",
            "plural": true,
            "selections": [
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "InstructorType",
                "kind": "LinkedField",
                "name": "instructor",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserType",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v16/*: any*/),
          (v17/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "SearchMutation",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "SearchMutation",
        "kind": "LinkedField",
        "name": "search",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CourseType",
            "kind": "LinkedField",
            "name": "courses",
            "plural": true,
            "selections": [
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "InstructorType",
                "kind": "LinkedField",
                "name": "instructor",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserType",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v16/*: any*/),
          (v17/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3d6eeb02f009b342dc04eaae2f49b56b",
    "id": null,
    "metadata": {},
    "name": "SearchMutation",
    "operationKind": "mutation",
    "text": "mutation SearchMutation(\n  $searchText: String!\n  $Categories: [String]\n  $Instructors: [String]\n  $levels: [String]\n  $rating: [String]\n  $fromPrice: String\n  $toPrice: String\n) {\n  search(searchText: $searchText, Categories: $Categories, Instructors: $Instructors, levels: $levels, rating: $rating, fromPrice: $fromPrice, toPrice: $toPrice) {\n    status\n    courses {\n      id\n      averageRating\n      isWishlist\n      name\n      price\n      image\n      discountPrice\n      instructor {\n        user {\n          name\n          id\n        }\n        id\n      }\n    }\n    instructors {\n      id\n      profilePic\n      designation\n      organization\n      user {\n        id\n        name\n      }\n    }\n    blogs {\n      id\n      title\n      body\n      featuredImage\n      featuredMobileImage\n      thumbnailImage\n      updatedAt\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd135e13571c73390dc6d32e80ccc2d20';

module.exports = node;
