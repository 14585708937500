import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'

const mutation = graphql`
  mutation SentOtpMutation(
    $mobileNumber: String
  ) {
      sentOtp(mobileNumber:$mobileNumber){
        status
        message
        otp
      }
  }
`

export default (userData, callback,errCallback) => {
    const variables = {
        mobileNumber: userData.mobileNumber,
    }

    // 5
    commitMutation(
        environment,
        {
            mutation,
            variables,
            // 6
            onCompleted: (response,err) => {

                if(response.sentOtp !== null){
                    callback(response)
                } else{
                    errCallback(err[0].message);
                }

            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}
