
import "./Loader.scss";

export const Loader = (props) => {
    const getHeight = () => {
        const headerHeight =
            document.getElementById("header-component") &&
            document.getElementById("header-component").offsetHeight;
        const footerHeight =
            document.getElementById("footer-component") &&
            document.getElementById("footer-component").offsetHeight;
        return window.innerHeight - headerHeight - footerHeight;
    };

    return (
        <div className={`loader ${props.full === true?'fullscreen':''}`} style={{ height: props.full === false?getHeight():"100%" }}>
            <div className="loader__items">
                <span />
                <span />
                <span />
            </div>
        </div>
    );
};

export default Loader;
