import graphql from "babel-plugin-relay/macro";
const InstructorRemoveProfilePictureQuery=graphql`

    query InstructorRemoveProfilePictureQuery{
        instructorRemoveProfilePicture{
       id
        }
    }

`
export default InstructorRemoveProfilePictureQuery