/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LessonType = "PDF" | "VIDEO" | "%future added value";
export type LessonCustomInputType = {|
  lessonId: string,
  name: string,
  isActive?: ?boolean,
  order?: ?number,
|};
export type UpdateLessonMutationVariables = {|
  data?: ?$ReadOnlyArray<?LessonCustomInputType>
|};
export type UpdateLessonMutationResponse = {|
  +updateLesson: ?{|
    +lessons: ?$ReadOnlyArray<?{|
      +id: string,
      +name: string,
      +video: ?string,
      +type: LessonType,
      +pdf: ?string,
      +currentTime: ?string,
      +isWatched: ?boolean,
      +isWatching: ?boolean,
      +transcodedVideo: ?string,
      +isTranscoded: boolean,
      +duration: ?string,
      +transcript: ?any,
      +isActive: boolean,
      +order: number,
    |}>,
    +message: ?string,
    +status: ?string,
  |}
|};
export type UpdateLessonMutation = {|
  variables: UpdateLessonMutationVariables,
  response: UpdateLessonMutationResponse,
|};
*/


/*
mutation UpdateLessonMutation(
  $data: [LessonCustomInputType]
) {
  updateLesson(data: $data) {
    lessons {
      id
      name
      video
      type
      pdf
      currentTime
      isWatched
      isWatching
      transcodedVideo
      isTranscoded
      duration
      transcript
      isActive
      order
    }
    message
    status
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "data"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "UpdateLesson",
    "kind": "LinkedField",
    "name": "updateLesson",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LessonNode",
        "kind": "LinkedField",
        "name": "lessons",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "video",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pdf",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isWatched",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isWatching",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "transcodedVideo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isTranscoded",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "duration",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "transcript",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isActive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "order",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateLessonMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateLessonMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "82e572871fe87873b4802143e9fc197d",
    "id": null,
    "metadata": {},
    "name": "UpdateLessonMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateLessonMutation(\n  $data: [LessonCustomInputType]\n) {\n  updateLesson(data: $data) {\n    lessons {\n      id\n      name\n      video\n      type\n      pdf\n      currentTime\n      isWatched\n      isWatching\n      transcodedVideo\n      isTranscoded\n      duration\n      transcript\n      isActive\n      order\n    }\n    message\n    status\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '06ac30ce2f7348b3a80567a6aa24b9b6';

module.exports = node;
