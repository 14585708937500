import {
    FETCH_WISHLIST_BEGIN,
    FETCH_WISHLIST_SUCCESS,
    FETCH_WISHLIST_FAILURE,
    CLEAR_WISHLIST
} from '../Actions/wishListAction';



const initialState = {
    wishlist_data:[],
    loading: false,
    error: null
}


const WishListReducer =  (state = initialState,action) => {
    switch (action.type) {

        case FETCH_WISHLIST_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_WISHLIST_SUCCESS:
            // All done: set loading "false".
            // Also, replace the items with the ones from the server
            return {
                ...state,
                loading: false,
                wishlist_data: action.payload.courses
            };
        case FETCH_WISHLIST_FAILURE:
            // The request failed. It's done. So set loading to "false".
            // Save the error, so we can display it somewhere.
            // Since it failed, we don't have items to display anymore, so set `items` empty.
            //
            // This is all up to you and your app though:
            // maybe you want to keep the items around!
            // Do whatever seems right for your use case.
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                wishlist_data: []
            };
        case CLEAR_WISHLIST:
            return {
                wishlist_data:[],
                loading: false,
                error: null
            };
        default:
            return state
    }

}

export default WishListReducer;
