import graphql from "babel-plugin-relay/macro";

const RemoveFromWishListQuery=graphql`

 query RemoveFromWishListQuery($courseId:String){
      removeFromWishList(courseId:$courseId){
        id
        name
        instructor{
            user{
                name
            }
        }
        image
        price
        discountPrice
        averageRating
        isWishlist
      }
}



`

export default RemoveFromWishListQuery;
