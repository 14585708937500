import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {connect} from "react-redux";

const PublicRoute = ({component: Component, restricted, ...rest}) => {
    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        <Route {...rest} render={props => (
            props.logged_in && restricted ?
                <Redirect to="/dashboard" />
                : <Component {...props} />
        )} />
    );
};

const mapStateToProps = state => ({
    user_details:state.UserReducer.user_details,
    logged_in:state.UserReducer.logged_in,
});

export default connect(mapStateToProps)(PublicRoute);
