import {RoundedButton} from "../../../Components/Buttons/RoundedButton";
import "./UpComingConsultations.scss"
import bell from "../../../assets/images/bell.png"
import React, {useEffect, useState} from "react";
import useMediaQuery from "../../../util/useMediaQuery";
import {fetchQuery} from "relay-runtime";
import environment from "../../../Environment";
import MyConsultationQuery from "../../../queries/MyConsultationQuery";
import dateFormat from "dateformat";
import uuid from 'react-uuid'
import ProfileNavbar from "../ProfileNavbar/ProfileNavbar";
import Header from "../../../Containers/Header/Header";
import {Footer} from "../../../Containers/Footer/Footer";


export const UpComingConsultations = () => {
    const isDesktop = useMediaQuery("(min-width: 770px)");

    const [upcommingConsultations, setUpcommingConsultations] = useState([]);
    const [pendingConsultations, setPendingConsultations] = useState([]);
    const [completedConsultations, setCompletedConsultations] = useState([]);

    useEffect(() => {
        consultationList();
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const consultationList = () => {
        fetchQuery(environment, MyConsultationQuery)
            .toPromise()
            .then(response => {
                if (response.myConsultations !== null && Object.keys(response.myConsultations).length > 0) {
                    let tmp_upcomming_consultations = [];
                    let tmp_pending_consultations = [];
                    let tmp_completed_consultations = [];
                    let edges = response.myConsultations.edges;
                    tmp_upcomming_consultations = edges.filter(function (consultation) {
                        return consultation.node.status === "APPROVED";
                    });
                    tmp_pending_consultations = edges.filter(function (consultation) {
                        return consultation.node.status === "PENDING";
                    });
                    tmp_completed_consultations = edges.filter(function (consultation) {
                        return consultation.node.status === "COMPLETED";
                    });
                    setUpcommingConsultations(tmp_upcomming_consultations);
                    setPendingConsultations(tmp_pending_consultations);
                    setCompletedConsultations(tmp_completed_consultations);
                } else {
                    setUpcommingConsultations([]);
                    setPendingConsultations([]);
                    setCompletedConsultations([]);
                }
            }, err => {
                // console.log(err);
                setUpcommingConsultations([]);
                setPendingConsultations([]);
                setCompletedConsultations([]);
            });
    }

    return (
        <>
            <Header page={"consultations"}/>
            <ProfileNavbar active_page={"Consultations"}>
            <div className={'UpComingConsultations'}>
                <div className="cus_container">
                    <div className="header">
                        <p className="sub_heading">Up-Coming Consultations</p>
                    </div>
                    {
                        upcommingConsultations.length > 0 ?(
                            <>
                                {
                                    upcommingConsultations.map((item) => {
                                        let consultationItem = item.node;
                                        return (
                                            <div className="block_break" key={uuid()}>
                                                <div className="card w-100 px-0 container">
                                                    <div className="d-flex justify-content-between breact_tag">
                                                        <div className={'text_header'}>
                                                            <p className="card_head">{consultationItem.interest}</p>
                                                            <p className="card_header">With  {consultationItem.name}</p>
                                                            <p className="card_head_part">On {dateFormat(consultationItem.consultationDate, 'dd,"th" mmmm yyyy,"at" h:MM:ss TT')}  </p>
                                                        </div>
                                                        <div className="block_text">
                                                            {
                                                                isDesktop ?
                                                                    <>
                                                                        <div className="button">
                                                                            <div className="d-flex text-align-center">
                                                                                <RoundedButton>
                                                                                    <img src={bell} className={'bell'}
                                                                                         alt={'bell'}/>
                                                                                    Remind me
                                                                                </RoundedButton>
                                                                            </div>
                                                                        </div>
                                                                        <div className="button btn_cancle">
                                                                            <RoundedButton>
                                                                                Cancel the meet
                                                                            </RoundedButton>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className="button btn_cancle">
                                                                            <RoundedButton>
                                                                                Cancel
                                                                            </RoundedButton>
                                                                        </div>
                                                                        <div className="button">
                                                                            <div className="d-flex text-align-center">
                                                                                <RoundedButton>
                                                                                    <img src={bell} className={'bell'}
                                                                                         alt={'bell'}/>
                                                                                    Remind me
                                                                                </RoundedButton>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </>
                        ):(
                            <div className={'cusdataerror'}>NO data found</div>
                        ) 
                    }
                    <div className="header1">
                        <p className="sub_heading">Pending Consultations</p>
                    </div>
                    <div className="row pending_main">
                        {
                            pendingConsultations.length > 0 ? (
                                <>
                                    {
                                        pendingConsultations.map((item, index) => {
                                            let consultationItem = item.node;
                                            return (
                                                <div className="col-xl-6 col-lg-6 col-md-6 " key={uuid()}>
                                                    <div className="card  pending_container">
                                                        <p className="card_head">{consultationItem.interest}</p>
                                                        <p className="card_header">With  {consultationItem.name}</p>
                                                        <div className="button sector_page">
                                                            <div className="d-flex text-align-center">
                                                                <RoundedButton>
                                                                    <img src={bell} className={'bell'} alt={'bell'}/>
                                                                    Remind me
                                                                </RoundedButton>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </>
                            ) : (
                                <div className={'cusdataerror w-100'}>NO data found</div>
                            ) 
                        }
                    </div>
                    <div className="header3">
                        <p className="sub_heading m-0">Completed Consultations</p>
                    </div>
                    <div className="row">
                        {
                            completedConsultations.length > 0 ? (
                                <>
                                    {
                                        completedConsultations.map((item, index) => {
                                            let consultationItem = item.node;
                                                return (
                                                    <div className="col-xl-6 col-lg-6 col-md-6 " key={uuid()}>
                                                        <div className="card pending_container1">
                                                            <p className="card_head">{consultationItem.interest}</p>
                                                            <p className="card_header">With  {consultationItem.name}</p>
                                                            <p className="card_head_part m-0">
                                                                On {dateFormat(consultationItem.consultationDate, 'dd,"th" mmmm yyyy,"at" h:MM:ss TT')}
                                                            </p>
                                                        </div>
                                                    </div>
                                                );
                                        })
                                    }
                                </>
                            ) : (
                                <div className={'cusdataerror w-100'}>NO data found</div>
                            ) 
                        }
                    </div>
                </div>
            </div>
        </ProfileNavbar>
            <Footer/>
        </>
    );
}
