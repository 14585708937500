/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LessonType = "PDF" | "VIDEO" | "%future added value";
export type CreateModuleMutationVariables = {|
  courseId: string,
  isActive: boolean,
  name: string,
  order: number,
|};
export type CreateModuleMutationResponse = {|
  +addModuleForCourse: ?{|
    +modules: ?$ReadOnlyArray<?{|
      +id: string,
      +name: string,
      +lessonCount: ?number,
      +isCompleted: ?boolean,
      +totalDuration: ?string,
      +order: number,
      +description: ?any,
      +isActive: boolean,
      +lessons: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: string,
            +video: ?string,
            +type: LessonType,
            +currentTime: ?string,
            +isWatched: ?boolean,
            +isWatching: ?boolean,
            +transcodedVideo: ?string,
            +isTranscoded: boolean,
            +duration: ?string,
            +transcript: ?any,
            +isActive: boolean,
            +order: number,
          |}
        |}>
      |},
    |}>,
    +message: ?string,
    +status: ?string,
  |}
|};
export type CreateModuleMutation = {|
  variables: CreateModuleMutationVariables,
  response: CreateModuleMutationResponse,
|};
*/


/*
mutation CreateModuleMutation(
  $courseId: String!
  $isActive: Boolean!
  $name: String!
  $order: Int!
) {
  addModuleForCourse(courseId: $courseId, isActive: $isActive, name: $name, order: $order) {
    modules {
      id
      name
      lessonCount
      isCompleted
      totalDuration
      order
      description
      isActive
      lessons {
        edges {
          node {
            id
            name
            video
            type
            currentTime
            isWatched
            isWatching
            transcodedVideo
            isTranscoded
            duration
            transcript
            isActive
            order
          }
        }
      }
    }
    message
    status
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "courseId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isActive"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isActive",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "courseId",
        "variableName": "courseId"
      },
      {
        "kind": "Variable",
        "name": "isActive",
        "variableName": "isActive"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "order",
        "variableName": "order"
      }
    ],
    "concreteType": "CreateModuleForCourse",
    "kind": "LinkedField",
    "name": "addModuleForCourse",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ModuleType",
        "kind": "LinkedField",
        "name": "modules",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lessonCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isCompleted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalDuration",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "LessonNodeConnection",
            "kind": "LinkedField",
            "name": "lessons",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LessonNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LessonNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "video",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "currentTime",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isWatched",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isWatching",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "transcodedVideo",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isTranscoded",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "duration",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "transcript",
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateModuleMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateModuleMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "18cff7416f11634d463b1dca49f70e37",
    "id": null,
    "metadata": {},
    "name": "CreateModuleMutation",
    "operationKind": "mutation",
    "text": "mutation CreateModuleMutation(\n  $courseId: String!\n  $isActive: Boolean!\n  $name: String!\n  $order: Int!\n) {\n  addModuleForCourse(courseId: $courseId, isActive: $isActive, name: $name, order: $order) {\n    modules {\n      id\n      name\n      lessonCount\n      isCompleted\n      totalDuration\n      order\n      description\n      isActive\n      lessons {\n        edges {\n          node {\n            id\n            name\n            video\n            type\n            currentTime\n            isWatched\n            isWatching\n            transcodedVideo\n            isTranscoded\n            duration\n            transcript\n            isActive\n            order\n          }\n        }\n      }\n    }\n    message\n    status\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bf460ed58d3ca762a766c7cbcf560f96';

module.exports = node;
