import "./CustomChart.scss";
import {MDBBox} from "mdbreact";
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from "recharts";
import {useEffect, useState} from "react";

const data = [
    {
        name: "Page A",
        uv: 4000,
        pv: 2400,
        amt: 2400
    },
    {
        name: "Page B",
        uv: 3000,
        pv: 1398,
        amt: 2210
    },
    {
        name: "Page C",
        uv: 2000,
        pv: 9800,
        amt: 2290
    },
    {
        name: "Page D",
        uv: 2780,
        pv: 3908,
        amt: 2000
    },
    {
        name: "Page E",
        uv: 1890,
        pv: 4800,
        amt: 2181
    },
    {
        name: "Page F",
        uv: 2390,
        pv: 3800,
        amt: 2500
    },
    {
        name: "Page G",
        uv: 3490,
        pv: 4300,
        amt: 2100
    }
];

const CustomChart = (props) => {

    const {strokeColor,gradient,values}= props;
    const [graphdata,setgraphdata]=useState([])

    useEffect(()=>{
        if (values!==undefined){
            values.map((item,index)=>{
                let data1={uv:item.count,pv:item.createdAt_Date}
                // setgraphdata(    {
                //         name: "Page A",
                //         uv: 4000,
                //         pv: 2400,
                //         amt: 2400
                //     },
                //     {
                //         name: "Page B",
                //         uv: 3000,
                //         pv: 1398,
                //         amt: 2210
                //     })
                let temp_data =[]

                // setgraphdata(prevState => data1)
                setgraphdata(oldArray => [...oldArray,data1] );


            })
        }
    },[values])





    return(
        <MDBBox className={"custom-chart-component"}>
            <ResponsiveContainer width="100%" height={130}>

            <AreaChart
                data={graphdata}
                // margin={{
                //     top: 10,
                //     right: 30,
                //     left: 0,
                //     bottom: 0
                // }}
            >
                <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="0" limitingConeAngle={"180deg"}>
                        {
                            gradient.map((item,index)=>{
                                return <stop offset={item.offset}
                                             stopColor={item.color}
                                             stopOpacity={item.opacity}
                                             key={index}
                                       />
                            })
                        }
                    </linearGradient>
                </defs>
                <Tooltip />
                <Area type="monotone" dataKey="uv" stroke={strokeColor} fill="url(#colorUv)" />
            </AreaChart>
            </ResponsiveContainer>
        </MDBBox>
    );
}

export default CustomChart;
