import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'

const mutation = graphql`
    mutation UpdateEducationHistoryMutation(
        $data:JSONString!
    )
    {
        updateEducationHistory(
            data:$data
        ){
            status
            message
        }
    }
`

export default (userData, callback,errCallback) => {
    // debugger
    const variables = {
        data:userData.data
    }
    commitMutation(
        environment,
        {

            mutation,
            variables,
            onCompleted: (response,err) => {

                if(response.UpdateEducationHistoryMutation !== null){
                    callback(response)
                } else{
                    errCallback(err[0].message);
                }
            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}
