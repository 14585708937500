import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'

const mutation = graphql`
  mutation UpdateModuleMutation(
    $description: String
    $id: String!
    $isActive: Boolean
    $name: String
    $order: Int
  ) {
     updateModuleForCourse(
        description: $description
        id: $id
        isActive: $isActive
        name: $name
        order: $order
     ){    
        modules{
            id
            name
            lessonCount
            isCompleted
            totalDuration
            order
            description
            isActive
            lessons{
                 edges{
                    node{
                        id
                        name
                        video
                        type
                        currentTime
                        isWatched
                        isWatching
                        transcodedVideo
                        isTranscoded
                        duration
                        transcript
                        isActive
                        order
                    }
                 }
            }
        }    
        message
        status
     }
  }
`

export default (data, callback,errCallback) => {
    const variables = {
        description: data.description,
        id: data.id,
        isActive: data.isActive,
        name: data.name,
        order: data.order
    }
    // 5
    commitMutation(
        environment,
        {
            mutation,
            variables,
            // 6
            onCompleted: (response,err) => {

                if(response.updateModuleForCourse !== null){
                    callback(response)
                } else{
                    errCallback(err[0].message);
                }

            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}
