import graphql from "babel-plugin-relay/macro";

const MyCartQuery=graphql`

 query MyCartQuery{
    myCart{
        id
        total
        discount
        discountName
        voucherCode
        checkoutitems{
            edges{
                node{
                    id
                    course{
                        id
                        name
                        instructor{
                            user{
                                name
                            }
                        }
                        image
                        price
                        discountPrice
                        averageRating
                        isWishlist
                    }
                }
             }
        }
    }
}



`

export default MyCartQuery
